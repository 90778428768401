import { Reservation } from '@/features/create-update-reservation'
import { Button, Icon, VaulHeader } from '@/shared/ui'

import css from './ReservationProcessHeader.module.css'

type ReservationProcessHeaderProps = {
  backHandler?: () => void
  title: string
}

export default function ReservationProcessHeader({
  backHandler,
  title
}: ReservationProcessHeaderProps) {
  return (
    <VaulHeader className={css.header}>
      {backHandler && (
        <Button size="icon" className={css.header__back} onClick={backHandler}>
          <Icon name="arrowLeft" />
        </Button>
      )}
      <span className={css.header__title}>{title}</span>
      <Reservation.MobileCloseCreate />
    </VaulHeader>
  )
}
