import { useEffect, useRef, useState } from 'react'

import { cx } from 'class-variance-authority'
import {
  type KeenSliderOptions,
  type TrackDetails,
  useKeenSlider
} from 'keen-slider/react'

import css from './TimePickerList.module.css'

export default function TimePickerList({
  selectedValue,
  count,
  selectValue,
  isFillLeftZero
}: {
  selectedValue?: number
  count: number
  selectValue: (value: number) => void
  isFillLeftZero?: boolean
}) {
  const wheelSize = 20
  const slideDegree = 360 / wheelSize
  const slidesPerView = 9
  const size = useRef(0)
  const options = useRef<KeenSliderOptions>({
    slides: {
      number: count,
      origin: 'center',
      perView: slidesPerView
    },
    vertical: true,
    initial: selectedValue ?? 0,
    loop: true,
    dragSpeed: (val) => {
      const height = size.current
      return (
        val *
        (height /
          ((height / 2) * Math.tan(slideDegree * (Math.PI / 180))) /
          slidesPerView)
      )
    },
    created: (s) => {
      size.current = s.size
    },
    updated: (s) => {
      size.current = s.size
    },
    detailsChanged: (s) => {
      setSliderState(s.track.details)
    },
    rubberband: false,
    mode: 'free-snap'
  })
  const [radius, setRadius] = useState(0)
  const [sliderState, setSliderState] = useState<TrackDetails | null>(null)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(options.current)

  const slideValues = () => {
    if (!sliderState) return []
    const offset = 1 / 2 - 1 / slidesPerView / 2

    const values = []
    for (let i = 0; i < count; i++) {
      const distance = sliderState
        ? (sliderState.slides[i].distance - offset) * slidesPerView
        : 0
      const rotate =
        Math.abs(distance) > wheelSize / 2
          ? 180
          : distance * (360 / wheelSize) * -1
      const style = {
        transform: `rotateX(${rotate}deg) translateZ(${radius}px)`
      }
      selectValue(sliderState.rel)
      values.push({ style, value: i, activeIndex: sliderState.rel })
    }
    return values
  }

  useEffect(() => {
    if (slider.current) setRadius(slider.current.size / 2)
  }, [slider])

  return (
    <div className={cx(css.wheel, css.wheel__perspective_left)} ref={sliderRef}>
      <div
        className={css.wheel__shadow_top}
        style={{
          transform: `translateZ(${radius}px)`
        }}
      />
      <div className={css.wheel__inner}>
        <div className={css.wheel__slides}>
          {slideValues().map(({ style, value, activeIndex }, idx) => (
            <div
              className={cx(css.wheel__slide, {
                [css.wheel__slide_active]: activeIndex === idx
              })}
              style={style}
              key={idx}
            >
              <span>
                {isFillLeftZero ? String(value).padStart(2, '0') : value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div
        className={css.wheel__shadow_bottom}
        style={{
          transform: `translateZ(${radius}px)`
        }}
      />
    </div>
  )
}
