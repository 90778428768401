import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  type KeyboardEvent,
  type MouseEventHandler,
  useRef,
  useState
} from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { useClickOutside } from '@/shared/hooks'

import { Button } from '../Button'
import { Icon } from '../Icon'

import css from './SearchInput.module.css'

const searchStyle = cva(css.search, {
  variants: {
    variant: {
      default: css.search_default,
      primary: css.search_primary
    },
    isCancelButton: {
      true: css.search_open,
      false: ''
    }
  },
  defaultVariants: {
    variant: 'default',
    isCancelButton: false
  }
})

const searchInputStyle = cva(css.search__input, {
  variants: {
    variant: {
      default: css.search__input_default,
      primary: css.search__input_primary
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type SearchInputProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> &
  VariantProps<typeof searchStyle> & {
    onChange?: (value: string) => void
    renderFilters?: () => JSX.Element
  }

export default function SearchInput({
  placeholder = 'Поиск',
  value,
  onChange,
  variant,
  renderFilters,
  isCancelButton = false,
  className,
  ...props
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [innerValue, setInnerValue] = useState(value)

  const searchRef = useClickOutside(() => {
    inputRef.current?.blur()
    setIsFocused(false)
  })

  const innerOnChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(e.target.value)
    onChange?.(e.target.value)
  }

  const clearInputValueHandler = () => {
    setInnerValue('')
    onChange?.('')
    inputRef.current?.focus()
  }

  const preventBlurClearButton: MouseEventHandler = (e) => {
    e.preventDefault()
  }

  const cancelInputHandler = () => {
    setInnerValue('')
    onChange?.('')
    setIsFocused(false)
  }

  const focusHandler = () => {
    setIsFocused(true)
  }

  const blurHandler = () => setIsFocused(false)

  const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsFocused(false)
      inputRef.current?.blur()
    }
  }

  const hasSomeValue = innerValue && typeof innerValue === 'string'

  const showCancelButton = Boolean(
    (isFocused || hasSomeValue) && isCancelButton
  )

  return (
    <div
      ref={searchRef}
      className={searchStyle({
        variant,
        isCancelButton: showCancelButton || !!renderFilters
      })}
    >
      <div className={css.search__block}>
        <Icon name="search" className={css.search__icon} />
        <input
          ref={inputRef}
          className={searchInputStyle({ variant, className })}
          placeholder={placeholder}
          type="text"
          value={innerValue}
          onKeyDown={keyDownHandler}
          onFocus={focusHandler}
          onChange={innerOnChangeHandler}
          onBlur={blurHandler}
          {...props}
        />
        {hasSomeValue && (
          <Button
            type="button"
            variant="transparent"
            className={css.search__close}
            onClick={clearInputValueHandler}
            onMouseDown={preventBlurClearButton}
          >
            <Icon name="x" />
          </Button>
        )}
      </div>
      {showCancelButton ? (
        <Button
          type="button"
          size="sm"
          variant="tertiary"
          onClick={cancelInputHandler}
          className={css.search__cancel}
        >
          Отмена
        </Button>
      ) : (
        renderFilters?.()
      )}
    </div>
  )
}
