import { utcToZonedTime } from 'date-fns-tz'

import { getTz } from '@/shared/api'

export const nowWithTimezone = () => {
  const tz = getTz()

  const currentTime = new Date()
  const currentUTCTime = currentTime.toISOString()

  return tz ? utcToZonedTime(currentUTCTime, tz) : new Date()
}
