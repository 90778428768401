/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as React from 'react'

import * as TabsUI from '@radix-ui/react-tabs'
import { cva, type VariantProps } from 'class-variance-authority'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import css from './Tabs.module.css'

const TabContent = TabsUI.Content

type TabsProviderProps = React.ComponentPropsWithoutRef<typeof TabsUI.Root> & {
  isSearchParams?: boolean
}

type TabsProviderRef = React.ElementRef<typeof TabsUI.Root>

const TabsProvider = React.forwardRef<TabsProviderRef, TabsProviderProps>(
  (restProps, ref) => {
    const {
      className,
      isSearchParams = false,
      defaultValue,
      value,
      onValueChange,
      ...props
    } = restProps
    const [searchParams] = useSearchParams()
    const defaultTab = searchParams.get('tab')

    const [tab, setTab] = React.useState(defaultValue || defaultTab || value)
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const createQueryString = React.useCallback(
      (name: string, value: string) => {
        const params = new URLSearchParams(searchParams)
        params.set(name, value)

        return params.toString()
      },
      [searchParams]
    )

    React.useEffect(() => {
      if (!isSearchParams) return
      const defaultTab = searchParams.get('tab')
      if (defaultTab) onValueChange?.(defaultTab)
    }, [])

    React.useEffect(() => {
      if (!isSearchParams) return

      const tab = searchParams.get('tab')
      if (tab) setTab(tab)
    }, [isSearchParams, searchParams])

    React.useEffect(() => {
      if (!isSearchParams || !tab) return

      navigate(pathname + '?' + createQueryString('tab', tab))
    }, [navigate, pathname, isSearchParams, createQueryString, tab])

    const tabChangeHandler = (value: string) => {
      onValueChange?.(value)
      setTab(value)
    }

    return (
      <TabsUI.Root
        ref={ref}
        className={className}
        value={tab}
        onValueChange={tabChangeHandler}
        {...props}
      />
    )
  }
)

TabsProvider.displayName = 'TabsProvider'

const listStyle = cva(css.list, {
  variants: {
    variant: {
      default: css.list_default,
      secondary: css.list_secondary,
      tertiary: css.list_tertiary,
      borderless: css.list_borderless
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type TabsGroupRef = React.ElementRef<typeof TabsUI.List>
type TabsGroupProps = React.ComponentPropsWithoutRef<typeof TabsUI.List> &
  VariantProps<typeof listStyle>

const TabsGroup = React.forwardRef<TabsGroupRef, TabsGroupProps>(
  ({ className, variant, ...props }, ref) => (
    <TabsUI.List
      ref={ref}
      className={listStyle({ variant, className })}
      {...props}
    />
  )
)

TabsGroup.displayName = 'TabsGroup'

const triggerStyle = cva(css.trigger, {
  variants: {
    variant: {
      default: css.trigger_default,
      primary: css.trigger_primary,
      secondary: css.trigger_secondary,
      tertiary: css.trigger_tertiary,
      transparent: css.trigger_transparent
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type TabRef = React.ElementRef<typeof TabsUI.Trigger>
type TabProps = React.ComponentPropsWithoutRef<typeof TabsUI.Trigger> &
  VariantProps<typeof triggerStyle>

const Tab = React.forwardRef<TabRef, TabProps>(
  ({ className, children, variant, ...props }, ref) => (
    <TabsUI.Trigger
      ref={ref}
      className={triggerStyle({ variant, className })}
      {...props}
    >
      <div className={css.trigger__content}>{children}</div>
    </TabsUI.Trigger>
  )
)

Tab.displayName = 'Tab'

export { TabsProvider, TabContent, TabsGroup, Tab }
