import { useState } from 'react'

import { cx } from 'class-variance-authority'

import { selectTimelineSort, setSortType } from '@/entities/timeline'
import { TimelineOrderingEnum } from '@/entities/timeline/lib/const'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './TimelineSort.module.css'

export default function TimelineSort() {
  const [open, setOpen] = useState(false)
  const sortType = useAppSelector(selectTimelineSort)
  const dispatch = useAppDispatch()

  const changeSortType = (type: (typeof sortType)[keyof typeof sortType]) => {
    switch (true) {
      case type.startsWith('default'):
        return type.slice(8)
      case type.startsWith('-'):
        return `default_${type.slice(1)}`
      default:
        return `-${type}`
    }
  }

  const setOrderingTables = () => {
    dispatch(
      setSortType({
        tables: changeSortType(sortType.tables) as TimelineOrderingTables,
        date: TimelineOrderingEnum.StartDateDefault,
        booked: TimelineOrderingEnum.BookedDefault
      })
    )
  }

  const setOrderingStarDate = () => {
    dispatch(
      setSortType({
        date: changeSortType(sortType.date) as TimelineOrderingStartDate,
        tables: TimelineOrderingEnum.TablesDefault,
        booked: TimelineOrderingEnum.BookedDefault
      })
    )
  }

  const setOrderingBooked = () => {
    dispatch(
      setSortType({
        tables: TimelineOrderingEnum.TablesDefault,
        date: TimelineOrderingEnum.StartDateDefault,
        booked: changeSortType(sortType.booked) as TimelineOrderingBooked
      })
    )
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>
        <Button
          size="icon"
          className={cx(css.trigger, {
            [css.trigger_selected]: Object.values(sortType).some(
              (value) => !value.startsWith('default')
            )
          })}
          variant={open ? 'secondary' : 'tertiary'}
        >
          <Icon name="filter" size={20} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={css.popover}
        sideOffset={8}
        align="center"
        variant="glass"
      >
        <Button
          className={cx(css.button, {
            [css.button_selected]: !sortType.tables.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={setOrderingTables}
        >
          Номер стола
          {sortType.tables && (
            <div className={css.button__subtitle}>
              {!sortType.tables.startsWith('-') &&
                !sortType.tables.startsWith('default') && (
                  <>
                    1-&gt;99
                    <Icon name="chevronDownDouble" />
                  </>
                )}
              {sortType.tables.startsWith('-') && (
                <>
                  99-&gt;1
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
        <Button
          className={cx(css.button, {
            [css.button_selected]: !sortType.date.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={setOrderingStarDate}
        >
          Время и дата
          {sortType.date && (
            <div className={css.button__subtitle}>
              {!sortType.date.startsWith('-') &&
                !sortType.date.startsWith('default') && (
                  <>
                    00:00-&gt;23:59
                    <Icon name="chevronDownDouble" />
                  </>
                )}
              {sortType.date.startsWith('-') && (
                <>
                  23:59-&gt;00:00
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
        <Button
          className={cx(css.button, {
            [css.button_selected]: !sortType.booked.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={setOrderingBooked}
        >
          Загрузка
          {sortType.booked && (
            <div className={css.button__subtitle}>
              {!sortType.booked.startsWith('-') &&
                !sortType.booked.startsWith('default') && (
                  <>
                    1-&gt;99
                    <Icon name="chevronDownDouble" />
                  </>
                )}
              {sortType.booked.startsWith('-') && (
                <>
                  99-&gt;1
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
      </PopoverContent>
    </Popover>
  )
}
