import { isBefore } from 'date-fns'

export const isBeforeWithoutSec = (
  date: Date,
  dateToCompare: Date
): boolean => {
  const cleanedDate = date.setSeconds(0, 0)
  const cleanedDateToCompare = dateToCompare.setSeconds(0, 0)
  return isBefore(cleanedDate, cleanedDateToCompare)
}
