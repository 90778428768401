import { type ChangeEventHandler, useEffect, useState } from 'react'

import { useUpdateReservationMutation } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { useClickOutside } from '@/shared/hooks'
import { useAppSelector } from '@/shared/model'
import { Button, Icon, Input } from '@/shared/ui'

import css from './EditComment.module.css'

export function EditComment({ comment, id }: EditCommentProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [newComment, setNewComment] = useState(comment)
  const addressId = useAppSelector(selectSelectedAddressId)

  const editFormRef = useClickOutside(() => setIsOpen(false))

  const [update, { isLoading, isSuccess }] = useUpdateReservationMutation()

  const changeCommentHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNewComment(e.target.value)
  }

  const closeEditHandler = () => {
    setIsOpen(false)
    setNewComment(comment)
  }

  const updateHandler = () => {
    if (!addressId) return
    update({
      addressId,
      reservationId: id,
      body: {
        comment: newComment
      }
    })
  }

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false)
    }
  }, [isSuccess])

  return (
    <div className={css.root}>
      <div className={css.container}>
        <span className={css.comment} title={comment}>
          {comment}
        </span>
        <Button
          onClick={() => setIsOpen(true)}
          variant="transparent"
          className={css.button}
        >
          <Icon name="pencil" />
        </Button>
      </div>
      {isOpen && (
        <div className={css.form} ref={editFormRef}>
          <Input
            className={css.form__input}
            value={newComment}
            onChange={changeCommentHandler}
          />
          <div className={css.form__actions}>
            <Button
              onClick={updateHandler}
              variant="primary"
              className={css.form_button}
              isLoading={isLoading}
            >
              <Icon name="check" />
            </Button>
            <Button
              onClick={closeEditHandler}
              variant="tertiary"
              className={css.form_button}
            >
              <Icon name="x" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
