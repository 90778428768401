import {
  setWaitingListRecord,
  setWaitingListStep,
  WaitingListSteps
} from '@/entities/waiting-list'
import { useIsDevice } from '@/shared/hooks'
import { useAppDispatch } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

type SitGuestButtonProps = {
  record: WaitingListRecord
}

export function SitGuestButton({ record }: SitGuestButtonProps) {
  const dispatch = useAppDispatch()
  const { isSmallTablet } = useIsDevice()

  const sitGuestHandler = () => {
    dispatch(setWaitingListRecord(record))
    dispatch(setWaitingListStep(WaitingListSteps.three))
  }

  return (
    <Button size="xs" variant="primary" onClick={sitGuestHandler}>
      {!isSmallTablet && 'Выбрать столик'}
      <Icon name="restaurant" />
    </Button>
  )
}
