import { memo } from 'react'

import { cx } from 'class-variance-authority'
import { format, isSameDay } from 'date-fns'

import { config } from '@/app/config'
import {
  selectCurDate,
  selectHours,
  selectMinutesLinePosition
} from '@/entities/timeline'
import { nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'

import { Hour } from './Hour'

import css from './HoursInterval.module.css'
const { GROUP_HOUR_COEFFICIENT } = config

export type HoursIntervalProps = {
  isShort?: boolean
}

export const HoursInterval = memo(function HoursInterval({
  isShort = false
}: HoursIntervalProps) {
  const hours = useAppSelector(selectHours)
  const curDate = useAppSelector(selectCurDate)
  const minutesLinePosition = useAppSelector(selectMinutesLinePosition)
  const now = nowWithTimezone()
  const isToday = isSameDay(curDate, now)

  const groupedHours = hours.filter(
    (hour) => hour.getMinutes() % GROUP_HOUR_COEFFICIENT === 0
  )

  return (
    <div className={css.root}>
      {groupedHours.map((hour, idx) => (
        <Hour
          key={idx}
          hour={hour}
          isFirst={idx === 0}
          isLast={idx === groupedHours.length - 1}
        />
      ))}
      {isToday && (
        <>
          <div
            className={css.hour__time}
            style={{
              left: minutesLinePosition
            }}
          >
            {format(nowWithTimezone(), 'HH:mm')}
          </div>
          <div
            className={cx(css.hour__line, {
              [css.hour__line_short]: isShort
            })}
            style={{ left: minutesLinePosition }}
          />
        </>
      )}
    </div>
  )
})
