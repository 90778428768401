import { MobileMapPagesEnum, selectMobileMapPage } from '@/entities/map'
import { useAppSelector } from '@/shared/model'
import { TimelineSwiper } from '@/widgets/timeline-swiper'

import { MobileMapFilter } from '../MobileMapFilter'

export default function MobileMapHeader() {
  const page = useAppSelector(selectMobileMapPage)

  switch (page) {
    case MobileMapPagesEnum.MAP:
      return <TimelineSwiper />
    case MobileMapPagesEnum.LIST:
      return <MobileMapFilter />
    case MobileMapPagesEnum.WAITING:
      return <></>
    default:
  }
}
