import { skipToken } from '@reduxjs/toolkit/dist/query'

import {
  createReservationComment,
  deleteReservationComment,
  selectReservationComments,
  selectReservationTags,
  setReservationTags,
  updateReservationComment
} from '@/entities/create-update-reservation'
import { selectProfileData, selectSelectedAddressId } from '@/entities/session'
import {
  useCreateReservationTagMutation,
  useReservationTagsQuery
} from '@/entities/tags'
import { useToastSuccessHandling } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import MobileTagsAndComments from '../MobileTagsAndComments'

export default function MobileNewReservationTagsAndComments() {
  const addressId = useAppSelector(selectSelectedAddressId)
  const profileData = useAppSelector(selectProfileData)
  const tags = useAppSelector(selectReservationTags) ?? []
  const comments = useAppSelector(selectReservationComments) ?? []
  const dispatch = useAppDispatch()

  const {
    data: tagsKit,
    isLoading: isLoadingTagsKit,
    isError: isErrorTagsKit
  } = useReservationTagsQuery(addressId ? { addressId } : skipToken, {
    refetchOnMountOrArgChange: true
  })

  const [
    createTag,
    { isLoading: isLoadingCreateTag, isSuccess: isCreateTagSuccess }
  ] = useCreateReservationTagMutation()
  useToastSuccessHandling(isCreateTagSuccess, 'Тег успешно создан')

  const deleteCommentHandle = async (id: number) => {
    dispatch(deleteReservationComment(id))
  }

  const updateCommentHandle = async (id: number, text: string) => {
    dispatch(updateReservationComment({ id, text }))
  }

  const createCommentHandle = async (text: string) => {
    if (!profileData) return

    dispatch(createReservationComment({ profileData, text }))
  }

  const setTagsHandle = async (tags: SimpleTag[]) => {
    dispatch(setReservationTags(tags))
  }

  const createTagKitHandle = async (name: string) => {
    if (!addressId) return

    await createTag({ addressId, body: { name } })
  }

  return (
    <MobileTagsAndComments
      tags={tags}
      tagsKit={tagsKit}
      isErrorTagsKit={isErrorTagsKit}
      isLoadingTagsKit={isLoadingTagsKit}
      isLoadingCreateTag={isLoadingCreateTag}
      setTagsHandle={setTagsHandle}
      createTagKitHandle={createTagKitHandle}
      comments={comments}
      deleteCommentHandle={deleteCommentHandle}
      updateCommentHandle={updateCommentHandle}
      createCommentHandle={createCommentHandle}
    />
  )
}
