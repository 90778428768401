import { differenceInSeconds } from 'date-fns'

import { nowWithTimezone } from './nowWithTimezone'

export const getCountUpSec = (timerStartedAt: Date) => {
  const now = nowWithTimezone()

  const diff = differenceInSeconds(now, timerStartedAt)

  return diff > 0 ? diff : 0
}
