import * as React from 'react'

import { cx } from 'class-variance-authority'
import { Drawer as DrawerUI } from 'vaul'

import { isPWA } from '@/shared/lib'

import { Icon } from '../Icon'

import css from './Vaul.module.css'

const Vaul = DrawerUI.Root

const VaulNested = DrawerUI.NestedRoot

const VaulTrigger = DrawerUI.Trigger

const VaulPortal = DrawerUI.Portal

type VaulOverlayRef = React.ElementRef<typeof DrawerUI.Overlay>
type VaulOverlayProps = React.ComponentPropsWithoutRef<typeof DrawerUI.Overlay>

const VaulOverlay = React.forwardRef<VaulOverlayRef, VaulOverlayProps>(
  ({ className, children, ...props }, ref) => (
    <DrawerUI.Overlay
      ref={ref}
      className={cx(css.overlay, className)}
      {...props}
    />
  )
)
VaulOverlay.displayName = 'VaulOverlay'

type VaulCloseRef = React.ElementRef<typeof DrawerUI.Close>
type VaulCloseProps = React.ComponentPropsWithoutRef<typeof DrawerUI.Close>

const VaulClose = React.forwardRef<VaulCloseRef, VaulCloseProps>(
  ({ className, children, ...props }, ref) => (
    <DrawerUI.Close
      ref={ref}
      className={cx(css.content__close, className)}
      {...props}
    >
      <Icon name="x" size={20} />
    </DrawerUI.Close>
  )
)
VaulClose.displayName = 'VaulClose'

type VaulContentRef = React.ElementRef<typeof DrawerUI.Content>
type VaulContentProps = React.ComponentPropsWithoutRef<
  typeof DrawerUI.Content
> & {
  fullScreen?: boolean
  onClose?: (e: React.MouseEvent) => void
  isVisibleButtonClose?: boolean
  dismissible?: boolean
}

const VaulContent = React.forwardRef<VaulContentRef, VaulContentProps>(
  (
    {
      className,
      children,
      fullScreen = false,
      isVisibleButtonClose = true,
      dismissible = true,
      onClose,
      ...props
    },
    ref
  ) => (
    <VaulPortal>
      <VaulOverlay onClick={onClose} />
      <DrawerUI.Content
        ref={ref}
        className={cx(
          css.content,
          { [css.content_fullScreen]: fullScreen },
          className
        )}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        <div className={css.content__background} />
        {dismissible && <div className={css.content__handle} />}
        <div className={css['content__scroll--boundary-hidden']}>
          <div className={css.content__viewport}>{children}</div>
        </div>
        {isVisibleButtonClose && (
          <VaulClose
            className={css['content__close_in-vaul-content']}
            onClick={onClose}
          />
        )}
      </DrawerUI.Content>
    </VaulPortal>
  )
)
VaulContent.displayName = 'VaulContent'

type VaulHeaderRef = React.ElementRef<'div'>
type VaulHeaderProps = React.ComponentPropsWithoutRef<'div'>

const VaulHeader = React.forwardRef<VaulHeaderRef, VaulHeaderProps>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cx(css.header, className)} {...props}>
      {children}
    </div>
  )
)
VaulHeader.displayName = 'VaulHeader'

type VaulBodyRef = React.ElementRef<'div'>
type VaulBodyProps = React.ComponentPropsWithoutRef<'div'>

const VaulBody = React.forwardRef<VaulBodyRef, VaulBodyProps>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cx(css.body, className)} {...props}>
      {children}
    </div>
  )
)
VaulBody.displayName = 'VaulBody'

type VaulFooterRef = React.ElementRef<'div'>
type VaulFooterProps = React.ComponentPropsWithoutRef<'div'> & {
  isTgBot?: boolean
}

const VaulFooter = React.forwardRef<VaulFooterRef, VaulFooterProps>(
  ({ className, children, isTgBot = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cx(
        css.footer,
        { [css.footer_pwa]: isPWA() || isTgBot },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
)
VaulFooter.displayName = 'VaulFooter'

export {
  Vaul,
  VaulNested,
  VaulTrigger,
  VaulPortal,
  VaulOverlay,
  VaulClose,
  VaulContent,
  VaulHeader,
  VaulBody,
  VaulFooter
}
