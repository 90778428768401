export const selectNewWaitingListGuest = (state: RootState) =>
  state.newWaitingListRecord.guest

export const selectNewWaitingListHallId = (state: RootState) =>
  state.newWaitingListRecord.hallId

export const selectNewWaitingListPersonsCount = (state: RootState) =>
  state.newWaitingListRecord.personsCount

export const selectNewWaitingListDuration = (state: RootState) =>
  state.newWaitingListRecord.duration

export const selectNewWaitingListIsOpenDrawer = (state: RootState) =>
  state.newWaitingListRecord.isOpenDrawer
