import { useEffect, useRef, useState } from 'react'

import { cx } from 'class-variance-authority'
import { IMask } from 'react-imask'

import {
  selectSearchGuestPhone,
  setSearchGuestPhone
} from '@/entities/search-guests'
import { masks } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import { buttons } from './buttons'
import { type DialButton } from './types'

import { Button } from '../Button'
import { Icon } from '../Icon'

import css from './PhoneDial.module.css'

const keys = '1234567890+'

const { PHONE_INPUT_MASK_RU } = masks

type PhoneDialProps = {
  defaultValue?: string | undefined
  noGlobalListener?: boolean
  rememberNumber?: boolean
  setNumber?: (value: string) => void
}

export function PhoneDial({
  defaultValue,
  noGlobalListener = false,
  rememberNumber = false,
  setNumber
}: PhoneDialProps) {
  const dispatch = useAppDispatch()
  const phoneNum = useAppSelector(selectSearchGuestPhone)
  const refContainer = useRef<HTMLDivElement | null>(null)
  const mask = IMask.createMask({
    mask: PHONE_INPUT_MASK_RU
  })
  const [displayedNumber, setDisplayedNumber] = useState('')
  const [value, setValue] = useState(defaultValue ?? phoneNum)

  useEffect(() => {
    mask.resolve(value)
    setDisplayedNumber(mask.value)
  }, [value])

  useEffect(() => {
    setNumber?.(value)
    if (rememberNumber) dispatch(setSearchGuestPhone(value))
  }, [value])

  const dialHandler = (button: DialButton) => {
    if (button.type === 'clear') {
      setValue((prev) => {
        const newVal = prev.slice(0, prev.length - 1)
        mask.resolve(newVal)
        return mask.unmaskedValue
      })
    } else {
      setValue((prev) => {
        const newVal = prev + button.val
        if (newVal === '+') return newVal
        mask.resolve(newVal)
        return mask.unmaskedValue
      })
    }
  }

  const keyPressHandler = (event: KeyboardEvent) => {
    if (event.key === 'Backspace') {
      setValue((prev) => {
        const newVal = prev.slice(0, prev.length - 1)
        mask.resolve(newVal)
        return mask.unmaskedValue
      })
    } else if (keys.includes(event.key)) {
      setValue((prev) => {
        const newVal = prev + event.key
        if (newVal === '+') return newVal
        mask.resolve(newVal)
        return mask.unmaskedValue
      })
    }
  }

  useEffect(() => {
    const element = (
      noGlobalListener && refContainer?.current ? refContainer?.current : window
    ) as HTMLDivElement

    element.addEventListener('keydown', keyPressHandler)

    return () => element.removeEventListener('keydown', keyPressHandler)
  }, [noGlobalListener])

  return (
    <div className={css.phone} ref={refContainer} tabIndex={0}>
      <div className={css.phone__display}>
        {displayedNumber.length === 0 ? (
          <div className={css.phone__placeholder}>+7 (999) 999-99-99</div>
        ) : (
          <span className={css.phone__number}>
            {displayedNumber}
            {displayedNumber && <div className={css.caret} />}
          </span>
        )}
      </div>
      <div className={css.phone__buttons}>
        {buttons.map((button) => (
          <Button
            key={button.val}
            type="button"
            className={cx(
              css.phone__button,
              {
                [css.phone__button_plus]: button.type === 'plus'
              },
              {
                [css.phone__button_transparent]:
                  button.type === 'plus' || button.type === 'clear'
              }
            )}
            size="lg"
            onClick={() => dialHandler(button)}
          >
            {button.icon ? <Icon name={button.icon} size={32} /> : button.val}
          </Button>
        ))}
      </div>
    </div>
  )
}
