import { addDays } from 'date-fns'

import {
  MobileNewReservationPickersSteps,
  MobileReservationSteps,
  ReservationSkipStep,
  selectReservationDate,
  selectReservationGuest,
  selectReservationSkipStep,
  setMobileReservationPickersStep,
  setMobileReservationStep,
  setReservationDate,
  setReservationGuest
} from '@/entities/create-update-reservation'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { DatePickerList, VaulBody } from '@/shared/ui'

import { ReservationProcessFooter } from '../../ReservationProcessFooter'
import { ReservationProcessHeader } from '../../ReservationProcessHeader'

import css from './ReservationDateStep.module.css'

export default function ReservationDateStep() {
  const dispatch = useAppDispatch()
  const selectedGuest = useAppSelector(selectReservationGuest)
  const selectedDate = useAppSelector(selectReservationDate)
  const skipStep = useAppSelector(selectReservationSkipStep)

  if (!selectedGuest) {
    return null
  }

  const backHandler = () => {
    dispatch(setReservationGuest(undefined))
    dispatch(setMobileReservationStep(MobileReservationSteps.ONE))
  }

  const selectDateHandler = (date?: Date) => {
    dispatch(setReservationDate(date))
  }

  const nextStepHandler = () => {
    dispatch(
      setMobileReservationPickersStep(MobileNewReservationPickersSteps.TWO)
    )
  }

  return (
    <VaulBody className={css.step}>
      <ReservationProcessHeader
        backHandler={
          skipStep === ReservationSkipStep.GUEST ? undefined : backHandler
        }
        title="Выберите дату"
      />
      <DatePickerList
        selectDate={selectDateHandler}
        selectedDate={selectedDate}
        startDate={nowWithTimezone()}
        endDate={addDays(nowWithTimezone(), 30)}
      />
      <ReservationProcessFooter onClick={nextStepHandler}>
        Далее
      </ReservationProcessFooter>
    </VaulBody>
  )
}
