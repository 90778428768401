import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  forwardRef,
  type Ref,
  useEffect,
  useId,
  useMemo,
  useState
} from 'react'

import { cva, cx, type VariantProps } from 'class-variance-authority'
import { useIMask } from 'react-imask'

import { masks } from '@/shared/lib'

import { Button } from '../Button'
import { FormLabel } from '../Form'
import { Icon, type IconNames } from '../Icon'

import css from './Input.module.css'

const { PHONE_INPUT_MASK_RU } = masks

const inputStyle = cva(css.input__field, {
  variants: {
    variant: {
      primary: css.input__field_primary,
      secondary: css.input__field_secondary,
      outlined: css.input__field_outlined,
      light: css.input__field_light
    },
    isError: {
      true: css.input__field_error,
      false: ''
    },
    isLabel: {
      true: css.input__field_label,
      false: ''
    },
    isIcon: {
      true: css.input__field_icon,
      false: ''
    }
  },
  defaultVariants: {
    variant: 'primary',
    isError: false,
    isLabel: false,
    isIcon: false
  }
})

export type InputStyleProps = VariantProps<typeof inputStyle>

type InputProps = ComponentPropsWithoutRef<'input'> &
  InputStyleProps & {
    label?: string
    icon?: IconNames
    classNameLabel?: string
  }

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classNameLabel,
      type,
      variant = 'primary',
      disabled,
      isError = false,
      label,
      icon,
      ...props
    },
    ref
  ) => {
    const id = useId()
    const buttonId = useId()
    const [isVisible, setIsVisible] = useState(false)

    const innerType = useMemo(
      () => (type === 'password' && isVisible ? 'text' : type),
      [type, isVisible]
    )

    const changePasswordVisibilityHandler = () => {
      setIsVisible((prev) => !prev)
    }

    return (
      <div className={css.input}>
        {label && (
          <FormLabel className={cx(css.input__label, classNameLabel)}>
            {label}
          </FormLabel>
        )}
        {icon && (
          <Icon
            name={icon}
            size={16}
            className={css.input__icon}
            strokeWidth={3}
          />
        )}
        <input
          id={id}
          ref={ref}
          type={innerType}
          className={inputStyle({
            variant,
            isError,
            isLabel: Boolean(label),
            isIcon: Boolean(icon),
            className
          })}
          disabled={disabled}
          {...props}
        />
        {type === 'password' && (
          <Button
            type="button"
            size="icon"
            variant="transparent"
            role="switch"
            id={buttonId}
            aria-checked={isVisible}
            aria-label={isVisible ? 'Скрыть пароль' : 'Показать пароль'}
            className={css.input__passwordIcon}
            onClick={changePasswordVisibilityHandler}
            disabled={disabled}
          >
            <Icon name={isVisible ? 'eyeOff' : 'eye'} />
          </Button>
        )}
      </div>
    )
  }
)

type PhoneInputProps = InputProps & {
  onValueChange: (value: string) => void
}

const PhoneInput = ({
  className,
  value,
  onValueChange,
  ...props
}: PhoneInputProps) => {
  const [opts] = useState({ mask: PHONE_INPUT_MASK_RU })
  const { ref, value: innerValue, setValue, unmaskedValue } = useIMask(opts)

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (value) setValue(value.toString())
  }, [])

  useEffect(() => {
    onValueChange(unmaskedValue)
  }, [unmaskedValue])

  return (
    <Input
      ref={ref as Ref<HTMLInputElement>}
      type="tel"
      className={className}
      value={innerValue}
      onChange={changeHandler}
      {...props}
    />
  )
}

export { Input, PhoneInput }
