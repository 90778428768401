/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { INFINITE_SCROLL_RES } from '@/app/config'
import { ReservationCard, useReservationsQuery } from '@/entities/reservation'
import {
  ReservationSortEnum,
  selectReservationsSearch,
  selectReservationsSearchStatus,
  selectReservationsSelectedInterval,
  selectReservationsSortType
} from '@/entities/reservations'
import {
  selectReservationsFilters,
  selectReservationsOpenModalId,
  selectReservationsSortDate,
  selectReservationsSortTable
} from '@/entities/reservations/model/selectors'
import { setReservationsOpenModalId } from '@/entities/reservations/model/slice'
import { selectSelectedAddressId } from '@/entities/session'
import { Reservation } from '@/features/reservation'
import { useDebounce } from '@/shared/hooks'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Error,
  Fetching,
  InfoBox,
  Loading,
  ScrollArea,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { ReservationVaulContent } from '@/widgets/reservation'

import css from './MobileReservationsList.module.css'

type Context = {
  sortType: ReservationSortEnum
  startDate: Date | null
  isContentLoading: boolean
}

function Header({ context }: { context?: Context }) {
  return (
    <div className={css.header}>
      <div className={css.header__space} />
      {context?.sortType === ReservationSortEnum.inProgress ? (
        context?.startDate && (
          <div className={css['header__date-container']}>
            <div className={css.header__line} />
            <p className={css.header__date}>
              {format(context.startDate, 'iiii, dd.MM.yyyy', { locale: ru })}
            </p>
            <div className={css.header__line} />
          </div>
        )
      ) : (
        <Reservation.DateFilter />
      )}
    </div>
  )
}

export function ReservationsListFooter() {
  return <div className={css.footer} />
}

export function ReservationsEmptyPlaceholder({
  context
}: {
  context?: Context
}) {
  if (context?.isContentLoading) {
    return (
      <div className={css.placeholder}>
        <Loading />
      </div>
    )
  }

  return (
    <InfoBox className={css.placeholder} noIcon>
      Ничего не найдено
    </InfoBox>
  )
}

function WrapperCard(props: ItemProps<Reservation>) {
  return <div {...props} className={css['wrapper-card']} />
}

type ReservationCardProps = {
  reservation: Reservation
  search: string
  openModalId: number | null
  setOpenModalId: (value: number | null) => void
  updateReservationsList: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

function ReservationCardWithVaul({
  reservation,
  search,
  openModalId,
  setOpenModalId,
  updateReservationsList
}: ReservationCardProps) {
  return (
    <Vaul
      open={openModalId === reservation.id}
      onOpenChange={(open) =>
        open ? setOpenModalId(reservation.id) : setOpenModalId(null)
      }
    >
      <VaulTrigger asChild>
        <ReservationCard
          id={reservation.id}
          guest={reservation.guest}
          personsCount={reservation.persons_count}
          startDate={reservation.start_date}
          mainTableNumber={reservation.main_table_number}
          source={reservation.source}
          link={reservation.link}
          tags={reservation.tags}
          status={reservation.status}
          endDate={reservation.end_date}
          updateReservationsList={updateReservationsList}
        />
      </VaulTrigger>
      <VaulContent fullScreen>
        <ReservationVaulContent
          reservationId={reservation.id}
          updateReservationsList={updateReservationsList}
        />
      </VaulContent>
    </Vaul>
  )
}

export default function MobileReservationsList() {
  const address = useAppSelector(selectSelectedAddressId)
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const searchStatus = useAppSelector(selectReservationsSearchStatus)
  const search = useAppSelector(selectReservationsSearch)
  const sortType = useAppSelector(selectReservationsSortType)
  const selectSortDate = useAppSelector(selectReservationsSortDate)
  const selectSortTable = useAppSelector(selectReservationsSortTable)
  const selectFilters = useAppSelector(selectReservationsFilters)
  const openModalId = useAppSelector(selectReservationsOpenModalId)
  const dispatch = useAppDispatch()

  const [page, setPage] = useState(1)
  const [reservations, setReservations] = useState<Reservation[]>([])
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | null>(null)
  const [isLoadingPage, setIsLoadingPage] = useState(false)

  const debouncedSearch = useDebounce(search)

  const { data, isFetching, isLoading, isError, isSuccess, refetch } =
    useReservationsQuery(
      address
        ? {
            address,
            limit: INFINITE_SCROLL_RES,
            offset: (page - 1) * INFINITE_SCROLL_RES,
            search: debouncedSearch,
            start_date: formatTimeForRequest(selectedInterval.start_date),
            end_date: formatTimeForRequest(selectedInterval.end_date),
            status: searchStatus ? [searchStatus] : undefined,
            ordering: selectSortDate,
            ordering_places: selectSortTable,
            filters: selectFilters
          }
        : skipToken
    )

  const updateReservationsList = (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => {
    if (isDelete) {
      setReservations((prev) =>
        prev.filter((item) => item.id !== reservationId)
      )
    } else if (data) {
      setReservations((prev) => {
        const updatedReservations = prev.map((reservation) => {
          if (reservation.id === reservationId) {
            const { comments_count, ...rest } = data as Partial<Reservation>
            return {
              ...reservation,
              ...rest,
              comments_count: comments_count
                ? Number(comments_count)
                : reservation.comments_count
            }
          }
          return reservation
        })

        return updatedReservations
      })
    }
  }

  const loadMoreRows = () => {
    if (data?.next) {
      setIsLoadingPage(true)
      setPage((prevPage) => prevPage + 1)
    }
  }

  const setOpenModalId = (value: number | null) => {
    dispatch(setReservationsOpenModalId(value))
  }

  useEffect(() => {
    setPage(1)
    setReservations([])
    dispatch(setReservationsOpenModalId(null))
    refetch()
  }, [
    sortType,
    debouncedSearch,
    searchStatus,
    selectedInterval.start_date,
    selectedInterval.end_date,
    selectSortDate,
    selectSortTable,
    selectFilters
  ])

  useEffect(() => {
    if (
      isSuccess &&
      data &&
      !isFetching &&
      data?.count <= reservations.length
    ) {
      setReservations(data.results)
      setIsLoadingPage(false)
    } else if (isSuccess && data && !isFetching) {
      setReservations((prev) => [...prev, ...data.results])
      setIsLoadingPage(false)
    }
  }, [isSuccess, data])

  const isContentLoading = !isLoadingPage && (isFetching || isLoading)

  if (isError) {
    return <Error refetch={refetch} />
  }

  return (
    <ScrollArea
      size="md"
      className={css.scroll}
      scrollBarClassName={css.scrollbar}
      isEmptyVirtualList={reservations.length === 0}
      setRefViewport={setScrollParent}
    >
      {isLoadingPage && isFetching && <Fetching className={css.fetching} />}
      <Virtuoso
        totalCount={reservations.length}
        defaultItemHeight={150}
        data={reservations}
        endReached={loadMoreRows}
        increaseViewportBy={200}
        context={{
          sortType,
          startDate: selectedInterval.start_date,
          isContentLoading
        }}
        components={{
          EmptyPlaceholder: ReservationsEmptyPlaceholder,
          Item: WrapperCard,
          Header,
          Footer: ReservationsListFooter
        }}
        itemContent={(_, reservation) => (
          <ReservationCardWithVaul
            reservation={reservation}
            search={search}
            openModalId={openModalId}
            setOpenModalId={setOpenModalId}
            updateReservationsList={updateReservationsList}
          />
        )}
        customScrollParent={scrollParent ?? undefined}
      />
    </ScrollArea>
  )
}
