import {
  SearchGuestSteps,
  selectSearchGuestStep
} from '@/entities/search-guests'
import { useAppSelector } from '@/shared/model'

import { GuestCreateStep } from './create'
import { GuestSearchStep } from './search'

export function MobileGuestsSearchStep() {
  const step = useAppSelector(selectSearchGuestStep)

  switch (step) {
    case SearchGuestSteps.one:
      return <GuestSearchStep />
    case SearchGuestSteps.two:
      return <GuestCreateStep />
  }
}
