/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { type SerializedError } from '@reduxjs/toolkit'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { config } from '@/app/config'

const { DEFAULT_ERROR_MES } = config

export enum HTTPErrors {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND'
}

interface BadRequestHTTPError {
  [key: string]: string
}

export interface AppError {
  type: HTTPErrors
  status: number
  title: string
  message: string
}

export const isFetchBaseQueryError = (
  error: FetchBaseQueryError | SerializedError
): error is FetchBaseQueryError => {
  return 'data' in error || 'status' in error
}

export const isParsingQueryError = (
  error: FetchBaseQueryError
): error is {
  status: 'PARSING_ERROR'
  originalStatus: number
  data: string
  error: string
} => {
  return 'status' in error && error.status === 'PARSING_ERROR'
}

export const parseHTTPErrors = (
  error: FetchBaseQueryError | SerializedError
): AppError => {
  if (isFetchBaseQueryError(error)) {
    // HTTP Error
    if (typeof error.status === 'number' || isParsingQueryError(error)) {
      const status =
        typeof error.status === 'number' ? error.status : error.originalStatus

      switch (status) {
        case 400:
          return {
            type: HTTPErrors.BAD_REQUEST,
            status,
            title: 'Неверные данные',
            message:
              Object.entries(error.data as BadRequestHTTPError)[0][1] ||
              DEFAULT_ERROR_MES.message
          }
        case 401:
          return {
            type: HTTPErrors.UNAUTHORIZED,
            status,
            title: 'Ошибка авторизации',
            message: 'К сожалению вы не авторизованы, войдите в аккаунт'
          }
        case 404:
          return {
            type: HTTPErrors.NOT_FOUND,
            status,
            title: 'Несуществующий запрос',
            message:
              'К сожалению такого запроса не существует, сообщите в поддержку'
          }
        default:
          return {
            type: HTTPErrors.INTERNAL,
            status,
            title: DEFAULT_ERROR_MES.title,
            message: DEFAULT_ERROR_MES.message
          }
      }
    } else {
      // Other Errors / we can handle and parse them here
      return {
        type: HTTPErrors.INTERNAL,
        status: 500,
        title: DEFAULT_ERROR_MES.title,
        message: DEFAULT_ERROR_MES.message
      }
    }
  } else {
    return {
      type: HTTPErrors.INTERNAL,
      status: 500,
      title: DEFAULT_ERROR_MES.title,
      message: error.message ?? DEFAULT_ERROR_MES.message
    }
  }
}
