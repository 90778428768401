export const getSideStatusPopover = (
  tablesElements: HTMLDivElement[],
  statusElement: HTMLDivElement | null,
  tableId: number
) => {
  const currentTableElement = tablesElements.find(
    (el) => el.dataset.id === String(tableId)
  )
  let side: 'bottom' | 'left' | 'right' | 'top' | undefined
  let isTopBoundary = false
  let isRightBoundary = false
  let isLeftBoundary = false
  let isBottomBoundary = false

  for (let i = 0; i < tablesElements.length; i++) {
    if (
      currentTableElement &&
      statusElement !== null &&
      tablesElements[i] !== null &&
      tablesElements[i].dataset.id !== String(tableId)
    ) {
      const tableRect = currentTableElement.getBoundingClientRect()
      const tableItemRect = tablesElements[i].getBoundingClientRect()
      const statusRect = statusElement.getBoundingClientRect()

      if (!isTopBoundary) {
        isTopBoundary =
          tableItemRect.x + tableItemRect.width >= tableRect.x &&
          tableItemRect.x <= tableRect.x + tableRect.width &&
          tableItemRect.y + tableItemRect.height >=
            tableRect.y - statusRect.height &&
          tableItemRect.y <= tableRect.y + tableRect.height
      }
      if (!isBottomBoundary) {
        isBottomBoundary =
          tableItemRect.x + tableItemRect.width >= tableRect.x &&
          tableItemRect.x <= tableRect.x + tableRect.width &&
          tableItemRect.y + tableItemRect.height >= tableRect.y &&
          tableItemRect.y <= tableRect.y + tableRect.height + statusRect.height
      }
      if (!isRightBoundary) {
        isRightBoundary =
          tableItemRect.x + tableItemRect.width >= tableRect.x &&
          tableItemRect.x <= tableRect.x + tableRect.width + statusRect.width &&
          tableItemRect.y + tableItemRect.height >= tableRect.y &&
          tableItemRect.y <= tableRect.y + tableRect.height
      }
      if (!isLeftBoundary) {
        isLeftBoundary =
          tableItemRect.x + tableItemRect.width >=
            tableRect.x - statusRect.width &&
          tableItemRect.x <= tableRect.x + tableRect.width &&
          tableItemRect.y + tableItemRect.height >= tableRect.y &&
          tableItemRect.y <= tableRect.y + tableRect.height
      }
    }
  }

  switch (true) {
    case !isTopBoundary: {
      side = 'top'
      break
    }
    case !isRightBoundary: {
      side = 'right'
      break
    }
    case !isLeftBoundary: {
      side = 'left'
      break
    }
    case !isBottomBoundary: {
      side = 'bottom'
      break
    }
    default: {
      side = undefined
      break
    }
  }

  return side
}
