import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { selectSelectedAddressId } from '@/entities/session'
import { useTelephonyWsQuery } from '@/entities/telephony'
import { useAppSelector } from '@/shared/model'
import {
  Badge,
  Button,
  Drawer,
  DrawerContent,
  DrawerTrigger,
  Icon,
  ScrollArea
} from '@/shared/ui'

import { CallsCard } from './CallsCard/CallsCard'

import css from './Quick.module.css'

function WrapperCard(props: ItemProps<ResponseTelephonyWS>) {
  return <div {...props} className={css.item} />
}

export function Quick() {
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const { data, isFetching } = useTelephonyWsQuery(
    addressId
      ? {
          addressId
        }
      : skipToken
  )

  useEffect(() => {
    if (!data?.[data.length - 1]?.length) {
      setOpen(false)
    }
  }, [data?.[data.length - 1]?.length])

  if (isFetching || !data?.[data.length - 1]?.length) {
    return null
  }

  return (
    <Drawer open={open} onOpenChange={setOpen} direction="right">
      <DrawerTrigger asChild>
        <Button
          variant="green"
          size="icon"
          className={css.button}
          contentClassName={css.button__content}
        >
          <Icon name="phoneCall" size={20} />
          {data?.[data.length - 1]?.length && (
            <Badge variant="white" size="xs" className={css.badge}>
              {data?.[data.length - 1].length}
            </Badge>
          )}
        </Button>
      </DrawerTrigger>
      <DrawerContent withCloseButton className={css.drawer__content}>
        <div className={css.drawer__header}>
          <h2 className={css.drawer__title}>Телефония</h2>
          <p className={css.drawer__subtitle}>
            {`Входящие звонки${
              data ? ` - ${data?.[data.length - 1]?.length}` : ''
            }`}
          </p>
        </div>
        <ScrollArea
          isEmptyVirtualList={data?.[data.length - 1]?.length === 0}
          className={css.list}
          padding="xl"
          size="md"
          setRefViewport={setScrollParent}
        >
          <Virtuoso
            totalCount={data?.[data.length - 1]?.length ?? 0}
            defaultItemHeight={160}
            data={data[data.length - 1] ?? []}
            increaseViewportBy={200}
            components={{
              Item: WrapperCard
            }}
            itemContent={(_, call) => (
              <CallsCard
                guest={call.guest}
                status={call.status}
                phoneNewGuest={call.phone_caller}
                onCloseDrawer={() => setOpen(false)}
              />
            )}
            customScrollParent={scrollParent ?? undefined}
          />
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  )
}
