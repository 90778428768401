import { baseApi, tagTypes } from '@/shared/api'

import {
  transformGuestTagsToFilter,
  transformReservationTagsToFilter
} from '../lib'

const { RESERVATION_TAGS_TAG } = tagTypes

export const tagsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    guestTags: build.query<Tag[], { chainId: number }>({
      query: ({ chainId }) => ({
        url: `/tags/${chainId}/guest-tags/`
      })
    }),
    guestTagsFilter: build.query<Filter, { chainId: number }>({
      query: ({ chainId }) => ({
        url: `/tags/${chainId}/guest-tags/`
      }),
      transformResponse: (response: Tag[]) =>
        transformGuestTagsToFilter(response)
    }),
    reservationTags: build.query<SimpleTag[], { addressId: number }>({
      query: ({ addressId }) => ({
        url: `/book/addresses/${addressId}/reservation-tags/`
      }),
      providesTags: [RESERVATION_TAGS_TAG]
    }),
    reservationTagsFilter: build.query<Filter, { addressId: number }>({
      query: ({ addressId }) => ({
        url: `/book/addresses/${addressId}/reservation-tags/`
      }),
      transformResponse: (response: SimpleTag[]) =>
        transformReservationTagsToFilter(response),
      providesTags: [RESERVATION_TAGS_TAG]
    }),
    createReservationTag: build.mutation<SimpleTag, CreateReservationTagParams>(
      {
        query: ({ addressId, body }) => ({
          url: `/book/addresses/${addressId}/reservation-tags/`,
          method: 'POST',
          body
        }),
        invalidatesTags: [RESERVATION_TAGS_TAG]
      }
    )
  })
})

export const {
  useGuestTagsQuery,
  useGuestTagsFilterQuery,
  useReservationTagsQuery,
  useCreateReservationTagMutation,
  useReservationTagsFilterQuery
} = tagsApi
