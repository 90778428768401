import { eachHourOfInterval, getDate, getMonth, getYear } from 'date-fns'

import { config } from '@/app/config'
import { splitHours } from '@/shared/lib'

import { dayIntervalsHoursEndTime } from '../model/config'
const { PICKERS_SPLIT_HOUR_BY } = config

export const generateTimeHoursEndTime = (date: Date): DayTimeHours[] => {
  const { one, two, three, four, five } = dayIntervalsHoursEndTime

  const year = getYear(date)
  const month = getMonth(date)
  const day = getDate(date)

  const intervalOne = {
    start: new Date(year, month, day, one.start),
    end: new Date(year, month, day, one.end)
  }

  const hoursOne = splitHours(
    eachHourOfInterval(intervalOne),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalTwo = {
    start: new Date(year, month, day, two.start),
    end: new Date(year, month, day, two.end)
  }

  const hoursTwo = splitHours(
    eachHourOfInterval(intervalTwo),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalThree = {
    start: new Date(year, month, day, three.start),
    end: new Date(year, month, day, three.end)
  }

  const hoursThree = splitHours(
    eachHourOfInterval(intervalThree),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalFour = {
    start: new Date(year, month, day, four.start),
    end: new Date(year, month, day, four.end)
  }

  const hoursFour = splitHours(
    eachHourOfInterval(intervalFour),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalFive = {
    start: new Date(year, month, day, five.start),
    end: new Date(year, month, day, five.end)
  }

  const hoursFive = splitHours(
    eachHourOfInterval(intervalFive),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const splittedByDayTimeHours: DayTimeHours[] = [
    {
      id: 'd1',
      hours: hoursOne
    },
    {
      id: 'd2',
      hours: hoursTwo
    },
    {
      id: 'd3',
      hours: hoursThree
    },
    {
      id: 'd4',
      hours: hoursFour
    },
    {
      id: 'd5',
      hours: hoursFive
    }
  ]

  return splittedByDayTimeHours
}
