import { type MouseEventHandler, type RefObject, useState } from 'react'

const useDraggableScroll = (containerRef: RefObject<HTMLElement>) => {
  const [isDown, setIsDown] = useState(false)
  const [startY, setStartY] = useState(0)
  const [startX, setStartX] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isScroll, setIsScroll] = useState(false)

  const onClickCapture: MouseEventHandler = (e) => {
    isScroll && e.stopPropagation()
    setIsScroll(false)
  }

  const onMouseDown: MouseEventHandler = (e) => {
    if (!containerRef.current) return
    setIsDown(true)
    setStartY(e.pageY - containerRef.current.offsetTop)
    setStartX(e.pageX - containerRef.current.offsetLeft)
    setScrollLeft(containerRef.current.scrollLeft)
    setScrollTop(containerRef.current.scrollTop)
  }

  const onMouseUp: MouseEventHandler = () => {
    setIsDown(false)
  }

  const onMouseLeave: MouseEventHandler = () => {
    setIsDown(false)
  }

  const onMouseMove: MouseEventHandler = (e) => {
    if (!containerRef.current) return
    if (isDown) {
      e.preventDefault()
      setIsScroll(true)
      // Move vertically
      const y = e.pageY - containerRef.current.offsetTop
      const walkY = y - startY
      containerRef.current.scrollTop = scrollTop - walkY

      // Move horizontally
      const x = e.pageX - containerRef.current.offsetLeft
      const walkX = x - startX
      containerRef.current.scrollLeft = scrollLeft - walkX
    }
  }

  return {
    onClickCapture,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onMouseMove
  }
}

export default useDraggableScroll
