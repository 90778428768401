import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { isBefore } from 'date-fns'

import { addReservationFromEmptySlot } from '@/entities/create-update-reservation'
import { getClosestNextFifteenMin } from '@/entities/timeline/lib'
import { nowWithTimezone } from '@/shared/lib'

import {
  generateTimeHours,
  generateTimeHoursEndTime,
  getCurIntervalIndex,
  getCurIntervalIndexEndTime
} from '../lib'

type TimePickerState = {
  allIntervals: DayTimeHours[]
  allIntervalsEndTime: DayTimeHours[]
  curDayIntervalIdx: number
  curDayIntervalIdxEndTime: number
}

const allIntervals = generateTimeHours(nowWithTimezone())
const allIntervalsEndTime = generateTimeHoursEndTime(nowWithTimezone())

const initialState: TimePickerState = {
  allIntervals,
  allIntervalsEndTime,
  curDayIntervalIdx: getCurIntervalIndex(),
  curDayIntervalIdxEndTime: getCurIntervalIndexEndTime()
}

export const timePickerSlice = createSlice({
  name: 'timePicker',
  initialState,
  reducers: {
    generateNewIntervals: (state, { payload }: PayloadAction<Date>) => {
      state.allIntervals = generateTimeHours(payload)
    },
    setCurDayIntervalIdx: (state, { payload }: PayloadAction<number>) => {
      state.curDayIntervalIdx = payload
    },
    setCurDayIntervalIdxEndTime: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.curDayIntervalIdxEndTime = payload
    },
    resetTimePicker: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(addReservationFromEmptySlot, (state, { payload }) => {
      const now = nowWithTimezone()

      const startDate = getClosestNextFifteenMin(
        !isBefore(now, payload.start_date) ? now : payload.start_date
      )
      state.curDayIntervalIdx = getCurIntervalIndex(startDate)
    })
  }
})

export const {
  setCurDayIntervalIdx,
  setCurDayIntervalIdxEndTime,
  resetTimePicker,
  generateNewIntervals
} = timePickerSlice.actions
