import { type PropsWithChildren, useState } from 'react'

import { Button, Drawer, DrawerContent, DrawerTrigger, Icon } from '@/shared/ui'

import css from './AllCommentsDrawer.module.css'

type AllCommentsDrawerProps = PropsWithChildren & {
  title: string
  commentsSlot: JSX.Element
}

export default function AllCommentsDrawer({
  title,
  commentsSlot,
  children
}: AllCommentsDrawerProps) {
  const [open, setOpen] = useState(false)

  const closeHandler = () => setOpen(false)

  return (
    <Drawer open={open} onOpenChange={setOpen} direction="right">
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerContent>
        <div className={css.drawer}>
          <div className={css.drawer__header}>
            <Button size="icon" onClick={closeHandler}>
              <Icon name="arrowLeft" size={20} />
            </Button>
            <h3 className={css.drawer__title}>{title}</h3>
          </div>
          {commentsSlot}
        </div>
      </DrawerContent>
    </Drawer>
  )
}
