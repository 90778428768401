/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable import/no-duplicates */
import { add, format, isSameDay, sub } from 'date-fns'
import { ru } from 'date-fns/locale'
import { type DateRange } from 'react-day-picker'

import { nowWithTimezone } from '@/shared/lib'

export const formatDatePickerLabel = (date: Date | null | undefined) => {
  if (!date) return 'Все время'

  const now = nowWithTimezone()

  const isToday = isSameDay(date, now)
  const isTomorrow = isSameDay(date, add(now, { days: 1 }))

  let label = format(date, 'dd.MM.yyyy, cccccc', {
    locale: ru
  })

  if (isToday) label = 'Сегодня'
  if (isTomorrow) label = 'Завтра'

  return label
}

export const formatDatePickerLabelRange = (date?: DateRange) => {
  if (!date) return 'Все время'

  const now = nowWithTimezone()

  const isToday =
    (date.from && isSameDay(date.from, now) && date.to === undefined) ||
    (date.from &&
      date.to &&
      isSameDay(date.from, now) &&
      isSameDay(date.to, now))
  const isYesterday =
    (date.from &&
      isSameDay(date.from, sub(now, { days: 1 })) &&
      date.to === undefined) ||
    (date.from &&
      date.to &&
      isSameDay(date.from, sub(now, { days: 1 })) &&
      isSameDay(date.to, sub(now, { days: 1 })))

  const labelStart = date.from && format(date.from, 'dd.MM.yyyy')
  const labelEnd = date.to && format(date.to, 'dd.MM.yyyy')

  if (isToday) return 'Сегодня'
  if (isYesterday) return 'Вчера'

  if (!date.from && !date.to) {
    return 'Все время'
  }

  if (date?.from && !date?.to) {
    return labelStart
  }

  if (date?.to && !date?.from) {
    return labelEnd
  }

  return `${labelStart} - ${labelEnd}`
}
