import {
  type ChangeEvent,
  type ComponentProps,
  forwardRef,
  useEffect,
  useRef
} from 'react'

import { cx } from 'class-variance-authority'

import { mergeRefs } from '@/shared/lib'

import css from './Textarea.module.css'

type TextareaProps = ComponentProps<'textarea'> & {
  value: string
  autoResize?: boolean
  setValue: (value: string) => void
}

function resizeTextarea(textarea: HTMLTextAreaElement) {
  textarea.style.height = 'auto'
  textarea.style.height = `${textarea.scrollHeight + 2}px`
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      placeholder,
      autoResize = false,
      value,
      rows = 1,
      setValue,
      ...props
    },
    ref
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const onChangeTextareaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value)
      if (textAreaRef.current && autoResize) resizeTextarea(textAreaRef.current)
    }

    useEffect(() => {
      if (textAreaRef.current && autoResize) resizeTextarea(textAreaRef.current)
    }, [value])

    return (
      <textarea
        ref={mergeRefs([textAreaRef, ref])}
        value={value}
        onChange={onChangeTextareaHandler}
        rows={rows}
        className={cx(css.textarea, className)}
        placeholder={placeholder}
        {...props}
      />
    )
  }
)

export default Textarea
