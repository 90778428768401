import { createSelector } from '@reduxjs/toolkit'

import { AccessLevel } from './accessTypes'

export const selectIsAuthorized = (state: RootState) =>
  state.session.isAuthorized
export const selectProfileData = (state: RootState) => state.session.profile
export const selectSelectedChainId = (state: RootState) =>
  state.session.selectedChainId
export const selectSelectedAddressId = (state: RootState) =>
  state.session.selectedAddressId

export const selectIsSelectedAddress = (state: RootState) =>
  state.session.isAddressSelected

export const selectAuthToken = (state: RootState) => state.session.accessToken

export const selectIsDemoAccount = createSelector(
  (state: RootState) => state.session.accessToken,
  (state: RootState) => state.session.isAuthorized,
  (token, isAuthorized) => {
    return isAuthorized && token === `Staff ${import.meta.env.VITE_DEMO_TOKEN}`
  }
)

export const selectCanEditAddress = createSelector(
  (state: RootState) => state.session.selectedAddressId,
  (state: RootState) => state.session.profile,
  (addressId, profile) => {
    const selectedAddress = profile?.addresses.find(
      (address) => address.address === addressId
    )

    if (!selectedAddress) return false

    const hostessAccess = selectedAddress.accesses.find(
      (access) => access.type === 'hostess_panel'
    )

    if (!hostessAccess) return false

    return hostessAccess.access_level === AccessLevel.edit
  }
)

export const selectAddressTimezone = createSelector(
  (state: RootState) => state.session.selectedAddressId,
  (state: RootState) => state.session.profile,
  (addressId, profile) => {
    const selectedAddress = profile?.addresses.find(
      (address) => address.address === addressId
    )

    if (!selectedAddress) return undefined

    return selectedAddress.tz
  }
)

export const selectSelectedChainData = createSelector(
  (state: RootState) => state.session.selectedChainId,
  (state: RootState) => state.session.profile,
  (chainId, profile) => {
    return profile?.chains.find((chain) => chain.id === chainId)
  }
)
