import { type ComponentPropsWithoutRef } from 'react'

import { resetHalls } from '@/entities/halls'
import { setIsSelectedAddress } from '@/entities/session'
import { baseApi, tagTypes } from '@/shared/api'
import { useAppDispatch } from '@/shared/model'
import { Button } from '@/shared/ui'

export function AddressLogoutButton({
  children = 'Выход',
  ...props
}: ComponentPropsWithoutRef<'button'>) {
  const dispatch = useAppDispatch()

  const logoutHandler = () => {
    dispatch(setIsSelectedAddress(false))
    dispatch(resetHalls())
    dispatch(baseApi.util.invalidateTags([...Object.values(tagTypes)]))
  }

  return (
    <Button onClick={logoutHandler} {...props}>
      {children}
    </Button>
  )
}
