import { eachHourOfInterval, endOfDay, isAfter, startOfDay } from 'date-fns'

import { config } from '@/app/config'
import { splitHours } from '@/shared/lib'

const { PICKERS_SPLIT_HOUR_BY } = config

export const getClosestNextFifteenMin = (date: Date) => {
  const interval = {
    start: startOfDay(date),
    end: endOfDay(date)
  }
  const hours = splitHours(
    eachHourOfInterval(interval),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  for (let i = 0; i < hours.length; i++) {
    const curHour = hours[i]
    curHour.setDate(date.getDate())
    if (isAfter(curHour, date)) {
      return curHour
    }
  }
  return date
}
