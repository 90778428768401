import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { sessionApi } from '../api/sessionApi'

export type SessionSliceState = {
  accessToken?: string
  isAuthorized: boolean
  isAddressSelected: boolean
  profile?: ProfileData
  selectedChainId?: number
  selectedAddressId?: number
}

const initialState: SessionSliceState = {
  isAuthorized: false,
  isAddressSelected: false
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsAuthorized: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthorized = payload
    },
    setAuthFromQsToken: (state, { payload }: PayloadAction<string>) => {
      state.isAuthorized = true
      state.isAddressSelected = true
      state.accessToken = payload
    },
    setSelectedAddressId: (state, { payload }: PayloadAction<number>) => {
      state.selectedAddressId = payload
      state.isAddressSelected = true
    },
    setIsSelectedAddress: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddressSelected = payload
    },
    setSelectedChainId: (state, { payload }: PayloadAction<number>) => {
      state.selectedChainId = payload
    },
    clearSessionData: (state: SessionSliceState) => {
      state.accessToken = undefined
      state.isAuthorized = false
      state.isAddressSelected = false
      state.profile = undefined
      state.selectedChainId = undefined
      state.selectedAddressId = undefined
    },
    setAccessToken: (
      state: SessionSliceState,
      { payload }: PayloadAction<string | undefined>
    ) => {
      if (payload) {
        state.accessToken = payload
        state.isAuthorized = true
      } else {
        state.accessToken = undefined
        state.isAuthorized = false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        sessionApi.endpoints.login.matchFulfilled,
        (state: SessionSliceState, { payload }) => {
          state.isAuthorized = true

          if (state.isAuthorized) {
            state.accessToken = payload.token
          }
        }
      )
      .addMatcher(
        sessionApi.endpoints.profileData.matchFulfilled,
        (state: SessionSliceState, { payload }) => {
          state.profile = payload

          const preSelectedAddressId =
            payload.addresses.length === 1
              ? payload.addresses[0]?.address
              : undefined

          state.selectedAddressId =
            state.selectedAddressId ?? preSelectedAddressId
          state.isAddressSelected = Boolean(
            state.selectedAddressId ?? preSelectedAddressId
          )

          const chainIdx = state.profile.chains.findIndex(
            (chain) => chain.id === state.selectedChainId
          )

          if (chainIdx !== -1) {
            state.selectedChainId = payload.chains[chainIdx].id
          } else if (payload.chains.length) {
            state.selectedChainId = payload.chains[0].id
          }
        }
      )
  }
})

export const {
  clearSessionData,
  setIsAuthorized,
  setAccessToken,
  setAuthFromQsToken,
  setSelectedAddressId,
  setIsSelectedAddress,
  setSelectedChainId
} = sessionSlice.actions
