export const selectWaitingListStep = (state: RootState) =>
  state.waitingList.step

export const selectWaitingListGuest = (state: RootState) =>
  state.waitingList.guest

export const selectWaitingListPersonsCount = (state: RootState) =>
  state.waitingList.personsCount

export const selectWaitingListDuration = (state: RootState) =>
  state.waitingList.duration

export const selectWaitingListPlace = (state: RootState) =>
  state.waitingList.place

export const selectWaitingList = (state: RootState) =>
  state.waitingList.waitingList

export const selectSelectedWLRecord = (state: RootState) =>
  state.waitingList.selectedWLRecordID

export const selectWaitingListHall = (state: RootState) =>
  state.waitingList.hall
