import { format, parseISO } from 'date-fns'

import { CommentAuthor } from './CommentAuthor'

import css from './Comment.module.css'

type CommentProps = {
  comment: UserComment
  actionsPopoverSlot?: JSX.Element
}

export default function Comment({ comment, actionsPopoverSlot }: CommentProps) {
  const { author, text, is_author, created_at } = comment

  return (
    <div className={css.comment}>
      <div className={css.comment__header}>
        <CommentAuthor isAuthor={is_author} author={author} />
        <div className={css.comment__actions}>
          <span>
            {created_at === null
              ? 'Отправляется...'
              : format(parseISO(created_at), 'dd.MM.yyyy (HH:mm)')}
          </span>
          {is_author && created_at !== null && actionsPopoverSlot}
        </div>
      </div>
      <p>{text}</p>
    </div>
  )
}
