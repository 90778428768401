import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import {
  addReservation,
  setReservationDate,
  setReservationEnd,
  setReservationPersonsCount,
  setReservationStart
} from '@/entities/create-update-reservation'
import { selectHall } from '@/entities/halls'
import { setReservationByIdPageStep } from '@/entities/reservation'
import { nowWithTimezone } from '@/shared/lib'

import { MapModeEnum } from './mapModes'
import { type TableStatusEnum } from './tableStatus'
import { getSliceOfTime } from './thunk'

export enum MobileMapPagesEnum {
  MAP,
  LIST,
  WAITING
}

export interface TableTime {
  allTime?: string
  time?: string
  timePercent?: number
  status?: TableStatusEnum
  guest: unknown
}

type MapSliceState = {
  datetime: Date
  mode: MapMode
  selectedTables: MapItem[]
  tables: { [key: number]: TableTime }
  tableToChangeTo: MapItem[]
  isTakeTable: boolean
  showDeposits: boolean
  mobileMapPage: MobileMapPagesEnum
}

const initialState: MapSliceState = {
  datetime: nowWithTimezone(),
  mode: 'one',
  selectedTables: [],
  tables: {},
  tableToChangeTo: [],
  isTakeTable: false,
  showDeposits: true,
  mobileMapPage: MobileMapPagesEnum.MAP
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapDatetime: (state, { payload }: PayloadAction<Date>) => {
      state.datetime = payload
    },
    setMapMode: (state, { payload }: PayloadAction<MapMode>) => {
      state.mode = payload
    },
    setShowDeposits: (state, { payload }: PayloadAction<boolean>) => {
      state.showDeposits = payload
    },
    selectTable: (state, { payload }: PayloadAction<MapItem>) => {
      switch (state.mode) {
        case MapModeEnum.one:
          state.selectedTables = [payload]
          break
        case MapModeEnum.many: {
          if (state.selectedTables.every((item) => item.id !== payload.id)) {
            state.selectedTables.push(payload)
          }
          break
        }
        case MapModeEnum.change:
          state.tableToChangeTo = [payload]
          break
      }
    },
    selectTables: (state, { payload }: PayloadAction<MapItem[]>) => {
      state.selectedTables = payload
    },
    unselectTable: (state, { payload }: PayloadAction<MapItem | undefined>) => {
      if (state.mode === MapModeEnum.one) {
        state.selectedTables = []
      } else if (state.mode === MapModeEnum.many) {
        const tableToRemoveIdx = state.selectedTables.findIndex(
          (item) => item.id === payload?.id
        )

        if (tableToRemoveIdx !== -1) {
          state.selectedTables.splice(tableToRemoveIdx, 1)
        }
      } else if (state.mode === MapModeEnum.change) {
        state.tableToChangeTo = []
      }
    },
    resetMap: (state) => {
      return state.isTakeTable ? undefined : initialState
    },
    setTakeTable: (state) => {
      state.isTakeTable = true
    },
    unsetTakeTable: (state) => {
      state.isTakeTable = false
    },
    setMobileMapPage: (
      state,
      { payload }: PayloadAction<MobileMapPagesEnum>
    ) => {
      state.mobileMapPage = payload
    }
  },
  extraReducers: (builder) => {
    const unselectTables = (state: MapSliceState) => {
      if (!state.isTakeTable) state.selectedTables = []
    }

    builder
      .addCase(getSliceOfTime.fulfilled, (state, action) => {
        state.tables = action.payload
      })
      .addCase(setReservationDate, unselectTables)
      .addCase(setReservationPersonsCount, unselectTables)
      .addCase(setReservationStart, unselectTables)
      .addCase(setReservationEnd, unselectTables)
      .addCase(setReservationByIdPageStep, (state) => {
        state.tableToChangeTo = []
      })
      .addCase(selectHall, (state: MapSliceState) => {
        if (!state.isTakeTable) state.selectedTables = []
      })
      .addCase(addReservation, (state: MapSliceState, { payload }) => {
        state.selectedTables = payload.places
      })
  }
})

export const {
  selectTable,
  selectTables,
  setMapMode,
  unselectTable,
  resetMap,
  setTakeTable,
  setMobileMapPage,
  unsetTakeTable,
  setMapDatetime,
  setShowDeposits
} = mapSlice.actions
