import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type MouseEventHandler
} from 'react'

import { cx } from 'class-variance-authority'
import { useNavigate } from 'react-router-dom'

import { MobileGuestWithTime } from '@/entities/guest'
import { MapModeEnum, selectMapMode } from '@/entities/map'
import { PATHS } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Card } from '@/shared/ui'

import { ReservationCardActions } from './ReservationCardActions'

import { MobileReservationBadgeCarousel } from '../MobileReservationBadgeCarousel'

import css from './ReservationCard.module.css'

type ReservationCardProps = Pick<ComponentPropsWithoutRef<'div'>, 'onClick'> & {
  id?: number
  guest: BaseGuest
  personsCount: number
  startDate: Date
  mainTableNumber: string
  source?: ReservationSource
  link?: ReservationLink | null
  tags?: SimpleTag[]
  status?: ReservationStatus
  endDate: Date | null
  withLink?: boolean
  isWhite?: boolean
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

const ReservationCard = forwardRef<HTMLDivElement, ReservationCardProps>(
  (
    {
      id,
      guest,
      personsCount,
      startDate,
      mainTableNumber,
      source,
      link,
      tags,
      status,
      endDate,
      updateReservationsList,
      withLink = false,
      isWhite = false,
      ...props
    },
    ref
  ) => {
    const mapMode = useAppSelector(selectMapMode)
    const navigate = useNavigate()

    const isChangingTable = mapMode === MapModeEnum.change

    const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation()
      if (isChangingTable) return

      if (withLink && id) {
        navigate(`${PATHS.reservation}/${id}`)
        return
      }

      props.onClick?.(e)
    }

    return (
      <Card
        ref={ref}
        className={cx(css.card, { [css.card_white]: isWhite })}
        onClick={onClickHandler}
      >
        <div className={css.container}>
          <MobileGuestWithTime
            className={css.card__guest}
            guest={guest}
            startDate={startDate}
            endDate={endDate}
            status={status}
            isWhite={isWhite}
          />
          <div
            className={cx(css.card__line, { [css.card__line_white]: isWhite })}
          />
        </div>
        <MobileReservationBadgeCarousel
          className={css.card__badges}
          personsCount={personsCount}
          tableNumber={mainTableNumber}
          source={source}
          linkName={link?.name ?? null}
          startDate={startDate}
          tags={tags}
          isWhite={isWhite}
        />
        <div className={cx(css.container, css.actions)}>
          {id && status && (
            <ReservationCardActions
              id={id}
              status={status}
              endDate={endDate}
              updateReservationsList={updateReservationsList}
              isWhite={isWhite}
            />
          )}
        </div>
      </Card>
    )
  }
)

ReservationCard.displayName = 'ReservationCard'

export default ReservationCard
