import { useState } from 'react'

import { addDays } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'
import { Button, DatePickerList, TemplateDates } from '@/shared/ui'
import { VaulBody, VaulFooter, VaulHeader } from '@/shared/ui/Vaul'

import css from './DatePickerVaul.module.css'

type DatePickerVaulProps = {
  value?: Date | null
  setValue: (value?: Date) => void
}

export default function DatePickerVaul({
  value,
  setValue
}: DatePickerVaulProps) {
  const [selectedDate, selectDate] = useState<Date | undefined>(
    value ?? undefined
  )

  return (
    <div className={css.content}>
      <VaulHeader className={css.header}>
        <h4 className={css.header__title}>Выберите дату</h4>
      </VaulHeader>
      <VaulBody>
        <DatePickerList
          selectDate={selectDate}
          selectedDate={selectedDate}
          startDate={TemplateDates.allTimeStartDate}
          endDate={addDays(nowWithTimezone(), 30)}
        />
        <VaulFooter>
          <Button
            className={css.button}
            variant="primary"
            size="lg"
            onClick={() => setValue(selectedDate)}
          >
            Выбрать
          </Button>
        </VaulFooter>
      </VaulBody>
    </div>
  )
}
