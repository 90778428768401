/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useParams } from 'react-router-dom'

import {
  useConfirmReservationRequestMutation,
  useReservationRequestQuery
} from '@/entities/reservation-request'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'

import { RequestPickTableStep } from './pick-table'
import { RequestConfirmSuccessScreen } from './success-screen'

type MobileRequestConfirmProcessProps = {
  requestId?: number
  onComplete?: () => void
}

export default function MobileRequestConfirmProcess({
  requestId = undefined,
  onComplete = undefined
}: MobileRequestConfirmProcessProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const { id: reservationReqId } = useParams()

  const [step, setStep] = useState(1)

  const id = requestId || reservationReqId

  const {
    data: dataRequestReservation,
    isFetching: isFetchingRequestReservation
  } = useReservationRequestQuery(
    addressId && id && !Number.isNaN(+id)
      ? { addressId, reservationReqId: +id }
      : skipToken
  )
  const [
    confirmReservation,
    { isLoading, isSuccess: isSuccessReservation, data: dataReservation }
  ] = useConfirmReservationRequestMutation()

  const nextStepHandler = (selectedTables: MapItem[]) => {
    if (!dataRequestReservation || !addressId || !selectedTables.length) return

    confirmReservation({
      addressId,
      reservationReqId: dataRequestReservation.id,
      body: {
        places: selectedTables.map((table) => table.id)
      }
    })
  }

  useEffect(() => {
    if (isSuccessReservation) {
      if (onComplete) onComplete()
      else setStep(2)
    }
  }, [isSuccessReservation])

  if (isFetchingRequestReservation) {
    return null
  }

  switch (step) {
    case 1:
      return (
        <RequestPickTableStep
          nextStepHandler={nextStepHandler}
          isLoading={isLoading}
        />
      )
    case 2:
      return (
        <RequestConfirmSuccessScreen
          reservation={dataReservation as Reservation}
        />
      )
    default:
  }
}
