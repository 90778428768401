import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type PropsWithChildren
} from 'react'

import { cx } from 'class-variance-authority'

import css from './Card.module.css'

type CardProps = PropsWithChildren & ComponentPropsWithoutRef<'div'>

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={cx(css.card, className)}>
        {children}
      </div>
    )
  }
)

export default Card
