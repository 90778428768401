import { cx } from 'class-variance-authority'
import { Link, useLocation } from 'react-router-dom'

import { Icon } from '@/shared/ui'

import { MobileNewReservationOptions } from './MobileNewReservationOptions'

import { MENU_ITEMS_MOBILE } from '../config'

import css from './MobileNav.module.css'

export default function MobileNav() {
  const { pathname } = useLocation()

  return (
    <nav className={css.menu}>
      {MENU_ITEMS_MOBILE.map((item) => {
        if (item.link) {
          return (
            <Link
              key={item.title}
              to={item.link}
              className={cx(css.menu__item, {
                [css.menu__item_active]: pathname.includes(item.link)
              })}
              aria-label={item.title}
            >
              <Icon name={item.icon} size={24} />
            </Link>
          )
        } else {
          return <MobileNewReservationOptions key={item.title} />
        }
      })}
    </nav>
  )
}
