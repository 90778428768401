import { useEffect, useState } from 'react'

import { A11y, FreeMode, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useGuestVisitsQuery } from '@/entities/visits'
import { formatRuWord } from '@/shared/lib'
import { Card, Skeleton } from '@/shared/ui'

import css from './VisitsInfo.module.css'

const guestInfoConfig = [
  {
    name: 'visit_count',
    label: ['Посещение', 'Посещения', 'Посещений']
  },
  {
    name: 'reservations_count',
    label: ['Бронь', 'Брони', 'Броней']
  },
  {
    name: 'canceled_visits',
    label: ['Отмена', 'Отмены', 'Отмен']
  }
]

type VisitsInfoProps = {
  guest: Guest
}

export function VisitsInfo({ guest }: VisitsInfoProps) {
  const [serializedVisitsInfo, setSerializedVisitsInfo] = useState<
    Guest & { reservations_count: number }
  >({
    ...guest,
    reservations_count: 0
  })

  const { data, isLoading, isError } = useGuestVisitsQuery(
    {
      guestId: guest.id,
      limit: 1,
      offset: 0
    },
    {
      refetchOnMountOrArgChange: true
    }
  )

  useEffect(() => {
    if (data) {
      setSerializedVisitsInfo((prev) => ({
        ...prev,
        reservations_count: data.count
      }))
    }
  }, [data])

  return (
    <Card className={css.root}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={8}
        modules={[A11y, FreeMode, Mousewheel]}
        className={css.slider}
        freeMode
        mousewheel={{
          sensitivity: 0.5
        }}
      >
        {isLoading || isError || !data
          ? Array.from({ length: 6 }).map((_, idx) => (
              <SwiperSlide key={idx} style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton} />
              </SwiperSlide>
            ))
          : guestInfoConfig.map(({ name, label }) => (
              <SwiperSlide key={name} style={{ width: 'auto' }}>
                <Card className={css.card}>
                  <span>{serializedVisitsInfo[name as keyof AllVisits]}</span>
                  <span className={css.label}>
                    {formatRuWord(
                      Number(serializedVisitsInfo[name as keyof AllVisits]),
                      label
                    )}
                  </span>
                </Card>
              </SwiperSlide>
            ))}
      </Swiper>
    </Card>
  )
}
