import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { hallsApi } from '@/entities/halls'

type DashboardState = {
  interval: IntervalData
  hallsOptions: Option[]
  selectedHall?: Option
}

const initialState: DashboardState = {
  interval: {
    start_date: dayjs().subtract(1, 'month').toDate(),
    end_date: dayjs().toDate()
  },
  hallsOptions: []
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardInterval: (state, { payload }: PayloadAction<IntervalData>) => {
      state.interval = payload
    },
    setDashboardHall: (state, { payload }: PayloadAction<Option>) => {
      state.selectedHall = payload
    },
    resetDashboard: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hallsApi.endpoints.halls.matchFulfilled,
      (state: DashboardState, { payload }) => {
        const hallsOptions = payload.map((hall) => ({
          value: hall.id.toString(),
          label: hall.name
        }))
        state.hallsOptions = hallsOptions
        state.selectedHall = hallsOptions[0]
      }
    )
  }
})

export const { setDashboardInterval, setDashboardHall, resetDashboard } =
  dashboardSlice.actions
