import { add, minutesInHour } from 'date-fns'

export const splitHours = (hours: Date[], splitBy: number): Date[] => {
  const outputArr = []
  for (let i = 0; i < hours.length; i++) {
    for (let j = 0; j < splitBy; j++) {
      const hour = add(hours[i], {
        minutes: Math.floor(minutesInHour / splitBy) * j
      })
      outputArr.push(hour)
    }
  }
  const lastHourOfInterval = add(hours[hours.length - 1], {
    hours: 1
  })
  outputArr.push(lastHourOfInterval)

  return outputArr
}
