import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Button,
  Checkbox,
  Icon,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import css from './FilterCheckboxListVaul.module.css'

type FilterCheckboxListVaulProps = {
  filterData: OldFilter
  setOpenFilter: Dispatch<SetStateAction<OldFilter | null>>
  activeValues: string[]
  setActiveFilters: Dispatch<
    SetStateAction<Record<string, string[]> | undefined>
  >
}

export function FilterCheckboxListVaul({
  filterData,
  setOpenFilter,
  activeValues,
  setActiveFilters
}: FilterCheckboxListVaulProps) {
  const { title, filterValues, name } = filterData
  const [activeCheckboxes, setActiveCheckboxes] =
    useState<string[]>(activeValues)

  const onChecked = (value: string) => {
    if (activeCheckboxes.includes(value)) {
      setActiveCheckboxes((prev) => prev.filter((item) => item !== value))
    } else {
      setActiveCheckboxes((prev) => [...prev, value])
    }
  }

  const onImprove = () => {
    setActiveFilters((prev) => ({ ...prev, [name]: activeCheckboxes }))
    setOpenFilter(null)
  }

  return (
    <>
      <VaulHeader>
        <Button
          size="icon"
          className={css.button_back}
          onClick={() => setOpenFilter(null)}
        >
          <Icon name="arrowLeft" />
        </Button>
        <h4 className={css.header}>{title}</h4>
      </VaulHeader>
      <VaulBody className={css.body}>
        {filterValues?.map((option) => (
          <div key={option.value} className={css.checkbox__wrapper}>
            <Checkbox
              variant="primary"
              id={option.value}
              onCheckedChange={() => onChecked(option.value)}
              checked={activeCheckboxes.includes(option.value)}
            />
            <label htmlFor={option.value} className={css.checkbox__label}>
              {option.label}
            </label>
          </div>
        ))}
      </VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button_submit}
          variant="primary"
          size="lg"
          onClick={onImprove}
        >
          Применить
          <Icon name="check" />
        </Button>
      </VaulFooter>
    </>
  )
}
