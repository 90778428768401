import {
  memo,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react'

import { cx } from 'class-variance-authority'
import { startOfDay } from 'date-fns'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { selectTables as setSelectTables, unselectTable } from '@/entities/map'
import {
  changeReservationsSelectedInterval,
  selectReservationsSelectedInterval
} from '@/entities/reservations'
import {
  selectCurDate,
  selectHours,
  selectTables,
  selectTableWithOpenTimeline,
  selectTimelineTable,
  setCurTimelineDate,
  setTableWithOpenTimeline,
  setTimelineTables,
  unselectTimelineTableThunk
} from '@/entities/timeline'
import { Reservation } from '@/features/create-update-reservation'
import { computeTimeSlots } from '@/features/timeline'
import { WaitingList } from '@/features/waiting-list'
import { nowWithTimezone, PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  DatePickerPopover,
  Drawer,
  DrawerContent,
  Icon,
  Loading,
  ScrollArea
} from '@/shared/ui'
import { ArchiveReservationsList } from '@/widgets/reservations'

import Slot from './Slot/Slot'

import css from './TimelineList.module.css'

type TimelineListProps = PropsWithChildren & {
  tableWSData?: Table[]
  isFetchingTableWSData: boolean
}

export const TimelineList = memo(
  ({ tableWSData, isFetchingTableWSData }: TimelineListProps) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isArchive, setIsArchive] = useState(false)
    const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
    const tables = useAppSelector(selectTables)
    const curDate = useAppSelector(selectCurDate)
    const hours = useAppSelector(selectHours)
    const tableWithOpenTimeline = useAppSelector(selectTableWithOpenTimeline)
    const dispatch = useAppDispatch()

    const tablesTimeSlots = useMemo(
      () =>
        tables.map((table) => ({
          id: table.id,
          timeSlots: computeTimeSlots(table, hours, startOfDay(curDate))
        })),
      [tables, hours, curDate]
    )

    const onCloseHandler = () => {
      dispatch(setTableWithOpenTimeline())
      setIsArchive(false)
      dispatch(unselectTable())
      searchParams.delete('places')
      navigate(pathname + '?' + searchParams.toString())
    }

    const toggleArchive = () => {
      if (isArchive) {
        dispatch(
          changeReservationsSelectedInterval({
            start_date: null,
            end_date: null
          })
        )
        searchParams.delete('places')
        navigate(pathname + '?' + searchParams.toString())
      } else {
        setDateArchiveHandler(startOfDay(nowWithTimezone()))
        searchParams.set('places', String(tableWithOpenTimeline?.id))
        navigate(pathname + '?' + searchParams.toString())
      }
      setIsArchive((prev) => !prev)
    }

    const setDateArchiveHandler = (date: Date | undefined) => {
      dispatch(
        changeReservationsSelectedInterval({
          start_date: date ?? null,
          end_date: date ?? null
        })
      )
    }

    const setDateTimelineHandler = (date: Date | undefined) => {
      if (!date) return
      dispatch(setCurTimelineDate(date))
    }

    useEffect(() => {
      if (tableWithOpenTimeline && tableWSData) {
        dispatch(setTimelineTables(tableWSData))
        dispatch(selectTimelineTable(tableWithOpenTimeline.id))
      }

      return () => {
        dispatch(unselectTimelineTableThunk())
      }
    }, [tableWithOpenTimeline, tableWSData])

    useEffect(() => {
      if (tableWSData && tableWithOpenTimeline) {
        dispatch(setSelectTables([tableWithOpenTimeline]))
      }
    }, [tableWSData])

    useEffect(() => {
      return () => {
        dispatch(setTableWithOpenTimeline())
      }
    }, [])

    const isLoading = isFetchingTableWSData || tablesTimeSlots.length === 0

    return (
      <Drawer open={!!tableWithOpenTimeline} direction="right">
        <DrawerContent
          withCloseButton
          className={css.content}
          onClose={onCloseHandler}
        >
          <div className={css.header}>
            <Button
              variant={isArchive ? 'secondary' : 'tertiary'}
              onClick={toggleArchive}
            >
              Архив
              <Icon name="archive" size={20} />
            </Button>
            {isArchive ? (
              <DatePickerPopover
                selected={selectedInterval.start_date ?? undefined}
                onSelectHandler={setDateArchiveHandler}
                disableNextDays
              />
            ) : (
              <DatePickerPopover
                selected={curDate}
                onSelectHandler={setDateTimelineHandler}
                withoutButtons
                disablePrevDays
              />
            )}
            {pathname === PATHS.map ? (
              <Reservation.Init />
            ) : (
              <WaitingList.SitGuestComplete />
            )}
          </div>
          <div className={css.body}>
            {isArchive ? (
              <ArchiveReservationsList />
            ) : (
              <ScrollArea className={css.scroll} padding="xl" size="md">
                <div
                  className={cx(css.slots, { [css.slots_loading]: isLoading })}
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    tablesTimeSlots.length !== 0 &&
                    tablesTimeSlots[0].timeSlots.map((slot) => (
                      <Slot key={slot.id} slot={slot} />
                    ))
                  )}
                </div>
              </ScrollArea>
            )}
          </div>
        </DrawerContent>
      </Drawer>
    )
  }
)
