import { differenceInMinutes, isAfter, isBefore } from 'date-fns'

export const getAvailableDuration = (timeSlots: TimeSlot[], curTime: Date) => {
  let closestSlot: TimeSlot

  for (const slot of timeSlots) {
    if (
      !slot.booked &&
      isAfter(curTime, slot.start_date) &&
      isBefore(curTime, slot.end_date)
    ) {
      closestSlot = slot
      const availableDuration = differenceInMinutes(
        closestSlot.end_date,
        curTime
      )
      return availableDuration
    }
  }
}
