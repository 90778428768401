import { differenceInMinutes, minutesInHour } from 'date-fns'

import { config } from '@/app/config'
import { nowWithTimezone } from '@/shared/lib'
const { TIMELINE_CELL_WIDTH, SPLIT_HOUR_BY, GROUP_HOUR_COEFFICIENT } = config

export const getPixelsFromIntervalStart = (startOfInterval: Date) => {
  const now = nowWithTimezone()

  const passedTimeFromStart = differenceInMinutes(now, startOfInterval)

  return (
    (((TIMELINE_CELL_WIDTH * SPLIT_HOUR_BY) / minutesInHour) *
      passedTimeFromStart) /
    GROUP_HOUR_COEFFICIENT
  )
}
