import { type ComponentPropsWithoutRef, useEffect } from 'react'

import { useLogoutMutation } from '@/entities/session'
import { baseApi, tagTypes } from '@/shared/api'
import { useAppDispatch } from '@/shared/model'
import { Button, type ButtonStyleProps } from '@/shared/ui'

import { logoutThunk } from '../model/logout'

type LogoutButtonProps = ButtonStyleProps & ComponentPropsWithoutRef<'button'>

export function LogoutButton({
  children = 'Выход',
  ...props
}: LogoutButtonProps) {
  const dispatch = useAppDispatch()

  const [logout, { isLoading, isSuccess }] = useLogoutMutation()

  const logoutHandler = () => {
    logout()
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(logoutThunk())
      dispatch(baseApi.util.invalidateTags([...Object.values(tagTypes)]))
    }
  }, [isSuccess])

  return (
    <Button onClick={logoutHandler} disabled={isLoading} {...props}>
      {children}
    </Button>
  )
}
