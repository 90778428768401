import { GuestsSearchHeader } from './GuestsSearchHeader'
import { MobileGuestsSearchList } from './MobileGuestsSearchList'

export function GuestSearchStep() {
  return (
    <>
      <GuestsSearchHeader />
      <MobileGuestsSearchList />
    </>
  )
}
