/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useAppSelector } from '@/shared/model'

import { selectReservation } from '../model/selectors'

export const useNewReservationSubmitAvailable = (): [
  boolean,
  ReservationDataWithGuest
] => {
  const newReservation = useAppSelector(selectReservation)

  const isNewReservationGuestStepAvailable = Boolean(
    newReservation.personsCount &&
      newReservation.tables.length &&
      newReservation.date &&
      newReservation.start &&
      (newReservation.end || newReservation.end === null) &&
      newReservation.guest
  )

  return [
    isNewReservationGuestStepAvailable,
    newReservation as ReservationDataWithGuest
  ]
}
