/* eslint-disable import/no-duplicates */
import { type ComponentPropsWithoutRef, useState } from 'react'

import { cva, cx, type VariantProps } from 'class-variance-authority'
import { add, eachDayOfInterval, isSameDay, startOfToday } from 'date-fns'
import { ru } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'

import { useClickOutside } from '@/shared/hooks'

import { generateDateLabel } from './lib'

import { Button } from '../Button'
import { Icon } from '../Icon'

import css from './DatePicker.module.css'

const pickerStyle = cva(css.picker, {
  variants: {
    variant: {
      default: css.picker_default,
      primary: css.picker_primary
    },
    size: {
      sm: css.picker_sm,
      md: css.picker_md,
      lg: css.picker_lg
    },
    isSuggestions: {
      true: '',
      false: css.picker_noSuggestions
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'sm',
    isSuggestions: true
  }
})

const calendarStyle = cva(css.picker__calendar, {
  variants: {
    size: {
      sm: css.picker__calendar_sm,
      md: css.picker__calendar_md,
      lg: css.picker__calendar_lg
    },
    isSuggestions: {
      true: '',
      false: css.picker__calendar_noSuggestions
    }
  },
  defaultVariants: {
    size: 'sm',
    isSuggestions: true
  }
})

export type DatePickerProps = ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof pickerStyle> & {
    selectedDate: Date | undefined
    selectDate: (date: Date) => void
    daysFromToday?: number
    disablePrevDays?: boolean
    disableCalendarLabel?: boolean
    buttonVariant?: 'transparent' | 'default'
  }

export default function DatePicker({
  selectedDate,
  selectDate,
  daysFromToday = 5,
  disablePrevDays = false,
  buttonVariant = 'transparent',
  variant,
  size,
  isSuggestions = true,
  disableCalendarLabel = false,
  className
}: DatePickerProps) {
  const today = startOfToday()
  const days = eachDayOfInterval({
    start: today,
    end: add(today, { days: daysFromToday })
  })

  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const calendarRef = useClickOutside(() => setIsCalendarOpen(false))

  const selectDateHandler = (date: Date | undefined) => {
    if (date) selectDate(date)
    setIsCalendarOpen(false)
  }

  const toggleCalendarHandler = () => {
    setIsCalendarOpen((prev) => !prev)
  }

  return (
    <div
      ref={calendarRef}
      className={pickerStyle({ variant, size, isSuggestions, className })}
    >
      <Button
        className={css.picker__trigger}
        variant={buttonVariant}
        size={disableCalendarLabel ? 'icon' : size}
        onClick={toggleCalendarHandler}
      >
        {!disableCalendarLabel && (
          <>{selectedDate ? generateDateLabel(selectedDate) : 'Выбрать дату'}</>
        )}
        <Icon name="calendar" />
      </Button>
      {isCalendarOpen && (
        <div className={calendarStyle({ size, isSuggestions })}>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={selectDateHandler}
            disabled={disablePrevDays && { before: today }}
            locale={ru}
          />
        </div>
      )}
      {isSuggestions && (
        <div className={css.picker__scrollable}>
          {days.map((day) => (
            <Button
              key={day.toISOString()}
              className={cx(css.picker__button, {
                [css.picker__button_sm]: size === 'sm',
                [css.picker__button_selected]:
                  selectedDate && isSameDay(selectedDate, day)
              })}
              variant={
                selectedDate && isSameDay(selectedDate, day)
                  ? 'tertiary'
                  : buttonVariant
              }
              size={size}
              onClick={() => selectDateHandler(day)}
            >
              {generateDateLabel(day)}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
