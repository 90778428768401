import { type ReactElement } from 'react'

import {
  selectIsSelectedAddress,
  useProfileDataQuery
} from '@/entities/session'
import NoAccountDataPage from '@/pages/no-account-data'
import SelectRestaurantPage from '@/pages/select-restaurant'
import { useAppSelector } from '@/shared/model'
import { Error, Loading } from '@/shared/ui'

export function ProfileProvider({ children }: { children: ReactElement }) {
  const isSelectedAddress = useAppSelector(selectIsSelectedAddress)
  const { data, isLoading, isError, refetch } = useProfileDataQuery()

  if (isLoading) {
    return <Loading fullscreen />
  }

  if (data?.chains.length === 0) {
    return <NoAccountDataPage title="К вашему аккаунту не привязана сеть" />
  }

  if (data?.addresses.length === 0) {
    return <NoAccountDataPage title="К вашему аккаунту не привязан ресторан" />
  }

  if (data?.addresses && data.addresses.length > 1 && !isSelectedAddress) {
    return <SelectRestaurantPage addresses={data.addresses} />
  }

  if (isError) {
    return <Error fullscreen refetch={refetch} />
  }

  return children
}
