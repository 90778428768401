import { createAsyncThunk } from '@reduxjs/toolkit'

import { unselectTimelineTable } from '../model/slice'

export const unselectTimelineTableThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('timeline/unselectTimelineTable', async (data, thunkAPI) => {
  const {
    map: { isTakeTable }
  } = thunkAPI.getState()

  if (!isTakeTable) {
    thunkAPI.dispatch(unselectTimelineTable())
  }
})
