import { useEffect, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import {
  ReservationSkipStep,
  resetReservation,
  setReservationGuest,
  setReservationIsNewGuest,
  setReservationSkipStep
} from '@/entities/create-update-reservation'
import { setIsCreateGuest, useCreateGuestMutation } from '@/entities/guest'
import { formatName, formatPhoneNumberRU, PATHS } from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'
import { Badge, Button, Card, Icon, Tag } from '@/shared/ui'
import { CreateEditGuest } from '@/widgets/guest'

import css from './CallsCard.module.css'

type CallsCardProps = {
  guest: TelephonyGuest | null
  status: CallStatus
  phoneNewGuest: null | string
  onCloseDrawer: () => void
}

export function CallsCard({
  guest,
  status,
  phoneNewGuest,
  onCloseDrawer
}: CallsCardProps) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const linkGuest = () => {
    if (!guest) return

    navigate(`${PATHS.guest}/${guest.id}`)
    onCloseDrawer()
  }

  const linkNewReservation = async () => {
    const addGuest = () => {
      if (guest) {
        dispatch(
          setReservationGuest({
            id: guest.id,
            last_name: guest.last_name ?? '',
            first_name: guest.first_name ?? '',
            tags: guest.tags,
            phone: guest.phone,
            comment: guest.comment,
            is_anonymous: false
          })
        )
        if (guest.first_name) {
          dispatch(setReservationSkipStep(ReservationSkipStep.GUEST))
        } else {
          dispatch(setReservationIsNewGuest(true))
        }
      } else {
        dispatch(
          setReservationGuest({
            id: -1,
            last_name: '',
            first_name: '',
            tags: [],
            phone: phoneNewGuest ?? '',
            comment: ''
          })
        )
        dispatch(setReservationIsNewGuest(true))
      }
    }

    if (pathname === PATHS.newReservation) {
      dispatch(resetReservation())
      addGuest()
    } else {
      addGuest()
      navigate(PATHS.newReservation)
    }
    onCloseDrawer()
  }

  const [createGuest, { isLoading, isSuccess }] = useCreateGuestMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setIsCreateGuest(true))
      setOpen(false)
    }
  }, [isSuccess])

  const defaultValues = {
    firstName: '',
    lastName: '',
    phone: phoneNewGuest ?? ''
  }

  return (
    <Card className={css.card}>
      <div className={css.card__info}>
        <div className={css.card__info_left}>
          <div className={css.card__guest}>
            <p>
              {guest
                ? formatName(guest.first_name, guest.last_name)
                : 'Новый гость'}
            </p>
            {guest?.tags[0] && (
              <Tag
                className={css.header__badge}
                size="xxs"
                tag={guest.tags[0]}
              />
            )}
            {guest && guest.tags.length > 1 && (
              <Badge size="xxs">+{guest.tags.length - 1}</Badge>
            )}
          </div>
          <p className={css.card__phone}>
            {formatPhoneNumberRU(guest ? guest.phone : phoneNewGuest)}
          </p>
        </div>
        {status === 'calling' && (
          <Badge variant="greenTwo">
            <Icon name="phoneCall" />
            Входящий звонок
          </Badge>
        )}
        {status === 'current' && (
          <Badge variant="yellow">
            <Icon name="phone" />
            Идёт разговор
          </Badge>
        )}
      </div>
      <div className={css.card__buttons}>
        {guest ? (
          <Button
            variant="tertiary"
            size="sm"
            contentClassName={css.button__content}
            onClick={linkGuest}
          >
            Карточка пользователя
            <Icon name="user" size={20} />
          </Button>
        ) : (
          <CreateEditGuest
            open={open}
            setOpen={setOpen}
            defaultValues={defaultValues}
            isLoading={isLoading}
            createGuest={createGuest}
          >
            <Button
              variant="tertiary"
              size="sm"
              contentClassName={css.button__content}
            >
              Создать карточку гостя
              <Icon name="plus" size={20} />
            </Button>
          </CreateEditGuest>
        )}
        <Button
          variant="primary"
          size="sm"
          contentClassName={css.button__content}
          onClick={linkNewReservation}
        >
          Создать бронь
          <Icon name="plus" size={20} />
        </Button>
      </div>
    </Card>
  )
}
