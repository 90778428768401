import { useState } from 'react'

import { Drawer } from 'vaul'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  VaulClose
} from '@/shared/ui'

export default function MobileCloseCreate() {
  const [open, setOpen] = useState(false)

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  return (
    <AlertDialog open={open} onOpenChange={toggleDialogHandler}>
      <AlertDialogTrigger asChild>
        <VaulClose
          onClick={(e) => {
            e.preventDefault()
            toggleDialogHandler()
          }}
        />
      </AlertDialogTrigger>
      <AlertDialogContent onClose={toggleDialogHandler}>
        <AlertDialogHeader>
          <AlertDialogTitle>Завершить бронирование</AlertDialogTitle>
          <AlertDialogDescription>
            Вы&nbsp;уверены, что хотите завершить процесс создания брони?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Drawer.Close>Завершить</Drawer.Close>
          </AlertDialogAction>
          <AlertDialogCancel>Отмена</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
