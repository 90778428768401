import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import { useUpdateReservationSaveAvailable } from '@/entities/create-update-reservation'
import {
  ReservationByIdPageSteps,
  setReservationByIdPageStep,
  useUpdateReservationMutation
} from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { dateToDateWithTimezone, formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, type ButtonStyleProps } from '@/shared/ui'

type SaveReservationButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonStyleProps &
  PropsWithChildren & {
    reservationId?: number
    onSuccessHandler?: () => void
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation | Visit>
    ) => void
  }

export function SaveReservationButton({
  reservationId,
  children,
  onSuccessHandler,
  updateReservationsList,
  ...props
}: SaveReservationButtonProps) {
  const dispatch = useAppDispatch()
  const [isAvailable, updatedReservation] = useUpdateReservationSaveAvailable()
  const addressId = useAppSelector(selectSelectedAddressId)
  const [update, { isLoading }] = useUpdateReservationMutation()

  const saveReservationHandler = async () => {
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return
    try {
      await update({
        addressId,
        reservationId: +reservationId,
        body: {
          start_date: formatTimeForRequest(
            dateToDateWithTimezone(updatedReservation.start)
          ),
          end_date: updatedReservation.end
            ? formatTimeForRequest(
                dateToDateWithTimezone(updatedReservation.end)
              )
            : undefined,
          places: updatedReservation.tables.map((table) => table.id),
          persons_count: updatedReservation.personsCount
        }
      })
      dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.VIEW))
      onSuccessHandler?.()
      updateReservationsList?.(reservationId, false, {
        main_table_number: updatedReservation.tables.map(
          (table) => table.item_number
        )[0],
        start_date: updatedReservation.start,
        end_date: updatedReservation.end ? updatedReservation.end : undefined,
        places: updatedReservation.tables.map((table) => table.id),
        persons_count: updatedReservation.personsCount
      })
    } catch {
      // do nothing
    }
  }

  return (
    <Button
      disabled={!isAvailable || !reservationId || !addressId}
      isLoading={isLoading}
      onClick={saveReservationHandler}
      {...props}
    >
      {children}
    </Button>
  )
}
