import { cx } from 'class-variance-authority'

import Loading from '../Loading/Loading'

import css from './Fetching.module.css'

type FetchingProps = {
  className?: string
}

export default function Fetching({ className }: FetchingProps) {
  return (
    <div className={cx(css.container, className)}>
      <Loading variant="black" />
    </div>
  )
}
