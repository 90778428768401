import { type Dispatch, type SetStateAction } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { isAfter, isBefore } from 'date-fns'

import { Map, useMapTablesQuery } from '@/entities/map'
import { ReservationStatusEnum } from '@/entities/reservations'
import { selectSelectedAddressId } from '@/entities/session'
import { useTimelineTablesWSQuery } from '@/entities/timeline'
import { Reservation } from '@/features/reservation'
import { formatTimeForRequest, nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Button, Card, Dot, Icon, Skeleton } from '@/shared/ui'

import { isReservationLate } from '../../lib'

import css from './MobileReservationTableCard.module.css'

type MobileReservationTableCardProps = {
  idx?: number
  tableNumber?: string
  tableId: number
  hallName: string
  hallId: number
  startDate: Date
  endDate?: Date | null
  personsCount: number
  reservationId?: number
  status?: ReservationStatus
  setPrevReservationId?: Dispatch<SetStateAction<number | null>>
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

export default function MobileReservationTableCard({
  idx,
  tableNumber,
  tableId,
  hallName,
  hallId,
  startDate,
  endDate,
  personsCount,
  reservationId,
  status,
  setPrevReservationId,
  updateReservationsList
}: MobileReservationTableCardProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const { data } = useMapTablesQuery(
    startDate && personsCount && hallId
      ? {
          hallId,
          start_date: formatTimeForRequest(startDate),
          end_date: formatTimeForRequest(endDate),
          persons: personsCount
        }
      : skipToken
  )
  const { data: doubleResponse, isFetching: isReservationsFetching } =
    useTimelineTablesWSQuery(
      startDate && addressId
        ? {
            addressId,
            date: formatTimeForRequest(startDate).split(' ')[0]
          }
        : skipToken
    )

  if (isReservationsFetching || !doubleResponse?.length) {
    return <Skeleton className={css.skeleton} />
  }

  const reservations = doubleResponse?.[doubleResponse.length - 1].reservations

  const curTableReservations = reservations?.filter(
    (reservation) => reservation.table === tableId
  )

  const prevReservation = curTableReservations?.find((reservation) => {
    return (
      isAfter(nowWithTimezone(), reservation.start_date) &&
      isBefore(nowWithTimezone(), reservation.end_date) &&
      (reservation.status === ReservationStatusEnum.inProgress ||
        reservation.status === ReservationStatusEnum.confirmed) &&
      reservation.id !== reservationId
    )
  })

  const isLate = endDate && isReservationLate(endDate)
  const isInProgress =
    endDate &&
    isAfter(nowWithTimezone(), startDate) &&
    isBefore(nowWithTimezone(), endDate)

  return (
    <Card className={css.card}>
      <div className={css.card__header}>
        <h4 className={css.card__title}>{hallName}</h4>
        <div className={css.card__info}>
          {tableNumber && (
            <span className={css.card__table}>Столик №{tableNumber}</span>
          )}
          {reservationId && prevReservation && (
            <>
              <Dot className={css.dot_red} />
              <span className={css.card__status}>занят</span>
            </>
          )}
          {reservationId && !isLate && !isInProgress && !prevReservation && (
            <Dot className={css.dot_green} />
          )}
        </div>
      </div>
      <div className={css.card__preview}>
        <Map
          tables={data}
          zoomTo={`${idx}${tableId}`}
          mapIdx={idx}
          selectedTableId={tableId}
          onlyView
          withComingSoon={false}
          withRecentlyCompleted
        />
      </div>
      {reservationId &&
        status &&
        (status === ReservationStatusEnum.confirmed ||
          status === ReservationStatusEnum.inProgress) && (
          <Reservation.MobileChangeTable
            variant="tertiary"
            size="lg"
            className={css.button}
            reservationId={reservationId}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="pencil" />
            Изменить посадку
          </Reservation.MobileChangeTable>
        )}
      {reservationId && prevReservation && !isInProgress && (
        <Button
          variant="tertiary"
          size="lg"
          className={css.button}
          onClick={() => setPrevReservationId?.(prevReservation.id)}
        >
          <Icon name="info" />
          Предыдущая бронь
        </Button>
      )}
    </Card>
  )
}
