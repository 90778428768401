import { useEffect } from 'react'

import { add } from 'date-fns'
import { useTimer } from 'react-timer-hook'

import { secondsToMinAndSec } from '@/shared/lib'
import { Badge, Icon } from '@/shared/ui'

import css from './TimerDown.module.css'

type TimerDownProps = {
  secondsLeft: number
}

export function TimerDown({ secondsLeft }: TimerDownProps) {
  const now = new Date()
  const expiryTimestamp = add(now, { seconds: secondsLeft })
  const { totalSeconds, restart } = useTimer({
    expiryTimestamp,
    autoStart: true
  })

  const [min, sec] = secondsToMinAndSec(totalSeconds)

  useEffect(() => {
    const now = new Date()
    const newExpiryTimestamp = add(now, { seconds: secondsLeft })
    restart(newExpiryTimestamp)
  }, [secondsLeft])

  return (
    <Badge
      size="xxs"
      variant={totalSeconds <= 0 ? 'red' : 'tertiary'}
      className={css.timer}
    >
      {`${min}:${sec.toString().padStart(2, '0')}`}
      <Icon name="hourglass" size={12} />
    </Badge>
  )
}
