export enum FilterTypesEnum {
  reservation = 'reservation',
  guest = 'guest',
  mailing = 'mailing'
}

export enum FilterSelectStrategy {
  single = 'single',
  multi = 'multi'
}
