import ReconnectingWebSocket from 'reconnecting-websocket'

import { config } from '@/app/config'

const initWS = () => {
  const wsConnections = new Map<string, ReconnectingWebSocket>()

  return (path: string) => {
    const pathname = path.split('?')[0]
    const queryParams = path.split('?')[1]

    let ws = wsConnections.get(pathname)

    const wsPathName = ws?.url.split('?')[0]
    const wsQueryParams = ws?.url.split('?')[1]

    if (
      pathname === wsPathName &&
      queryParams &&
      wsQueryParams &&
      queryParams !== wsQueryParams
    ) {
      ws?.close()
    }

    if (ws && ws.readyState !== ws.CLOSED && ws.readyState !== ws.CLOSING) {
      return ws
    }

    ws = new ReconnectingWebSocket(`${config.WS_API_ENDPOINT}${path}`, [], {
      maxRetries: 10
    })
    wsConnections.set(path, ws)
    return ws
  }
}

export const wsInstance = initWS()
