import { useState } from 'react'

import { resetHalls } from '@/entities/halls'
import {
  selectSelectedAddressId,
  setSelectedAddressId,
  setSelectedChainId
} from '@/entities/session'
import { LogoutButton } from '@/features/authorization/Logout'
import { baseApi, tagTypes } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/shared/ui'

import css from './index.module.css'

type SelectRestaurantPageProps = {
  addresses: ProfileDataAddress[]
}

export default function SelectRestaurantPage({
  addresses
}: SelectRestaurantPageProps) {
  const dispatch = useAppDispatch()
  const selectedAddressId = useAppSelector(selectSelectedAddressId)

  const addressOptions: Option[] = addresses.map((address) => ({
    label: address.name,
    value: address.address.toString()
  }))

  const defaultOption = selectedAddressId
    ? addressOptions.find((opt) => opt.value === selectedAddressId?.toString())
    : addressOptions[0]

  const [selectedAddressValue, setSelectedAddressValue] = useState(
    defaultOption?.value
  )

  const enterRestaurantHandler = () => {
    if (!selectedAddressValue || Number.isNaN(+selectedAddressValue)) {
      return
    }

    const selectedAddress = addresses.find(
      (address) => address.address === +selectedAddressValue
    )

    if (!selectedAddress) return

    dispatch(setSelectedChainId(selectedAddress.chain_id))
    dispatch(setSelectedAddressId(+selectedAddress.address))
    dispatch(resetHalls())
    dispatch(baseApi.util.invalidateTags(Object.values(tagTypes)))
  }

  return (
    <div className={css.root}>
      <h2 className={css.root__heading}>Выберите ресторан</h2>
      <Select
        value={selectedAddressValue}
        onValueChange={setSelectedAddressValue}
      >
        <SelectTrigger className={css.root__select}>
          <SelectValue placeholder="Выберите" />
        </SelectTrigger>
        <SelectContent>
          {addressOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className={css.root__actions}>
        <LogoutButton>Выйти</LogoutButton>
        <Button
          variant="primary"
          onClick={enterRestaurantHandler}
          disabled={!selectSelectedAddressId || !selectedAddressValue}
        >
          Войти
        </Button>
      </div>
    </div>
  )
}
