import { useEffect, useState } from 'react'

const useUpdateComponent = (interval = 1) => {
  const [someValue, setSomeValue] = useState<number>()

  useEffect(() => {
    const timer = setInterval(() => {
      setSomeValue(Date.now())
    }, interval * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [someValue])
}

export default useUpdateComponent
