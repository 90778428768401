import { useEffect } from 'react'

import { useFocus } from '@/shared/hooks'

export default function DynamicViewportUnits() {
  const { isFocus, dvhWithKeyboard } = useFocus()

  useEffect(() => {
    const handleWindowResize = () => {
      if (!window.visualViewport) return

      const dvh = window.visualViewport.height / 100
      const dvw = window.visualViewport.width / 100

      document.body.style.setProperty(
        '--dvh',
        `${isFocus ? dvhWithKeyboard : dvh}px`
      )
      document.body.style.setProperty('--dvw', `${dvw}px`)
    }

    handleWindowResize()
    window.visualViewport?.addEventListener('resize', handleWindowResize)

    return () => {
      window.visualViewport?.removeEventListener('resize', handleWindowResize)
    }
  }, [isFocus, dvhWithKeyboard])

  return null
}
