import { Tab, TabsGroup, VaulHeader } from '@/shared/ui'

import css from './GuestVaulCardHeader.module.css'

export default function GuestVaulCardHeader() {
  return (
    <VaulHeader className={css.header}>
      <div className={css.header}>
        <TabsGroup className={css.header__tabs}>
          <Tab className={css.header__tab} value="info">
            Инфо
          </Tab>
          <Tab className={css.header__tab} value="reservations">
            Брони
          </Tab>
        </TabsGroup>
        <div />
      </div>
    </VaulHeader>
  )
}
