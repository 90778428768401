import {
  MobileReservationSteps,
  setMobileReservationStep,
  setReservationGuest
} from '@/entities/create-update-reservation'
import { SearchGuestSteps, setSearchGuestStep } from '@/entities/search-guests'
import { CreateGuestForm } from '@/features/guest/create-mobile/ui/CreateGuestForm'
import { useAppDispatch } from '@/shared/model'
import { VaulBody } from '@/shared/ui'

import { ReservationProcessHeader } from '../../ReservationProcessHeader'

export function GuestCreateStep() {
  const dispatch = useAppDispatch()

  const backHandler = () => {
    dispatch(setSearchGuestStep(SearchGuestSteps.one))
  }

  const selectGuestHandler = (guest?: Guest) => {
    dispatch(setReservationGuest(guest))
    dispatch(setSearchGuestStep(SearchGuestSteps.one))
    dispatch(setMobileReservationStep(MobileReservationSteps.TWO))
  }

  return (
    <>
      <ReservationProcessHeader backHandler={backHandler} title="Новый гость" />
      <VaulBody>
        <CreateGuestForm successHandler={selectGuestHandler} />
      </VaulBody>
    </>
  )
}
