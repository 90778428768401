import { Suspense } from 'react'

import { cx } from 'class-variance-authority'
import { Outlet } from 'react-router-dom'

import { Telephony } from '@/features/telephony'
import { useFocus } from '@/shared/hooks'
import { isPWA } from '@/shared/lib'
import { Loading } from '@/shared/ui'
import { MobileNav } from '@/widgets/navigation'

import css from './MobileLayout.module.css'

type MobileLayoutProps = {
  withMenu?: boolean
  headerSlot?: JSX.Element
  footerSlot?: JSX.Element
}

export default function MobileLayout({
  withMenu = false,
  headerSlot,
  footerSlot
}: MobileLayoutProps) {
  const { isFocus } = useFocus()

  return (
    <div className={css.layout}>
      <Telephony.MobileQuick />
      {headerSlot && <div className={css.layout__header}>{headerSlot}</div>}
      <Suspense fallback={<Loading fullscreen />}>
        <Outlet />
      </Suspense>
      {withMenu && (
        <div
          className={cx(css.layout__footer, {
            [css.layout__footer_pwa]: isPWA()
          })}
        >
          {footerSlot}
          {!isFocus && <MobileNav />}
        </div>
      )}
    </div>
  )
}
