import { useEffect, useRef } from 'react'

import { isWithinInterval } from 'date-fns'

import { ReservationCard } from '@/entities/reservation'
import { nowWithTimezone } from '@/shared/lib'

import EmptySlot from '../EmptySlot/EmptySlot'

type SlotProps = {
  slot: TimeSlot
}

export default function Slot({ slot }: SlotProps) {
  const {
    id,
    booked,
    start_date,
    end_date,
    guest,
    persons_count,
    main_table_number,
    source,
    link,
    tags,
    status
  } = slot
  const slotRef = useRef<HTMLDivElement>(null)
  const now = nowWithTimezone()

  useEffect(() => {
    const isScrollIntoView = isWithinInterval(now, {
      start: start_date,
      end: end_date
    })
    if (slotRef.current && isScrollIntoView) {
      slotRef.current.scrollIntoView({ behavior: 'instant' })
    }
  }, [slotRef])

  return (
    <div ref={slotRef}>
      {booked && guest && persons_count && main_table_number ? (
        <ReservationCard
          id={+id}
          guest={guest}
          personsCount={persons_count}
          startDate={start_date}
          mainTableNumber={main_table_number}
          source={source}
          link={link}
          tags={tags}
          status={status}
          endDate={end_date}
          withLink
        />
      ) : (
        <EmptySlot key={slot.id} slot={slot} />
      )}
    </div>
  )
}
