import { isAfter } from 'date-fns'

import { nowWithTimezone } from './nowWithTimezone'

export const findCurrentIntervalValue = (interval: Date[]) => {
  for (let i = 0; i < interval.length; i++) {
    if (isAfter(interval[i], nowWithTimezone())) {
      return { value: interval[i], idx: i }
    }
  }
}
