import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'

import css from './Icon.module.css'

const Icons = {
  settings: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 8h12m0 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0Zm-6 8h12M9 16a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  ),
  filter: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h12M3 6h18M9 18h6"
      />
    </svg>
  ),
  archive: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 8a2 2 0 0 1-1.96-1.6C2 6.19 2 5.95 2 5.49s0-.7.04-.89A2 2 0 0 1 3.6 3.04C3.81 3 4.04 3 4.5 3h15c.46 0 .7 0 .89.04a2 2 0 0 1 1.57 1.57c.04.2.04.43.04.89s0 .7-.04.89A2 2 0 0 1 20 7.99M10 13h4M4 8h16v8.2c0 1.68 0 2.52-.33 3.16a3 3 0 0 1-1.3 1.31c-.65.33-1.49.33-3.17.33H8.8c-1.68 0-2.52 0-3.16-.33a3 3 0 0 1-1.31-1.3C4 18.71 4 17.87 4 16.2V8Z"
      />
    </svg>
  ),
  x: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 7 7 17M7 7l10 10"
      />
    </svg>
  ),
  bookmark: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M5 7.8c0-1.68 0-2.52.33-3.16a3 3 0 0 1 1.3-1.31C7.29 3 8.13 3 9.8 3h4.4c1.68 0 2.52 0 3.16.33a3 3 0 0 1 1.31 1.3c.33.65.33 1.49.33 3.17V21l-7-4-7 4V7.8Z"
      />
    </svg>
  ),
  plusCircle: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 8v8m-4-4h8m6 0a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z"
      />
    </svg>
  ),
  user: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 21c0-1.4 0-2.1-.17-2.66a4 4 0 0 0-2.67-2.67c-.57-.17-1.26-.17-2.66-.17h-5c-1.4 0-2.1 0-2.66.17a4 4 0 0 0-2.67 2.67C4 18.9 4 19.6 4 21M16.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
      />
    </svg>
  ),
  userX: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.75 3.16675L15.0833 6.50008M15.0833 3.16675L11.75 6.50008M11.4167 14.5001V13.7001C11.4167 12.58 11.4167 12.0199 11.1987 11.5921C11.0069 11.2158 10.701 10.9098 10.3247 10.7181C9.89683 10.5001 9.33678 10.5001 8.21668 10.5001H5.28334C4.16324 10.5001 3.60319 10.5001 3.17536 10.7181C2.79904 10.9098 2.49308 11.2158 2.30133 11.5921C2.08334 12.0199 2.08334 12.58 2.08334 13.7001V14.5001M9.08334 5.50008C9.08334 6.78875 8.03867 7.83341 6.75001 7.83341C5.46135 7.83341 4.41668 6.78875 4.41668 5.50008C4.41668 4.21142 5.46135 3.16675 6.75001 3.16675C8.03867 3.16675 9.08334 4.21142 9.08334 5.50008Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  users: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 21v-2a4 4 0 0 0-3-3.87M15.5 3.29a4 4 0 0 1 0 7.42M17 21c0-1.86 0-2.8-.3-3.53a4 4 0 0 0-2.17-2.17C13.8 15 12.86 15 11 15H8c-1.86 0-2.8 0-3.53.3a4 4 0 0 0-2.17 2.17C2 18.2 2 19.14 2 21M13.5 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      />
    </svg>
  ),
  home: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 17h8M11.02 2.76 4.24 8.04c-.46.35-.68.53-.85.75a2 2 0 0 0-.32.65C3 9.7 3 9.99 3 10.57v7.23c0 1.12 0 1.68.22 2.1.19.38.5.69.87.88.43.22.99.22 2.11.22h11.6c1.12 0 1.68 0 2.1-.22a2 2 0 0 0 .88-.87c.22-.43.22-.99.22-2.11v-7.23c0-.58 0-.87-.07-1.13a2 2 0 0 0-.32-.65c-.17-.22-.4-.4-.85-.75l-6.78-5.28a2.33 2.33 0 0 0-.72-.46 1 1 0 0 0-.52 0c-.2.05-.37.2-.72.46Z"
      />
    </svg>
  ),
  eye: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.42 12.71c-.14-.21-.2-.32-.24-.49a1.17 1.17 0 0 1 0-.44c.04-.17.1-.28.24-.5C3.55 9.5 6.9 5 12 5s8.46 4.5 9.58 6.29c.14.21.2.32.24.49.03.12.03.32 0 .44-.04.17-.1.28-.24.5C20.46 14.5 17.11 19 12 19c-5.1 0-8.45-4.5-9.58-6.29Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
    </svg>
  ),
  eyeOff: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.74 5.1c.4-.07.83-.1 1.26-.1 5.1 0 8.46 4.5 9.58 6.29.14.21.2.32.24.49.03.12.03.32 0 .44-.04.17-.1.28-.24.5-.3.47-.76 1.14-1.36 1.86M6.72 6.72a16 16 0 0 0-4.3 4.57c-.14.21-.2.32-.24.49-.03.12-.03.32 0 .44.04.17.1.28.24.5C3.55 14.5 6.9 19 12 19c2.06 0 3.83-.73 5.29-1.72M3 3l18 18M9.88 9.88A3 3 0 0 0 12 15a3 3 0 0 0 2.12-.88"
      />
    </svg>
  ),
  switch: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 17h16m0 0-4-4m4 4-4 4m4-14H4m0 0 4-4M4 7l4 4"
      />
    </svg>
  ),
  merge: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 3v3m0 4.5v3m0 4.5v3m10-9h-6.5m0 0 4 4m-4-4 4-4M2 12h6.5m0 0-4 4m4-4-4-4"
      />
    </svg>
  ),
  trash: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 3h6M3 6h18m-2 0-.7 10.52c-.1 1.58-.16 2.37-.5 2.96a3 3 0 0 1-1.3 1.22c-.62.3-1.4.3-3 .3h-3c-1.6 0-2.38 0-3-.3a3 3 0 0 1-1.3-1.21c-.34-.6-.4-1.4-.5-2.97L5 6"
      />
    </svg>
  ),
  split: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 12h12M6 12l2-3m-2 3 2 3m10-3-2-3m2 3-2 3m5 6V3M3 21V3"
      />
    </svg>
  ),
  clock: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6v6l4 2m6-2a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z"
      />
    </svg>
  ),
  clockSnooze: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M16.5 17h5l-5 5h5m.45-9A10 10 0 1 0 13 21.95M12 6v6l3.74 1.87"
      />
    </svg>
  ),
  clockTransfer: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6333 7.66667L14.3004 9L12.9666 7.66667M14.4634 8.66667C14.4876 8.44778 14.5 8.22534 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14C10.3848 14 12.0667 13.1309 13.1667 11.7716M8.5 4.66667V8L10.5 9.33333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect fill="currentColor" transform="translate(0.5)" />
    </svg>
  ),
  restaurant: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M3 2v7c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2V2M7 2v20M21 15V2a5 5 0 0 0-5 5v6c0 1.1.9 2 2 2h3Zm0 0v7"
      />
    </svg>
  ),
  comment: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <path
        fill="currentColor"
        d="M15 3C7.82 3 2 7.92 2 14c0 3.37 1.8 6.38 4.61 8.4.07 1.53-.16 3.65-2.28 4.63a.5.5 0 0 0-.33.47.5.5 0 0 0 .5.5h.04c2.44-.02 4.5-1.33 5.93-2.72a1.94 1.94 0 0 1 1.7-.54c.91.17 1.86.26 2.83.26 7.18 0 13-4.93 13-11 0-6.08-5.82-11-13-11z"
      />
    </svg>
  ),
  check: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M20 6 9 17l-5-5"
      />
    </svg>
  ),
  copy: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.5 3h7.1c2.24 0 3.36 0 4.22.44a4 4 0 0 1 1.74 1.74c.44.86.44 1.98.44 4.22v7.1M6.2 21h8.1c1.12 0 1.68 0 2.1-.22a2 2 0 0 0 .88-.87c.22-.43.22-.99.22-2.11V9.7c0-1.12 0-1.68-.22-2.1a2 2 0 0 0-.87-.88c-.43-.22-.99-.22-2.11-.22H6.2c-1.12 0-1.68 0-2.1.22a2 2 0 0 0-.88.87C3 8.02 3 8.58 3 9.7v8.1c0 1.12 0 1.68.22 2.1.19.38.5.69.87.88.43.22.99.22 2.11.22Z"
      />
    </svg>
  ),
  search: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m21 21-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
      />
    </svg>
  ),
  send: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.5 13.5 21 3M10.63 13.83l2.63 6.76c.23.6.34.89.5.98a.5.5 0 0 0 .47 0c.17-.09.28-.39.52-.98l6.59-16.9c.2-.53.31-.8.25-.97a.5.5 0 0 0-.31-.31c-.17-.06-.44.04-.98.25L3.41 9.26c-.6.23-.89.34-.98.5a.5.5 0 0 0 0 .47c.09.17.39.28.98.51l6.76 2.63.23.1a.5.5 0 0 1 .12.13c.04.05.06.1.1.23Z"
      />
    </svg>
  ),
  info: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 16v-4m0-4h.01M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z"
      />
    </svg>
  ),
  phone: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M8.38 8.85a14.6 14.6 0 0 0 6.86 6.86c.12.06.18.09.26.11.28.08.63.03.86-.14.07-.05.13-.11.24-.22a5 5 0 0 1 .7-.64 2 2 0 0 1 2.18 0 5 5 0 0 1 .7.64l.2.2c.53.52.8.79.94 1.07a2 2 0 0 1 0 1.8 5 5 0 0 1-.94 1.1l-.16.15c-.53.53-.8.8-1.15 1-.4.22-1.02.38-1.48.38-.41 0-.7-.08-1.26-.24a19.04 19.04 0 0 1-8.29-4.87 19.04 19.04 0 0 1-4.87-8.29c-.16-.56-.24-.85-.24-1.26 0-.46.16-1.08.38-1.48.2-.36.47-.62 1-1.15l.16-.16a5 5 0 0 1 1.08-.94 2 2 0 0 1 1.8 0c.3.14.56.41 1.09.94l.2.2c.34.35.52.52.63.7a2 2 0 0 1 0 2.18c-.11.17-.29.35-.64.7a1.05 1.05 0 0 0-.25 1.37Z"
      />
    </svg>
  ),
  phoneCall: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.05 6A5 5 0 0 1 18 9.95M14.05 2A9 9 0 0 1 22 9.94m-11.77 3.92A14.6 14.6 0 0 1 7.27 9.6c-.08-.28-.03-.63.14-.86.05-.07.1-.13.22-.24.35-.35.53-.53.64-.7a2 2 0 0 0 0-2.18c-.11-.18-.29-.35-.64-.7l-.2-.2a5 5 0 0 0-1.07-.94 2 2 0 0 0-1.8 0 5 5 0 0 0-1.1.94l-.15.16c-.53.53-.8.8-1 1.15-.22.4-.38 1.02-.38 1.48 0 .41.08.7.24 1.26a19.04 19.04 0 0 0 13.16 13.16c.56.16.85.24 1.26.24.46 0 1.08-.16 1.48-.38.36-.2.62-.47 1.15-1l.16-.16c.53-.53.8-.8.94-1.08a2 2 0 0 0 0-1.8 5 5 0 0 0-.94-1.09l-.2-.2c-.35-.34-.52-.52-.7-.63a2 2 0 0 0-2.18 0c-.17.11-.35.29-.7.64a1.05 1.05 0 0 1-1.37.25 14.6 14.6 0 0 1-4-2.85Z"
      />
    </svg>
  ),
  message: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12a9 9 0 0 1-12.38 8.34c-.2-.08-.3-.12-.39-.14a.9.9 0 0 0-.21-.02l-.36.04-3.56.6c-.37.06-.55.09-.69.03a.5.5 0 0 1-.26-.26c-.06-.14-.03-.32.03-.7l.6-3.55.04-.36a.9.9 0 0 0-.02-.21c-.02-.09-.06-.19-.14-.39A9 9 0 1 1 21 12Z"
      />
    </svg>
  ),
  dotsHorizontal: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
    </svg>
  ),
  menu: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h18M3 6h18M3 18h18"
      />
    </svg>
  ),
  arrowLeft: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 12H4m0 0 6 6m-6-6 6-6"
      />
    </svg>
  ),
  arrowRight: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M4 12h16m0 0-6-6m6 6-6 6"
      />
    </svg>
  ),
  arrowUp: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M12 20V4m0 0-6 6m6-6 6 6"
      />
    </svg>
  ),
  plus: ({ strokeWidth, ...props }: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth ?? 2}
        d="M12 5v14m-7-7h14"
      />
    </svg>
  ),
  minus: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12h14"
      />
    </svg>
  ),
  delete: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17 9-6 6m0-6 6 6M2.72 12.96l4.32 5.76c.35.47.53.7.75.87a2 2 0 0 0 .66.33c.27.08.56.08 1.15.08h7.6c1.68 0 2.52 0 3.16-.33a3 3 0 0 0 1.31-1.3c.33-.65.33-1.49.33-3.17V8.8c0-1.68 0-2.52-.33-3.16a3 3 0 0 0-1.3-1.31C19.71 4 18.87 4 17.2 4H9.6c-.59 0-.88 0-1.15.08a2 2 0 0 0-.66.33c-.22.17-.4.4-.75.87l-4.32 5.76c-.26.34-.39.52-.44.7a1 1 0 0 0 0 .51c.05.2.18.37.44.71Z"
      />
    </svg>
  ),
  calendar: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10H3m13-8v4M8 2v4m-.2 16h8.4c1.68 0 2.52 0 3.16-.33a3 3 0 0 0 1.31-1.3c.33-.65.33-1.49.33-3.17V8.8c0-1.68 0-2.52-.33-3.16a3 3 0 0 0-1.3-1.31C18.71 4 17.87 4 16.2 4H7.8c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3C3 6.29 3 7.13 3 8.8v8.4c0 1.68 0 2.52.33 3.16a3 3 0 0 0 1.3 1.31c.65.33 1.49.33 3.17.33Z"
      />
    </svg>
  ),
  book: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 19v-3H7a3 3 0 0 0-3 3m4.8 3h8c1.12 0 1.68 0 2.1-.22a2 2 0 0 0 .88-.87c.22-.43.22-.99.22-2.11V5.2c0-1.12 0-1.68-.22-2.1a2 2 0 0 0-.87-.88C18.48 2 17.92 2 16.8 2h-8c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3C4 4.29 4 5.13 4 6.8v10.4c0 1.68 0 2.52.33 3.16a3 3 0 0 0 1.3 1.31c.65.33 1.49.33 3.17.33Z"
      />
    </svg>
  ),
  bookOpen: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 20H5.2c-1.12 0-1.68 0-2.1-.22a2 2 0 0 1-.88-.87C2 18.48 2 17.92 2 16.8V7.2c0-1.12 0-1.68.22-2.1a2 2 0 0 1 .87-.88C3.52 4 4.08 4 5.2 4h.4c2.24 0 3.36 0 4.22.44a4 4 0 0 1 1.74 1.74c.44.86.44 1.98.44 4.22m0 9.6v-9.6m0 9.6h6.8c1.12 0 1.68 0 2.1-.22a2 2 0 0 0 .88-.87c.22-.43.22-.99.22-2.11V7.2c0-1.12 0-1.68-.22-2.1a2 2 0 0 0-.87-.88C20.48 4 19.92 4 18.8 4h-.4c-2.24 0-3.36 0-4.22.44a4 4 0 0 0-1.74 1.74C12 7.04 12 8.16 12 10.4"
      />
    </svg>
  ),
  timeline: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 10c.93 0 1.4 0 1.77-.15a2 2 0 0 0 1.08-1.08C17 8.4 17 7.93 17 7s0-1.4-.15-1.77a2 2 0 0 0-1.08-1.08C15.4 4 14.93 4 14 4H6c-.93 0-1.4 0-1.77.15a2 2 0 0 0-1.08 1.08C3 5.6 3 6.07 3 7s0 1.4.15 1.77a2 2 0 0 0 1.08 1.08C4.6 10 5.07 10 6 10h8ZM18 20c.93 0 1.4 0 1.77-.15a2 2 0 0 0 1.08-1.08c.15-.37.15-.84.15-1.77s0-1.4-.15-1.77a2 2 0 0 0-1.08-1.08C19.4 14 18.93 14 18 14H6c-.93 0-1.4 0-1.77.15a2 2 0 0 0-1.08 1.08C3 15.6 3 16.07 3 17s0 1.4.15 1.77a2 2 0 0 0 1.08 1.08C4.6 20 5.07 20 6 20h12Z"
      />
    </svg>
  ),
  map: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m8 3 8 18M3 17l9-5m-4.2 9h8.4c1.68 0 2.52 0 3.16-.33a3 3 0 0 0 1.31-1.3c.33-.65.33-1.49.33-3.17V7.8c0-1.68 0-2.52-.33-3.16a3 3 0 0 0-1.3-1.31C18.71 3 17.87 3 16.2 3H7.8c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3C3 5.29 3 6.13 3 7.8v8.4c0 1.68 0 2.52.33 3.16a3 3 0 0 0 1.3 1.31c.65.33 1.49.33 3.17.33Z"
      />
    </svg>
  ),
  pencil: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.88 18.12c.04-.42.06-.62.13-.82a2 2 0 0 1 .23-.48c.11-.17.26-.32.55-.61L17 3a2.83 2.83 0 1 1 4 4L7.8 20.2c-.3.3-.45.45-.62.56a2 2 0 0 1-.48.23c-.2.07-.4.09-.82.13l-3.38.38.38-3.38Z"
      />
    </svg>
  ),
  table: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.27 1.04c-1.4.08-2.97.3-4.1.56-2.2.51-3.52 1.22-4 2.14L2 4.07v3.14l.15.28c.59 1.14 2.57 2.01 5.62 2.47l1.06.13c.31.03.37.05.4.13a26.44 26.44 0 0 1 .3 6.14l-.02.2-.48.13c-.57.16-1.34.51-1.77.79-1.33.87-1.73 2.18-1 3.34.18.31.77.85 1.2 1.1.76.45 1.75.79 2.88.97.88.15 2.44.15 3.32 0a6.83 6.83 0 0 0 3.59-1.5c.58-.55.85-1.08.85-1.73 0-.64-.23-1.16-.75-1.68a4.7 4.7 0 0 0-.6-.5 7.83 7.83 0 0 0-1.78-.8c-.38-.1-.49-.15-.51-.22-.02-.05-.04-.67-.05-1.38a26.1 26.1 0 0 1 .36-4.86c.03-.08.09-.1.4-.13 1.26-.1 2.98-.45 4.01-.81 1.44-.5 2.3-1.08 2.67-1.79l.15-.28V4.07l-.17-.33c-.48-.92-1.8-1.63-4-2.14-2.07-.49-4.96-.7-7.56-.56Zm3.72 1.47c2.16.13 4.05.52 5.3 1.06.56.25 1 .54 1.12.75.09.16.09.16 0 .32-.26.43-1.48.99-2.93 1.32-3.17.74-7.8.74-10.96 0-1.45-.33-2.67-.89-2.93-1.32-.09-.16-.09-.16 0-.32.13-.21.56-.5 1.13-.75 2.02-.88 5.64-1.3 9.27-1.06ZM5.03 7.06C9.3 8.4 16.46 8.22 20 6.67l.48-.2v.17c0 .24-.12.41-.43.64-1.25.9-4.57 1.54-8.05 1.54-3.48 0-6.8-.64-8.05-1.54-.3-.23-.43-.4-.43-.64v-.18l.48.21c.27.12.73.3 1.03.39Zm8.12 3.3a41.7 41.7 0 0 0-.22 1.86c-.04.37-.06 1.25-.06 2.37 0 1.83.02 2.25.25 3.94l.08.61-.31.03c-.37.05-1.48.04-1.83 0l-.26-.03.06-.44c.23-1.72.27-2.3.27-4.14 0-1.16-.02-2-.06-2.34-.11-1.05-.2-1.74-.22-1.86l-.02-.12h2.34l-.02.12Zm-3.9 8.51c-.12.88-.1 1 .15 1.24.77.71 4.43.71 5.2 0 .25-.23.27-.36.16-1.13l-.11-.77c-.04-.14.03-.13.61.14 1.37.63 1.66 1.41.78 2.13-1.25 1-4.04 1.38-6.23.84-1.4-.36-2.35-1.03-2.35-1.68 0-.28.18-.59.52-.86.23-.18 1.2-.67 1.33-.67.03 0 .02.22-.05.76Z"
      />
    </svg>
  ),
  announcement: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 8v4M10.25 5.5H6.8c-1.68 0-2.52 0-3.16.33a3 3 0 0 0-1.31 1.3C2 7.79 2 8.63 2 10.3v1.2c0 .93 0 1.4.15 1.77a2 2 0 0 0 1.08 1.08c.37.15.84.15 1.77.15v4.25l.01.45a2 2 0 0 0 1.8 1.79l.44.01.45-.01a2 2 0 0 0 1.79-1.8l.01-.44V14.5h.75c1.77 0 3.93.95 5.6 1.86.97.53 1.45.8 1.77.75.3-.03.52-.17.7-.4.18-.27.18-.8.18-1.84V5.13c0-1.05 0-1.57-.19-1.83a.95.95 0 0 0-.69-.41c-.32-.04-.8.22-1.78.75-1.66.91-3.82 1.86-5.59 1.86Z"
      />
    </svg>
  ),
  banknote: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 11v4m12-6v4m-1-9c2.45 0 3.77.37 4.43.67a.7.7 0 0 1 .26.17c.08.08.21.29.25.39.06.16.06.25.06.43v10.75c0 .91 0 1.36-.14 1.6a.87.87 0 0 1-.53.44c-.25.09-.77-.01-1.8-.21-.72-.14-1.57-.24-2.53-.24-3 0-6 2-10 2-2.45 0-3.77-.37-4.43-.67-.09-.03-.13-.05-.26-.17a1.46 1.46 0 0 1-.25-.39C2 18.61 2 18.52 2 18.34V7.59c0-.91 0-1.36.14-1.6a.87.87 0 0 1 .53-.44c.25-.09.77.01 1.8.21C5.19 5.9 6.04 6 7 6c3 0 6-2 10-2Zm-2.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
    </svg>
  ),
  chevronDown: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m6 9 6 6 6-6"
      />
    </svg>
  ),
  chevronUp: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m18 15-6-6-6 6"
      />
    </svg>
  ),
  chevronRight: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m9 18 6-6-6-6"
      />
    </svg>
  ),
  chevronUpDouble: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m17 18-5-5-5 5m10-7-5-5-5 5"
      />
    </svg>
  ),
  chevronDownDouble: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m7 13 5 5 5-5M7 6l5 5 5-5"
      />
    </svg>
  ),
  clockPlus: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6145 8.84325C14.6494 8.56704 14.6673 8.28558 14.6673 7.99992C14.6673 4.31802 11.6826 1.33325 8.00065 1.33325C4.31875 1.33325 1.33398 4.31802 1.33398 7.99992C1.33398 11.6818 4.31875 14.6666 8.00065 14.6666C8.29092 14.6666 8.57686 14.648 8.85734 14.6121M8.00065 3.99992V7.99992L10.4929 9.24604M12.6673 14.6666V10.6666M10.6673 12.6666H14.6673"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  list: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 10.0003L7.5 10.0003M17.5 5.00033L7.5 5.00033M17.5 15.0003L7.5 15.0003M4.16667 10.0003C4.16667 10.4606 3.79357 10.8337 3.33333 10.8337C2.8731 10.8337 2.5 10.4606 2.5 10.0003C2.5 9.54009 2.8731 9.16699 3.33333 9.16699C3.79357 9.16699 4.16667 9.54009 4.16667 10.0003ZM4.16667 5.00033C4.16667 5.46056 3.79357 5.83366 3.33333 5.83366C2.8731 5.83366 2.5 5.46056 2.5 5.00033C2.5 4.54009 2.8731 4.16699 3.33333 4.16699C3.79357 4.16699 4.16667 4.54009 4.16667 5.00033ZM4.16667 15.0003C4.16667 15.4606 3.79357 15.8337 3.33333 15.8337C2.8731 15.8337 2.5 15.4606 2.5 15.0003C2.5 14.5401 2.8731 14.167 3.33333 14.167C3.79357 14.167 4.16667 14.5401 4.16667 15.0003Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  logo: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 46 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.0498 17.0696H45.6967V18.4748H41.4422V19.3644H45.3357V20.6537H41.4422V21.5303H45.6967V22.9356H40.0498V17.0696Z"
        fill="currentColor"
      />
      <path
        d="M32.9614 17.0696H34.6116L36.3134 21.2338L38.0152 17.0696H39.6655L37.2675 22.9356H35.3465L32.9614 17.0696Z"
        fill="currentColor"
      />
      <path
        d="M28.4742 18.4748V19.6481H30.2146C31.1042 19.6481 31.2202 19.3386 31.2202 19.0808C31.2202 18.8101 31.1171 18.4748 30.2146 18.4748H28.4742ZM27.0818 17.0696H30.3951C32.0454 17.0696 32.7802 17.8689 32.7802 19.0679C32.7802 19.8157 32.4708 20.3958 31.8649 20.731C32.5353 21.3885 32.8318 22.9356 32.8318 22.9356H31.3234C31.3234 22.9356 31.0397 21.1822 30.1115 21.0533H28.4742V22.9356H27.0818V17.0696Z"
        fill="currentColor"
      />
      <path
        d="M21.0511 17.0696H26.698V18.4748H22.4435V19.3644H26.337V20.6537H22.4435V21.5303H26.698V22.9356H21.0511V17.0696Z"
        fill="currentColor"
      />
      <path
        d="M17.1065 17.0178C18.7309 17.0178 20.2522 17.6367 20.394 18.9001H18.5375C18.3313 18.1524 15.3918 18.2168 15.366 18.913C15.3402 19.8799 20.6648 18.5907 20.5359 21.0145C20.4585 22.3682 18.8083 22.9999 17.0549 22.9999C15.2242 22.9999 13.6771 22.2521 13.5095 21.1176H15.4821C16.0106 21.9298 18.7309 21.7493 18.7696 21.0274C18.8212 20.112 13.5095 21.3497 13.6513 18.7712C13.7029 17.8172 14.9277 17.0178 17.1065 17.0178Z"
        fill="currentColor"
      />
      <path
        d="M7.479 17.0696H13.1259V18.4748H8.87138V19.3644H12.7649V20.6537H8.87138V21.5303H13.1259V22.9356H7.479V17.0696Z"
        fill="currentColor"
      />
      <path
        d="M2.72734 18.4748V19.6481H4.46781C5.35738 19.6481 5.47341 19.3386 5.47341 19.0808C5.47341 18.8101 5.37027 18.4748 4.46781 18.4748H2.72734ZM1.33496 17.0696H4.6483C6.29853 17.0696 7.03339 17.8689 7.03339 19.0679C7.03339 19.8157 6.72397 20.3958 6.11803 20.731C6.78844 21.3885 7.08496 22.9356 7.08496 22.9356H5.57655C5.57655 22.9356 5.29292 21.1822 4.36467 21.0533H2.72734V22.9356H1.33496V17.0696Z"
        fill="currentColor"
      />
      <path
        d="M38.1311 0C41.7067 0 45.0552 1.28035 45.3674 3.89439H41.281C40.827 2.3473 34.3569 2.48067 34.3001 3.92106C34.2434 5.9216 45.9633 3.25421 45.6795 8.2689C45.5093 11.0697 41.8769 12.3767 38.0176 12.3767C33.988 12.3767 30.5827 10.8296 30.2137 8.4823H34.5555C35.719 10.1627 41.7067 9.78931 41.7918 8.29558C41.9053 6.40173 30.2137 8.96242 30.5259 3.62765C30.6394 1.65378 33.3353 0 38.1311 0Z"
        fill="currentColor"
      />
      <path
        d="M15.2587 0H18.8213L22.4379 8.26925L26.0815 0H29.6981V12.3767H26.7832V5.4675L23.9493 11.6422H20.8995L18.1735 5.4947V12.3767H15.2587V0Z"
        fill="currentColor"
      />
      <path
        d="M0.303467 0H14.2272V2.72015H8.79559V12.3767H5.70754V2.72015H0.303467V0Z"
        fill="currentColor"
      />
    </svg>
  ),
  hourglass: (props: ComponentPropsWithoutRef<'svg'>) => (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 9.25H7.5M3.3 1H8.7C8.98003 1 9.12004 1 9.227 1.0545C9.32108 1.10243 9.39757 1.17892 9.4455 1.273C9.5 1.37996 9.5 1.51997 9.5 1.8V2.83726C9.5 3.08185 9.5 3.20414 9.47237 3.31923C9.44787 3.42127 9.40747 3.51881 9.35264 3.60828C9.2908 3.7092 9.20432 3.79568 9.03137 3.96863L7.56569 5.43431C7.36768 5.63232 7.26867 5.73133 7.23158 5.84549C7.19895 5.94591 7.19895 6.05409 7.23158 6.15451C7.26867 6.26867 7.36768 6.36768 7.56568 6.56568L9.03137 8.03137C9.20432 8.20432 9.2908 8.2908 9.35264 8.39172C9.40747 8.48119 9.44787 8.57873 9.47237 8.68077C9.5 8.79586 9.5 8.91815 9.5 9.16274V10.2C9.5 10.48 9.5 10.62 9.4455 10.727C9.39757 10.8211 9.32108 10.8976 9.227 10.9455C9.12004 11 8.98003 11 8.7 11H3.3C3.01997 11 2.87996 11 2.773 10.9455C2.67892 10.8976 2.60243 10.8211 2.5545 10.727C2.5 10.62 2.5 10.48 2.5 10.2V9.16274C2.5 8.91815 2.5 8.79586 2.52763 8.68077C2.55213 8.57873 2.59253 8.48119 2.64736 8.39172C2.7092 8.2908 2.79568 8.20432 2.96863 8.03137L4.43432 6.56568C4.63232 6.36768 4.73133 6.26867 4.76842 6.15451C4.80105 6.05409 4.80105 5.94591 4.76842 5.84549C4.73133 5.73133 4.63232 5.63232 4.43431 5.43431L2.96863 3.96863C2.79568 3.79568 2.7092 3.7092 2.64736 3.60828C2.59253 3.51881 2.55213 3.42127 2.52763 3.31923C2.5 3.20414 2.5 3.08185 2.5 2.83726V1.8C2.5 1.51997 2.5 1.37996 2.5545 1.273C2.60243 1.17892 2.67892 1.10243 2.773 1.0545C2.87996 1 3.01997 1 3.3 1Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

type IconProps = {
  name: keyof typeof Icons
  size?: number
}

export function Icon({
  name,
  size = 16,
  className,
  ...props
}: IconProps & ComponentPropsWithoutRef<'svg'>) {
  const IconComponent = Icons[name]

  return (
    <IconComponent
      className={cx(css.icon, className)}
      height={size}
      {...props}
    />
  )
}

export type IconNames = keyof typeof Icons
