/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { format, isBefore } from 'date-fns'
import { ru } from 'date-fns/locale'

import { config } from '@/app/config'
import {
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationPersonsCount,
  selectReservationStart,
  setReservationDate,
  setReservationEnd,
  setReservationPersonsCount,
  setReservationStart
} from '@/entities/create-update-reservation'
import { Reservation } from '@/features/create-update-reservation'
import { DatePickerVaul } from '@/features/date-picker-vaul'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Card,
  Icon,
  Label,
  PersonsPicker,
  Tab,
  TabContent,
  TabsGroup,
  TabsProvider,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileReservationChange.module.css'

const { MIN_RESERVATION_TIME_MIN, PICKERS_INTERVAL } = config

type MobileReservationChangeProps = {
  isLastPage?: boolean
  onConfirm: () => void
}

export default function MobileReservationChange({
  isLastPage = false,
  onConfirm
}: MobileReservationChangeProps) {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const personsCount = useAppSelector(selectReservationPersonsCount)

  const isPageDuration =
    !selectedDuration ||
    (selectedDuration >= MIN_RESERVATION_TIME_MIN &&
      selectedDuration <= 4 * 60 &&
      selectedDuration % PICKERS_INTERVAL === 0)

  const selectStartHandler = (date: Date) => {
    if (!selectedDate) return

    selectedDate.setHours(date.getHours(), date.getMinutes())
    dispatch(setReservationStart(selectedDate))
  }

  const selectEndHandler = (date: Date) => {
    if (!selectedStart) return

    if (isBefore(date, selectedStart)) {
      dispatch(
        setReservationEnd(
          (24 - selectedStart.getHours() + date.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    } else {
      dispatch(
        setReservationEnd(
          (date.getHours() - selectedStart.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    }
  }

  const setDateHandler = (date?: Date) => {
    dispatch(setReservationDate(date))
    setCalendarOpen(false)
  }

  return (
    <>
      <VaulHeader>
        <h1 className={css.title}>Изменить бронь</h1>
      </VaulHeader>
      <VaulBody className={css.body}>
        <Card data-vaul-no-drag className={css.step__picker_start}>
          <Label className={css.step__label}>Время старта</Label>
          <Reservation.MobileTimePicker
            selectedValue={selectedStart}
            setSelectedValue={selectStartHandler}
          />
        </Card>
        <Card className={css.step__picker_duration}>
          <TabsProvider
            className={css.tabs}
            defaultValue={isPageDuration ? 'duration' : 'time'}
          >
            <TabsGroup variant="tertiary" className={css.tabs__group}>
              <Tab
                variant="tertiary"
                className={css.tabs__tab}
                value="duration"
              >
                Длительность
              </Tab>
              <Tab variant="tertiary" className={css.tabs__tab} value="time">
                Время завершения
              </Tab>
            </TabsGroup>
            <TabContent value="duration">
              <Reservation.MobileDurationPicker />
            </TabContent>
            <TabContent data-vaul-no-drag value="time">
              <Reservation.MobileTimePicker
                selectedValue={selectedEnd}
                setSelectedValue={selectEndHandler}
              />
            </TabContent>
          </TabsProvider>
        </Card>
        <PersonsPicker
          personsCount={personsCount}
          setPersonsCount={(value) =>
            dispatch(setReservationPersonsCount(value))
          }
        />
        <Card className={css.step__picker_date}>
          <Label>Дата</Label>
          <Vaul open={calendarOpen} onOpenChange={setCalendarOpen}>
            <VaulTrigger asChild>
              <Button variant="tertiary" className={css.button_date}>
                {selectedDate &&
                  format(selectedDate, 'dd.MM.yyyy (EEEE)', {
                    locale: ru
                  })}
                <Icon name="pencil" size={20} />
              </Button>
            </VaulTrigger>
            <VaulContent fullScreen>
              <DatePickerVaul value={selectedDate} setValue={setDateHandler} />
            </VaulContent>
          </Vaul>
        </Card>
      </VaulBody>
      <VaulFooter>
        <Button
          variant="primary"
          size="lg"
          className={css.button_next}
          onClick={onConfirm}
        >
          {isLastPage ? 'Сохранить' : 'Далее'}
        </Button>
      </VaulFooter>
    </>
  )
}
