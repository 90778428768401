import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import {
  Button,
  type ButtonStyleProps,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { MobileChangeReservationTableVaulContent } from '@/widgets/reservation/MobileReservationChangeTable/MobileChangeReservationTableVaulContent'

type ChangeTableProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number
    className?: string
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation | Visit>
    ) => void
  }

export function MobileChangeTable({
  children,
  reservationId,
  size,
  className,
  updateReservationsList,
  ...props
}: ChangeTableProps) {
  const [open, setOpen] = useState(false)

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button
          size={size}
          className={className}
          {...props}
          onClick={toggleDialogHandler}
        >
          {children}
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <MobileChangeReservationTableVaulContent
          reservationId={reservationId}
          setOpen={setOpen}
          isWithoutRefetchOnMountOrArgChange
          updateReservationsList={updateReservationsList}
        />
      </VaulContent>
    </Vaul>
  )
}
