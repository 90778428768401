import { type DialButton } from './types'

export const buttons: DialButton[] = [
  {
    type: 'number',
    val: '1'
  },
  {
    type: 'number',
    val: '2'
  },
  {
    type: 'number',
    val: '3'
  },
  {
    type: 'number',
    val: '4'
  },
  {
    type: 'number',
    val: '5'
  },
  {
    type: 'number',
    val: '6'
  },
  {
    type: 'number',
    val: '7'
  },
  {
    type: 'number',
    val: '8'
  },
  {
    type: 'number',
    val: '9'
  },
  {
    type: 'plus',
    val: '+',
    icon: 'plus'
  },
  {
    type: 'number',
    val: '0'
  },
  {
    type: 'clear',
    val: '',
    icon: 'delete'
  }
]
