import { type ReactElement } from 'react'

import { Navigate } from 'react-router-dom'

import { selectIframePage } from '@/entities/iframe'
import { selectIsAuthorized } from '@/entities/session'
import { useAppSelector } from '@/shared/model'

type AuthGuardProps = {
  children: ReactElement
}

export function AuthGuard({ children }: AuthGuardProps) {
  const isAuthorized = useAppSelector(selectIsAuthorized)
  const iframePage = useAppSelector(selectIframePage)

  if (isAuthorized) return <Navigate to={iframePage ? `/${iframePage}` : '/'} />

  return children
}
