import { useState } from 'react'

import { cx } from 'class-variance-authority'
import { useSearchParams } from 'react-router-dom'

import { Button, Icon, Vaul, VaulContent, VaulTrigger } from '@/shared/ui'

import { FilterVaul } from './Filter/FilterVaul'
import { SortVaul } from './Sort/SortVaul'

import css from './MobileFilters.module.css'

export function MobileFilters() {
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [openSort, setOpenSort] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  return (
    <div className={css.actions}>
      <Vaul open={openFilter} onOpenChange={setOpenFilter}>
        <VaulTrigger asChild>
          <Button
            variant="tertiary"
            className={cx(css.button__filter, {
              [css.button__filter_active]: !!searchParams.size
            })}
          >
            <Icon name="settings" size={20} />
          </Button>
        </VaulTrigger>
        <VaulContent>
          <FilterVaul setOpen={setOpenFilter} />
        </VaulContent>
      </Vaul>
      <Vaul open={openSort} onOpenChange={setOpenSort}>
        <VaulTrigger asChild>
          <Button disabled variant="tertiary" className={css.button__sort}>
            <Icon name="filter" size={20} />
          </Button>
        </VaulTrigger>
        <VaulContent>
          <SortVaul setOpen={setOpenSort} />
        </VaulContent>
      </Vaul>
    </div>
  )
}
