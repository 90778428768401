import {
  MobileNewReservationPickersSteps,
  selectMobileReservationPickersStep
} from '@/entities/create-update-reservation'
import { useAppSelector } from '@/shared/model'

import { ReservationDateStep } from './ReservationDateStep'
import { ReservationTimeStep } from './ReservationTimeStep'

export function MobileReservationPickersStep() {
  const step = useAppSelector(selectMobileReservationPickersStep)

  switch (step) {
    case MobileNewReservationPickersSteps.ONE:
      return <ReservationDateStep />
    case MobileNewReservationPickersSteps.TWO:
      return <ReservationTimeStep />
  }
}
