import { differenceInMinutes, isAfter, isBefore } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

import { TimelineOrderingEnum } from './const'

export const sortTables = (
  stateTables: Table[],
  sortType: TimelineOrdering
) => {
  const tables = [...stateTables]

  switch (sortType.booked) {
    case TimelineOrderingEnum.BookedASC:
      return tables.sort(
        (a, b) => a.reservations.length - b.reservations.length
      )
    case TimelineOrderingEnum.BookedDESC:
      return tables.sort(
        (a, b) => b.reservations.length - a.reservations.length
      )
  }

  switch (sortType.tables) {
    case TimelineOrderingEnum.TablesASC:
      return tables.sort((a, b) => a.item_number - b.item_number)
    case TimelineOrderingEnum.TablesDESC:
      return tables.sort((a, b) => b.item_number - a.item_number)
  }

  switch (sortType.date) {
    case TimelineOrderingEnum.StartDateASC: {
      const now = nowWithTimezone()

      return tables.sort((a, b) => {
        const minDiffA = Math.min(
          ...a.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const minDiffB = Math.min(
          ...b.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const nowInProgressA = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        const nowInProgressB = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (nowInProgressA) {
          return -1
        }

        if (nowInProgressB) {
          return 1
        }

        return minDiffA - minDiffB
      })
    }
    case TimelineOrderingEnum.StartDateDESC: {
      const now = nowWithTimezone()

      return tables.sort((a, b) => {
        const minDiffA = Math.min(
          ...a.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const minDiffB = Math.min(
          ...b.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const nowInProgressA = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        const nowInProgressB = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (nowInProgressA) {
          return 1
        }

        if (nowInProgressB) {
          return -1
        }

        return minDiffB - minDiffA
      })
    }
  }

  return tables
}
