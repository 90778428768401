import { type MouseEvent, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { isDesktop } from 'react-device-detect'

import {
  AllCommentsDrawer,
  CommentsList,
  useDeleteReservationCommentMutation,
  useReservationCommentsQuery,
  useSendReservationCommentMutation,
  useUpdateReservationCommentMutation
} from '@/entities/comments'
import { MobileComments, MobileTextAreaComments } from '@/entities/reservation'
import { useToastSuccessHandling } from '@/shared/hooks'
import { isPWA } from '@/shared/lib'
import {
  Button,
  type ButtonStyleProps,
  Icon,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileComments.module.css'

type MobileCommentsActionProps = ButtonStyleProps & {
  reservationId: number
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

export function MobileCommentsAction({
  reservationId,
  updateReservationsList,
  ...props
}: MobileCommentsActionProps) {
  const [open, setOpen] = useState(false)
  const [commentUpdate, setCommentUpdate] = useState<UserComment | null>(null)

  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments
  } = useReservationCommentsQuery(
    open ? { reservationId: +reservationId } : skipToken,
    { refetchOnMountOrArgChange: true }
  )

  const [
    deleteComment,
    { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }
  ] = useDeleteReservationCommentMutation()
  useToastSuccessHandling(isDeleteSuccess, 'Комментарий успешно удален')

  const [sendComment, { isLoading: isSendLoading, isSuccess: isSendSuccess }] =
    useSendReservationCommentMutation()
  useToastSuccessHandling(isSendSuccess, 'Комментарий успешно отправлен')

  const [
    updateComment,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess }
  ] = useUpdateReservationCommentMutation()
  useToastSuccessHandling(isUpdateSuccess, 'Комментарий успешно обновлен')

  const deleteCommentHandle = async (id: number) => {
    if (!comments) return

    await deleteComment({
      reservationId,
      commentId: id
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length - 1
    })
  }

  const updateCommentHandle = async (id: number, text: string) => {
    await updateComment({
      reservationId,
      commentId: id,
      body: {
        text
      }
    })
  }

  const createCommentHandle = async (text: string) => {
    if (!comments) return

    await sendComment({
      reservationId,
      body: {
        text
      }
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length + 1
    })
  }

  const toggleVaulHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen(!open)
  }

  if (isDesktop) {
    return (
      <AllCommentsDrawer
        title="Комментарии к бронированию"
        commentsSlot={
          <CommentsList
            placeholder="Введите комментарий брони"
            comments={comments}
            isDeleteLoading={isDeleteLoading}
            isSendLoading={isSendLoading}
            isUpdateLoading={isUpdateLoading}
            deleteCommentHandler={deleteCommentHandle}
            sendCommentHandler={createCommentHandle}
            updateCommentHandler={updateCommentHandle}
          />
        }
      >
        <Button
          variant="tertiary"
          size="icon"
          className={css.button}
          contentClassName={css.button__content}
          onClick={toggleVaulHandler}
          {...props}
        >
          <Icon name="message" />
          {!!comments?.length && comments.length}
        </Button>
      </AllCommentsDrawer>
    )
  }

  return (
    <Vaul open={open} onOpenChange={(open) => !open && setOpen(false)}>
      <VaulTrigger asChild>
        <Button
          variant="tertiary"
          size="icon"
          className={css.button}
          contentClassName={css.button__content}
          onClick={toggleVaulHandler}
          {...props}
        >
          <Icon name="message" />
          {!!comments?.length && comments.length}
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <div className={css.content}>
          <VaulHeader>
            <h4 className={css.title}>Комментарии</h4>
          </VaulHeader>
          <VaulBody className={css.body}>
            <div
              className={cx(css.body__content, {
                [css.body__content_pwa]: isPWA()
              })}
            >
              <MobileComments
                comments={comments}
                isLoadingComments={isLoadingComments}
                isErrorComments={isErrorComments}
                deleteCommentHandle={deleteCommentHandle}
                isDeleteLoading={isDeleteLoading}
                setCommentUpdate={setCommentUpdate}
              />
            </div>
          </VaulBody>
          <VaulFooter>
            <MobileTextAreaComments
              commentUpdate={commentUpdate}
              setCommentUpdate={setCommentUpdate}
              isSendLoading={isSendLoading}
              isUpdateLoading={isUpdateLoading}
              updateCommentHandle={updateCommentHandle}
              createCommentHandle={createCommentHandle}
            />
          </VaulFooter>
        </div>
      </VaulContent>
    </Vaul>
  )
}
