import { type PropsWithChildren, useState } from 'react'

import { cx } from 'class-variance-authority'

import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './ReservationsSortPopover.module.css'

export default function ReservationsSortPopover({
  ordering,
  setOrdering,
  children
}: PropsWithChildren & {
  ordering: {
    date: ReservationsOrderingDate
    tables: ReservationsOrderingTables
  }
  setOrdering: (ordering: {
    date: ReservationsOrderingDate
    tables: ReservationsOrderingTables
  }) => void
}) {
  const [open, setOpen] = useState(false)
  const { date, tables } = ordering

  const changeSortType = (type: string) => {
    switch (true) {
      case type.startsWith('default'):
        return type.slice(8)
      case type.startsWith('-'):
        return `default_${type.slice(1)}`
      default:
        return `-${type}`
    }
  }

  const changeDateSortDirectionHandler = () => {
    setOrdering({
      date: changeSortType(date) as ReservationsOrderingDate,
      tables: 'default_1'
    })
  }

  const changeTablesSortDirectionHandler = () => {
    setOrdering({
      date: 'default_start_date',
      tables: changeSortType(tables) as ReservationsOrderingTables
    })
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        align="start"
        variant="glass"
        className={css.popover}
      >
        <Button
          className={cx(css.button, {
            [css.button_selected]: !date?.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={changeDateSortDirectionHandler}
        >
          Время и дата
          {date && (
            <div className={css.button__subtitle}>
              {!date.startsWith('-') && !date.startsWith('default') && (
                <>
                  00:00-&gt;23:59
                  <Icon name="chevronDownDouble" />
                </>
              )}
              {date.startsWith('-') && (
                <>
                  23:59-&gt;00:00
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
        <Button
          className={cx(css.button, {
            [css.button_selected]: !tables?.startsWith('default')
          })}
          contentClassName={css.button__content}
          onClick={changeTablesSortDirectionHandler}
        >
          Номер стола
          {tables && (
            <div className={css.button__subtitle}>
              {!tables.startsWith('-') && !tables.startsWith('default') && (
                <>
                  1-&gt;99
                  <Icon name="chevronDownDouble" />
                </>
              )}
              {tables.startsWith('-') && (
                <>
                  99-&gt;1
                  <Icon name="chevronUpDouble" />
                </>
              )}
            </div>
          )}
        </Button>
      </PopoverContent>
    </Popover>
  )
}
