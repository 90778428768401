import { type ReactElement, useEffect } from 'react'

import * as qs from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'

import { setIframePage } from '@/entities/iframe'
import {
  selectIsAuthorized,
  setAccessToken,
  setIsSelectedAddress,
  setSelectedAddressId
} from '@/entities/session'
import { PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'

type GuestGuardProps = {
  children: ReactElement
}

export function GuestGuard({ children }: GuestGuardProps) {
  const dispatch = useAppDispatch()
  const { search, pathname } = useLocation()

  useEffect(() => {
    const params = qs.parse(search.slice(1)) as {
      token: string
      page: string
      address: string
    }
    if (params.address && !Number.isNaN(+params.address)) {
      dispatch(setSelectedAddressId(+params.address))
      dispatch(setIsSelectedAddress(true))
    }
    if (typeof params.token === 'string') {
      dispatch(
        setAccessToken(
          params.token.startsWith('Staff')
            ? params.token
            : `Staff ${params.token}`
        )
      )
      dispatch(setIframePage(params.page || pathname.slice(1)))
    }
  }, [search])

  const isAuthorized = useAppSelector(selectIsAuthorized)

  if (!isAuthorized) return <Navigate to={PATHS.login} />

  return children
}
