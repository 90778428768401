import { cx } from 'class-variance-authority'
import {
  differenceInMinutes,
  endOfDay,
  format,
  isAfter,
  isFuture,
  isWithinInterval,
  minutesInHour
} from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { config } from '@/app/config'
import { addReservationFromEmptySlot } from '@/entities/create-update-reservation'
import { selectCurDate } from '@/entities/timeline'
import { MIN_SLOT_DURATION_FOR_SUGGESTION } from '@/entities/timeline/lib/config'
import { nowWithTimezone, PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Icon } from '@/shared/ui'

import css from './EmptySlot.module.css'

const {
  MIN_DURATION_INTERVAL_MIN,
  PICKERS_SPLIT_HOUR_BY,
  WORKING_HOURS_START
} = config

type EmptySlotProps = {
  slot: TimeSlot
}

export default function EmptySlot({ slot }: EmptySlotProps) {
  const { start_date, end_date, table } = slot
  const curDate = useAppSelector(selectCurDate)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const now = nowWithTimezone()
  const workingHoursStart = new Date(curDate)
  workingHoursStart.setHours(WORKING_HOURS_START)

  const isSlotInFuture =
    isWithinInterval(now, {
      start: start_date,
      end: end_date
    }) || isFuture(start_date)

  const largeEnoughForReservation =
    differenceInMinutes(
      end_date,
      isAfter(now, workingHoursStart) ? now : workingHoursStart
    ) >=
    MIN_SLOT_DURATION_FOR_SUGGESTION + minutesInHour / PICKERS_SPLIT_HOUR_BY

  const isClickable = isSlotInFuture && largeEnoughForReservation

  const slotClickHandler = () => {
    if (isSlotInFuture && largeEnoughForReservation) {
      dispatch(
        addReservationFromEmptySlot({
          start_date: endOfDay(start_date),
          duration: MIN_DURATION_INTERVAL_MIN,
          persons_count: table.min_persons_count,
          tableIds: [table.id],
          hall_id: table.hall.id
        })
      )
      navigate(PATHS.newReservation)
    }
  }

  return (
    <button
      tabIndex={isClickable ? 0 : -1}
      className={cx(css.slot, {
        [css.slot_now]: isSlotInFuture && largeEnoughForReservation
      })}
      onClick={slotClickHandler}
    >
      {format(start_date, 'HH:mm')}
      <Icon className={css.slot__arrow} name="arrowUp" size={20} />
      {format(end_date, 'HH:mm')}
    </button>
  )
}
