import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { selectSelectedAddressId } from '@/entities/session'
import { useTelephonyWsQuery } from '@/entities/telephony'
import { useAppSelector } from '@/shared/model'
import {
  Badge,
  Button,
  Icon,
  Vaul,
  VaulBody,
  VaulContent,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import { CallsCard } from './CallsCard/CallsCard'

import css from './MobileQuick.module.css'

export function MobileQuick() {
  const [open, setOpen] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const { data, isFetching } = useTelephonyWsQuery(
    addressId
      ? {
          addressId
        }
      : skipToken
  )

  useEffect(() => {
    if (!data?.[data.length - 1]?.length) {
      setOpen(false)
    }
  }, [data?.[data.length - 1]?.length])

  if (isFetching || !data?.[data.length - 1]?.length) {
    return null
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button
          variant="green"
          size="icon"
          className={css.button}
          contentClassName={css.button__content}
        >
          <Icon name="phoneCall" size={20} />
          {data?.[data.length - 1]?.length && (
            <Badge variant="white" size="xs" className={css.badge}>
              {data?.[data.length - 1].length}
            </Badge>
          )}
        </Button>
      </VaulTrigger>
      <VaulContent>
        <VaulHeader className={css.vaul__header}>
          <h4 className={css.vaul__title}>Телефония</h4>
          <p className={css.vaul__subtitle}>
            {`Входящие звонки${
              data ? ` - ${data?.[data.length - 1]?.length}` : ''
            }`}
          </p>
        </VaulHeader>
        <VaulBody className={css.vaul__body}>
          {data?.[data.length - 1].map((call) => (
            <CallsCard
              key={call.phone_caller ?? call.guest?.phone}
              guest={call.guest}
              status={call.status}
              phoneNewGuest={call.phone_caller}
            />
          ))}
        </VaulBody>
      </VaulContent>
    </Vaul>
  )
}
