import { useEffect, useState } from 'react'

const useFocus = () => {
  const [isFocus, setIsFocus] = useState(false)
  const [dvhWithKeyboard, setDvhWithKeyboard] = useState<number | null>(null)

  const onFocus = (ev: FocusEvent) => {
    if (
      (ev.target as Element).tagName.toLowerCase() === 'input' &&
      window.visualViewport
    ) {
      setDvhWithKeyboard(window.visualViewport.height / 100)
      setIsFocus(true)
    }
  }

  const onBlur = (ev: FocusEvent) => {
    if ((ev.target as Element).tagName.toLowerCase() === 'input') {
      setDvhWithKeyboard(null)
      setIsFocus(false)
    }
  }

  useEffect(() => {
    document.addEventListener('focusin', onFocus)
    document.addEventListener('focusout', onBlur)

    return () => {
      document.removeEventListener('focusin', onFocus)
      document.removeEventListener('focusout', onBlur)
    }
  }, [])

  return {
    isFocus,
    dvhWithKeyboard
  }
}

export default useFocus
