import { CancelReservation as Cancel } from './cancel'
import { CompleteReservation as Complete } from './complete'
import { ConfirmReservation as Confirm } from './confirm'
import { DateFilter } from './date-filter'
import { DeleteReservation as Delete } from './delete'
import { EditComment as Comment, EditReservation as Edit } from './edit'
import { HistoryReservation as History } from './history'
import { InProgressReservation as InProgress } from './inProgress'
import { MobileChangeTable } from './mobile-change-table'
import { MobileCommentsAction as MobileComments } from './mobile-comments'
import { MobileFilters } from './mobile-filters'
import { MobileTransfer } from './mobile-transfer'
import { Phone } from './phone'

export const Reservation = {
  Delete,
  Cancel,
  InProgress,
  Complete,
  Edit,
  Confirm,
  Comment,
  Phone,
  History,
  DateFilter,
  MobileFilters,
  MobileComments,
  MobileChangeTable,
  MobileTransfer
}
