import { cx } from 'class-variance-authority'
import { Link } from 'react-router-dom'

import { Icon } from '@/shared/ui'

import { type MenuItemType } from '../../config'

import css from './MenuItem.module.css'

interface MenuItemProps extends MenuItemType {
  selected: boolean
}

export function MenuItem({ title, icon, link, selected }: MenuItemProps) {
  return (
    <Link to={link} className={cx(css.link, { [css.link_selected]: selected })}>
      <Icon name={icon} size={24} />
      <span className={css.link__title}>{title}</span>
    </Link>
  )
}
