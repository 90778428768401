export const findStartingIntersection = (
  stringToCheck: string,
  matcher: string
) => {
  const cleanMatcher = matcher.trim().toLowerCase()
  const cleanStringToCheck = stringToCheck.trim().toLowerCase()

  if (cleanStringToCheck.startsWith(cleanMatcher) && cleanMatcher) {
    const otherPart = stringToCheck.slice(matcher.length)
    return [matcher, otherPart]
  }
  return [undefined, stringToCheck]
}
