import { createSelector } from '@reduxjs/toolkit'

import { type FilterTypesEnum } from '../model/filterEnums'
export const selectSelectedFilters = (state: RootState) =>
  state.filters.selectedFilters

export const selectCurPageSelectedFilters = createSelector(
  [
    (state: RootState) => state.filters.selectedFilters,
    (_: RootState, filters: OldFilter[], type: FilterTypesEnum) => ({
      filters,
      type
    })
  ],
  (selectedFilters, { filters, type }) =>
    Object.fromEntries(
      filters.map(({ name }) => [name, selectedFilters[type][name]])
    )
)

export const selectIsCurPageSelectedFilters = (
  state: RootState,
  type: FilterTypesEnum
) => {
  let isSelectedFilters = false

  if (
    Object.keys(state.filters.selectedFilters).length &&
    state.filters.selectedFilters[type]
  ) {
    isSelectedFilters = Object.entries(
      state.filters.selectedFilters[type]
    ).some(([_, values]) => values.length)
  }
  return isSelectedFilters
}
