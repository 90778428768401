import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Badge,
  Button,
  Icon,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'

import { TagsCheckboxesVaul } from './TagsCheckboxesVaul'
import { TextAreaTagsVaul } from './TextAreaTagsVaul'

import css from './MobileTags.module.css'

type MobileTagsProps = {
  tagsKit?: SimpleTag[]
  tagsState: SimpleTag[]
  isErrorTagsKit?: boolean
  isLoadingTagsKit?: boolean
  isLoadingSetTags?: boolean
  isLoadingCreateTagKit?: boolean
  setTagsState: Dispatch<SetStateAction<SimpleTag[]>>
  setTagsHandle: (tags: SimpleTag[]) => Promise<void>
  createTagKitHandle?: (name: string) => Promise<void>
}

export function MobileTags({
  tagsKit,
  tagsState,
  isErrorTagsKit,
  isLoadingTagsKit,
  isLoadingSetTags,
  isLoadingCreateTagKit,
  setTagsState,
  setTagsHandle,
  createTagKitHandle
}: MobileTagsProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [isNewTagPage, setIsNewTagPage] = useState<boolean>(false)

  const onOpenHandler = (open: boolean) => {
    if (!open) {
      setIsNewTagPage(false)
    }
    setOpen(open)
  }

  return (
    <div className={css.container}>
      <Vaul open={open} onOpenChange={onOpenHandler}>
        <VaulTrigger asChild>
          <Button
            className={css.button}
            contentClassName={css.button__content}
            variant="gray"
          >
            <Icon name="plus" size={12} strokeWidth={4} />
            Добавить тег
          </Button>
        </VaulTrigger>
        <VaulContent>
          {isNewTagPage ? (
            <TextAreaTagsVaul
              isLoadingCreateTagKit={isLoadingCreateTagKit}
              setIsNewTagPage={setIsNewTagPage}
              createTagKitHandle={createTagKitHandle}
            />
          ) : (
            <TagsCheckboxesVaul
              tagsState={tagsState}
              tagsKit={tagsKit}
              isErrorTagsKit={isErrorTagsKit}
              isLoadingTagsKit={isLoadingTagsKit}
              isLoadingSetTags={isLoadingSetTags}
              setIsNewTagPage={setIsNewTagPage}
              setOpen={setOpen}
              setTagsState={setTagsState}
              setTagsHandle={setTagsHandle}
              createTagKitHandle={createTagKitHandle}
            />
          )}
        </VaulContent>
      </Vaul>
      {tagsState.map((tag) => (
        <Badge key={tag.id} className={css.badge} size="xxs">
          {tag.name}
        </Badge>
      ))}
    </div>
  )
}
