import { useState } from 'react'

import { config } from '@/app/config'
import { NoGuestDialogContent } from '@/entities/guest'
import { useIsDevice } from '@/shared/hooks'
import { getCountdownSec, parseISOWithoutTimezone } from '@/shared/lib'
import { Button, Dialog, DialogContent, DialogTrigger, Icon } from '@/shared/ui'

import { TimerDown } from '../../timer-down'

import css from './NoGuestButton.module.css'

const { TIMER_COUNTDOWN_SEC } = config

type NoGuestButtonProps = {
  record: WaitingListRecord
}

export function NoGuestButton({ record }: NoGuestButtonProps) {
  const [open, setOpen] = useState(false)
  const { isSmallTablet } = useIsDevice()
  const { called_at } = record

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  const secondsLeft = called_at
    ? getCountdownSec(parseISOWithoutTimezone(called_at), TIMER_COUNTDOWN_SEC)
    : 0

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="xs" variant="tertiary" onClick={toggleDialogHandler}>
          {called_at && <TimerDown secondsLeft={secondsLeft} />}
          {!isSmallTablet && 'Гостя нет'}
          <Icon name="minus" />
        </Button>
      </DialogTrigger>
      <DialogContent className={css.content}>
        <NoGuestDialogContent
          record={record}
          toggleDialog={toggleDialogHandler}
        />
      </DialogContent>
    </Dialog>
  )
}
