import { type MouseEvent, useState } from 'react'

import { selectSelectedAddressId } from '@/entities/session'
import { deleteWaitingListRecord } from '@/entities/waiting-list'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Icon
} from '@/shared/ui'

type DeleteButtonProps = {
  id: number
}

export function DeleteButton({ id }: DeleteButtonProps) {
  const address = useAppSelector(selectSelectedAddressId)
  const [open, setOpen] = useState(false)

  const toggleDialogHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const deleteFromWaitingListHandler = () => {
    if (!address) return
    deleteWaitingListRecord(address, { id })
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button variant="tertiary" size="icon-sm" onClick={toggleDialogHandler}>
          <Icon name="trash" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent onClose={() => setOpen(false)}>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;удалите гостя из&nbsp;листа
            ожиданий, это действие будет невозможно отменить
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button onClick={deleteFromWaitingListHandler}>Удалить</Button>
          </AlertDialogAction>
          <AlertDialogCancel onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
