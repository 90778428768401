import * as React from 'react'

import * as DialogUI from '@radix-ui/react-dialog'
import { cx } from 'class-variance-authority'

import styles from './Dialog.module.css'

const Dialog = DialogUI.Root

const DialogTrigger = DialogUI.Trigger

const DialogPortal = DialogUI.Portal

type DialogOverlayProps = React.ComponentPropsWithoutRef<
  typeof DialogUI.Overlay
>

type DialogOverlayRef = React.ElementRef<typeof DialogUI.Overlay>

const DialogOverlay = React.forwardRef<DialogOverlayRef, DialogOverlayProps>(
  ({ className, ...props }, ref) => (
    <DialogUI.Overlay
      ref={ref}
      className={cx(styles.overlay, className)}
      {...props}
    />
  )
)
DialogOverlay.displayName = 'DialogOverlay'

type DialogContentRef = React.ElementRef<typeof DialogUI.Content>

type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogUI.Content
> & {
  onClose?: (e: React.MouseEvent) => void
}

const DialogContent = React.forwardRef<DialogContentRef, DialogContentProps>(
  ({ className, children, onClose, ...props }, ref) => (
    <DialogPortal>
      <DialogOverlay onClick={onClose} />
      <DialogUI.Content
        ref={ref}
        className={cx(styles.content, className)}
        {...props}
      >
        {children}
      </DialogUI.Content>
    </DialogPortal>
  )
)
DialogContent.displayName = 'DialogContent'

export { Dialog, DialogContent, DialogOverlay, DialogPortal, DialogTrigger }
