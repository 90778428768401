import { useState } from 'react'

import { selectTimelineSort, setSortType } from '@/entities/timeline'
import { TimelineOrderingEnum } from '@/entities/timeline/lib/const'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, VaulBody, VaulFooter, VaulHeader } from '@/shared/ui'

import { SortButton } from './SortButton'

import css from './SortVaul.module.css'

type SortVaulProps = {
  setOpen: (value: boolean) => void
}

export function SortVaul({ setOpen }: SortVaulProps) {
  const selectSortType = useAppSelector(selectTimelineSort)
  const [ordering, setOrdering] = useState<TimelineOrdering>(selectSortType)
  const dispatch = useAppDispatch()

  const changeSortType = (type: (typeof ordering)[keyof typeof ordering]) => {
    switch (true) {
      case type.startsWith('default'):
        return type.slice(8)
      case type.startsWith('-'):
        return `default_${type.slice(1)}`
      default:
        return `-${type}`
    }
  }

  const setOrderingTables = () => {
    setOrdering({
      tables: changeSortType(ordering.tables) as TimelineOrderingTables,
      date: TimelineOrderingEnum.StartDateDefault,
      booked: TimelineOrderingEnum.BookedDefault
    })
  }

  const setOrderingStarDate = () => {
    setOrdering({
      date: changeSortType(ordering.date) as TimelineOrderingStartDate,
      tables: TimelineOrderingEnum.TablesDefault,
      booked: TimelineOrderingEnum.BookedDefault
    })
  }

  const setOrderingBooked = () => {
    setOrdering({
      tables: TimelineOrderingEnum.TablesDefault,
      date: TimelineOrderingEnum.StartDateDefault,
      booked: changeSortType(ordering.booked) as TimelineOrderingBooked
    })
  }

  const onImprove = () => {
    dispatch(setSortType(ordering))
    setOpen(false)
  }

  return (
    <>
      <VaulHeader>
        <h4 className={css.header}>Сортировка</h4>
      </VaulHeader>
      <VaulBody className={css.body}>
        <SortButton
          sort={ordering.tables}
          setSort={setOrderingTables}
          downContent="1-&gt;999"
          upContent="999-&gt;1"
        >
          Номер стола
        </SortButton>
        <SortButton
          sort={ordering.date}
          setSort={setOrderingStarDate}
          downContent="00:00-&gt;23:59"
          upContent="23:59-&gt;00:00"
        >
          Время и дата
        </SortButton>
        <SortButton
          sort={ordering.booked}
          setSort={setOrderingBooked}
          downContent="1-&gt;999"
          upContent="999-&gt;1"
        >
          Загрузка
        </SortButton>
      </VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button}
          variant="primary"
          size="lg"
          onClick={onImprove}
        >
          Применить
        </Button>
      </VaulFooter>
    </>
  )
}
