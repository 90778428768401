import { getHours } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

import { dayIntervalsHoursEndTime } from '../model/config'

export const getCurIntervalIndexEndTime = (date?: Date): number => {
  const now = date ?? nowWithTimezone()
  const hour = getHours(now)

  const { one, two, three, four, five } = dayIntervalsHoursEndTime

  if (hour >= one.start && hour <= one.end) {
    return 0
  } else if (hour >= two.start && hour <= two.end) {
    return 1
  } else if (hour >= three.start && hour <= three.end) {
    return 2
  } else if (hour >= four.start || hour <= four.end) {
    return 3
  } else if (hour >= five.start || hour <= five.end) {
    return 4
  } else {
    return 0
  }
}
