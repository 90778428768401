import { combineReducers } from '@reduxjs/toolkit'

import { createUpdateMailingSlice } from '@/entities/create-update-mailing'
import { createUpdateReservationSlice } from '@/entities/create-update-reservation'
import { dashboardSlice } from '@/entities/dashboard'
import { filtersSlice } from '@/entities/filters'
import { guestSlice } from '@/entities/guest'
import { hallSlice } from '@/entities/halls'
import { iframeSlice } from '@/entities/iframe'
import { mailingSlice } from '@/entities/mailing'
import { mapSlice } from '@/entities/map'
import { newWaitingListRecordSlice } from '@/entities/new-waiting-list-record'
import { phoneSlice } from '@/entities/phone'
import { reservationByIdSlice } from '@/entities/reservation'
import { timePickerSlice } from '@/entities/reservation-time-picker'
import {
  reservationsOrderingSlice,
  reservationsSlice
} from '@/entities/reservations'
import { searchGuestSlice } from '@/entities/search-guests'
import { sessionSlice } from '@/entities/session'
import { takeTableSlice } from '@/entities/take-table'
import { themeSlice } from '@/entities/theme'
import { timelineSlice } from '@/entities/timeline'
import { waitingListSlice } from '@/entities/waiting-list'
import { baseApi } from '@/shared/api'

export const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [themeSlice.name]: themeSlice.reducer,
  [timelineSlice.name]: timelineSlice.reducer,
  [createUpdateReservationSlice.name]: createUpdateReservationSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [timePickerSlice.name]: timePickerSlice.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [guestSlice.name]: guestSlice.reducer,
  [hallSlice.name]: hallSlice.reducer,
  [newWaitingListRecordSlice.name]: newWaitingListRecordSlice.reducer,
  [waitingListSlice.name]: waitingListSlice.reducer,
  [reservationsSlice.name]: reservationsSlice.reducer,
  [reservationByIdSlice.name]: reservationByIdSlice.reducer,
  [filtersSlice.name]: filtersSlice.reducer,
  [searchGuestSlice.name]: searchGuestSlice.reducer,
  [takeTableSlice.name]: takeTableSlice.reducer,
  [dashboardSlice.name]: dashboardSlice.reducer,
  [phoneSlice.name]: phoneSlice.reducer,
  [mailingSlice.name]: mailingSlice.reducer,
  [createUpdateMailingSlice.name]: createUpdateMailingSlice.reducer,
  [iframeSlice.name]: iframeSlice.reducer,
  [reservationsOrderingSlice.name]: reservationsOrderingSlice.reducer
})
