import { useState } from 'react'

import { cx } from 'class-variance-authority'
import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isFuture,
  isWithinInterval,
  minutesInHour
} from 'date-fns'
import { isMobileOnly } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import { config } from '@/app/config'
import {
  addReservationFromEmptySlot,
  ReservationSkipStep,
  setReservationSkipStep,
  setReservationTables
} from '@/entities/create-update-reservation'
import { selectHall } from '@/entities/halls'
import { MapModeEnum, setMapMode } from '@/entities/map'
import {
  ReservationByIdPageSteps,
  resetReservationByIdState,
  selectReservation,
  setReservationByIdPageStep
} from '@/entities/reservation'
import { Reservation } from '@/features/create-update-reservation'
import { formatName, nowWithTimezone, PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Badge, Icon } from '@/shared/ui'

import ReservationPopover from './ReservationPopover/ReservationPopover'
import ReservationVaul from './ReservationVaul/ReservationVaul'

import {
  MIN_HIDDEN_EMPTY_SLOT,
  MIN_MEDIUM_BOOKED_SLOT,
  MIN_SLOT_DURATION_FOR_SUGGESTION,
  MIN_SMALL_EMPTY_SLOT
} from '../lib/config'
import { selectCurDate } from '../model/selectors'

import css from './TimeSlot.module.css'

const {
  MIN_RESERVATION_TIME_MIN,
  MIN_DURATION_INTERVAL_MIN,
  PICKERS_SPLIT_HOUR_BY,
  WORKING_HOURS_START
} = config

type TimeSlotProps = {
  slot: TimeSlot
  showEmptySuggestion: boolean
  selectedTables?: Table[]
  mapItem?: MapItem
}

export function TimeSlot({
  slot,
  showEmptySuggestion,
  selectedTables,
  mapItem
}: TimeSlotProps) {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const reservationById = useAppSelector(selectReservation)
  const curDate = useAppSelector(selectCurDate)

  const workingHoursStart = new Date(curDate)
  workingHoursStart.setHours(WORKING_HOURS_START)

  const now = nowWithTimezone()

  const {
    guest,
    persons_count,
    booked,
    status,
    spanStart,
    spanEnd,
    start_date,
    end_date
  } = slot

  const isReservationNow = isAfter(now, start_date) && isBefore(now, end_date)

  const isReservationLate =
    isReservationNow && status !== 'in_progress' && status !== 'completed'

  const isSlotInFuture =
    isWithinInterval(now, {
      start: start_date,
      end: end_date
    }) || isFuture(start_date)

  const isReservationInPast =
    isAfter(now, end_date) && status && status !== 'completed'

  const minutes = differenceInMinutes(
    end_date,
    isAfter(start_date, workingHoursStart) ? start_date : workingHoursStart
  )

  const isSmall = minutes < MIN_SMALL_EMPTY_SLOT
  const isMedium = minutes < MIN_RESERVATION_TIME_MIN
  const isHidden = minutes < MIN_HIDDEN_EMPTY_SLOT

  const largeEnoughForReservation =
    differenceInMinutes(
      end_date,
      isAfter(now, workingHoursStart) ? now : workingHoursStart
    ) >=
    MIN_SLOT_DURATION_FOR_SUGGESTION + minutesInHour / PICKERS_SPLIT_HOUR_BY

  const isClickable =
    booked ||
    (isSlotInFuture && showEmptySuggestion && largeEnoughForReservation)

  const timeSlotClassName = cx({
    [css.root]: true,
    [css.root_popover]: open,
    [css.root_default]: booked,
    [css.root_default_mobile]: booked && isMobileOnly,
    [css.root_default_mobile_now]:
      booked &&
      isMobileOnly &&
      isWithinInterval(now, {
        start: start_date,
        end: end_date
      }),
    [css.root_empty]: !booked,
    [css.root_empty_mobile]: !booked && isMobileOnly,
    [css.root_empty_mobile_now]:
      !booked &&
      isMobileOnly &&
      isWithinInterval(now, {
        start: start_date,
        end: end_date
      }),
    [css.root_empty_now]:
      !booked &&
      isSlotInFuture &&
      largeEnoughForReservation &&
      showEmptySuggestion,
    [css.root_empty_small]: isSmall,
    [css.root_empty_hidden]: isHidden,
    [css.root_default_late]: isReservationLate,
    [css.root_default_medium]: isMedium,
    [css.root_default_finished_late]: isReservationInPast
  })

  const slotClickHandler = () => {
    if (
      !booked &&
      isSlotInFuture &&
      largeEnoughForReservation &&
      showEmptySuggestion
    ) {
      const guest = reservationById?.guest

      dispatch(selectHall(slot.table.hall.id))

      dispatch(
        addReservationFromEmptySlot({
          start_date: slot.start_date,
          duration: MIN_DURATION_INTERVAL_MIN,
          persons_count: slot.table.min_persons_count,
          tableIds: [slot.table.id],
          hall_id: slot.table.hall.id,
          guest
        })
      )

      if (guest) {
        dispatch(setMapMode(MapModeEnum.one))
        dispatch(resetReservationByIdState())
        dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.EDIT))
      } else if (!isMobileOnly) {
        navigate(PATHS.newReservation)
      } else if (mapItem) {
        dispatch(setReservationSkipStep(ReservationSkipStep.TABLE))
        dispatch(setReservationTables([mapItem]))
      }
    }
  }

  let content

  if (!booked) {
    content = (
      <div className={css.empty}>
        {minutes >= MIN_RESERVATION_TIME_MIN ? (
          <div className={css.empty__time}>
            <span>{start_date ? format(start_date, 'с H:mm') : ''}</span>
            <span>{end_date ? format(end_date, 'до H:mm') : ''}</span>
          </div>
        ) : (
          <Icon name="x" size={24} />
        )}
      </div>
    )
  }

  if (booked) {
    const isDefaultSize = minutes > MIN_MEDIUM_BOOKED_SLOT

    content = (
      <div
        className={cx(css.booked, {
          [css.booked_medium]: !isDefaultSize
        })}
      >
        {persons_count && !isMedium && (
          <Badge size="xs" className={css.booked__badge}>
            {persons_count}
            <Icon name="users" size={12} />
          </Badge>
        )}
        {isDefaultSize && guest && (
          <span className={css.booked__name}>
            {formatName(guest.first_name, `${guest.last_name.slice(0, 1)}.`)}
          </span>
        )}
        {!isSmall && (
          <span>{format(end_date, `${isDefaultSize ? 'до' : ''} HH:mm`)}</span>
        )}
        {isSmall && <Icon name="book" />}
      </div>
    )
  }

  if (isMobileOnly && booked) {
    return (
      <ReservationVaul open={open} setOpen={setOpen} slot={slot}>
        <button
          tabIndex={0}
          style={{ gridColumn: `${spanStart} / ${spanEnd}` }}
          className={timeSlotClassName}
        >
          {content}
        </button>
      </ReservationVaul>
    )
  }

  if (isMobileOnly && selectedTables && isClickable) {
    return (
      <Reservation.MobileInit
        setOpen={setOpen}
        tabIndex={0}
        style={{ gridColumn: `${spanStart} / ${spanEnd}` }}
        className={timeSlotClassName}
        contentClassName={css.button__content}
        table={mapItem}
        onClick={slotClickHandler}
      >
        {content}
      </Reservation.MobileInit>
    )
  }

  return (
    <ReservationPopover open={open} setOpen={setOpen} slot={slot}>
      <button
        tabIndex={isClickable ? 0 : -1}
        style={{ gridColumn: `${spanStart} / ${spanEnd}` }}
        className={timeSlotClassName}
        onClick={booked ? undefined : slotClickHandler}
      >
        {content}
      </button>
    </ReservationPopover>
  )
}
