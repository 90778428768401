import { createSelector } from '@reduxjs/toolkit'

import { sortTables } from '../lib'

export const selectHours = (state: RootState) => state.timeline.hours
export const selectTables = createSelector(
  (state: RootState) => state.timeline.tables,
  (state: RootState) => state.timeline.sortType,
  (state: RootState) => state.hall.selectedHall,
  (state: RootState) => state.timeline.search,
  (tables, sortType, selectedHall, search) =>
    sortTables(tables, sortType).filter((t) =>
      search
        ? t.hall.id === selectedHall && String(t.item_number) === search
        : t.hall.id === selectedHall
    )
)

export const selectMinutesLinePosition = (state: RootState) =>
  state.timeline.minutesLinePosition
export const selectCurDate = (state: RootState) => state.timeline.curDate
export const selectSelectedTimelineTable = (state: RootState) =>
  state.timeline.selectedTable
export const selectTimelineSort = (state: RootState) => state.timeline.sortType
export const selectTablesTimeSlots = (state: RootState) =>
  state.timeline.tablesTimeSlots

export const selectSelectedTableTimeSlots = (state: RootState) =>
  state.timeline.selectedTableTimeSlots

export const selectSelectedTimelineTables = (state: RootState) =>
  state.timeline.tables

export const selectTableWithOpenTimeline = (state: RootState) =>
  state.timeline.tableWithOpenTimeline

export const selectSearch = (state: RootState) => state.timeline.search
