import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useGuestQuery } from '@/entities/guest'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import { formatTimeForRequest, nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Skeleton, TabsProvider, TemplateDates, VaulFooter } from '@/shared/ui'

import { GuestVaulCardContent } from './GuestVaulCardContent'
import { GuestVaulCardHeader } from './GuestVaulCardHeader'

import css from './GuestVaulContent.module.css'

type GuestVaulContentProps = {
  guestId?: number | string
  updateGuestsList?: (
    guestId: number,
    isDelete: boolean,
    data?: Partial<Guest>
  ) => void
}

export default function GuestVaulContent({
  guestId,
  updateGuestsList
}: GuestVaulContentProps) {
  const chain = useAppSelector(selectSelectedChainId)
  const address = useAppSelector(selectSelectedAddressId)
  const now = nowWithTimezone()

  const [selectedDateInterval] = useState<IntervalData>({
    start_date: TemplateDates.allTimeStartDate,
    end_date: now
  })

  const { data, isFetching } = useGuestQuery(
    guestId && !Number.isNaN(+guestId) && chain && address
      ? {
          chain,
          guestId: +guestId,
          address,
          start_date: formatTimeForRequest(selectedDateInterval.start_date),
          end_date: formatTimeForRequest(selectedDateInterval.end_date)
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    if (data) {
      updateGuestsList?.(data.id, false, {
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone
      })
    }
  }, [data])

  if (isFetching || !data) {
    return (
      <div className={css.skeleton}>
        <div className={css.skeleton__header}>
          <Skeleton className={css.skeleton__tab} />
          <Skeleton className={css.skeleton__tab} />
        </div>
        <Skeleton className={css.skeleton__guest} />
        <Skeleton className={css.skeleton__statistics} />
        <Skeleton className={css.skeleton__comments} />
        <VaulFooter>
          <Skeleton className={css.skeleton__button} />
        </VaulFooter>
      </div>
    )
  }

  return (
    <TabsProvider defaultValue="info">
      <div className={css.card}>
        <GuestVaulCardHeader />
        <GuestVaulCardContent guest={data} />
      </div>
    </TabsProvider>
  )
}
