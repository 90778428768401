import { DeleteButton as Delete } from './delete'
import { GuestsPicker } from './guests-picker'
import { HallsPicker } from './halls-picker'
import { NoGuestButton as NoGuest } from './no-guest'
import { SitGuestButton as SitGuest } from './sit'
import { SitComplete as SitGuestComplete } from './sit-complete'
import { TimerDown } from './timer-down'
import { TimerUp } from './timer-up'

export const WaitingList = {
  NoGuest,
  Delete,
  SitGuest,
  SitGuestComplete,
  TimerUp,
  TimerDown,
  HallsPicker,
  GuestsPicker
}
