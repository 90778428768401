import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { Icon, type IconNames } from '../Icon'

import css from './InfoBox.module.css'

type InfoBoxProps = ComponentPropsWithoutRef<'div'> &
  PropsWithChildren & {
    icon?: IconNames
    noIcon?: boolean
  }

export default function InfoBox({
  className,
  icon = 'info',
  noIcon = false,
  children
}: InfoBoxProps) {
  return (
    <div className={cx(css.info, className)}>
      {!noIcon && <Icon name={icon} size={24} />}
      <p className={css.info__text}>{children}</p>
    </div>
  )
}
