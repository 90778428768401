import { useEffect, useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'

import { setHasUnsentComments } from '@/entities/create-update-reservation'
import { PATHS } from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Icon,
  Input,
  Prompt
} from '@/shared/ui'

import css from './SendCommentForm.module.css'

const formSchema = z.object({
  message: z.string().min(1, { message: 'Введите комментарий' })
})

export default function SendCommentForm({
  placeholder,
  commentToEdit,
  isSendLoading,
  isUpdateLoading,
  setCommentToEdit,
  sendCommentHandler,
  updateCommentHandler
}: {
  placeholder: string
  commentToEdit: UserComment | undefined
  isSendLoading?: boolean
  isUpdateLoading?: boolean
  setCommentToEdit: (comment: UserComment | undefined) => void
  sendCommentHandler: (text: string) => Promise<void>
  updateCommentHandler: (commentId: number, text: string) => Promise<void>
}) {
  const editInputRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const sendForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      message: ''
    }
  })

  const editForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      message: ''
    }
  })

  const submitSendHandler = async ({ message }: z.infer<typeof formSchema>) => {
    try {
      await sendCommentHandler(message)
      sendForm.reset()
    } catch {
      // do nothing
    }
  }

  const submitEditHandler = async ({ message }: z.infer<typeof formSchema>) => {
    if (!commentToEdit) return

    try {
      await updateCommentHandler(commentToEdit.id, message)
      editForm.reset()
      setCommentToEdit(undefined)
    } catch {
      // do nothing
    }
  }

  useEffect(() => {
    if (commentToEdit) {
      editForm.setValue('message', commentToEdit.text)
      setTimeout(() => editInputRef.current?.focus(), 10)
    }
  }, [commentToEdit])

  const message = sendForm.watch('message')

  useEffect(() => {
    dispatch(setHasUnsentComments(Boolean(message)))
  }, [message])

  const cancelEditHandler = () => {
    setCommentToEdit(undefined)
  }

  if (commentToEdit) {
    return (
      <div className={css.comment}>
        <div className={css.comment__box}>
          <div className={css.comment__info}>
            <Icon name="pencil" />
            <div className={css.comment__text}>{commentToEdit.text}</div>
          </div>
          <Button
            size="xxs"
            variant="tertiary"
            className={css.comment__cancel}
            onClick={cancelEditHandler}
          >
            <Icon name="x" />
          </Button>
        </div>
        <Form {...editForm}>
          <form
            onSubmit={editForm.handleSubmit(submitEditHandler)}
            className={css.comment__form}
            noValidate
          >
            <FormField
              control={editForm.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      data-vaul-no-drag
                      ref={editInputRef}
                      className={css.comment__input}
                      variant="outlined"
                      placeholder={placeholder}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button size="icon" variant="primary" isLoading={isUpdateLoading}>
              <Icon name="check" />
            </Button>
          </form>
        </Form>
      </div>
    )
  }

  return (
    <Form {...sendForm}>
      <form
        onSubmit={sendForm.handleSubmit(submitSendHandler)}
        className={css.comment__form}
        noValidate
      >
        <FormField
          control={sendForm.control}
          name="message"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  data-vaul-no-drag
                  className={css.comment__input}
                  variant="outlined"
                  placeholder={placeholder}
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <Button
          size="icon"
          variant={sendForm.watch('message') ? 'primary' : 'tertiary'}
          isLoading={isSendLoading}
        >
          <Icon name="send" />
        </Button>
      </form>
      {Boolean(sendForm.watch('message')) &&
        pathname.startsWith(PATHS.reservation) && (
          <Prompt
            isShow={Boolean(sendForm.watch('message'))}
            title="Выйти без сохранения?"
            description="Вы уверены, что хотите выйти без отправки комментария?"
          />
        )}
    </Form>
  )
}
