import { baseApi, MAILING_TAG, MAILINGS_TAG } from '@/shared/api'

// TODO change this requests later
export const mailingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    mailings: builder.query<ResponseAllMailing, AllMailingsParams>({
      query: ({ limit, offset }) => ({
        url: '/mailings/',
        params: {
          limit,
          offset
        }
      }),
      providesTags: [MAILINGS_TAG]
    }),
    mailing: builder.query<IMailing, string>({
      query: (id) => ({
        url: `/mailings/${id}/`
      }),
      providesTags: [MAILING_TAG]
    }),
    mailingReceivers: builder.query<
      ResponseMailingReceivers,
      MailingReceiversParams
    >({
      query: ({ id, address, limit, offset }) => ({
        url: `/mailings/${id}/receivers/`,
        params: {
          address,
          limit,
          offset
        }
      })
    }),
    createMailing: builder.mutation<ResponseCreateUpdateMailing, MailingBody>({
      query: (body) => ({
        url: '/mailings/',
        method: 'POST',
        body
      }),
      invalidatesTags: [MAILINGS_TAG]
    }),
    updateMailing: builder.mutation<
      ResponseCreateUpdateMailing,
      UpdateMailingDto
    >({
      query: ({ id, body }) => ({
        url: `/mailings/${id}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [MAILINGS_TAG, MAILING_TAG]
    }),
    sendMailing: builder.mutation<void, string>({
      query: (id) => ({
        url: `/mailings/${id}/send/`,
        method: 'POST'
      }),
      invalidatesTags: [MAILINGS_TAG]
    }),
    deleteMailing: builder.mutation<void, string>({
      query: (id) => ({
        url: `/mailings/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [MAILINGS_TAG]
    })
  })
})

export const {
  useMailingsQuery,
  useMailingQuery,
  useSendMailingMutation,
  useCreateMailingMutation,
  useUpdateMailingMutation,
  useMailingReceiversQuery,
  useDeleteMailingMutation
} = mailingsApi
