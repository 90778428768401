import { useEffect } from 'react'

import { toast } from 'react-toastify'

const useToastSuccessHandling = (isSuccess: boolean, description: string) => {
  useEffect(() => {
    if (isSuccess) toast.success(description)
  }, [isSuccess, description])
}
export default useToastSuccessHandling
