import { type ButtonHTMLAttributes, type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { Button, Icon } from '@/shared/ui'

import css from './FilterButton.module.css'

type FilterButtonProps = PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    countActive?: number
  }

export function FilterButton({
  countActive,
  children,
  ...props
}: FilterButtonProps) {
  return (
    <Button
      size="lg"
      className={cx(css.button, {
        [css.button_active]: countActive
      })}
      contentClassName={css.button__content}
      {...props}
    >
      {children}
      <div
        className={cx(css.button__info, {
          [css.button__info_active]: countActive
        })}
      >
        {!!countActive && <span>{countActive}</span>}
        <Icon name="chevronRight" />
      </div>
    </Button>
  )
}
