import { baseApi, tagTypes } from '@/shared/api'

const { GUEST_COMMENTS_TAG, RESERVATION_COMMENTS_TAG } = tagTypes

export const commentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    guestComments: builder.query<UserComment[], { guestId: number }>({
      query: ({ guestId }) => ({
        url: `/book/chains/guests/${guestId}/comments/`
      }),
      providesTags: [GUEST_COMMENTS_TAG]
    }),
    sendGuestComment: builder.mutation<UserComment, SendGuestCommentParams>({
      query: ({ guestId, body }) => ({
        url: `/book/chains/guests/${guestId}/comments/`,
        method: 'POST',
        body
      }),
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    updateGuestComment: builder.mutation<Comment, UpdateGuestCommentParams>({
      query: ({ guestId, commentId, body }) => ({
        url: `/book/chains/guests/${guestId}/comments/${commentId}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    deleteGuestComment: builder.mutation<void, DeleteGuestCommentParams>({
      query: ({ guestId, commentId }) => ({
        url: `/book/chains/guests/${guestId}/comments/${commentId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    reservationComments: builder.query<
      UserComment[],
      { reservationId: number }
    >({
      query: ({ reservationId }) => ({
        url: `/book/reservations/${reservationId}/comments/`
      }),
      providesTags: [RESERVATION_COMMENTS_TAG]
    }),
    sendReservationComment: builder.mutation<
      Comment,
      SendReservationCommentParams
    >({
      query: ({ reservationId, body }) => ({
        url: `/book/reservations/${reservationId}/comments/`,
        method: 'POST',
        body
      }),
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    }),
    updateReservationComment: builder.mutation<
      Comment,
      UpdateReservationCommentParams
    >({
      query: ({ reservationId, commentId, body }) => ({
        url: `/book/reservations/${reservationId}/comments/${commentId}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    }),
    deleteReservationComment: builder.mutation<
      void,
      DeleteReservationCommentParams
    >({
      query: ({ reservationId, commentId }) => ({
        url: `/book/reservations/${reservationId}/comments/${commentId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    })
  })
})

export const {
  useGuestCommentsQuery,
  useReservationCommentsQuery,
  useSendGuestCommentMutation,
  useSendReservationCommentMutation,
  useDeleteGuestCommentMutation,
  useDeleteReservationCommentMutation,
  useUpdateReservationCommentMutation,
  useUpdateGuestCommentMutation
} = commentsApi
