import * as React from 'react'

import { cx } from 'class-variance-authority'
import { Command as CommandUI } from 'cmdk'

import css from './Command.module.css'

const Command = React.forwardRef<
  React.ElementRef<typeof CommandUI>,
  React.ComponentPropsWithoutRef<typeof CommandUI>
>(({ className, ...props }, ref) => (
  <CommandUI ref={ref} className={cx(css.command, className)} {...props} />
))
Command.displayName = 'Command'

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandUI.List>,
  React.ComponentPropsWithoutRef<typeof CommandUI.List>
>(({ className, ...props }, ref) => (
  <CommandUI.List ref={ref} className={cx(css.list, className)} {...props} />
))

CommandList.displayName = 'CommandList'

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandUI.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandUI.Empty>
>((props, ref) => (
  <CommandUI.Empty ref={ref} className={css.empty} {...props} />
))

CommandEmpty.displayName = 'CommandEmpty'

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandUI.Item>,
  React.ComponentPropsWithoutRef<typeof CommandUI.Item>
>(({ className, ...props }, ref) => (
  <CommandUI.Item ref={ref} className={cx(css.item, className)} {...props} />
))

CommandItem.displayName = 'CommandItem'

export { Command, CommandList, CommandEmpty, CommandItem }
