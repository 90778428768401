import { baseApi } from '@/shared/api'

import { transformReservationSourcesToFilter } from '../lib'

export const reservationSourceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    reservationSources: build.query<ReservationLink[], { addressId: number }>({
      query: ({ addressId }) => ({
        url: `/book/widget/addresses/${addressId}/links/`
      })
    }),
    reservationSourceFilter: build.query<Filter, { addressId: number }>({
      query: ({ addressId }) => ({
        url: `/book/widget/addresses/${addressId}/links/`
      }),
      transformResponse: (response: ReservationLink[]) =>
        transformReservationSourcesToFilter(response)
    })
  })
})

export const { useReservationSourceFilterQuery, useReservationSourcesQuery } =
  reservationSourceApi
