/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react'

import { type DraggableSyntheticListeners } from '@dnd-kit/core'

interface Context {
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref: (node: HTMLElement | null) => void
}

export const WaitingListSortableContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
})
