import {
  type ComponentPropsWithoutRef,
  type Dispatch,
  memo,
  type SetStateAction,
  useEffect
} from 'react'

import { cva, type VariantProps } from 'class-variance-authority'
import { format, isBefore } from 'date-fns'
import { useSwiper } from 'swiper/react'

import { findCurrentIntervalValue, nowWithTimezone } from '@/shared/lib'

import css from './SwiperStep.module.css'

type SwiperStepProps = ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof stepStyle> & {
    isActive: boolean
    step: Date
    timeSteps: Date[]
    setIsActiveLong: Dispatch<SetStateAction<boolean>>
    setActiveTime: (date: Date) => void
  }

const stepStyle = cva(css.step, {
  variants: {
    isLong: {
      true: css.step_long,
      false: ''
    },
    isPast: {
      true: css.step_past,
      false: ''
    }
  },
  defaultVariants: {
    isLong: false,
    isPast: false
  }
})

const SwiperStep = memo(
  ({
    step,
    timeSteps,
    isActive,
    setIsActiveLong,
    setActiveTime,
    className
  }: SwiperStepProps) => {
    const swiper = useSwiper()
    const minutes = step.getMinutes()
    const isLong = minutes === 0 || minutes === 30
    const now = nowWithTimezone()
    const isPast = isBefore(step, now)

    useEffect(() => {
      if (isActive) {
        setIsActiveLong(isLong)
        setActiveTime(step)
        if (isPast) {
          const valueObj = findCurrentIntervalValue(timeSteps)
          if (valueObj?.idx) {
            swiper.slideTo(valueObj.idx)
          }
        }
      }
    }, [isActive, isPast])

    return (
      <>
        <div
          className={stepStyle({
            isLong,
            isPast,
            className
          })}
        >
          {isLong && (
            <span className={css.step__time}>{format(step, 'HH:mm')}</span>
          )}
        </div>
      </>
    )
  }
)

export default SwiperStep
