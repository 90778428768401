import { createSelector } from '@reduxjs/toolkit'

export const selectTakeTableStep = (state: RootState) => state.takeTable.step

export const selectAvailablePersonsCount = createSelector(
  (state: RootState) => state.takeTable.minPersonsCount,
  (state: RootState) => state.takeTable.maxPersonsCount,
  (min, max) => [min, max]
)

export const selectAvailableDuration = (state: RootState) =>
  state.takeTable.maxDuration
