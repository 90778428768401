import { useLocation } from 'react-router-dom'

import { selectProfileData } from '@/entities/session'
import { LogoutButton } from '@/features/authorization/Logout'
import { AddressLogoutButton } from '@/features/authorization/logout-address'
import { useAppSelector } from '@/shared/model'
import { Icon } from '@/shared/ui'

import { MenuItem } from './MenuItem'

import { MENU_ITEMS } from '../config'

import css from './SidebarNav.module.css'

export function SidebarNav() {
  const location = useLocation()
  const profileData = useAppSelector(selectProfileData)

  return (
    <aside className={css.sidebar}>
      <nav className={css.sidebar__nav}>
        <div className={css.sidebar__logo}>
          <Icon name="logo" width={46} height={23} />
        </div>
        {MENU_ITEMS.map(({ icon, link, title }) => (
          <MenuItem
            key={title}
            title={title}
            icon={icon}
            link={link}
            selected={location.pathname.includes(link)}
          />
        ))}
      </nav>
      <div className={css.sidebar__logout}>
        {profileData?.addresses && profileData.addresses.length > 1 ? (
          <AddressLogoutButton className={css.sidebar__logout__button} />
        ) : (
          <LogoutButton className={css.sidebar__logout__button} />
        )}
      </div>
    </aside>
  )
}
