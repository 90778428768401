import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { selectSelectedAddressId } from '@/entities/session'
import { useSubscribeToWaitingListQuery } from '@/entities/waiting-list'
import { Reservation } from '@/features/create-update-reservation'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  Skeleton,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileNewReservationOptions.module.css'

export default function MobileNewReservationOptions() {
  const [open, setOpen] = useState(false)
  const address = useAppSelector(selectSelectedAddressId)

  // TODO: add all free tables and free intervals

  const {
    data: waitingList,
    isLoading: isLoadingWaitingList,
    isError: isErrorWaitingList
  } = useSubscribeToWaitingListQuery(address ? { address } : skipToken)

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button className={css.trigger} variant="transparent">
          <Icon name="plusCircle" size={24} />
        </Button>
      </VaulTrigger>
      <VaulContent>
        <div className={css.actions}>
          <Button
            className={css.button}
            contentClassName={css.button__content}
            size="xs"
            disabled
          >
            <div className={css.button__content__info}>
              <span className={css.button__title}>
                Добавить в лист ожиданий
              </span>
              {isLoadingWaitingList || isErrorWaitingList || !waitingList ? (
                <Skeleton className={css.skeleton__subtitle} />
              ) : (
                <span className={css.button__subtitle}>
                  В очереди - {waitingList.records?.length ?? 0}
                </span>
              )}
            </div>
            <Icon name="clockSnooze" size={20} />
          </Button>
          <Reservation.MobileInit
            setOpen={setOpen}
            className={css.button}
            contentClassName={css.button__content}
            size="xs"
          >
            <div className={css.button__content__info}>
              <span className={css.button__title}>Забронировать столик</span>
              {/* <Skeleton className={css.skeleton__subtitle} /> */}
              <span className={css.button__subtitle}>
                Свободные интервалы на сегодня
              </span>
            </div>
            <Icon name="bookmark" size={20} strokeWidth={3} />
          </Reservation.MobileInit>
          <Button
            className={css.button}
            contentClassName={css.button__content}
            size="xs"
            disabled
          >
            <div className={css.button__content__info}>
              <span className={css.button__title}>Посадить сразу</span>
              {/* <Skeleton className={css.skeleton__subtitle} /> */}
              <span className={css.button__subtitle}>Свободные столы</span>
            </div>
            <Icon name="restaurant" size={20} />
          </Button>
        </div>
      </VaulContent>
    </Vaul>
  )
}
