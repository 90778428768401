import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'
import { useParams } from 'react-router-dom'

import { useReservationQuery } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Skeleton, VaulFooter } from '@/shared/ui'

import { ReservationVaulCardContent } from './ReservationVaulCardContent'
import { ReservationVaulCardHeader } from './ReservationVaulCardHeader'

import css from './ReservationVaulContent.module.css'

type ReservationVaulContentProps = {
  reservationId?: number | string
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

export default function ReservationVaulContent({
  reservationId,
  updateReservationsList
}: ReservationVaulContentProps) {
  const { id } = useParams()
  const [prevReservationId, setPrevReservationId] = useState<number | null>(
    null
  )
  const addressId = useAppSelector(selectSelectedAddressId)

  const { data, isLoading } = useReservationQuery(
    addressId && reservationId && !Number.isNaN(+reservationId)
      ? { addressId, reservationId: prevReservationId ?? +reservationId }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  )

  if (isLoading || !data) {
    return (
      <div className={css.skeleton}>
        <div className={css.skeleton__header}>
          <Skeleton className={css.skeleton__badge} />
          <Skeleton className={css.skeleton__title} />
        </div>
        <Skeleton className={css.skeleton__guest} />
        <Skeleton className={css.skeleton__time} />
        <Skeleton className={css.skeleton__map} />
        <Skeleton className={css.skeleton__source} />
        <Skeleton className={css.skeleton__source} />
        <VaulFooter>
          <Skeleton className={css.skeleton__button} />
        </VaulFooter>
      </div>
    )
  }

  return (
    <div className={cx(css.card, { [css.card__noVaul]: id })}>
      <ReservationVaulCardHeader reservation={data} />
      <ReservationVaulCardContent
        reservation={data}
        updateReservationsList={updateReservationsList}
        setPrevReservationId={setPrevReservationId}
      />
    </div>
  )
}
