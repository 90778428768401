import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import { Button, type ButtonStyleProps, VaulFooter } from '@/shared/ui'

import css from './ReservationProcessFooter.module.css'

type ReservationProcessFooterProps = ComponentPropsWithoutRef<'button'> &
  ButtonStyleProps &
  PropsWithChildren

export default function ReservationProcessFooter({
  children,
  ...props
}: ReservationProcessFooterProps) {
  return (
    <VaulFooter>
      <Button size="lg" variant="primary" className={css.button} {...props}>
        {children}
      </Button>
    </VaulFooter>
  )
}
