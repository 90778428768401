import { type Dispatch, type SetStateAction } from 'react'

import { customAlphabet } from 'nanoid'

import { AllCommentsDrawer, CommentsList } from '@/entities/comments'
import { GuestTags } from '@/entities/guest'
import { selectProfileData } from '@/entities/session'
import { ReservationTagsCombobox } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'
import { Button, Card } from '@/shared/ui'

import css from '../Comments.module.css'

type NewGuestCommentsProps = {
  tags: SimpleTag[]
  setTags: Dispatch<SetStateAction<SimpleTag[]>>
  comments: UserComment[]
  setComments: Dispatch<SetStateAction<UserComment[]>>
  tagsKit: Tag[] | undefined
  isLoadingTagsKit: boolean
}

export default function NewGuestComments({
  tags,
  setTags,
  comments,
  setComments,
  tagsKit,
  isLoadingTagsKit
}: NewGuestCommentsProps) {
  const profileData = useAppSelector(selectProfileData)
  const nanoid = customAlphabet('1234567890', 18)

  const deleteCommentHandler = async (commentId: number) => {
    setComments((prev) => prev.filter((comment) => comment.id !== commentId))
  }

  const sendCommentHandler = async (text: string) => {
    if (!profileData) return

    setComments((prev) => [
      ...prev,
      {
        id: Number(nanoid()),
        is_author: true,
        created_at: new Date().toISOString(),
        text,
        author: {
          id: profileData.id,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          image: profileData.image
        }
      }
    ])
  }

  const updateCommentHandler = async (commentId: number, text: string) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === commentId
          ? {
              ...comment,
              text
            }
          : comment
      )
    )
  }

  const setTagsHandler = async (tags: SimpleTag[]) => {
    setTags(tags)
  }

  return (
    <Card className={css.card}>
      <div className={css.card__guest}>
        <div className={css.card__info}>
          <div className={css.card__name}>
            <div className={css.card__text}>О госте</div>
          </div>
          <GuestTags
            tags={tagsKit?.filter((item) =>
              tags.some((tag) => tag.id === item.id)
            )}
            size="xs"
            showCount={1}
          />
        </div>
        <ReservationTagsCombobox
          side="bottom"
          dataTags={tagsKit?.map((tag) => ({
            id: tag.id,
            name: tag.tag
          }))}
          isLoadingDataTags={isLoadingTagsKit}
          tags={tags}
          setTagsHandler={setTagsHandler}
        />
      </div>
      <div className={css.separator}>
        <div className={css.separator__line} />
        <AllCommentsDrawer
          title="Комментарии к гостю"
          commentsSlot={
            <CommentsList
              placeholder="Введите комментарий гостя"
              comments={comments}
              deleteCommentHandler={deleteCommentHandler}
              sendCommentHandler={sendCommentHandler}
              updateCommentHandler={updateCommentHandler}
            />
          }
        >
          <Button
            variant="transparent"
            size="xxs"
            className={css.separator__button}
          >
            Смотреть все комментарии
          </Button>
        </AllCommentsDrawer>
        <div className={css.separator__line} />
      </div>
      <CommentsList
        placeholder="Введите комментарий гостя"
        comments={comments}
        deleteCommentHandler={deleteCommentHandler}
        sendCommentHandler={sendCommentHandler}
        updateCommentHandler={updateCommentHandler}
      />
    </Card>
  )
}
