import { cx } from 'class-variance-authority'
import { useParams } from 'react-router-dom'

import { Badge, Icon, VaulHeader } from '@/shared/ui'

import css from './ReservationVaulCardHeader.module.css'

type ReservationVaulCardHeaderProps = {
  reservation: TimelineReservation
}

export default function ReservationVaulCardHeader({
  reservation
}: ReservationVaulCardHeaderProps) {
  const { id: reservationId } = useParams()
  const { id, persons_count } = reservation

  return (
    <VaulHeader
      className={cx(css.header, { [css.header__noVaul]: reservationId })}
    >
      <Badge className={css.header__badge} size="xs">
        {persons_count} <Icon name="users" size={12} />
      </Badge>
      <h4 className={css.header__title}>Бронь №{id}</h4>
    </VaulHeader>
  )
}
