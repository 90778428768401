import { getHours } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

import { dayIntervalsHours } from '../model/config'

export const getCurIntervalIndex = (date?: Date): number => {
  const now = date ?? nowWithTimezone()
  const hour = getHours(now)

  const { morning, afternoon, evening, night } = dayIntervalsHours

  if (hour >= morning.start && hour <= morning.end) {
    return 0
  } else if (hour >= afternoon.start && hour <= afternoon.end) {
    return 1
  } else if (hour >= evening.start && hour <= evening.end) {
    return 2
  } else if (hour >= night.start || hour <= night.end) {
    return 3
  } else {
    return 0
  }
}
