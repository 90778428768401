import {
  changeReservationsSearch,
  ReservationSortEnum,
  selectReservationsSearch,
  selectReservationsSortType
} from '@/entities/reservations'
import { Reservation } from '@/features/reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { SearchInput } from '@/shared/ui'

export default function MobileReservationsFooter() {
  const search = useAppSelector(selectReservationsSearch)
  const dispatch = useAppDispatch()
  const sortType = useAppSelector(selectReservationsSortType)

  if (sortType === ReservationSortEnum.requests) {
    return null
  }

  return (
    <SearchInput
      placeholder="поиск"
      variant="primary"
      value={search}
      onChange={(value) => dispatch(changeReservationsSearch(value))}
      isCancelButton
      renderFilters={() => <Reservation.MobileFilters />}
    />
  )
}
