import {
  areIntervalsOverlapping,
  isAfter,
  isBefore,
  setHours,
  subSeconds
} from 'date-fns'
import { nanoid } from 'nanoid'

import { config } from '@/app/config'
import { isBeforeWithoutSec } from '@/shared/lib'

import { computeSpans } from './computeSpans'

const { WORKING_HOURS_START, WORKING_HOURS_END } = config

const populateEmptySlots = (
  table: Table,
  reservations: TimeSlot[],
  hours: Date[]
): TimeSlot[] => {
  const populatedSlots: TimeSlot[] = []

  let reservationPointer = 0
  let newEmptySlot: TimeSlot | null = null
  for (let i = 0; i < hours.length; i++) {
    const curReservation = reservations[reservationPointer]

    // To not push empty slot if reservation is till the closing time
    if (!curReservation && i === hours.length - 1) break

    // If no more reservations, we just fill the remaining space with an empty slot
    if (!curReservation) {
      const [spanStart, spanEnd] = computeSpans(
        hours[i],
        hours[hours.length - 1],
        hours[0]
      )

      populatedSlots.push({
        id: nanoid(),
        start_date: hours[i],
        end_date: hours[hours.length - 1],
        spanStart,
        spanEnd,
        booked: false,
        table
      })
      break
    }

    // While cur hour is before reservation start date we create empty slot and update its end span
    if (hours[i] && isBeforeWithoutSec(hours[i], curReservation.start_date)) {
      if (!newEmptySlot) {
        const [spanStart, spanEnd] = computeSpans(
          hours[i],
          hours[i + 1],
          hours[0]
        )

        newEmptySlot = {
          id: nanoid(),
          start_date: hours[i],
          end_date: hours[i + 1],
          spanStart,
          spanEnd,
          booked: false,
          table
        }
      } else {
        const [spanStart, spanEnd] = computeSpans(
          newEmptySlot.start_date,
          hours[i + 1],
          hours[0]
        )

        newEmptySlot.end_date = hours[i + 1]
        newEmptySlot.spanStart = spanStart
        newEmptySlot.spanEnd = spanEnd
      }
    } else {
      // When we reach the start of reservation on time, we push empty slot and than reservation itself
      if (newEmptySlot) {
        populatedSlots.push(newEmptySlot)
        newEmptySlot = null
      }
      populatedSlots.push(curReservation)
      // Move pointers to skip reservation time (cuz its already pushed)
      i += curReservation.spanEnd - curReservation.spanStart - 1
      reservationPointer += 1
    }
  }

  return populatedSlots
}

export const computeTimeSlots = (
  table: Table,
  hours: Date[],
  date: Date
): TimeSlot[] => {
  const intervalStart = setHours(new Date(date), WORKING_HOURS_START)
  const intervalEnd = subSeconds(setHours(new Date(date), WORKING_HOURS_END), 1)

  const todaysReservations: TimelineRenderReservation[] = table.reservations
    .filter((res) =>
      areIntervalsOverlapping(
        { start: res.start_date, end: res.end_date },
        { start: intervalStart, end: intervalEnd }
      )
    )
    .map((res) => {
      const startDate = isBefore(res.start_date, intervalStart)
        ? intervalStart
        : res.start_date

      const endDate = isAfter(res.end_date, intervalEnd)
        ? intervalEnd
        : res.end_date

      return { ...res, timelineStartDate: startDate, timelineEndDate: endDate }
    })

  const reservations = todaysReservations

  const startHour = hours[0]

  // Convert reservations to time slots
  const timeSlots: TimeSlot[] = []
  for (const reservation of reservations) {
    const [spanStart, spanEnd] = computeSpans(
      reservation.timelineStartDate,
      reservation.timelineEndDate,
      startHour
    )

    const timeSlot: TimeSlot = {
      ...reservation,
      spanStart,
      spanEnd,
      booked: true,
      table
    }

    timeSlots.push(timeSlot)
  }

  // We sort time slots (with only reservations for now) in order
  const sortedTimeSlots = timeSlots.sort((a, b) => a.spanStart - b.spanStart)

  return populateEmptySlots(table, sortedTimeSlots, hours)
}
