import { createSelector } from '@reduxjs/toolkit'

export const selectAllIntervals = (state: RootState) =>
  state.timePicker.allIntervals

export const selectAllIntervalsEndTime = (state: RootState) =>
  state.timePicker.allIntervalsEndTime

export const selectCurDayIntervalIdx = (state: RootState) =>
  state.timePicker.curDayIntervalIdx

export const selectCurDayIntervalIdxEndTime = (state: RootState) =>
  state.timePicker.curDayIntervalIdxEndTime

export const selectCurDayInterval = createSelector(
  (state: RootState) => state.timePicker.allIntervals,
  (state: RootState) => state.timePicker.curDayIntervalIdx,
  (allIntervals, curDayIntervalIdx) => allIntervals[curDayIntervalIdx]
)

export const selectCurDayIntervalEndTime = createSelector(
  (state: RootState) => state.timePicker.allIntervalsEndTime,
  (state: RootState) => state.timePicker.curDayIntervalIdxEndTime,
  (allIntervalsEndTime, curDayIntervalIdxEndTime) =>
    allIntervalsEndTime[curDayIntervalIdxEndTime]
)
