/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ComponentPropsWithoutRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import css from './Tabs.module.css'

const tabsStyle = cva(css.tabs, {
  variants: {
    variant: {
      default: css.tabs_default,
      reverseColor: css.tabs_reverse
    },
    size: {
      small: css.tabs_small,
      medium: css.tabs_medium,
      large: css.tabs_large
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'large'
  }
})

const tabStyle = cva(css.tabs__button, {
  variants: {
    variant: {
      default: css.tabs__button_default,
      reverseColor: css.tabs__button_reverse
    },
    isActive: {
      true: css.tabs__button_active,
      false: ''
    },
    isCount: {
      true: css.tabs__button_count,
      false: ''
    },
    size: {
      small: css.tabs__button_small,
      medium: css.tabs__button_medium,
      large: css.tabs__button_large
    }
  },
  defaultVariants: {
    variant: 'default',
    isActive: false,
    isCount: false,
    size: 'large'
  }
})

const countStyle = cva(css.count, {
  variants: {
    variant: {
      default: css.count_default,
      reverseColor: css.count_reverse
    },
    isActive: {
      true: css.count_active,
      false: ''
    },
    size: {
      small: css.count_small,
      medium: css.count_medium,
      large: css.count_large
    }
  },
  defaultVariants: {
    variant: 'default',
    isActive: false,
    size: 'large'
  }
})

type TabsProps = ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof tabsStyle> & {
    tabs: Array<{
      id: ID
      label: string
      count?: string
    }>
    activeTab: ID
    setActiveTab: (id: any) => void
    showCount?: boolean
  }

/**
 * @deprecated
 * please use Tabs2 and when this component is no longer used - remove it and rename Tabs2 to Tabs
 */
export function Tabs({
  tabs,
  activeTab,
  setActiveTab,
  variant,
  size,
  className,
  showCount = false
}: TabsProps) {
  const selectHandle = (id: ID) => {
    setActiveTab(id)
  }

  return (
    <div className={tabsStyle({ variant, size, className })}>
      {tabs.map(({ id, label, count }) => {
        const isActive = activeTab === id
        const isCount = Boolean(showCount && count)

        return (
          <button
            key={id}
            onClick={() => selectHandle(id)}
            className={tabStyle({
              variant,
              size,
              isActive,
              isCount
            })}
          >
            {label}
            {showCount && count && (
              <span className={countStyle({ isActive, size, variant })}>
                {count}
              </span>
            )}
          </button>
        )
      })}
    </div>
  )
}
