import { useState } from 'react'

import { Reservation } from '@/features/create-update-reservation'
import { CreateGuestForm } from '@/features/guest/create-mobile/ui/CreateGuestForm'
import { formatName, formatPhoneNumberRU } from '@/shared/lib'
import {
  Badge,
  Button,
  Card,
  Icon,
  Tag,
  Vaul,
  VaulBody,
  VaulContent,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'
import { GuestVaulContent } from '@/widgets/guest'

import css from './CallsCard.module.css'

type CallsCardProps = {
  guest: TelephonyGuest | null
  status: CallStatus
  phoneNewGuest: null | string
}

export function CallsCard({ guest, status, phoneNewGuest }: CallsCardProps) {
  const [vaulNewGuestOpen, setVaulNewGuestOpen] = useState(false)

  return (
    <Card className={css.card}>
      <div className={css.card__info}>
        <div className={css.card__info_left}>
          <div className={css.card__guest}>
            <p>
              {guest
                ? formatName(guest.first_name, guest.last_name)
                : 'Новый гость'}
            </p>
            {guest?.tags[0] && (
              <Tag
                className={css.header__badge}
                size="xxs"
                tag={guest.tags[0]}
              />
            )}
            {guest && guest.tags.length > 1 && (
              <Badge size="xxs">+{guest.tags.length - 1}</Badge>
            )}
          </div>
          <p className={css.card__phone}>
            {formatPhoneNumberRU(guest ? guest.phone : phoneNewGuest)}
          </p>
        </div>
        {status === 'calling' && (
          <Badge variant="greenTwo">
            <Icon name="phoneCall" />
            Входящий звонок
          </Badge>
        )}
        {status === 'current' && (
          <Badge variant="yellow">
            <Icon name="phone" />
            Идёт разговор
          </Badge>
        )}
      </div>
      <div className={css.card__buttons}>
        {guest ? (
          <Vaul>
            <VaulTrigger asChild>
              <Button
                variant="tertiary"
                size="sm"
                contentClassName={css.button__content}
              >
                Карточка пользователя
                <Icon name="user" size={20} />
              </Button>
            </VaulTrigger>
            <VaulContent fullScreen>
              <GuestVaulContent guestId={guest.id} />
            </VaulContent>
          </Vaul>
        ) : (
          <Vaul open={vaulNewGuestOpen} onOpenChange={setVaulNewGuestOpen}>
            <VaulTrigger asChild>
              <Button
                variant="tertiary"
                size="sm"
                contentClassName={css.button__content}
              >
                Создать карточку гостя
                <Icon name="plus" size={20} />
              </Button>
            </VaulTrigger>
            <VaulContent fullScreen>
              <VaulHeader>
                <h4 className={css.header}>Новый гость</h4>
              </VaulHeader>
              <VaulBody>
                <CreateGuestForm
                  successHandler={() => setVaulNewGuestOpen(false)}
                  phone={phoneNewGuest}
                  withoutInvalidation
                />
              </VaulBody>
            </VaulContent>
          </Vaul>
        )}
        <Reservation.MobileInit
          variant="primary"
          size="sm"
          contentClassName={css.button__content}
          guest={
            guest !== null
              ? {
                  id: guest.id,
                  last_name: guest.last_name ?? '',
                  first_name: guest.first_name ?? '',
                  tags: guest.tags,
                  phone: guest.phone,
                  comment: guest.comment,
                  is_anonymous: false
                }
              : undefined
          }
        >
          Создать бронь
          <Icon name="plus" size={20} />
        </Reservation.MobileInit>
      </div>
    </Card>
  )
}
