import { type MouseEvent, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { PATHS } from '@/shared/lib'
import {
  Button,
  type ButtonStyleProps,
  Icon,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { MobileRequestConfirmProcess } from '@/widgets/requests'

type SitGuestVaulProps = ButtonStyleProps & {
  id: number
  className?: string
  withLink?: boolean
}

export default function SitGuestVaul({
  id,
  className,
  withLink = false,
  ...props
}: SitGuestVaulProps) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const successHandler = () => {
    setOpen(false)
  }

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  if (withLink) {
    return (
      <Button
        className={className}
        onClick={() => navigate(`${PATHS.requests}/${id}`)}
        {...props}
      >
        <Icon name="restaurant" />
        Выбрать столик
      </Button>
    )
  }

  return (
    <Vaul open={open} onClose={() => setOpen(false)}>
      <VaulTrigger asChild>
        <Button className={className} onClick={toggleDialogHandler} {...props}>
          <Icon name="restaurant" />
          Выбрать столик
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen onClose={toggleDialogHandler}>
        <MobileRequestConfirmProcess
          requestId={id}
          onComplete={successHandler}
        />
      </VaulContent>
    </Vaul>
  )
}
