import { nanoid } from 'nanoid'

export const transformGuestTagsToFilter = (tags: Tag[]): Filter => {
  return {
    id: nanoid(),
    label: 'Теги гостя',
    key: 'guest_tag',
    type: 'multi',
    options: tags.map((tag) => ({ value: String(tag.id), label: tag.tag }))
  }
}
