import { parseISO } from 'date-fns'

export const transformReservationById = (
  data: ResponseReservationById
): Reservation => {
  return {
    ...data,
    start_date: parseISO(data.start_date),
    end_date: parseISO(data.end_date)
  }
}
