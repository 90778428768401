import { Suspense } from 'react'

import { Outlet, ScrollRestoration } from 'react-router-dom'

import { Telephony } from '@/features/telephony'
import { Loading } from '@/shared/ui'
import { SidebarNav } from '@/widgets/navigation'

import css from './DesktopLayout.module.css'

export default function DesktopLayout() {
  return (
    <div className={css.layout}>
      <Telephony.Quick />
      <SidebarNav />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <ScrollRestoration />
    </div>
  )
}
