import { useEffect, useState } from 'react'

const useCSSImageLoading = (imageURL: string | null | undefined) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false)

  useEffect(() => {
    const onLoad = () => {
      setIsImgLoaded(true)
    }
    const imageEl = new Image()

    if (imageURL) {
      imageEl.addEventListener('load', onLoad)
      imageEl.src = imageURL
    }

    return () => {
      imageEl.removeEventListener('load', onLoad)
    }
  }, [imageURL])

  return { isImgLoaded }
}

export default useCSSImageLoading
