import { type MouseEvent } from 'react'

import { Link } from 'react-router-dom'

import { formatPhoneNumberRU } from '@/shared/lib'
import { Button, type ButtonStyleProps, Icon } from '@/shared/ui'

type PhoneProps = ButtonStyleProps & {
  phone: string | null
  className?: string
  showNumber?: boolean
}

export function Phone({
  phone,
  className,
  showNumber = false,
  ...props
}: PhoneProps) {
  const onClick = (e: MouseEvent) => {
    e.stopPropagation()
    window.location.href = `tel:${phone}`
  }

  if (!phone) return null

  return (
    <Button to="#" as={Link} className={className} onClick={onClick} {...props}>
      <Icon name="phoneCall" />
      {showNumber && formatPhoneNumberRU(phone)}
    </Button>
  )
}
