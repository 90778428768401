import { toast } from 'react-toastify'
import { type ErrorEvent } from 'reconnecting-websocket'

import { config } from '@/app/config'
import { clearSessionData } from '@/entities/session'
import {
  baseApi,
  getTz,
  replaceDateTimezones,
  tagTypes,
  ws
} from '@/shared/api'
import { GlobalWsEvents } from '@/shared/lib'

import { RequestsWsEvents } from './requestsWsEvents'

const { FEATURE_FLAG_LOGS } = config

const { RESERVATIONS_TAG } = tagTypes

export const reservationRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    reservationRequest: builder.query<
      ReservationRequest,
      { addressId: number; reservationReqId: number }
    >({
      query: ({ addressId, reservationReqId }) => ({
        url: `/book/addresses/${addressId}/reservation-requests/${reservationReqId}/`
      })
    }),
    deleteReservationRequest: builder.mutation<
      void,
      { addressId: number; reservationReqId: number }
    >({
      query: ({ addressId, reservationReqId }) => ({
        url: `/book/addresses/${addressId}/reservation-requests/${reservationReqId}/`,
        method: 'DELETE'
      })
    }),
    confirmReservationRequest: builder.mutation<
      Reservation,
      ConfirmReservationRequestRequest
    >({
      query: ({ addressId, reservationReqId, body }) => ({
        url: `/book/addresses/${addressId}/reservation-requests/${reservationReqId}/confirm/`,
        method: 'POST',
        body
      }),
      invalidatesTags: [RESERVATIONS_TAG]
    }),
    reservationRequestsWs: builder.query<
      ReservationRequest[][],
      ReservationRequestsWsParams
    >({
      queryFn: async () => ({ data: [] }),
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch,
          getState
        }
      ) {
        await cacheDataLoaded

        const {
          session: { accessToken }
        } = getState() as RootState

        const tz = getTz()

        const socket = ws(`requests/${arg.addressId}/`)

        try {
          const listener = (e: MessageEvent) => {
            const { type, data, detail } = JSON.parse(e.data)

            if (tz) replaceDateTimezones(data, tz)

            switch (type) {
              case RequestsWsEvents.GetRequests: {
                updateCachedData((draft) => {
                  draft.push(data)
                })
                break
              }
              case GlobalWsEvents.Error: {
                if (detail === 'Invalid token') {
                  toast.error('Ошибка авторизации')
                  dispatch(clearSessionData())
                } else {
                  toast.error(detail)
                }
                break
              }
            }
          }

          const openListener = () => {
            console.log('Соединение установлено')
            const authMessage = JSON.stringify({
              type: GlobalWsEvents.Auth,
              data: {
                authorization: accessToken
              }
            })
            socket.send(authMessage)
          }

          const closeListener = () => {
            console.log('Соединение закрыто')
          }

          const errorListener = (error: ErrorEvent) => {
            if (FEATURE_FLAG_LOGS) {
              toast.error(JSON.stringify(error, null, 2))
            }
            console.log(`Ошибка: ${error.message}`)
          }

          socket.addEventListener('open', openListener)
          socket.addEventListener('close', closeListener)
          socket.addEventListener('error', errorListener)
          socket.addEventListener('message', listener)
        } catch {}
        await cacheEntryRemoved
        console.log('cache removed, socket timeline closed')
        socket.close()
      }
    })
  })
})

export const {
  useReservationRequestQuery,
  useConfirmReservationRequestMutation,
  useReservationRequestsWsQuery,
  useDeleteReservationRequestMutation
} = reservationRequestApi
