import { eachHourOfInterval, getDate, getMonth, getYear } from 'date-fns'

import { config } from '@/app/config'
import { splitHours } from '@/shared/lib'

import { dayIntervalsHours } from '../model/config'
const { PICKERS_SPLIT_HOUR_BY } = config

export const generateTimeHours = (date: Date): DayTimeHours[] => {
  const { morning, afternoon, evening, night } = dayIntervalsHours

  const year = getYear(date)
  const month = getMonth(date)
  const day = getDate(date)

  const intervalNight = {
    start: new Date(year, month, day, night.start),
    end: new Date(year, month, day + 1, night.end)
  }

  const hoursNight = splitHours(
    eachHourOfInterval(intervalNight),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalMorning = {
    start: new Date(year, month, day, morning.start),
    end: new Date(year, month, day, morning.end)
  }

  const hoursMorning = splitHours(
    eachHourOfInterval(intervalMorning),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalDay = {
    start: new Date(year, month, day, afternoon.start),
    end: new Date(year, month, day, afternoon.end)
  }

  const hoursDay = splitHours(
    eachHourOfInterval(intervalDay),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const intervalEvening = {
    start: new Date(year, month, day, evening.start),
    end: new Date(year, month, day, evening.end)
  }

  const hoursEvening = splitHours(
    eachHourOfInterval(intervalEvening),
    PICKERS_SPLIT_HOUR_BY
  ).slice(0, -1)

  const splittedByDayTimeHours: DayTimeHours[] = [
    {
      id: 'd1',
      hours: hoursMorning
    },
    {
      id: 'd2',
      hours: hoursDay
    },
    {
      id: 'd3',
      hours: hoursEvening
    },
    {
      id: 'd4',
      hours: hoursNight
    }
  ]

  return splittedByDayTimeHours
}
