import { baseApi } from '@/shared/api'

export const visitsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    guestVisits: build.query<ResponseGuestVisits, GuestVisitsParams>({
      query: ({ guestId, limit, offset }) => ({
        url: `/staff/guests/${guestId}/reservations/`,
        params: {
          limit,
          offset
        }
      })
    })
  })
})

export const { useGuestVisitsQuery } = visitsApi
