import { useParams } from 'react-router-dom'

import { useUpdateReservationMutation } from '@/entities/reservation'
import { ReservationStatusEnum } from '@/entities/reservations'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Button, type ButtonStyleProps, Icon } from '@/shared/ui'

export function ConfirmReservation(props: ButtonStyleProps) {
  const { id: reservationId } = useParams()
  const addressId = useAppSelector(selectSelectedAddressId)

  const [setConfirmed, { isLoading }] = useUpdateReservationMutation()

  const setInProgressHandler = () => {
    if (!addressId || !reservationId || Number.isNaN(+reservationId)) return

    setConfirmed({
      addressId,
      reservationId: +reservationId,
      body: { status: ReservationStatusEnum.confirmed }
    })
  }

  return (
    <Button onClick={setInProgressHandler} isLoading={isLoading} {...props}>
      Подтвердить бронь
      <Icon name="check" size={18} />
    </Button>
  )
}
