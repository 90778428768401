import useBreakpoint from './useBreakpoint'

export enum BreakpointEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

const useIsDevice = () => {
  const breakpoint = useBreakpoint()
  const isMobile =
    breakpoint === BreakpointEnum.xs ||
    breakpoint === BreakpointEnum.sm ||
    breakpoint === BreakpointEnum.md

  const isSmallTabletAndLess =
    breakpoint === BreakpointEnum.xs || breakpoint === BreakpointEnum.sm

  const isPhone = breakpoint === BreakpointEnum.xs
  const isSmallTablet = breakpoint === BreakpointEnum.sm
  const isTablet = breakpoint === BreakpointEnum.lg
  const isDesktop = breakpoint === BreakpointEnum.xl

  return {
    isPhone,
    isMobile,
    isTablet,
    isSmallTablet,
    isSmallTabletAndLess,
    isDesktop
  }
}

export default useIsDevice
