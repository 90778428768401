import { add, differenceInSeconds } from 'date-fns'

import { nowWithTimezone } from './nowWithTimezone'

export const getCountdownSec = (timerStartedAt: Date, timerSeconds: number) => {
  const timerEndsAt = add(timerStartedAt, { seconds: timerSeconds })
  const now = nowWithTimezone()

  const diff = differenceInSeconds(timerEndsAt, now)
  return diff
}
