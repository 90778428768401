export const filters: OldFilter[] = [
  {
    id: 1,
    name: 'type',
    title: 'Тип сообщения',
    description: 'Обязательное поле',
    defaultValue: ['sms'],
    filterValues: [
      { label: 'СМС', value: 'sms' },
      { label: 'WhatsApp', value: 'whatsapp' }
    ],
    selectedDescription: 'Тип - ',
    type: 'mailing',
    strategy: 'single'
  },
  {
    id: 2,
    name: 'all-visits',
    title: 'Всего посещений',
    description: 'Выберите диапазон или точное число',
    defaultValue: [],
    filterValues: [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '4', value: '5' },
      { label: '10', value: '10' },
      { label: '20', value: '20' },
      { label: '50', value: '50' },
      { label: '100+', value: '100' }
    ],
    selectedDescription: 'Статус -',
    type: 'mailing',
    strategy: 'single'
  },
  {
    id: 3,
    name: 'last-visit',
    title: 'Последнее посещение',
    description: null,
    defaultValue: [],
    filterValues: [
      { label: 'Сегодня', value: '0' },
      { label: 'Вчера', value: '1' },
      { label: '7 дней', value: '7' },
      { label: '14 дней', value: '14' },
      { label: '3 месяца', value: '90' },
      { label: 'Полгода', value: '180' },
      { label: 'Год', value: '360' }
    ],
    selectedDescription: 'Последнее посещение - до',
    type: 'mailing',
    strategy: 'single'
  },
  {
    id: 4,
    name: 'guest-status',
    title: 'Статус гостя',
    description: null,
    defaultValue: [],
    filterValues: [
      { label: 'VIP', value: 'vip' },
      { label: 'Premium', value: 'premium' }
    ],
    selectedDescription: 'Статус -',
    type: 'mailing',
    strategy: 'multi'
  }
]
