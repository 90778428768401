import { toast } from 'react-toastify'

import { selectSelectedAddressId } from '@/entities/session'
import {
  createReservationFromWaitingList,
  selectSelectedWLRecord,
  selectWaitingListPlace,
  setWaitingListStep,
  WaitingListSteps
} from '@/entities/waiting-list'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export function SitComplete() {
  const dispatch = useAppDispatch()
  const address = useAppSelector(selectSelectedAddressId)

  const selectedPlace = useAppSelector(selectWaitingListPlace)
  const waitingListRecordId = useAppSelector(selectSelectedWLRecord)

  const sitGuestHandler = () => {
    if (!waitingListRecordId || !address) return
    createReservationFromWaitingList(
      address,
      { id: waitingListRecordId, places: [...selectedPlace] },
      () => {
        toast.success('Бронирование успешно создано!')
        dispatch(setWaitingListStep(WaitingListSteps.one))
      }
    )
  }

  return (
    <Button variant="primary" onClick={sitGuestHandler}>
      Посадить
      <Icon name="restaurant" />
    </Button>
  )
}
