import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Button,
  Checkbox,
  Icon,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import { type TypeFilterButtonEnum } from '../../lib/const'

import css from './FilterCheckboxListVaul.module.css'

type FilterCheckboxListVaulProps = {
  headerName: string
  options?: Array<{ label: string; value: string }>
  type?: string
  activeFilters?: FiltersDto
  setActiveTypeButton: (value: TypeFilterButtonEnum | null) => void
  setActiveFilters: Dispatch<SetStateAction<FiltersDto | undefined>>
}

export function FilterCheckboxListVaul({
  headerName,
  options,
  type,
  activeFilters,
  setActiveTypeButton,
  setActiveFilters
}: FilterCheckboxListVaulProps) {
  const [activeCheckboxes, setActiveCheckboxes] = useState<
    FiltersDto | undefined
  >(activeFilters)

  const onChecked = (value: string) => {
    setActiveCheckboxes((prev) => {
      const prevCopy = prev
        ? (JSON.parse(JSON.stringify(prev)) as FiltersDto)
        : undefined
      if (type) {
        const values = prevCopy?.[type]

        if (values?.includes(value)) {
          const newValues = values?.filter((item) => item !== value)

          if (newValues?.length) {
            return {
              ...prevCopy,
              [type]: newValues
            }
          }
          return undefined
        }
        return {
          ...prevCopy,
          [type]: values?.push(value) ? values : [value]
        }
      }
    })
  }

  const onImprove = () => {
    if (!type) return

    setActiveFilters((prev) => {
      if (prev) {
        let newValues: FiltersDto
        if (activeCheckboxes) {
          newValues = { ...prev, [type]: activeCheckboxes[type] }
        } else {
          const { [type]: _, ...rest } = prev
          newValues = rest
        }

        if (Object.keys(newValues).length === 0) {
          return undefined
        }

        return newValues
      } else {
        return activeCheckboxes
      }
    })
    setActiveTypeButton(null)
  }

  return (
    <>
      <VaulHeader>
        <Button
          size="icon"
          className={css.button_back}
          onClick={() => setActiveTypeButton(null)}
        >
          <Icon name="arrowLeft" />
        </Button>
        <h4 className={css.header}>{headerName}</h4>
      </VaulHeader>
      <VaulBody className={css.body}>
        {options?.map((option) => (
          <div key={option.value} className={css.checkbox__wrapper}>
            <Checkbox
              variant="primary"
              id={option.value}
              onCheckedChange={() => onChecked(option.value)}
              checked={
                type ? activeCheckboxes?.[type]?.includes(option.value) : false
              }
            />
            <label htmlFor={option.value} className={css.checkbox__label}>
              {option.label}
            </label>
          </div>
        ))}
      </VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button_submit}
          variant="primary"
          size="lg"
          onClick={onImprove}
        >
          Применить
          <Icon name="check" />
        </Button>
      </VaulFooter>
    </>
  )
}
