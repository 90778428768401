import { differenceInHours, differenceInMinutes } from 'date-fns'

export const dateFormat = (date: string | Date) => {
  if (typeof date === 'object') return date
  return new Date(date.replace(' ', 'T'))
}

export const getTimeBetween = (
  start_date: string | Date,
  end_date: string | Date
) => {
  const hourDiff = differenceInHours(new Date(end_date), new Date(start_date))
  const minDiff = differenceInMinutes(new Date(end_date), new Date(start_date))

  return `${hourDiff}:${`0${minDiff % 60}`.slice(-2)}`
}
