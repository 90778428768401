import dayjs from 'dayjs'

const templateEndDate = dayjs().toDate()

const monthStartDate = dayjs().subtract(1, 'month').toDate()

const thisMonthStartDate = dayjs().startOf('month').toDate()

const weekStartDate = dayjs().subtract(6, 'day').toDate()

const quarterStartDate = dayjs().subtract(3, 'month').toDate()

const yearStartDate = dayjs().subtract(1, 'year').toDate()

const allTimeStartDate = dayjs(new Date(2022, 1, 1)).toDate()

export const TemplateDates = {
  templateEndDate,
  monthStartDate,
  thisMonthStartDate,
  weekStartDate,
  quarterStartDate,
  yearStartDate,
  allTimeStartDate
}

export const getTemplateLabel = (
  start_date: Date | null,
  end_date: Date | null
) => {
  const checkInterval = (startInterval: Date) =>
    start_date &&
    end_date &&
    dayjs(start_date).isSame(startInterval, 'day') &&
    dayjs(end_date).isSame(templateEndDate, 'day')

  if (checkInterval(monthStartDate)) return 'Последний месяц'
  if (checkInterval(thisMonthStartDate)) return 'Этот месяц'
  if (checkInterval(weekStartDate)) return 'Последняя неделя'
  if (checkInterval(quarterStartDate)) return 'Последний квартал'
  if (checkInterval(yearStartDate)) return 'Последний год'
  if (checkInterval(allTimeStartDate)) return 'Все время'

  const startDate = start_date ? dayjs(start_date).format('DD.MM.YYYY') : '*'
  const endDate = end_date ? dayjs(end_date).format('DD.MM.YYYY') : '*'
  return `${startDate} - ${endDate}`
}
