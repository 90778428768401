import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { getAvailableDuration } from '../lib'

type TakeTableSliceState = {
  step: TakeTableStep
  minPersonsCount?: number
  maxPersonsCount?: number
  maxDuration?: number
}

export enum TakeTableSteps {
  one = 1,
  two = 2,
  three = 3,
  four = 4
}

const initialState: TakeTableSliceState = {
  step: 1
}

export const takeTableSlice = createSlice({
  name: 'takeTable',
  initialState,
  reducers: {
    setTakeTableStep: (state, { payload }: PayloadAction<TakeTableStep>) => {
      state.step = payload
    },
    setAvailablePersonsCount: (
      state,
      {
        payload
      }: PayloadAction<{
        min: number
        max: number
      }>
    ) => {
      state.minPersonsCount = payload.min
      state.maxPersonsCount = payload.max
    },
    setAvailableDuration: (
      state,
      {
        payload
      }: PayloadAction<{
        timeSlot: TimeSlot[]
        curTime: Date
      }>
    ) => {
      state.maxDuration = getAvailableDuration(
        payload.timeSlot,
        payload.curTime
      )
    },
    resetAvailableParams: (state) => {
      state.minPersonsCount = undefined
      state.maxPersonsCount = undefined
      state.maxDuration = undefined
    },
    resetTakeTable: () => initialState
  }
})

export const {
  setTakeTableStep,
  setAvailablePersonsCount,
  setAvailableDuration,
  resetAvailableParams,
  resetTakeTable
} = takeTableSlice.actions
