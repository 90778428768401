import {
  ReservationByIdPageSteps,
  setReservationByIdPageStep
} from '@/entities/reservation'
import { selectCanEditAddress } from '@/entities/session'
import { useIsDevice } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export function EditReservation() {
  const dispatch = useAppDispatch()
  const canEditAddress = useAppSelector(selectCanEditAddress)
  const { isDesktop } = useIsDevice()

  const editReservationHandler = () => {
    dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.EDIT))
  }

  return (
    <Button
      variant="tertiary"
      onClick={editReservationHandler}
      disabled={!canEditAddress}
    >
      {isDesktop && 'Изменить'}
      <Icon name="pencil" size={20} />
    </Button>
  )
}
