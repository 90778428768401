export enum WaitingListEvents {
  Auth = 'authenticate',
  GetList = 'get_waiting_list',
  ResponseList = 'waiting_list',
  CreateRecord = 'add_to_waiting_list',
  UpdateRecord = 'update_waiting_list',
  DeleteRecord = 'delete_from_waiting_list',
  CreateReservation = 'create_reservation',
  Error = 'error'
}
