import { parseISO } from 'date-fns'

export const transformAllReservations = (
  data: ResponseReservationsPage
): ResponseReservations => {
  return {
    ...data,
    results: data.results.map((reservation) => ({
      ...reservation,
      start_date: parseISO(reservation.start_date),
      end_date: parseISO(reservation.end_date)
    }))
  }
}
