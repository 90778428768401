import { useLayoutEffect, useState } from 'react'

type WindowSizeState = {
  width: number | undefined
  height: number | undefined
}

const breakpoints = {
  599: 'xs',
  767: 'sm',
  960: 'md',
  1280: 'lg',
  1920: 'xl'
}

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState('')
  const [windowSize, setWindowSize] = useState<WindowSizeState>({
    width: undefined,
    height: undefined
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (windowSize.width && windowSize.width > 0 && windowSize.width < 600) {
      setBreakPoint(breakpoints[599])
    }
    if (windowSize.width && windowSize.width >= 600 && windowSize.width < 767) {
      setBreakPoint(breakpoints[767])
    }
    if (
      windowSize.width &&
      windowSize.width >= 767 &&
      windowSize.width <= 960
    ) {
      setBreakPoint(breakpoints[960])
    }
    if (
      windowSize.width &&
      windowSize.width > 960 &&
      windowSize.width <= 1280
    ) {
      setBreakPoint(breakpoints[1280])
    }
    if (windowSize.width && windowSize.width > 1280) {
      setBreakPoint(breakpoints[1920])
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])
  return breakpoint
}

export default useBreakpoint
