import { useState } from 'react'

import { cx } from 'class-variance-authority'
import { format } from 'date-fns'

import { config } from '@/app/config'
import {
  selectReservationDuration,
  setReservationEnd
} from '@/entities/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Card,
  Icon,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileDurationPicker.module.css'

const { MIN_RESERVATION_TIME_MIN, PICKERS_INTERVAL } = config

const MAX_MINUTES = 4 * 60

export default function MobileDurationPicker() {
  const dispatch = useAppDispatch()
  const selectedDuration = useAppSelector(selectReservationDuration)
  const [selectedDurationInVaul, setSelectedDurationInVaul] = useState<
    number | null | undefined
  >(selectedDuration)
  const [open, setOpen] = useState<boolean>(false)
  const quickPanelMinutes = Array.from({ length: 4 }).map(
    (_, i) => (i + 1) * 60
  )
  const fullPanelMinutes = Array.from({
    length: MAX_MINUTES / PICKERS_INTERVAL - 2
  }).reduce<number[]>(
    (acc, _, i) => {
      acc.push(acc[i] + PICKERS_INTERVAL)

      return acc
    },
    [MIN_RESERVATION_TIME_MIN]
  )

  const pickDurationHandler = (duration?: number | null) => {
    dispatch(setReservationEnd(duration))
    setOpen(false)
  }

  const onOpenChangeHandler = (open: boolean) => {
    setOpen(open)
    setSelectedDurationInVaul(selectedDuration)
  }

  return (
    <div className={css.quick}>
      <div className={css.quick__scroll}>
        <Vaul open={open} onOpenChange={onOpenChangeHandler}>
          <VaulTrigger asChild>
            <Button
              variant="tertiary"
              className={cx(css.button, css.button__vaul)}
            >
              Указать
              <Icon name="pencil" size={20} />
            </Button>
          </VaulTrigger>
          <VaulContent fullScreen>
            <VaulHeader>
              <span className={css.vaul__title}>Укажите длительность</span>
            </VaulHeader>
            <VaulBody className={css.vaul__body}>
              <Card className={css.durations}>
                {fullPanelMinutes.map((minute) => (
                  <Button
                    variant="tertiary"
                    className={cx(css.button, css.button__full, {
                      [css.button_active]: minute === selectedDurationInVaul,
                      [css['button_first-row']]: minute < 60,
                      [css['button_last-row']]: minute >= MAX_MINUTES
                    })}
                    key={minute}
                    onClick={() => setSelectedDurationInVaul(minute)}
                  >
                    {format(new Date(0, 0, 0, 0, minute), 'H:mm')}
                  </Button>
                ))}
                <Button
                  variant="tertiary"
                  className={cx(
                    css.button,
                    css.button__full,
                    css['button_last-row'],
                    {
                      [css.button_active]: selectedDurationInVaul === null
                    }
                  )}
                  onClick={() => setSelectedDurationInVaul(null)}
                >
                  По ситуации
                </Button>
              </Card>
            </VaulBody>
            <VaulFooter>
              <Button
                variant="primary"
                size="lg"
                className={css.button__submit}
                disabled={selectedDurationInVaul === undefined}
                onClick={() => pickDurationHandler(selectedDurationInVaul)}
              >
                Выбрать
              </Button>
            </VaulFooter>
          </VaulContent>
        </Vaul>
        {quickPanelMinutes.map((minute) => (
          <Button
            variant="tertiary"
            className={cx(css.button, css.button__quick, {
              [css.button_active]: minute === selectedDuration
            })}
            key={minute}
            onClick={() => pickDurationHandler(minute)}
          >
            {minute / 60}ч
          </Button>
        ))}
      </div>
    </div>
  )
}
