import { AccessLevel } from '../model/accessTypes'

export const transformProfileAddresses = (
  profile: ProfileData
): ProfileData => {
  return {
    ...profile,
    addresses: profile.addresses.filter((address) => {
      const adminAccess = address.accesses.find(
        (access) => access.type === 'hostess_panel'
      )

      if (!adminAccess) return false

      return adminAccess.access_level !== AccessLevel.no_access
    })
  }
}
