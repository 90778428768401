import { Skeleton } from '@/shared/ui'

import css from './ReservationsList.module.css'

export default function ReservationListSkeleton({
  height
}: {
  height: number
}) {
  return (
    <div className={css.skeleton}>
      <Skeleton className={css.skeleton__card} style={{ height }} />
      <Skeleton className={css.skeleton__card} style={{ height }} />
    </div>
  )
}
