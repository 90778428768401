import { useLayoutEffect, useState } from 'react'

import { isTMA } from '@tma.js/sdk-react'
import { isMobileOnly } from 'react-device-detect'
import { RouterProvider } from 'react-router-dom'

import { Loading } from '@/shared/ui'

import { desktopRouter, mobileRouter } from '../appRouter'

export function CustomRouterProvider() {
  const [isTMAResponse, setIsTMAResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const getIsTMA = async () => {
    const res = await isTMA()
    setIsTMAResponse(res)
    setIsLoading(false)
  }

  useLayoutEffect(() => {
    getIsTMA()
  }, [])

  if (isLoading) {
    return <Loading fullscreen />
  }

  return (
    <RouterProvider
      router={isMobileOnly || isTMAResponse ? mobileRouter : desktopRouter}
    />
  )
}
