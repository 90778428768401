import { LogoutButton } from '@/features/authorization/Logout'

import css from './index.module.css'

type NoAccountDataPageProps = {
  title: string
}

export default function NoAccountDataPage({ title }: NoAccountDataPageProps) {
  return (
    <div className={css.root}>
      <h2>{title}</h2>
      <LogoutButton />
    </div>
  )
}
