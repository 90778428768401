import {
  ReservationByIdPageSteps,
  setReservationByIdPageStep
} from '@/entities/reservation'
import { useIsDevice } from '@/shared/hooks'
import { useAppDispatch } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export function HistoryReservation() {
  const dispatch = useAppDispatch()
  const { isDesktop } = useIsDevice()

  const editReservationHandler = () => {
    dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.HISTORY))
  }

  return (
    <Button variant="tertiary" onClick={editReservationHandler}>
      {isDesktop && 'История изменений'}
      <Icon name="bookOpen" size={20} />
    </Button>
  )
}
