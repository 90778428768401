import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './MobileCommentActions.module.css'

type MobileCommentActionsProps = {
  comment: UserComment
  setCommentUpdate: Dispatch<SetStateAction<UserComment | null>>
  deleteCommentHandle: (id: number) => Promise<void>
  isDeleteLoading?: boolean
}

export function MobileCommentActions({
  comment,
  setCommentUpdate,
  deleteCommentHandle,
  isDeleteLoading
}: MobileCommentActionsProps) {
  const [open, setOpen] = useState<boolean>(false)

  const updateCommentHandle = () => {
    setCommentUpdate(comment)
    setOpen(false)
  }

  const deleteComment = async () => {
    try {
      await deleteCommentHandle(comment.id)
    } catch {
      // do nothing
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger asChild>
        <Button variant="transparent" className={css.button}>
          <Icon name="dotsHorizontal" />
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={8} side="left" variant="glass">
        <Button
          className={css.button__popover}
          contentClassName={css.button__popover__content}
          variant="transparent"
          onClick={deleteComment}
          isLoading={isDeleteLoading}
        >
          Удалить
          <Icon name="trash" />
        </Button>
        <Button
          className={css.button__popover}
          contentClassName={css.button__popover__content}
          variant="transparent"
          onClick={updateCommentHandle}
        >
          Редактировать
          <Icon name="pencil" />
        </Button>
      </PopoverContent>
    </Popover>
  )
}
