import { cx } from 'class-variance-authority'

import css from './LoadingSpinner.module.css'

type LoadingSpinnerProps = {
  variant?: 'default' | 'white' | 'black'
}

export default function LoadingSpinner({
  variant = 'default'
}: LoadingSpinnerProps) {
  return (
    <div className={cx(css.spinner, css[`spinner_${variant}`])}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke={variant === 'default' ? '#868686' : variant}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" fill="none" />
      </svg>
    </div>
  )
}
