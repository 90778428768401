import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Button,
  Checkbox,
  Icon,
  Skeleton,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import css from './TagsCheckboxesVaul.module.css'

type TagsCheckboxesVaulProps = {
  tagsState: SimpleTag[]
  tagsKit?: SimpleTag[]
  isLoadingTagsKit?: boolean
  isErrorTagsKit?: boolean
  isLoadingSetTags?: boolean
  setIsNewTagPage: Dispatch<SetStateAction<boolean>>
  setOpen: Dispatch<SetStateAction<boolean>>
  setTagsState: Dispatch<SetStateAction<SimpleTag[]>>
  setTagsHandle: (tags: SimpleTag[]) => Promise<void>
  createTagKitHandle?: (name: string) => Promise<void>
}

export function TagsCheckboxesVaul({
  tagsState,
  tagsKit,
  isLoadingTagsKit = false,
  isErrorTagsKit = false,
  isLoadingSetTags = false,
  setIsNewTagPage,
  setOpen,
  setTagsState,
  setTagsHandle,
  createTagKitHandle
}: TagsCheckboxesVaulProps) {
  const [checkedTags, setCheckedTags] = useState<SimpleTag[]>(tagsState)

  const onChecked = (tag: SimpleTag) => {
    setCheckedTags((prev) => {
      const prevCopy = JSON.parse(JSON.stringify(prev)) as SimpleTag[]

      if (prevCopy.some(({ id }) => id === tag.id)) {
        return prevCopy.filter(({ id }) => id !== tag.id)
      }
      prevCopy.push(tag)

      return prevCopy
    })
  }

  const onImprove = async () => {
    try {
      await setTagsHandle(checkedTags)
      setTagsState(checkedTags)
      setOpen(false)
    } catch {
      setTagsState(tagsState)
    }
  }

  return (
    <>
      <VaulHeader>
        <h4 className={css.header}>Теги брони</h4>
      </VaulHeader>
      <VaulBody className={css.body}>
        {isLoadingTagsKit || isErrorTagsKit || !tagsKit
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} className={css.skeleton} />
            ))
          : tagsKit.map((tag) => (
              <div key={tag.id} className={css.checkbox__wrapper}>
                <Checkbox
                  id={String(tag.id)}
                  checked={checkedTags.some(({ id }) => id === tag.id)}
                  onCheckedChange={() => onChecked(tag)}
                  variant="primary"
                />
                <label htmlFor={String(tag.id)} className={css.checkbox__label}>
                  {tag.name}
                </label>
              </div>
            ))}
        {createTagKitHandle && (
          <Button
            className={css.button_new}
            contentClassName={css.button_new__content}
            variant="transparent"
            onClick={() => setIsNewTagPage(true)}
          >
            <Icon name="plus" />
            Новый тег
          </Button>
        )}
      </VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button_save}
          variant="primary"
          size="lg"
          isLoading={isLoadingSetTags}
          onClick={onImprove}
        >
          Сохранить
          <Icon name="check" />
        </Button>
      </VaulFooter>
    </>
  )
}
