import { sendToWebSocket } from '@/shared/api'

import { WAITING_LIST_WS_PATH } from '../config'
import { waitingListEventHandlers } from '../waitingListApi'
import { WaitingListEvents } from '../waitingListEvents'

export const deleteWaitingListRecord = (
  address: number,
  body: DeleteFromWaitingListDto,
  cb?: () => void
) => {
  if (cb) waitingListEventHandlers.set(WaitingListEvents.DeleteRecord, cb)

  sendToWebSocket(
    address,
    JSON.stringify({
      type: WaitingListEvents.DeleteRecord,
      data: body
    }),
    WAITING_LIST_WS_PATH
  )
}
