/* eslint-disable no-case-declarations */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { eachHourOfInterval, startOfToday } from 'date-fns'

import { config } from '@/app/config'
import { computeTimeSlots } from '@/features/timeline'
import { getTz } from '@/shared/api'
import { nowWithTimezone, splitHours } from '@/shared/lib'

import { getPixelsFromIntervalStart } from '../lib'
import { TimelineOrderingEnum } from '../lib/const'
const { SPLIT_HOUR_BY, WORKING_HOURS_START, WORKING_HOURS_END } = config

const tz = getTz()

const curDate = tz ? nowWithTimezone() : startOfToday()
const startDate = tz ? nowWithTimezone() : startOfToday()
const endDate = tz ? nowWithTimezone() : startOfToday()

startDate.setHours(WORKING_HOURS_START)
endDate.setHours(WORKING_HOURS_END - 1) // -1 because interval splits each hours to 15 min chunks and for example last hour 23 will be splitted to 23:00, 23:15, 23:30, 23:45

const TEST_INTERVAL = {
  start: startDate,
  end: endDate
}

type TimelineSliceState = {
  sortType: TimelineOrdering
  search: string
  curDate: Date
  interval: {
    start: Date
    end: Date
  }
  allTables: Table[]
  tables: Table[]
  selectedTable: number | null
  hours: Date[]
  minutesLinePosition: number // pixels from interval start
  tablesTimeSlots: TableTimeListSlots[]
  selectedTableTimeSlots: TimeSlot[]
  tableWithOpenTimeline?: MapItem
}

const hours = splitHours(eachHourOfInterval(TEST_INTERVAL), SPLIT_HOUR_BY)

const initialState: TimelineSliceState = {
  sortType: {
    date: TimelineOrderingEnum.StartDateDefault,
    tables: TimelineOrderingEnum.TablesDefault,
    booked: TimelineOrderingEnum.BookedDESC
  },
  search: '',
  curDate,
  interval: TEST_INTERVAL,
  allTables: [],
  tables: [],
  selectedTable: null,
  hours,
  minutesLinePosition: getPixelsFromIntervalStart(hours[0]),
  tablesTimeSlots: [],
  selectedTableTimeSlots: []
}

export const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setTimelineTables: (state, { payload }: PayloadAction<Table[]>) => {
      state.allTables = payload
      state.tables = payload
    },
    setTablesTimeSlots: (
      state,
      { payload }: PayloadAction<TableTimeListSlots[]>
    ) => {
      state.tablesTimeSlots = payload
    },
    setCurTimelineDate: (state, { payload }: PayloadAction<Date>) => {
      state.curDate = payload
      const startDate = new Date(payload)
      const endDate = new Date(payload)

      startDate.setHours(WORKING_HOURS_START)
      endDate.setHours(WORKING_HOURS_END - 1)

      const interval = {
        start: startDate,
        end: endDate
      }
      state.interval = interval
      state.hours = splitHours(eachHourOfInterval(interval), SPLIT_HOUR_BY)
    },
    setMinutesLinePosition: (state) => {
      state.minutesLinePosition = getPixelsFromIntervalStart(state.hours[0])
    },
    selectTimelineTable: (state, { payload }: PayloadAction<number>) => {
      state.selectedTable = payload

      const selectedTable = state.allTables.find(
        (table: Table) => table.id === payload
      )

      if (selectedTable) {
        state.tables = [selectedTable]

        state.selectedTableTimeSlots = computeTimeSlots(
          selectedTable,
          state.hours,
          state.curDate
        )
        state.tablesTimeSlots = [selectedTable].map((table) => ({
          id: table.id,
          timeSlots: computeTimeSlots(table, state.hours, state.curDate)
        }))
      } else {
        state.tables = []
      }
    },
    setSortType: (state, { payload }: PayloadAction<TimelineOrdering>) => {
      state.sortType = payload
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.search = payload
    },
    unselectTimelineTable: (state) => {
      state.selectedTable = null
      state.selectedTableTimeSlots = []
      state.tables = state.allTables ?? []
    },
    setTableWithOpenTimeline: (
      state,
      { payload }: PayloadAction<MapItem | undefined>
    ) => {
      state.tableWithOpenTimeline = payload
    },
    resetTimeline: () => initialState
  }
})

export const {
  setMinutesLinePosition,
  selectTimelineTable,
  unselectTimelineTable,
  setSortType,
  setSearch,
  setCurTimelineDate,
  setTimelineTables,
  setTablesTimeSlots,
  setTableWithOpenTimeline,
  resetTimeline
} = timelineSlice.actions
