import { endOfDay, startOfDay } from 'date-fns'

import { selectCurDate, setCurTimelineDate } from '@/entities/timeline'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { DatePickerPopover } from '@/shared/ui'
import { MapHalls } from '@/widgets/map'

import TimelineSort from './TimelineSort/TimelineSort'

export function TimelineFilters() {
  const dispatch = useAppDispatch()
  const curDate = useAppSelector(selectCurDate)

  const setCurDateHandler = (date: Date | undefined) => {
    if (!date) return
    dispatch(setCurTimelineDate(date))
  }

  return (
    <>
      <MapHalls
        variant="reverseColor"
        size="medium"
        start={startOfDay(curDate)}
        end={endOfDay(curDate)}
      />
      <TimelineSort />
      <DatePickerPopover
        selected={curDate}
        onSelectHandler={setCurDateHandler}
        withoutButtons
      />
    </>
  )
}
