import { type ComponentPropsWithoutRef, memo, useMemo, useState } from 'react'

import { cva, cx } from 'class-variance-authority'

import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import { getSideStatusPopover } from '../../lib'
import { type TableTime } from '../../model/slice'
import { TableStatusEnum } from '../../model/tableStatus'

import css from './MapStatusPopover.module.css'

const statusStyle = cva(css.status, {
  variants: {
    isEmpty: {
      true: css.status_empty,
      false: ''
    },
    isComingSoon: {
      true: css.status_soon,
      false: ''
    },
    isProgress: {
      true: css.status_progress,
      false: ''
    }
  }
})

const statusTimeStyle = cva(css.status__time, {
  variants: {
    isEmpty: {
      true: css.status__time_empty,
      false: ''
    },
    isComingSoon: {
      true: css.status__time_soon,
      false: ''
    },
    isProgress: {
      true: css.status__time_progress,
      false: ''
    }
  }
})

type MapStatusPopoverProps = ComponentPropsWithoutRef<'div'> & {
  table: ConfItem
  isNoInfo: boolean
  tablesElements: HTMLDivElement[]
  tableTime?: TableTime
}

export const MapStatusPopover = memo(
  ({
    table,
    isNoInfo,
    tablesElements,
    tableTime,
    className,
    children,
    ...props
  }: MapStatusPopoverProps) => {
    const { width, height, direction, map_item } = table
    const { id, type } = map_item
    const { status, time, allTime } = tableTime ?? {}
    const [statusElement, setStatusElement] = useState<HTMLDivElement | null>(
      null
    )

    const isEmpty = !status
    const isComingSoon = status === TableStatusEnum.comingSoon
    const isProgress =
      status === TableStatusEnum.inProgress ||
      status === TableStatusEnum.inProgressEnds

    const sideStatusPopover = useMemo(
      () => getSideStatusPopover(tablesElements, statusElement, id),
      [tablesElements, statusElement, id]
    )

    const colorArrow = useMemo(() => {
      switch (true) {
        case isProgress:
          return {
            fill: 'var(--white-1)',
            stroke: 'var(--gray-4)'
          }
        case isComingSoon:
          return {
            fill: 'var(--yellow-1)',
            stroke: 'var(--black-1)'
          }
        case isEmpty:
          return {
            fill: 'var(--gray-3)',
            stroke: 'var(--white-1)'
          }
        default:
          return {
            fill: 'var(--gray-3)',
            stroke: 'var(--white-1)'
          }
      }
    }, [isComingSoon, isEmpty, isProgress])

    const sideOffset = useMemo(() => {
      const radian = (Math.abs(direction % 90) / 180) * Math.PI
      return Math.ceil(
        (direction / 90) % 1 === 0 ? (direction + 1) / 90 : direction / 90
      ) %
        2 ===
        0
        ? -(height * Math.sin(radian) + width * Math.cos(radian)) / 2 + 20
        : -(width * Math.sin(radian) + height * Math.cos(radian)) / 2 + 20
    }, [direction, height, width])

    return (
      <Popover open={type === 'place' && !!time} modal={false}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent
          avoidCollisions={false}
          withoutPortal
          sideOffset={sideOffset}
          variant="transparent"
          side={sideStatusPopover}
          className={cx(css.popover, {
            [css.popover_active]: !isNoInfo
          })}
          {...props}
        >
          <div
            ref={(ref) => setStatusElement(ref)}
            className={statusStyle({
              isProgress,
              isEmpty,
              isComingSoon,
              className
            })}
          >
            <div
              className={statusTimeStyle({
                isProgress,
                isEmpty,
                isComingSoon
              })}
            >
              {isProgress ? `${time} / ${allTime}` : time}
            </div>
            <PopoverArrow
              className={css.status__arrow}
              fill={colorArrow.fill}
              stroke={colorArrow.stroke}
            />
          </div>
        </PopoverContent>
      </Popover>
    )
  }
)
