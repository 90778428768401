import { type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { Button, Icon } from '@/shared/ui'

import css from './SortButton.module.css'

type SortButtonProps = PropsWithChildren & {
  sort: string
  setSort: () => void
  upContent: string
  downContent: string
}

export function SortButton({
  sort,
  setSort,
  upContent,
  downContent,
  children
}: SortButtonProps) {
  const getIconName = () => {
    switch (true) {
      case sort.startsWith('default'):
        return 'minus'
      case sort.startsWith('-'):
        return 'arrowUp'
      default:
        return 'arrowUp'
    }
  }

  return (
    <Button size="lg" contentClassName={css.button__content} onClick={setSort}>
      {children}
      <div
        className={cx(css.button__info, {
          [css.button__info_active]: !sort.startsWith('default')
        })}
      >
        {!sort.startsWith('default') && !sort.startsWith('-') && (
          <span>{downContent}</span>
        )}
        {sort.startsWith('-') && <span>{upContent}</span>}
        <Icon
          name={getIconName()}
          className={cx(css.button__icon, {
            [css['button__icon_arrow-down']]:
              !sort.startsWith('default') && !sort.startsWith('-')
          })}
        />
      </div>
    </Button>
  )
}
