import * as React from 'react'

import * as PopoverUI from '@radix-ui/react-popover'
import { cva, type VariantProps } from 'class-variance-authority'

import css from './Popover.module.css'

const Popover = PopoverUI.Root

const PopoverTrigger = PopoverUI.Trigger

const popoverStyles = cva(css.popover, {
  variants: {
    variant: {
      default: css.popover_default,
      glass: css.popover_glass,
      transparent: css.popover_transparent
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

const PopoverArrow = React.forwardRef<
  React.ElementRef<typeof PopoverUI.Arrow>,
  React.ComponentPropsWithoutRef<typeof PopoverUI.Arrow>
>(({ ...props }, ref) => {
  return (
    <PopoverUI.Arrow
      height={9}
      width={12}
      strokeWidth={3}
      strokeDasharray="0 30 35"
      ref={ref}
      {...props}
    />
  )
})
PopoverArrow.displayName = PopoverUI.Arrow.displayName

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverUI.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverUI.Content> &
    VariantProps<typeof popoverStyles> & { withoutPortal?: boolean }
>(
  (
    {
      className,
      align = 'center',
      sideOffset = 4,
      variant,
      withoutPortal = false,
      ...props
    },
    ref
  ) => {
    const content = (
      <PopoverUI.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={popoverStyles({ variant, className })}
        {...props}
      />
    )

    if (withoutPortal) {
      return content
    }

    return <PopoverUI.Portal>{content}</PopoverUI.Portal>
  }
)
PopoverContent.displayName = PopoverUI.Content.displayName

export { Popover, PopoverTrigger, PopoverContent, PopoverArrow }
