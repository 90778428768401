import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Label } from '@radix-ui/react-label'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Icon,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './NewReservationTagPopover.module.css'

const formSchema = z.object({
  name: z.string().min(1, { message: 'Введите название' })
})

type NewReservationTagPopoverProps = {
  createTagHandler: (name: string) => Promise<void>
  isCreateTagLoading?: boolean
}

export default function NewReservationTagPopover({
  createTagHandler,
  isCreateTagLoading
}: NewReservationTagPopoverProps) {
  const [open, setOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: ''
    }
  })

  const submitHandler = async (values: z.infer<typeof formSchema>) => {
    try {
      await createTagHandler(values.name)
      setOpen(false)
      form.reset()
    } catch {
      // do nothing
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          variant="transparent"
          className={css.trigger}
          contentClassName={css.trigger__content}
        >
          Новый тег
          <Icon name="plus" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={css.popover}
        variant="glass"
        side="top"
        align="end"
        alignOffset={-8}
        sideOffset={16}
      >
        <Form {...form}>
          <form
            className={css.popover__form}
            onSubmit={form.handleSubmit(submitHandler)}
            noValidate
          >
            <Label className={css.popover__label}>Новый тег</Label>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className={css.popover__item}>
                      <Input
                        type="text"
                        placeholder="Введите название"
                        variant="light"
                        className={css.popover__input}
                        {...field}
                      />
                      <Button
                        size="icon"
                        type="submit"
                        variant="white"
                        className={css.popover__button}
                        isLoading={isCreateTagLoading}
                      >
                        <Icon name="plus" />
                      </Button>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </PopoverContent>
    </Popover>
  )
}
