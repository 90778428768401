import { toast } from 'react-toastify'
import { type ErrorEvent } from 'reconnecting-websocket'

import { config } from '@/app/config'
import { clearSessionData } from '@/entities/session'
import { baseApi, MAP_TAG, ws } from '@/shared/api'
import { GlobalWsEvents } from '@/shared/lib'

import { MapWsEvents } from './mapWsEvents'

const { FEATURE_FLAG_LOGS } = config

export const mapReservationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    mapTables: builder.query<ConfItem[], MapTablesParams>({
      query: ({ hallId, start_date, end_date, persons }) => ({
        url: `/book/halls/${hallId}/map/`,
        params: {
          start_date,
          end_date,
          persons
        }
      }),
      providesTags: [MAP_TAG]
    }),
    mapTablesWs: builder.query<ConfItem[][], MapTablesWsParams>({
      queryFn: async () => ({ data: [] }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        await cacheDataLoaded

        const socket = ws(`map/${arg.hallId}/`)

        try {
          const listener = (e: MessageEvent) => {
            const { type, data, detail } = JSON.parse(e.data)

            switch (type) {
              case MapWsEvents.GetCurrentMap: {
                updateCachedData((draft) => {
                  draft.push(data)
                })
                break
              }
              case GlobalWsEvents.Error: {
                if (detail === 'Invalid token') {
                  toast.error('Ошибка авторизации')
                  dispatch(clearSessionData())
                } else {
                  toast.error(detail)
                }
                break
              }
            }
          }

          const openListener = () => {
            console.log('Соединение установлено')
          }

          const closeListener = () => {
            console.log('Соединение закрыто')
          }

          const errorListener = (error: ErrorEvent) => {
            if (FEATURE_FLAG_LOGS) {
              toast.error(JSON.stringify(error, null, 2))
            } else {
              // toast.error(
              //   `Ошибка: ${
              //     error.message
              //       ? error.message
              //       : 'Не удается подключиться к серверу, попробуйте позже или перезагрузите страницу...'
              //   }`
              // )
            }
            console.log(`Ошибка: ${error.message}`)
          }

          socket.addEventListener('open', openListener)
          socket.addEventListener('close', closeListener)
          socket.addEventListener('error', errorListener)
          socket.addEventListener('message', listener)
        } catch {}
        await cacheEntryRemoved
        console.log('cache removed, socket timeline closed')
        socket.close()
      }
    })
  })
})

export const { useMapTablesQuery, useMapTablesWsQuery } = mapReservationsApi
