import { useEffect, useState } from 'react'

import { config } from '@/app/config'

const { DEBOUNCE_DELAY } = config

const useDebounce = (value: string, delay: number = DEBOUNCE_DELAY): string => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
