import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'

import { selectHall, selectSelectedHall, useHallsQuery } from '@/entities/halls'
import { selectSelectedAddressId } from '@/entities/session'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Skeleton, Tab, TabsGroup, TabsProvider } from '@/shared/ui'

import css from './MobileMapHalls.module.css'

type MobileMapHallsProps = {
  isGray?: boolean
  start?: Date
  end?: Date
  personsCount?: number
  withCount?: boolean
}

export default function MobileMapHalls({
  isGray = false,
  start,
  end,
  withCount = true
}: MobileMapHallsProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedHall = useAppSelector(selectSelectedHall)

  const { data, isFetching } = useHallsQuery(
    addressId
      ? {
          addressId,
          start_date: formatTimeForRequest(start),
          end_date: formatTimeForRequest(end)
        }
      : skipToken
  )

  const valueChangeHandler = (value: string) => {
    dispatch(selectHall(+value))
  }

  const tabsHallData = data?.map((hall) => ({
    id: hall.id,
    label: hall.name,
    count: hall.count
  }))

  return (
    <div className={cx(css.halls, { [css.halls_gray]: isGray })}>
      {!isFetching && tabsHallData && selectedHall ? (
        <TabsProvider
          value={selectedHall.toString()}
          onValueChange={valueChangeHandler}
        >
          <TabsGroup
            variant="borderless"
            className={cx(css.tabs, { [css.tabs_gray]: isGray })}
          >
            {tabsHallData.map((tab) => (
              <Tab
                key={tab.id}
                variant={isGray ? 'transparent' : 'primary'}
                className={cx(css.tab, {
                  [css.tab_gray]: isGray
                })}
                value={tab.id.toString()}
              >
                {tab.label}
                {withCount && (
                  <span
                    className={cx(css.tab__count, {
                      [css.tab__count_active]: tab.id === selectedHall
                    })}
                  >
                    {tab.count}
                  </span>
                )}
              </Tab>
            ))}
          </TabsGroup>
        </TabsProvider>
      ) : (
        <div className={css.skeleton}>
          {Array(2)
            .fill(null)
            .map((_, idx) => (
              <Skeleton
                key={idx}
                className={cx(css.skeleton__tab, {
                  [css.skeleton__tab_gray]: isGray
                })}
              />
            ))}
        </div>
      )}
    </div>
  )
}
