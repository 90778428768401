import { type ReactElement } from 'react'

import { Navigate } from 'react-router-dom'

import { PATHS } from '@/shared/lib'

import { config } from '../config'

type MobileGuardProps = {
  children: ReactElement
}

export function MobileGuard({ children }: MobileGuardProps) {
  const isMobile = window.innerWidth < config.MAX_MOBILE_WIDTH
  if (isMobile) return <Navigate to={PATHS.map} />

  return children
}
