import { IMask } from 'react-imask'

import { masks } from '../masks'
const { PHONE_INPUT_MASK_RU } = masks

export const formatPhoneNumberRU = (str: string | null) => {
  if (str === null || str === undefined) return '-'
  const mask = IMask.createMask({
    mask: PHONE_INPUT_MASK_RU
  })

  mask.resolve(str)
  return mask.value
}
