/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useAppSelector } from '@/shared/model'

import { selectReservation } from '../model/selectors'

export const useNewReservationGuestAvailable = (): [
  boolean,
  ReservationData
] => {
  const newReservation = useAppSelector(selectReservation)

  const isNewReservationGuestStepAvailable = Boolean(
    newReservation.personsCount &&
      newReservation.tables.length &&
      newReservation.date &&
      newReservation.start &&
      (newReservation.end || newReservation.end === null)
  )

  return [isNewReservationGuestStepAvailable, newReservation as ReservationData]
}
