import { differenceInMinutes, isEqual, startOfDay } from 'date-fns'

export const computeSpans = (
  slotStart: Date,
  slotEnd: Date,
  startOfTimeline: Date
) => {
  const spanStartDiff = differenceInMinutes(slotStart, startOfTimeline)
  const spanEndDiff = differenceInMinutes(slotEnd, slotStart)

  const spanStart = isEqual(startOfDay(slotStart), slotStart)
    ? 1
    : 3 + spanStartDiff
  const spanEnd = isEqual(startOfDay(slotStart), slotStart)
    ? spanStart + spanEndDiff + 2
    : spanStart + spanEndDiff

  return [spanStart, spanEnd]
}
