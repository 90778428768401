import {
  ReservationSortDateEnum,
  ReservationSortTableEnum
} from '@/entities/reservations/model/slice'

import { SortVariantsEnum } from './const'

const getSortDateAdapterFrontToBack = (sort: SortVariantsEnum) => {
  switch (sort) {
    case SortVariantsEnum.DOWN:
      return ReservationSortDateEnum.StartDate
    case SortVariantsEnum.UP:
      return ReservationSortDateEnum.EndDate
    case SortVariantsEnum.NONE:
      return undefined
  }
}

const getSortDateAdapterBackToFront = (
  sort: ReservationSortDateEnum | undefined
) => {
  switch (sort) {
    case ReservationSortDateEnum.StartDate:
      return SortVariantsEnum.DOWN
    case ReservationSortDateEnum.EndDate:
      return SortVariantsEnum.UP
    case undefined:
      return SortVariantsEnum.NONE
  }
}

const getSortTableAdapterFrontToBack = (sort: SortVariantsEnum) => {
  switch (sort) {
    case SortVariantsEnum.DOWN:
      return ReservationSortTableEnum.Ascending
    case SortVariantsEnum.UP:
      return ReservationSortTableEnum.Descending
    case SortVariantsEnum.NONE:
      return undefined
  }
}

const getSortTableAdapterBackToFront = (
  sort: ReservationSortTableEnum | undefined
) => {
  switch (sort) {
    case ReservationSortTableEnum.Ascending:
      return SortVariantsEnum.DOWN
    case ReservationSortTableEnum.Descending:
      return SortVariantsEnum.UP
    case undefined:
      return SortVariantsEnum.NONE
  }
}

const transformSources = (links?: ReservationLink[]) => {
  if (!links) return []

  const SOURCES = [
    { label: 'С улицы', value: 'street' },
    { label: 'Виджет', value: 'widget' },
    { label: 'Книга резервов', value: 'book' }
  ]
  const transformedLinks = links.map((link) => ({
    label: link.name,
    value: `${link.id}`
  }))

  return [...SOURCES, ...transformedLinks]
}

export {
  getSortDateAdapterFrontToBack,
  getSortDateAdapterBackToFront,
  getSortTableAdapterFrontToBack,
  getSortTableAdapterBackToFront,
  transformSources
}
