import {
  selectNewWaitingListGuest,
  setNewWaitingListIsOpenDrawer
} from '@/entities/new-waiting-list-record'
import { setWaitingListStep, WaitingListSteps } from '@/entities/waiting-list'
import { formatName } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Card, Icon, Label } from '@/shared/ui'

import css from './GuestsPicker.module.css'

export function GuestsPicker() {
  const dispatch = useAppDispatch()
  const guest = useAppSelector(selectNewWaitingListGuest)

  const nextStep = () => {
    dispatch(setWaitingListStep(WaitingListSteps.two))
    dispatch(setNewWaitingListIsOpenDrawer(true))
  }

  return (
    <Card className={css.picker}>
      <Label className={css.picker__title}>Гость</Label>
      <div className={css.picker__container}>
        <p className={css.picker__guest}>
          {guest && formatName(guest.first_name, guest.last_name)}
        </p>
        <Button
          type="button"
          variant="tertiary"
          className={css.button}
          onClick={nextStep}
        >
          Выбрать из базы
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
    </Card>
  )
}
