import { Card } from '@/shared/ui'

import { getSourceText } from '../../lib'

import css from './MobileReservationSource.module.css'

type MobileReservationSourceProps = {
  source: ReservationSource
  linkName: string | null
}

export default function MobileReservationSource({
  source,
  linkName
}: MobileReservationSourceProps) {
  return (
    <Card className={css.card}>
      <h4 className={css.card__title}>Источник брони</h4>
      <p className={css.card__source}>{getSourceText(source, linkName)}</p>
    </Card>
  )
}
