import { cx } from 'class-variance-authority'

import css from './ColInfo.module.css'

type ColInfoProps = {
  label: string
  text: string | JSX.Element
  highlight?: boolean
}

export function ColInfo({ label, text, highlight = false }: ColInfoProps) {
  return (
    <div className={css.root}>
      <span className={css.root__label}>{label}</span>
      <h5
        className={cx(css.root__content, {
          [css.root__content_highlight]: highlight
        })}
      >
        {text}
      </h5>
    </div>
  )
}
