/* eslint-disable @typescript-eslint/no-explicit-any */
import { type MouseEventHandler, useRef } from 'react'

const useCheckClick = (handleClick: () => void) => {
  const ref = useRef<any>(null)
  const xRef = useRef<number[]>()

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    xRef.current = [e.clientX, e.clientY]
  }

  const handleMouseUp: MouseEventHandler<HTMLDivElement> = (e) => {
    if (
      xRef.current?.[0] === e.clientX &&
      xRef.current?.[1] === e.clientY &&
      ref.current?.contains(e.target as Node)
    ) {
      handleClick()
    }
  }

  return { ref, handlers: [handleMouseDown, handleMouseUp] }
}

export default useCheckClick
