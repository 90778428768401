import { useCallback, useEffect, useRef, useState } from 'react'

import { AlertDialog } from '@radix-ui/react-alert-dialog'
import {
  type BlockerFunction,
  type Location,
  useBeforeUnload,
  useBlocker,
  useLocation,
  useNavigate
} from 'react-router-dom'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../AlertDialog'
import { Button } from '../Button'

const stepLinks: string[] = []

type PromptProps = {
  isShow?: boolean
  title: string
  description: string
  deleteShowHandler?: () => void
}

export const Prompt = ({
  isShow = false,
  title,
  description,
  deleteShowHandler
}: PromptProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [nextLocation, setNextLocation] = useState<Location | null>(null)
  const navigate = useNavigate()

  const onLocationChange: BlockerFunction = useCallback(
    ({ nextLocation }) => {
      if (!nextLocation.state && nextLocation.state !== null) {
        nextLocation.state = 'new-reservation'

        return false
      }

      if (
        !stepLinks.some((link) => nextLocation.pathname.includes(link)) &&
        isShow
      ) {
        setOpen(true)
        setNextLocation(nextLocation)

        return true
      }
      return false
    },
    [isShow]
  )

  usePrompt(onLocationChange, isShow)
  useBeforeUnload(
    useCallback(
      (event) => {
        if (isShow) {
          event.preventDefault()
          event.returnValue = ''
        }
      },
      [isShow]
    ),
    { capture: true }
  )

  const location = useLocation()

  useEffect(() => {
    if (!isShow) {
      deleteShowHandler?.()
    }
  }, [location])

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent onClose={() => setOpen(false)}>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              onClick={() =>
                nextLocation &&
                navigate(nextLocation.pathname, {
                  state: false
                })
              }
            >
              Продолжить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel>Отмена</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const usePrompt = (
  onLocationChange: BlockerFunction,
  hasUnsavedChanges: boolean
) => {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false)
  const prevState = useRef(blocker.state)

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
    prevState.current = blocker.state
  }, [blocker])
}
