import { type FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {
  type FetchArgs,
  type FetchBaseQueryError
} from '@reduxjs/toolkit/query'
import {
  type BaseQueryApi,
  type QueryReturnValue
} from '@reduxjs/toolkit/src/query/baseQueryTypes'
import { toast } from 'react-toastify'

import { baseQuery } from './baseQuery'
import { getTz } from './getTz'
import { invalidateAccessToken } from './invalidateTokenEvent'
import { replaceDateTimezones } from './replaceTimezones'

import { isParsingQueryError, parseHTTPErrors, PATHS } from '../lib'

const AUTH_ERROR_CODES = new Set([401, 403])

export async function baseQueryWithReauth(
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> {
  const result = await baseQuery(args, api, extraOptions)

  const isProfileReq = result.meta?.request.url.includes('/staff/profile/')
  const isLoginReq = result.meta?.request.url.includes(
    '/admin/auth/staff-login/'
  )

  if (
    AUTH_ERROR_CODES.has(Number(result.error?.status)) ||
    (isProfileReq &&
      (result.error?.status === 500 ||
        (typeof result.error?.status === 'string' &&
          'originalStatus' in result.error &&
          result.error?.originalStatus === 500)))
  ) {
    api.dispatch(invalidateAccessToken())
  } else if (
    typeof result.error?.status === 'number' ||
    (result.error && isParsingQueryError(result.error))
  ) {
    const err = parseHTTPErrors(result.error)

    const message =
      typeof err.message === 'string' ? err.message : err.message[0]

    const isNotFound = err.status === 404

    if (isNotFound) {
      window.location.replace(PATHS.notFound)
    }

    if (!isLoginReq && !isNotFound) {
      toast.error(message)
    }
  }

  // Replace all UTC +0 timezones with address specific timezone
  const tz = getTz()
  if (tz) {
    replaceDateTimezones(result.data, tz)
  }

  return result
}
