import {
  type ComponentPropsWithoutRef,
  type ElementType,
  forwardRef,
  type PropsWithChildren,
  type Ref
} from 'react'

import type { VariantProps } from 'class-variance-authority'
import { cva, cx } from 'class-variance-authority'

import { type PolymorphicComponentProp } from '@/types'

import { LoadingSpinner } from '../Loading'

import css from './Button.module.css'

export type ButtonStyleProps = VariantProps<typeof buttonStyle> & {
  disabled?: boolean
  asChild?: boolean
  isLoading?: boolean
  contentClassName?: string
}

export const buttonStyle = cva(css.button, {
  variants: {
    variant: {
      default: css.button_variantDefault,
      primary: css.button_variantPrimary,
      secondary: css.button_variantSecondary,
      tertiary: css.button_variantTertiary,
      red: css.button_variantRed,
      transparent: css.button_variantTransparent,
      transparentRed: css.button_variantTransparentRed,
      transparentPrimary: css.button_variantTransparentPrimary,
      gray: css.button_variantGray,
      white: css.button_variantWhite,
      green: css.button_variantGreen
    },
    size: {
      none: css.button_sizeNone,
      xxs: css.button_sizeXxs,
      xs: css.button_sizeXs,
      sm: css.button_sizeSm,
      md: css.button_sizeMd,
      lg: css.button_sizeLg,
      icon: css.button_sizeIcon,
      'icon-sm': css.button_sizeIconSm
    },
    disabled: {
      true: css.button_disabled,
      false: ''
    },
    withSlot: {
      true: css.button_withSlot,
      false: ''
    },
    isLoading: {
      true: css.button_isLoading,
      false: ''
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'md',
    disabled: false,
    withSlot: false,
    isLoading: false
  }
})

export type ButtonProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  ButtonStyleProps
>

type ButtonContentProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> & {
    withSlot: boolean
  }

function ButtonContent({ withSlot, className, children }: ButtonContentProps) {
  if (withSlot) return children

  return <div className={cx(css.button__content, className)}>{children}</div>
}

function ButtonLoading({ children }: PropsWithChildren) {
  return (
    <div className={css.button__content}>
      <div className={css.button__hiddenText}>{children}</div>
      <div className={css.button__loader}>
        <LoadingSpinner variant="white" />
      </div>
    </div>
  )
}

function InnerButton<C extends ElementType = 'button'>(
  {
    className,
    children,
    variant,
    size,
    disabled,
    as,
    isLoading = false,
    contentClassName,
    ...props
  }: ButtonProps<C>,
  ref: Ref<HTMLButtonElement>
) {
  const Comp = as ?? 'button'

  return (
    <Comp
      className={buttonStyle({
        variant,
        size,
        disabled,
        withSlot: Boolean(as),
        isLoading,
        className
      })}
      disabled={disabled}
      tabIndex={disabled ?? isLoading ? -1 : 0}
      ref={ref}
      {...props}
    >
      {isLoading ? (
        <ButtonLoading>{children}</ButtonLoading>
      ) : (
        <ButtonContent withSlot={Boolean(as)} className={contentClassName}>
          {children}
        </ButtonContent>
      )}
    </Comp>
  )
}

export const Button = forwardRef(InnerButton) as <
  C extends ElementType = 'button'
>(
  props: ButtonProps<C> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof InnerButton>
