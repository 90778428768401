import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { selectTable, unselectTable } from '@/entities/map'

type WaitingListSliceState = {
  step: WaitingListStep
  guest?: Guest
  personsCount?: number
  duration?: number
  place: number[]
  hall?: number
  waitingList: WaitingListRecord[]
  selectedWLRecordID?: number
}

export enum WaitingListSteps {
  one = 1,
  two = 2,
  three = 3
}

const initialState: WaitingListSliceState = {
  step: 1,
  place: [],
  waitingList: []
}

export const waitingListSlice = createSlice({
  name: 'waitingList',
  initialState,
  reducers: {
    setWaitingList: (
      state,
      { payload }: PayloadAction<WaitingListRecord[]>
    ) => {
      state.waitingList = payload
    },
    setWaitingListRecord: (
      state,
      { payload }: PayloadAction<WaitingListRecord>
    ) => {
      state.selectedWLRecordID = payload.id
      state.guest = payload.guest
      state.personsCount = payload.persons_count
      state.duration = payload.duration
      state.hall = payload.hall
    },
    setWaitingListStep(state, { payload }: PayloadAction<WaitingListStep>) {
      state.step = payload
    },
    resetWaitingList: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectTable, (state, action) => {
        state.place.push(action.payload.id)
      })
      .addCase(unselectTable, (state) => {
        state.place = []
      })
  }
})

export const {
  setWaitingList,
  setWaitingListStep,
  setWaitingListRecord,
  resetWaitingList
} = waitingListSlice.actions
