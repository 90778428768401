import { cx } from 'class-variance-authority'
import { addMinutes, format, isBefore, subMinutes } from 'date-fns'

import { config } from '@/app/config'
import { nowWithTimezone } from '@/shared/lib'

import css from './Hour.module.css'

const { GROUP_HOUR_COEFFICIENT } = config

type HourProps = {
  hour: Date
  isFirst?: boolean
  isLast?: boolean
}

export function Hour({ hour, isFirst = false, isLast = false }: HourProps) {
  const isFutureFirstLine = isBefore(
    nowWithTimezone(),
    subMinutes(hour, GROUP_HOUR_COEFFICIENT / 3)
  )

  const isFutureSecondLine = isBefore(nowWithTimezone(), hour)
  const isFutureThirtyLine = isBefore(
    nowWithTimezone(),
    addMinutes(hour, GROUP_HOUR_COEFFICIENT / 3)
  )

  return (
    <div
      className={cx(css.hour, {
        [css.hour_future]: isFutureSecondLine
      })}
    >
      <div className={css.hour__lines}>
        <div
          className={cx(css.hour__line, {
            [css.hour__line_future]: isFutureFirstLine,
            [css.hour__line_first]: isFirst
          })}
        />
        <div
          className={cx(css.hour__line, css.hour__line_large, {
            [css.hour__line_future]: isFutureSecondLine
          })}
        />
        <div
          className={cx(css.hour__line, {
            [css.hour__line_future]: isFutureThirtyLine,
            [css.hour__line_last]: isLast
          })}
        />
      </div>
      {format(hour, 'HH:mm')}
    </div>
  )
}
