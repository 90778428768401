import * as React from 'react'

import * as SelectUI from '@radix-ui/react-select'
import { cx } from 'class-variance-authority'

import { Icon } from '../Icon'

import css from './Select.module.css'

const Select = SelectUI.Root

const SelectGroup = SelectUI.Group

const SelectValue = SelectUI.Value

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectUI.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectUI.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectUI.Trigger ref={ref} className={cx(css.trigger, className)} {...props}>
    {children}
    <SelectUI.Icon asChild>
      <Icon name="chevronDown" />
    </SelectUI.Icon>
  </SelectUI.Trigger>
))
SelectTrigger.displayName = SelectUI.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectUI.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectUI.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectUI.ScrollUpButton
    ref={ref}
    className={cx(css.scroll, className)}
    {...props}
  >
    <Icon name="chevronUp" />
  </SelectUI.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectUI.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectUI.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectUI.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectUI.ScrollDownButton
    ref={ref}
    className={cx(css.scroll, className)}
    {...props}
  >
    <Icon name="chevronDown" />
  </SelectUI.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectUI.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectUI.Content>,
  React.ComponentPropsWithoutRef<typeof SelectUI.Content>
>(({ className, children, position = 'item-aligned', ...props }, ref) => (
  <SelectUI.Portal>
    <SelectUI.Content
      ref={ref}
      className={cx(css.content, className)}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectUI.Viewport className={css.viewport}>{children}</SelectUI.Viewport>
      <SelectScrollDownButton />
    </SelectUI.Content>
  </SelectUI.Portal>
))
SelectContent.displayName = SelectUI.Content.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectUI.Item>,
  React.ComponentPropsWithoutRef<typeof SelectUI.Item>
>(({ className, children, ...props }, ref) => (
  <SelectUI.Item ref={ref} className={cx(css.item, className)} {...props}>
    <span className={css.item__content}>
      <SelectUI.ItemIndicator>
        <Icon name="check" size={14} />
      </SelectUI.ItemIndicator>
    </span>

    <SelectUI.ItemText>{children}</SelectUI.ItemText>
  </SelectUI.Item>
))
SelectItem.displayName = SelectUI.Item.displayName

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectScrollUpButton,
  SelectScrollDownButton
}
