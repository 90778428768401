import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cx } from 'class-variance-authority'
import { isMobile } from 'react-device-detect'

import css from './Label.module.css'

type LabelRef = React.ElementRef<typeof LabelPrimitive.Root>
type LabelProps = React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>

const Label = React.forwardRef<LabelRef, LabelProps>(
  ({ className, ...props }, ref) => (
    <LabelPrimitive.Root
      ref={ref}
      className={cx(css.label, { [css.label_mobile]: isMobile }, className)}
      {...props}
    />
  )
)
Label.displayName = 'Label'

export { Label }
