import { formatName } from '@/shared/lib'
import { Icon } from '@/shared/ui'

import css from './CommentAuthor.module.css'

export default function CommentAuthor({
  isAuthor,
  author
}: {
  isAuthor: boolean
  author: UserComment['author']
}) {
  if (isAuthor) {
    return <span className={css.author}>Вы</span>
  }

  if (!author) {
    return (
      <span className={css.author_guest}>
        Гость <Icon name="user" />
      </span>
    )
  }

  const { first_name, last_name } = author

  return <span className={css.author}>{formatName(first_name, last_name)}</span>
}
