import { z } from 'zod'

export const guestFormSchema = z.object({
  firstName: z.string().trim().min(1, { message: 'Имя обязательно' }),
  lastName: z.string().trim().optional(),
  phone: z.string().min(11, { message: 'Номер обязателен' }),
  tags: z.array(z.string()),
  comment: z.string().trim().optional()
})

export type GuestFormSchema = z.infer<typeof guestFormSchema>
