import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export enum MailingModeEnum {
  VIEW,
  EDIT
}

type MailingSliceState = {
  mode: MailingModeEnum
  interval: IntervalData
  mailing?: IMailing
}

export enum MailingStatusEnum {
  WAITING = 'waiting',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

const initialState: MailingSliceState = {
  mode: MailingModeEnum.VIEW,
  interval: {
    start_date: dayjs().subtract(1, 'month').toDate(),
    end_date: dayjs().toDate()
  }
}

export const mailingSlice = createSlice({
  name: 'mailing',
  initialState,
  reducers: {
    setMailingMode: (state, { payload }: PayloadAction<MailingModeEnum>) => {
      state.mode = payload
    },
    setMailingInterval: (state, { payload }: PayloadAction<IntervalData>) => {
      state.interval = payload
    },
    setMailing: (state, { payload }: PayloadAction<IMailing>) => {
      state.mailing = payload
    },
    resetMailing: () => initialState
  }
})

export const { setMailingMode, setMailing, setMailingInterval, resetMailing } =
  mailingSlice.actions
