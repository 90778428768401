import { type ComponentPropsWithRef, type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { formatPhoneNumberRU } from '@/shared/lib'

import { Button } from '../Button'
import { Popover, PopoverContent, PopoverTrigger } from '../Popover'

import css from './ConfirmPhonePopup.module.css'

type ConfirmPhonePopupProps = PropsWithChildren &
  ComponentPropsWithRef<typeof PopoverContent> & {
    phone: string | null
    onConfirm?: () => void
    textConfirmButton: string
    form?: string | undefined
    isLoading?: boolean
  }

export default function ConfirmPhonePopup({
  phone,
  onConfirm,
  textConfirmButton,
  form,
  children,
  isLoading = false,
  ...props
}: ConfirmPhonePopupProps) {
  return (
    <Popover modal={false}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className={css.popover} {...props}>
        <div className={css.info}>
          <p className={css.text}>Подтвердите номер телефона</p>
          <p className={cx(css.text, css.text_phone)}>
            {formatPhoneNumberRU(phone)}
          </p>
        </div>
        <Button
          variant="white"
          size="lg"
          className={css.button_confirm}
          onClick={onConfirm}
          form={form}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {textConfirmButton}
        </Button>
      </PopoverContent>
    </Popover>
  )
}
