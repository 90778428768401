import { TimelineFilters as Filters } from './filters/ui/TimelineFilters'
import { HoursInterval } from './hours/ui/HoursInterval'
import { Reservations } from './reservations/ui/Reservations'
import { TablesInfoList as Tables } from './tables/ui/TablesInfoList'

export const Timeline = {
  Tables,
  HoursInterval,
  Reservations,
  Filters
}
export * from './reservations'
