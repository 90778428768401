import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { hallsApi } from '@/entities/halls'

type NewWaitingListRecordSliceState = {
  guest?: BaseGuest
  comment?: string
  hallId?: number
  duration?: number | null
  personsCount: number
  isOpenDrawer: boolean
}

const initialState: NewWaitingListRecordSliceState = {
  personsCount: 1,
  duration: 30,
  isOpenDrawer: false
}

export const newWaitingListRecordSlice = createSlice({
  name: 'newWaitingListRecord',
  initialState,
  reducers: {
    addNewWaitingListRecordGuest: (
      state,
      { payload }: PayloadAction<BaseGuest>
    ) => {
      state.guest = payload
    },
    setNewWaitingListHallId(state, { payload }: PayloadAction<number>) {
      state.hallId = payload
    },
    setNewWaitingListPersonsCount: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.personsCount = payload
    },
    setNewWaitingListDuration: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.duration = payload
    },
    setNewWaitingListIsOpenDrawer: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isOpenDrawer = payload
    },
    resetNewWaitingListRecord: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hallsApi.endpoints.halls.matchFulfilled,
      (state: NewWaitingListRecordSliceState, { payload }) => {
        state.hallId = payload[0]?.id
      }
    )
  }
})

export const {
  addNewWaitingListRecordGuest,
  resetNewWaitingListRecord,
  setNewWaitingListHallId,
  setNewWaitingListPersonsCount,
  setNewWaitingListDuration,
  setNewWaitingListIsOpenDrawer
} = newWaitingListRecordSlice.actions
