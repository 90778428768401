import { cx } from 'class-variance-authority'

import { Button } from '../Button'

import css from './Error.module.css'

type ErrorProps = {
  refetch?: () => void
  errMes?: string
  fullscreen?: boolean
}

export default function Error({
  refetch,
  errMes = 'Произошла ошибка при загрузке данных...',
  fullscreen = false
}: ErrorProps) {
  return (
    <div className={cx(css.error, { [css.error_fullscreen]: fullscreen })}>
      <p className={css.error__text}>{errMes}</p>
      {refetch && <Button onClick={refetch}>Попробовать снова</Button>}
    </div>
  )
}
