/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { addYears, format } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'
import {
  Button,
  DatePickerList,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './TimelineDatePicker.module.css'

type TimelineDatePickerProps = {
  selectedDate: Date | undefined
  selectDate: (date: Date) => void
}

export default function TimelineDatePicker({
  selectedDate = nowWithTimezone(),
  selectDate
}: TimelineDatePickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [selectedDateState, selectDateState] = useState<Date | undefined>(
    selectedDate
  )

  const selectDateHandler = () => {
    if (selectedDateState) {
      selectDate(selectedDateState)
      setIsCalendarOpen(false)
    }
  }

  return (
    <Vaul open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
      <VaulTrigger asChild>
        <Button className={css.button__picker} variant="transparent" size="sm">
          {format(selectedDate, 'dd.MM.yy')}
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <div className={css.content}>
          <VaulHeader className={css.header}>
            <h4 className={css.header__title}>Выберите дату</h4>
          </VaulHeader>
          <VaulBody>
            <DatePickerList
              selectDate={selectDateState}
              selectedDate={selectedDateState}
              startDate={nowWithTimezone()}
              endDate={addYears(nowWithTimezone(), 1)}
            />
            <VaulFooter>
              <Button
                className={css.button__submit}
                variant="primary"
                size="lg"
                onClick={selectDateHandler}
              >
                Выбрать
              </Button>
            </VaulFooter>
          </VaulBody>
        </div>
      </VaulContent>
    </Vaul>
  )
}
