import { useState } from 'react'

import { cx } from 'class-variance-authority'

import { setReservationIsNewGuest } from '@/entities/create-update-reservation'
import { CreateGuestForm } from '@/features/guest/create-mobile/ui/CreateGuestForm'
import {
  findStartingIntersection,
  formatName,
  formatPhoneNumberRU
} from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'
import {
  Button,
  Card,
  Icon,
  Tag,
  Vaul,
  VaulBody,
  VaulContent,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './MobileSimpleSearchGuestCard.module.css'

type MobileSimpleSearchGuestCardTelephonyProps = {
  guest: TelephonyGuest | null
  phoneNewGuest: string | null
  searchValue?: string
  status: CallStatus
  selectGuestHandler: (guest: BaseGuest) => void
}

export default function MobileSimpleSearchGuestCardTelephony({
  guest,
  phoneNewGuest,
  searchValue = '',
  status,
  selectGuestHandler
}: MobileSimpleSearchGuestCardTelephonyProps) {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const phone = guest ? guest.phone : phoneNewGuest
  const guestName = guest
    ? formatName(guest.first_name, guest.last_name)
    : 'Новый гость'
  const isPhone = !Number.isNaN(+searchValue)

  const [matchedPhone, otherPhonePart] = findStartingIntersection(
    formatPhoneNumberRU(phone),
    formatPhoneNumberRU(searchValue)
  )

  const onClick = async () => {
    if (guest) {
      selectGuestHandler({
        id: guest.id,
        last_name: guest.last_name ?? '',
        first_name: guest.first_name ?? '',
        tags: guest.tags,
        comment: guest.comment,
        phone: guest.phone,
        is_anonymous: false
      })
    }
  }

  const successHandler = (guest?: BaseGuest) => {
    dispatch(setReservationIsNewGuest(true))
    guest && selectGuestHandler(guest)
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Card className={css.card}>
          <div className={css.card__guest}>
            <div className={css.card__status}>
              {status === 'calling' ? (
                <>
                  <Icon name="phoneCall" />
                  Входящий звонок
                </>
              ) : (
                <>
                  <Icon name="phone" />
                  Идёт разговор
                </>
              )}
            </div>
            <span className={css.card__name}>{guestName}</span>
            {phone && (
              <div className={css.card__text}>
                <span className={cx(isPhone && css.card__text_active)}>
                  {matchedPhone}
                </span>
                <span>{otherPhonePart}</span>
              </div>
            )}
          </div>
          {guest && guest.tags.length > 0 && (
            <Tag tag={guest.tags[0]} size="xs" />
          )}
        </Card>
      </VaulTrigger>
      <VaulContent
        fullScreen={!guest}
        isVisibleButtonClose={!guest}
        onClose={() => setOpen(false)}
      >
        {guest ? (
          <div className={css.vaul__content}>
            <div className={css.vaul__info}>
              <p className={css.vaul__text}>Подтвердите номер телефона</p>
              <p className={css.vaul__phone}>{formatPhoneNumberRU(phone)}</p>
            </div>
            <Button variant="primary" size="lg" onClick={onClick}>
              Подтвердить
              <Icon name="arrowRight" />
            </Button>
            <Button size="lg" onClick={() => setOpen(false)}>
              Отмена
              <Icon name="x" />
            </Button>
          </div>
        ) : (
          <>
            <VaulHeader>
              <h4 className={css.header}>Новый гость</h4>
            </VaulHeader>
            <VaulBody>
              <CreateGuestForm
                successHandler={successHandler}
                phone={phone}
                withoutInvalidation
              />
            </VaulBody>
          </>
        )}
      </VaulContent>
    </Vaul>
  )
}
