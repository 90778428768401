import * as React from 'react'

import * as AlertDialogUI from '@radix-ui/react-alert-dialog'
import { cx } from 'class-variance-authority'
import { isMobile } from 'react-device-detect'

import { buttonStyle, type ButtonStyleProps } from '../Button'

import css from './AlertDialog.module.css'

const AlertDialog = AlertDialogUI.Root

const AlertDialogTrigger = AlertDialogUI.Trigger

const AlertDialogPortal = AlertDialogUI.Portal

type AlertDialogRef = React.ElementRef<typeof AlertDialogUI.Overlay>

type AlertDialogProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Overlay
>

const AlertDialogOverlay = React.forwardRef<AlertDialogRef, AlertDialogProps>(
  ({ className, children, ...props }, ref) => (
    <AlertDialogUI.Overlay
      className={cx(css.overlay, className)}
      {...props}
      ref={ref}
    />
  )
)
AlertDialogOverlay.displayName = 'AlertDialogOverlay'

type AlertDialogContentRef = React.ElementRef<typeof AlertDialogUI.Content>

type AlertDialogContentProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Content
> & {
  onClose?: (e: React.MouseEvent) => void
}

const AlertDialogContent = React.forwardRef<
  AlertDialogContentRef,
  AlertDialogContentProps
>(({ className, onClose, ...props }, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay onClick={onClose} />
    <AlertDialogUI.Content
      ref={ref}
      className={cx(css.content, { [css.content_mobile]: isMobile }, className)}
      {...props}
    />
  </AlertDialogPortal>
))
AlertDialogContent.displayName = 'AlertDialogContent'

function AlertDialogHeader({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cx(css.header, { [css.header_mobile]: isMobile }, className)}
      {...props}
    />
  )
}
AlertDialogHeader.displayName = 'AlertDialogHeader'

function AlertDialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cx(css.footer, { [css.footer_mobile]: isMobile }, className)}
      {...props}
    />
  )
}
AlertDialogFooter.displayName = 'AlertDialogFooter'

type AlertDialogTitleRef = React.ElementRef<typeof AlertDialogUI.Title>

type AlertDialogTitleProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Title
>

const AlertDialogTitle = React.forwardRef<
  AlertDialogTitleRef,
  AlertDialogTitleProps
>(({ className, ...props }, ref) => (
  <AlertDialogUI.Title
    ref={ref}
    className={cx(css.title, { [css.title_mobile]: isMobile }, className)}
    {...props}
  />
))
AlertDialogTitle.displayName = 'AlertDialogTitle'

type AlertDialogDescriptionRef = React.ElementRef<
  typeof AlertDialogUI.Description
>

type AlertDialogDescriptionProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Description
>

const AlertDialogDescription = React.forwardRef<
  AlertDialogDescriptionRef,
  AlertDialogDescriptionProps
>(({ className, ...props }, ref) => (
  <AlertDialogUI.Description
    ref={ref}
    className={cx(
      css.description,
      { [css.description_mobile]: isMobile },
      className
    )}
    {...props}
  />
))
AlertDialogDescription.displayName = 'AlertDialogDescription'

type AlertDialogActionRef = React.ElementRef<typeof AlertDialogUI.Action>

type AlertDialogActionProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Action
> &
  ButtonStyleProps

const AlertDialogAction = React.forwardRef<
  AlertDialogActionRef,
  AlertDialogActionProps
>(({ className, variant, size, ...props }, ref) => (
  <AlertDialogUI.Action
    ref={ref}
    className={cx(
      buttonStyle({
        variant: isMobile ? 'transparentRed' : 'primary',
        size: 'lg'
      }),
      { [css.action_mobile]: isMobile },
      className
    )}
    {...props}
  />
))
AlertDialogAction.displayName = 'AlertDialogAction'

type AlertDialogCancelRef = React.ElementRef<typeof AlertDialogUI.Cancel>

type AlertDialogCancelProps = React.ComponentPropsWithoutRef<
  typeof AlertDialogUI.Cancel
>

const AlertDialogCancel = React.forwardRef<
  AlertDialogCancelRef,
  AlertDialogCancelProps
>(({ className, ...props }, ref) => (
  <AlertDialogUI.Cancel
    ref={ref}
    className={cx(
      buttonStyle({
        variant: isMobile ? 'transparent' : 'tertiary',
        size: 'lg'
      }),
      { [css.cancel_mobile]: isMobile },
      className
    )}
    {...props}
  />
))
AlertDialogCancel.displayName = 'AlertDialogCancel'

export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger
}
