import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { FilterTypesEnum, useFiltersQuery } from '@/entities/filters'
import {
  Button,
  Icon,
  Skeleton,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import { FilterButton } from './FilterButton'
import { FilterCheckboxListVaul } from './FilterCheckboxListVaul'

import css from './FilterVaul.module.css'

type FilterVaulProps = {
  setOpen: (value: boolean) => void
}

export function FilterVaul({ setOpen }: FilterVaulProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [openFilter, setOpenFilter] = useState<OldFilter | null>(null)
  const [activeFilters, setActiveFilters] = useState<
    Record<string, string[]> | undefined
  >()

  const { data, isLoading, isError } = useFiltersQuery({
    type: FilterTypesEnum.guest
  })

  const onImprove = () => {
    if (!activeFilters) return
    for (const key in activeFilters) {
      searchParams.delete(key)
      activeFilters[key].forEach((value) => {
        searchParams.append(key, value)
      })
    }
    navigate(pathname + '?' + searchParams.toString())
    setOpen(false)
  }

  const onClear = () => {
    setActiveFilters(undefined)
    navigate(pathname)
  }

  useEffect(() => {
    if (data) {
      setActiveFilters(() => {
        const res: Record<string, string[]> = {}
        data.forEach((filter) => {
          const paramsValues = searchParams.getAll(filter.name)

          res[filter.name] = paramsValues
        })

        return res
      })
    }
  }, [data])

  return (
    <>
      {openFilter ? (
        <FilterCheckboxListVaul
          filterData={openFilter}
          setOpenFilter={setOpenFilter}
          activeValues={activeFilters?.[openFilter.name] ?? []}
          setActiveFilters={setActiveFilters}
        />
      ) : (
        <>
          <VaulHeader>
            <h4 className={css.header}>Фильтр</h4>
          </VaulHeader>
          <VaulBody className={css.body}>
            {isLoading || isError || !data ? (
              <>
                <Skeleton className={css.skeleton__button} />
                <Skeleton className={css.skeleton__button} />
                <Skeleton className={css.skeleton__button} />
              </>
            ) : (
              data.map((filter) => (
                <FilterButton
                  onClick={() => setOpenFilter(filter)}
                  countActive={activeFilters?.[filter.name]?.length}
                >
                  {filter.title}
                </FilterButton>
              ))
            )}
          </VaulBody>
          <VaulFooter className={css.footer}>
            <Button className={css.button} size="lg" onClick={onClear}>
              Сбросить
              <Icon name="x" size={16} strokeWidth={3} />
            </Button>
            <Button
              className={css.button}
              variant="primary"
              size="lg"
              onClick={onImprove}
            >
              Показать
              <Icon name="arrowRight" size={16} strokeWidth={3} />
            </Button>
          </VaulFooter>
        </>
      )}
    </>
  )
}
