import { nanoid } from 'nanoid'

export const transformReservationSourcesToFilter = (
  links: ReservationLink[]
): Filter => {
  const linksSources = links.map((link) => ({
    value: String(link.id),
    label: link.name
  }))

  const appSources = [
    { value: 'book', label: 'Книга резервов' },
    { value: 'widget', label: 'Через виджет' },
    { value: 'street', label: 'С улицы' }
  ]

  return {
    id: nanoid(),
    label: 'Источник брони',
    key: 'source',
    type: 'multi',
    options: [...appSources, ...linksSources]
  }
}
