/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { cx } from 'class-variance-authority'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { selectSelectedChainId } from '@/entities/session'
import { TagsPicker } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  PhoneDial
} from '@/shared/ui'

import {
  type GuestFormSchema,
  guestFormSchema
} from '../../model/guestFormSchema'

import css from './GuestForm.module.css'

type GuestFormProps = {
  id?: string | number
  createGuest?: (args: { chainId: number; body: CreateGuestBody }) => void
  changeGuest?: (args: UpdateGuestParams) => void
  defaultValues?: GuestFormSchema
  rememberNumber?: boolean
}

export function GuestForm({
  id,
  defaultValues,
  createGuest,
  changeGuest,
  rememberNumber = false
}: GuestFormProps) {
  const chainId = useAppSelector(selectSelectedChainId)

  const form = useForm<GuestFormSchema>({
    values: defaultValues,
    resolver: zodResolver(guestFormSchema)
  })

  const {
    formState: { errors },
    register,
    control
  } = form

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error(errors.phone?.message)
    }
  }, [errors])

  const submitHandler = ({
    firstName,
    lastName,
    tags,
    phone,
    comment
  }: GuestFormSchema) => {
    if (!chainId) return

    if (createGuest) {
      const body: CreateGuestBody = {
        phone: `+${phone}`,
        comment,
        first_name: firstName,
        last_name: lastName || '',
        tags: tags || []
      }
      createGuest({ chainId, body })
    }
    if (changeGuest && id) {
      const body: UpdateGuestBody = {
        phone: `+${phone}`,
        comment,
        first_name: firstName,
        last_name: lastName,
        tags: tags || []
      }
      changeGuest({ chainId, guestId: id, body })
    }
  }

  return (
    <Form {...form}>
      <form
        className={css.form}
        onSubmit={form.handleSubmit(submitHandler)}
        id="guest_edit_form"
      >
        <div className={css.form__info}>
          <div className={css.form__name}>
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={css.form__label}>Имя</FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      placeholder="Введите имя гостя"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={css.form__label}>Фамилия</FormLabel>
                  <FormControl>
                    <Input placeholder="Введите фамилию гостя" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="comment"
            render={({ field }) => (
              <FormItem>
                <FormLabel className={css.form__label}>Комментарий</FormLabel>
                <FormControl>
                  <Input placeholder="Введите комментарий" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tags"
            render={({ field }) => (
              <FormItem>
                <FormLabel
                  className={cx(css.form__label, css.form__label_type)}
                >
                  Тип
                </FormLabel>
                <FormControl>
                  <TagsPicker {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={css.form__phone}>
          <Controller
            control={control}
            {...register('phone')}
            render={({ field: { onChange, value } }) => (
              <PhoneDial
                defaultValue={value}
                rememberNumber={rememberNumber}
                setNumber={onChange}
                noGlobalListener
              />
            )}
          />
        </div>
      </form>
    </Form>
  )
}
