import { useEffect } from 'react'

import 'swiper/css'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { A11y, FreeMode, Mousewheel } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useHallsQuery } from '@/entities/halls'
import {
  selectNewWaitingListHallId,
  setNewWaitingListHallId
} from '@/entities/new-waiting-list-record'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Card, Label, Skeleton } from '@/shared/ui'

import css from './HallsPicker.module.css'

export function HallsPicker() {
  const dispatch = useAppDispatch()
  const selectedHallId = useAppSelector(selectNewWaitingListHallId)
  const addressId = useAppSelector(selectSelectedAddressId)

  const {
    data: halls,
    isFetching: isHallsLoading,
    isError: hallsError
  } = useHallsQuery(
    addressId
      ? {
          addressId
        }
      : skipToken
  )

  const pickHallHandler = (hallId: number) => {
    dispatch(setNewWaitingListHallId(hallId))
  }

  useEffect(() => {
    if (!selectedHallId && halls) {
      dispatch(setNewWaitingListHallId(halls[0].id))
    }
  }, [halls])

  return (
    <Card className={css.picker}>
      <Label className={css.picker__title}>Зал</Label>
      <div style={{ overflow: 'hidden' }}>
        <Swiper
          modules={[A11y, FreeMode, Mousewheel]}
          slidesPerView="auto"
          freeMode
          mousewheel={{
            sensitivity: 0.2
          }}
          className={css.picker__buttons}
          spaceBetween={8}
          data-vaul-no-drag
        >
          {isHallsLoading || hallsError || !halls ? (
            <>
              <SwiperSlide style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton__button} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton__button} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton__button} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton__button} />
              </SwiperSlide>
              <SwiperSlide style={{ width: 'auto' }}>
                <Skeleton className={css.skeleton__button} />
              </SwiperSlide>
            </>
          ) : (
            halls.map((hall) => (
              <SwiperSlide style={{ width: 'auto' }}>
                <Button
                  type="button"
                  variant={hall.id === selectedHallId ? 'white' : 'tertiary'}
                  className={css.button}
                  onClick={() => pickHallHandler(hall.id)}
                >
                  {hall.name}
                </Button>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
    </Card>
  )
}
