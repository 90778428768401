import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type IframeSliceState = {
  iframePage?: string
}

const initialState: IframeSliceState = {}

export const iframeSlice = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    setIframePage: (
      state: IframeSliceState,
      { payload }: PayloadAction<string>
    ) => {
      state.iframePage = payload
    }
  }
})

export const { setIframePage } = iframeSlice.actions
