import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useFiltersQuery } from '@/entities/filters'
import { useReservationSourcesQuery } from '@/entities/reservation-source'
import { selectReservationsFilters } from '@/entities/reservations/model/selectors'
import { setReservationsFilters } from '@/entities/reservations/model/slice'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import { useGuestTagsQuery } from '@/entities/tags'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  Skeleton,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import { FilterButton } from './FilterButton'
import { FilterCheckboxListVaul } from './FilterCheckboxListVaul'

import { TypeFilterButtonEnum } from '../../lib/const'
import { transformSources } from '../../lib/utils'

import css from './FilterVaul.module.css'

type FilterVaulProps = {
  setOpen: (value: boolean) => void
}

export function FilterVaul({ setOpen }: FilterVaulProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const chainId = useAppSelector(selectSelectedChainId)
  const selectFilters = useAppSelector(selectReservationsFilters)
  const [activeTypeButton, setActiveTypeButton] =
    useState<TypeFilterButtonEnum | null>(null)
  const [activeFilters, setActiveFilters] = useState<FiltersDto | undefined>(
    selectFilters
  )
  const dispatch = useAppDispatch()

  const {
    data: reservationFilter,
    isLoading: isLoadingReservation,
    isError: isErrorReservation
  } = useFiltersQuery({
    type: 'reservation'
  })

  const {
    data: links,
    isLoading: isLoadingLinks,
    isError: isErrorLinks
  } = useReservationSourcesQuery(addressId ? { addressId } : skipToken)

  const {
    data: guestTags,
    isLoading: isLoadingGuestTags,
    isError: isErrorGuestTags
  } = useGuestTagsQuery(chainId ? { chainId } : skipToken)

  const onImprove = () => {
    dispatch(setReservationsFilters(activeFilters))
    setOpen(false)
  }

  const onClear = () => {
    setActiveFilters(undefined)
  }

  switch (activeTypeButton) {
    case TypeFilterButtonEnum.GuestTag:
      return (
        <FilterCheckboxListVaul
          headerName="Теги гостя"
          options={guestTags?.map((item) => ({
            label: item.tag,
            value: String(item.id)
          }))}
          type={TypeFilterButtonEnum.GuestTag}
          setActiveTypeButton={setActiveTypeButton}
          setActiveFilters={setActiveFilters}
          activeFilters={activeFilters}
        />
      )
    case TypeFilterButtonEnum.Source:
      return (
        <FilterCheckboxListVaul
          headerName="Источник брони"
          options={transformSources(links)}
          type={TypeFilterButtonEnum.Source}
          setActiveTypeButton={setActiveTypeButton}
          setActiveFilters={setActiveFilters}
          activeFilters={activeFilters}
        />
      )
    case TypeFilterButtonEnum.Status:
      return (
        <FilterCheckboxListVaul
          headerName="Статус брони"
          options={reservationFilter?.[0].filterValues}
          type={reservationFilter?.[0].name}
          setActiveTypeButton={setActiveTypeButton}
          setActiveFilters={setActiveFilters}
          activeFilters={activeFilters}
        />
      )
    default:
      return (
        <>
          <VaulHeader>
            <h4 className={css.header}>Фильтр</h4>
          </VaulHeader>
          <VaulBody className={css.body}>
            {isLoadingGuestTags || isErrorGuestTags || !guestTags ? (
              <Skeleton className={css.skeleton__button} />
            ) : (
              <FilterButton
                setActiveTypeButton={setActiveTypeButton}
                type={TypeFilterButtonEnum.GuestTag}
                countActive={
                  activeFilters?.[TypeFilterButtonEnum.GuestTag]?.length
                }
              >
                Теги гостя
              </FilterButton>
            )}
            {isLoadingLinks || isErrorLinks || !links ? (
              <Skeleton className={css.skeleton__button} />
            ) : (
              <FilterButton
                setActiveTypeButton={setActiveTypeButton}
                type={TypeFilterButtonEnum.Source}
                countActive={
                  activeFilters?.[TypeFilterButtonEnum.Source]?.length
                }
              >
                Источник брони
              </FilterButton>
            )}
            {isLoadingReservation ||
            isErrorReservation ||
            !reservationFilter ? (
              <Skeleton className={css.skeleton__button} />
            ) : (
              <FilterButton
                setActiveTypeButton={setActiveTypeButton}
                type={TypeFilterButtonEnum.Status}
                countActive={
                  activeFilters?.[reservationFilter?.[0].name]?.length
                }
              >
                Статус брони
              </FilterButton>
            )}
          </VaulBody>
          <VaulFooter className={css.footer}>
            <Button className={css.button} size="lg" onClick={onClear}>
              Сбросить
              <Icon name="x" size={16} strokeWidth={3} />
            </Button>
            <Button
              className={css.button}
              variant="primary"
              size="lg"
              onClick={onImprove}
            >
              Показать
              <Icon name="arrowRight" size={16} strokeWidth={3} />
            </Button>
          </VaulFooter>
        </>
      )
  }
}
