import { useState } from 'react'

import { AlertDialogTitle } from '@radix-ui/react-alert-dialog'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  changeShowPrompt,
  selectHasUnsentComments,
  selectReservationComments,
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationGuest,
  selectReservationPersonsCount,
  selectReservationStart,
  selectReservationTables,
  selectReservationTags,
  setHasUnsentComments
} from '@/entities/create-update-reservation'
import {
  ReservationCard,
  useCreateReservationMutation
} from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { getTz } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Icon
} from '@/shared/ui'

import css from './GoToConfirmReservationButton.module.css'

export function GoToConfirmReservationButton() {
  const [open, setOpen] = useState(false)
  const [canContinue, setCanContinue] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedGuest = useAppSelector(selectReservationGuest)
  const personsCount = useAppSelector(selectReservationPersonsCount)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedTables = useAppSelector(selectReservationTables)
  const selectedTags = useAppSelector(selectReservationTags)
  const selectedComments = useAppSelector(selectReservationComments)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const hasUnsentComments = useAppSelector(selectHasUnsentComments)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [createReservation, { isLoading }] = useCreateReservationMutation()

  const tz = getTz()
  const isSubmitAvailable =
    selectedGuest &&
    selectedDate &&
    selectedStart &&
    (selectedEnd ?? selectedEnd === null) &&
    (selectedDuration ?? selectedDuration === null) &&
    personsCount &&
    selectedTables.length > 0

  const submitHandler = async () => {
    if (!addressId || !isSubmitAvailable || !tz) return

    try {
      dispatch(changeShowPrompt(false))
      const body: RequestNewReservation = {
        start_date: format(selectedStart, 'yyyy-MM-dd HH:mm'),
        end_date: selectedEnd
          ? format(selectedEnd, 'yyyy-MM-dd HH:mm')
          : undefined,
        persons_count: personsCount,
        places: selectedTables.map((table) => table.id),
        guest: selectedGuest.id,
        source: 'book',
        tags: selectedTags?.map((tag) => tag.id) as number[],
        comments: selectedComments?.map((comment) => comment.text)
      }
      const res = await createReservation({ addressId, body })

      if ('data' in res) {
        if (res.data.comments?.length && res.data.tags?.length) {
          toast.success('Бронь c комментариями и тегами успешно создана !')
        } else if (res.data.comments?.length) {
          toast.success('Бронь c комментариями успешно создана !')
        } else if (res.data.tags?.length) {
          toast.success('Бронь c тегами успешно создана !')
        } else {
          toast.success('Бронь успешно создана !')
        }
      }
      setOpen(false)
      dispatch(setHasUnsentComments(false))
      navigate(-1)
    } catch {
      // do nothing
    }
  }

  if (
    !selectedGuest ||
    !personsCount ||
    !selectedStart ||
    selectedEnd === undefined
  ) {
    return null
  }

  if (hasUnsentComments && !canContinue) {
    return (
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger asChild>
          <Button variant="primary" size="sm" className={css.button_next}>
            Продолжить
            <Icon name="arrowRight" size={20} />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent
          className={css.alert}
          onClose={() => setOpen(false)}
        >
          <AlertDialogHeader>
            <AlertDialogTitle>Продолжить без комментария ?</AlertDialogTitle>
            <AlertDialogDescription>
              Вы уверены, что хотите продолжить без отправки комментария ?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction asChild>
              <Button
                onClick={() => {
                  setCanContinue(true)

                  setTimeout(() => {
                    setOpen(true)
                  }, 300)
                }}
              >
                Продолжить
              </Button>
            </AlertDialogAction>
            <AlertDialogCancel>Отмена</AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  const openChangeHandler = (isOpen: boolean) => {
    if (!isOpen) setCanContinue(false)
    setOpen(isOpen)
  }

  return (
    <Dialog open={open} onOpenChange={openChangeHandler}>
      <DialogTrigger asChild>
        <Button variant="primary" size="sm" className={css.button_next}>
          Продолжить
          <Icon name="arrowRight" size={20} />
        </Button>
      </DialogTrigger>
      <DialogContent className={css.content} onClose={() => setOpen(false)}>
        <h1 className={css.title}>Подтвердите параметры брони</h1>
        <ReservationCard
          guest={selectedGuest}
          personsCount={personsCount}
          startDate={selectedStart}
          mainTableNumber={selectedTables
            .map((table) => table.item_number)
            .join(', ')}
          source="book"
          link={null}
          tags={selectedTags ?? []}
          endDate={selectedEnd}
        />
        <Button
          variant="primary"
          size="lg"
          className={css.button_confirm}
          onClick={submitHandler}
          isLoading={isLoading}
          disabled={!isSubmitAvailable || isLoading}
        >
          Забронировать
        </Button>
      </DialogContent>
    </Dialog>
  )
}
