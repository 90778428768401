import { baseApi } from '@/shared/api'

import { transformFilters } from '../lib/transformFilters'

export const filtersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    filters: build.query<OldFilter[], FiltersParams>({
      query: (params) => ({
        url: '/filters/',
        params
      }),
      transformResponse: (response: ResponseFiltersData): OldFilter[] =>
        transformFilters(response)
    })
  })
})

export const { useFiltersQuery } = filtersApi
