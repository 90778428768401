import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'

import css from './Dot.module.css'

type DotProps = ComponentPropsWithoutRef<'div'> & {
  size?: number
}

export function Dot({ size = 4, className }: DotProps) {
  return <div style={{ height: size }} className={cx(css.dot, className)} />
}
