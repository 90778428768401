import { type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { Button, Icon } from '@/shared/ui'

import { type TypeFilterButtonEnum } from '../../lib/const'

import css from './FilterButton.module.css'

type FilterButtonProps = PropsWithChildren & {
  setActiveTypeButton: (value: TypeFilterButtonEnum | null) => void
  type: TypeFilterButtonEnum
  countActive?: number
}

export function FilterButton({
  setActiveTypeButton,
  type,
  countActive,
  children
}: FilterButtonProps) {
  return (
    <Button
      size="lg"
      className={cx(css.button, {
        [css.button_active]: countActive
      })}
      contentClassName={css.button__content}
      onClick={() => setActiveTypeButton(type)}
    >
      {children}
      <div
        className={cx(css.button__info, {
          [css.button__info_active]: countActive
        })}
      >
        {countActive && <span>{countActive}</span>}
        <Icon name="chevronRight" />
      </div>
    </Button>
  )
}
