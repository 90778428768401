import { type ComponentPropsWithoutRef } from 'react'

import { getColorByBgColor } from '@/shared/lib'

import { Badge, type BadgeStyleProps } from '../Badge'

type TagProps = ComponentPropsWithoutRef<'div'> &
  BadgeStyleProps & {
    tag: Tag
  }

export default function Tag({ className, tag, size = 'lg' }: TagProps) {
  const { tag: name, tag_color } = tag

  return (
    <Badge
      className={className}
      style={{
        backgroundColor: tag_color,
        color: getColorByBgColor(tag_color)
      }}
      size={size}
    >
      {name}
    </Badge>
  )
}
