import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'

import { formatDateLabel, PATHS } from '@/shared/lib'
import { Button, Icon } from '@/shared/ui'

import css from './RequestConfirmSuccessScreen.module.css'

type RequestConfirmSuccessScreenProps = {
  reservation: Reservation
}

export default function RequestConfirmSuccessScreen({
  reservation
}: RequestConfirmSuccessScreenProps) {
  const { id, start_date, end_date } = reservation

  const formattedDate = formatDateLabel(parseISO(String(start_date)))
  const formattedStart = format(parseISO(String(start_date)), 'HH:mm')
  const formattedEnd = end_date
    ? format(parseISO(String(end_date)), 'HH:mm')
    : 'По ситуации'

  return (
    <div className={css.success}>
      <div className={css.success__container}>
        <div className={css.success__check}>
          <Icon name="check" size={44} />
        </div>
        <div role="heading" aria-level={2} className={css.success__title}>
          Заявка на бронь успешно подтверждена
        </div>
        <p
          className={css.success__text}
        >{`${formattedDate}, ${formattedStart} - ${formattedEnd}`}</p>
        <Button as={Link} to={`${PATHS.reservation}/${id}`} variant="primary">
          Перейти к брони
        </Button>
      </div>
    </div>
  )
}
