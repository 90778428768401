import { getHours, getMinutes, setHours, setMinutes } from 'date-fns'

export const updateHoursAndMinutes = (
  dateToUpdate: Date,
  dateToGetHoursAnsMin: Date
): Date => {
  const hours = getHours(dateToGetHoursAnsMin)
  const min = getMinutes(dateToGetHoursAnsMin)
  return setMinutes(setHours(dateToUpdate, hours), min)
}
