import { useEffect, useState } from 'react'

import { useDebounce } from '@/shared/hooks'
import { nowWithTimezone } from '@/shared/lib'
import { TimePickerList } from '@/shared/ui'

import css from './MobileTimePicker.module.css'

type MobileTimePickerProps = {
  selectedValue?: Date | null
  setSelectedValue: (value: Date) => void
}

export default function MobileTimePicker({
  selectedValue,
  setSelectedValue
}: MobileTimePickerProps) {
  const [selectedHours, setSelectedHours] = useState(
    selectedValue?.getHours() ?? nowWithTimezone().getHours()
  )
  const [selectedMinutes, setSelectedMinutes] = useState(
    selectedValue?.getMinutes() ?? nowWithTimezone().getMinutes()
  )
  const debouncedHours = useDebounce(String(selectedHours), 200)
  const debouncedMinutes = useDebounce(String(selectedMinutes), 200)

  useEffect(() => {
    const date = nowWithTimezone()
    date.setHours(+debouncedHours, +debouncedMinutes, 0, 0)
    setSelectedValue(date)
  }, [debouncedHours, debouncedMinutes])

  return (
    <div className={css.container}>
      <TimePickerList
        count={24}
        selectedValue={selectedHours}
        selectValue={setSelectedHours}
      />
      <TimePickerList
        count={60}
        selectedValue={selectedMinutes}
        selectValue={setSelectedMinutes}
        isFillLeftZero
      />
    </div>
  )
}
