import { PATHS } from '@/shared/lib'
import { type IconNames } from '@/shared/ui'

type MobileNavItem = {
  title: string
  icon: IconNames
  isMain: boolean
  link?: string
}

export const MENU_ITEMS_MOBILE: MobileNavItem[] = [
  {
    title: 'Главная страница',
    icon: 'home',
    isMain: false,
    link: PATHS.map
  },
  {
    title: 'База гостей',
    icon: 'users',
    isMain: false,
    link: PATHS.guests
  },
  {
    title: 'Создать новую бронь',
    icon: 'plusCircle',
    isMain: true
  },
  {
    title: 'Список броней',
    icon: 'bookmark',
    isMain: false,
    link: PATHS.reservations
  },
  {
    title: 'Профиль',
    icon: 'user',
    isMain: false,
    link: PATHS.profile
  }
]
