export const depositColorsDark = [
  {
    color: '#fff',
    backgroundColor: '#6E3E7F'
  },
  {
    color: '#fff',
    backgroundColor: '#3E7F6C'
  },
  {
    color: '#fff',
    backgroundColor: '#7F3E3E'
  },
  {
    color: '#fff',
    backgroundColor: '#000'
  },
  {
    color: '#fff',
    backgroundColor: '#000'
  }
]

export const getUniqueDepositsWithColors = (
  tables: ConfItem[]
): DepositWithColors[] => {
  const allDeposits = tables
    .map((item) => item.map_item.deposit)
    .filter((item) => item) as Deposit[]

  const uniqueDeposits = [
    ...new Map(allDeposits.map((item) => [item.value, item])).values()
  ]

  if (uniqueDeposits.length === 0) {
    return []
  }

  const deposits = [
    {
      id: 0,
      value: 0,
      type: uniqueDeposits[0].type
    },
    ...uniqueDeposits
  ]

  return deposits.map((item, idx) => ({
    ...item,
    backgroundColor: depositColorsDark?.[idx]?.backgroundColor || '#000',
    color: depositColorsDark?.[idx]?.color || '#fff'
  }))
}
