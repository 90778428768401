import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { parseISO } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

export enum CreateUpdateMailingStepEnum {
  ONE,
  TWO
}

type CreateUpdateMailingSliceState = {
  step: CreateUpdateMailingStepEnum
  date: Date
  message: string
  heading: string
}

const initialState: CreateUpdateMailingSliceState = {
  step: CreateUpdateMailingStepEnum.ONE,
  date: nowWithTimezone(),
  message: '',
  heading: ''
}

export const createUpdateMailingSlice = createSlice({
  name: 'createUpdateMailing',
  initialState,
  reducers: {
    setMailingStep: (
      state,
      { payload }: PayloadAction<CreateUpdateMailingStepEnum>
    ) => {
      state.step = payload
    },
    setMailingDate: (state, { payload }: PayloadAction<Date>) => {
      state.date = payload
    },
    setMailingMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload
    },
    setMailingHeading: (state, { payload }: PayloadAction<string>) => {
      state.heading = payload
    },
    setCreateUpdateMailing: (state, { payload }: PayloadAction<IMailing>) => {
      state.date = parseISO(payload.date)
      state.heading = payload.heading
      state.message = payload.message
    },
    resetCreateUpdateMailing: () => initialState
  }
})

export const {
  setMailingStep,
  setMailingDate,
  setMailingMessage,
  setMailingHeading,
  setCreateUpdateMailing,
  resetCreateUpdateMailing
} = createUpdateMailingSlice.actions
