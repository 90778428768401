import { useState } from 'react'

import {
  Button,
  Icon,
  Vaul,
  VaulBody,
  VaulContent,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import { EditGuestForm } from './EditGuestForm'

import css from './EditGuest.module.css'

type EditGuestProps = {
  guest: BaseGuest
}

export default function EditGuest({ guest }: EditGuestProps) {
  const [open, setOpen] = useState(false)

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button variant="tertiary" size="icon">
          <Icon name="pencil" />
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <VaulHeader>
          <h4 className={css.header}>Карточка гостя</h4>
        </VaulHeader>
        <VaulBody>
          <EditGuestForm guest={guest} setOpen={setOpen} />
        </VaulBody>
      </VaulContent>
    </Vaul>
  )
}
