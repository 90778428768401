import { type ComponentPropsWithoutRef } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'

import { selectHall, selectSelectedHall, useHallsQuery } from '@/entities/halls'
import { selectSelectedAddressId } from '@/entities/session'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Skeleton, Tabs } from '@/shared/ui'

import css from './MapHalls.module.css'

type MapHallsProps = ComponentPropsWithoutRef<'div'> &
  Partial<ComponentPropsWithoutRef<typeof Tabs>> & {
    start?: Date
    end?: Date | null
    classNameTabsContainer?: string
  }

export function MapHalls({
  start,
  end,
  className,
  classNameTabsContainer,
  size,
  variant
}: MapHallsProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedHall = useAppSelector(selectSelectedHall)

  const { data, isLoading } = useHallsQuery(
    addressId
      ? {
          addressId,
          start_date: formatTimeForRequest(start),
          end_date: formatTimeForRequest(end)
        }
      : skipToken
  )

  const setActiveTab = (id: number) => dispatch(selectHall(id))

  const tabsHallData = data?.map((hall) => ({
    id: hall.id,
    label: hall.name,
    count: hall.count
  }))

  if (isLoading || !tabsHallData || !selectedHall) {
    return (
      <Skeleton
        className={cx(css.skeleton, {
          [css.skeleton_small]: size === 'small',
          [css.skeleton_medium]: size === 'medium',
          [css.skeleton_large]: size === 'large' || !size
        })}
      />
    )
  }

  return (
    <div className={cx(css.halls, className)} data-vaul-no-drag>
      <Tabs
        activeTab={selectedHall}
        setActiveTab={setActiveTab}
        tabs={tabsHallData}
        showCount={true}
        size={size}
        variant={variant}
        className={classNameTabsContainer}
      />
    </div>
  )
}
