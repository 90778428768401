import { type ComponentPropsWithoutRef } from 'react'

import { Button, type ButtonStyleProps, Icon } from '@/shared/ui'

type DeleteCommentProps = ButtonStyleProps &
  ComponentPropsWithoutRef<'button'> & {
    commentId: number
    onDelete?: () => void
    deleteCommentHandler: (commentId: number) => Promise<void>
  }

export default function DeleteComment({
  commentId,
  onDelete,
  deleteCommentHandler,
  ...props
}: DeleteCommentProps) {
  const deleteHandler = async () => {
    try {
      await deleteCommentHandler(commentId)
      onDelete?.()
    } catch {
      // do nothing
    }
  }

  return (
    <Button size="sm" variant="transparent" onClick={deleteHandler} {...props}>
      Удалить
      <Icon name="trash" />
    </Button>
  )
}
