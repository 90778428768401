const SOURCE_TEXT = {
  street: 'С улицы',
  widget: 'Виджет',
  book: 'Книга резервов'
}

const getSourceText = (
  source: ReservationSource,
  linkName: null | undefined | string
) => {
  if (source === 'widget' && linkName) {
    return linkName
  }

  return SOURCE_TEXT[source]
}

export { getSourceText }
