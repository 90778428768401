import { type SessionSliceState } from '@/entities/session'

export const getTz = (): string | undefined => {
  const root = localStorage.getItem('persist:root')
  const session = root
    ? (JSON.parse(JSON.parse(root).session) as SessionSliceState)
    : undefined
  const selectedAddressId = session?.selectedAddressId
  const addresses = session?.profile?.addresses ?? []
  const selectedAddress = addresses?.find(
    (val) => val.address === selectedAddressId
  )

  const tz = selectedAddress?.tz

  return tz
}
