import { minutesInHour, minutesToHours } from 'date-fns'

export const formatDuration = (duration: unknown) => {
  if (typeof duration === 'number') {
    const minutes = duration - minutesToHours(duration) * minutesInHour
    return `${minutesToHours(duration)}${
      minutes === 0 ? '' : `:${minutes.toString().padStart(2, '0')}`
    }`
  } else return '-'
}
