import { config } from '@/app/config'
import { BreakpointEnum, useBreakpoint } from '@/shared/hooks'

const { TIMELINE_CELL_WIDTH, SPLIT_HOUR_BY, GROUP_HOUR_COEFFICIENT } = config

const DEFAULT_SHIFT =
  (TIMELINE_CELL_WIDTH * SPLIT_HOUR_BY) / GROUP_HOUR_COEFFICIENT

export function useMinLineShift() {
  const breakpoint = useBreakpoint()
  const isPhone = breakpoint === BreakpointEnum.xs
  const isSmallTablet = breakpoint === BreakpointEnum.sm

  let scrollShift

  if (isSmallTablet) {
    scrollShift = DEFAULT_SHIFT / 2
  } else if (isPhone) {
    scrollShift = DEFAULT_SHIFT / 4
  } else if (breakpoint) {
    scrollShift = DEFAULT_SHIFT
  }
  return scrollShift
}
