import { toast } from 'react-toastify'
import { type ErrorEvent } from 'reconnecting-websocket'

import { config } from '@/app/config'
import { clearSessionData } from '@/entities/session'
import { baseApi, getTz, replaceDateTimezones, ws } from '@/shared/api'
import { GlobalWsEvents } from '@/shared/lib'

import { WAITING_LIST_WS_PATH } from './config'
import { type WaitingListReqParams } from './types'

import { WaitingListEvents } from '../api/waitingListEvents'

const { FEATURE_FLAG_LOGS } = config

export const waitingListEventHandlers = new Map<
  `${WaitingListEvents}`,
  Function
>()

export const waitingListApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    subscribeToWaitingList: builder.query<
      { records: WaitingListRecord[] | undefined },
      WaitingListReqParams
    >({
      queryFn() {
        return { data: { records: undefined } }
      },
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch
        }
      ) {
        await cacheDataLoaded

        const {
          session: { accessToken }
        } = getState() as RootState

        const socket = ws(`${WAITING_LIST_WS_PATH}/${arg.address}/`)

        const tz = getTz()

        try {
          const listener = (e: MessageEvent) => {
            const { type, data, detail } = JSON.parse(e.data)

            if (tz) replaceDateTimezones(data, tz, { withSeconds: true })

            switch (type) {
              case WaitingListEvents.GetList: {
                updateCachedData((draft) => {
                  draft.records = data
                })
                break
              }
              case GlobalWsEvents.Error: {
                if (detail === 'Invalid token') {
                  toast.error('Ошибка авторизации')
                  dispatch(clearSessionData())
                } else {
                  toast.error(detail)
                }
                break
              }
              case WaitingListEvents.CreateRecord: {
                const handler = waitingListEventHandlers.get(
                  WaitingListEvents.CreateRecord
                )
                handler?.()
                break
              }
              case WaitingListEvents.DeleteRecord: {
                const handler = waitingListEventHandlers.get(
                  WaitingListEvents.DeleteRecord
                )
                handler?.()
                break
              }
              case WaitingListEvents.UpdateRecord: {
                const handler = waitingListEventHandlers.get(
                  WaitingListEvents.UpdateRecord
                )
                handler?.()
                break
              }
              case WaitingListEvents.CreateReservation: {
                const handler = waitingListEventHandlers.get(
                  WaitingListEvents.CreateReservation
                )
                handler?.()
                break
              }
            }
          }

          const openListener = () => {
            console.log('Соединение установлено')
            const authMessage = JSON.stringify({
              type: GlobalWsEvents.Auth,
              data: {
                authorization: accessToken
              }
            })
            socket.send(authMessage)
          }

          const closeListener = () => {
            console.log('Соединение закрыто')
          }

          const errorListener = (error: ErrorEvent) => {
            if (FEATURE_FLAG_LOGS) {
              toast.error(JSON.stringify(error, null, 2))
            } else {
              // toast.error(
              //   `Ошибка: ${
              //     error.message
              //       ? error.message
              //       : 'Не удается подключиться к серверу, попробуйте позже или перезагрузите страницу...'
              //   }`
              // )
            }
            console.log(`Ошибка: ${error.message}`)
          }

          socket.addEventListener('open', openListener)
          socket.addEventListener('close', closeListener)
          socket.addEventListener('error', errorListener)
          socket.addEventListener('message', listener)
        } catch {}
        await cacheEntryRemoved
        console.log('cache removed, socket closed')
        socket.close()
      }
    })
  })
})

export const { useSubscribeToWaitingListQuery } = waitingListApi
