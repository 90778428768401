import { type Dispatch, type SetStateAction, useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { useDeleteGuestMutation } from '@/entities/guest'
import { useUpdateGuestWithoutGuestsInvalidationMutation } from '@/entities/guest/api/guestApi'
import { selectSelectedChainId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Icon,
  Input,
  PhoneInput,
  VaulFooter
} from '@/shared/ui'

import {
  type EditGuestFormSchema,
  editGuestFormSchema
} from '../model/editGuestFormSchema'

import css from './EditGuestForm.module.css'

type EditGuestFormProps = {
  guest: BaseGuest
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function EditGuestForm({ guest, setOpen }: EditGuestFormProps) {
  const chainId = useAppSelector(selectSelectedChainId)

  const { id, first_name, last_name, phone } = guest

  const form = useForm<EditGuestFormSchema>({
    resolver: zodResolver(editGuestFormSchema),
    defaultValues: {
      firstName: first_name ?? '',
      lastName: last_name ?? '',
      phone: phone ?? ''
    }
  })

  const [updateGuest, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] =
    useUpdateGuestWithoutGuestsInvalidationMutation()
  const [deleteGuest, { isLoading: isDeleting }] = useDeleteGuestMutation()

  useEffect(() => {
    if (isUpdateSuccess) {
      setOpen(false)
    }
  }, [isUpdateSuccess])

  const submitHandler = ({
    firstName,
    lastName,
    phone
  }: EditGuestFormSchema) => {
    if (!chainId) return

    updateGuest({
      chainId,
      guestId: id,
      body: {
        first_name: firstName,
        last_name: lastName,
        phone: `+${phone}`
      }
    })
  }

  const deleteGuestHandler = () => {
    if (!chainId) return
    deleteGuest({ chainId, guestId: id })
  }

  const isLoading = isUpdating || isDeleting

  return (
    <div className={css.form}>
      <Form {...form}>
        <form
          className={css.form__content}
          onSubmit={form.handleSubmit(submitHandler)}
          noValidate
        >
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Введите имя"
                    label="Имя"
                    className={css.input}
                    classNameLabel={css.input__label}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Введите фамилию"
                    label="Фамилия"
                    className={css.input}
                    classNameLabel={css.input__label}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PhoneInput
                    value={field.value}
                    onValueChange={field.onChange}
                    placeholder="Введите телефон"
                    label="Телефон"
                    className={css.input}
                    classNameLabel={css.input__label}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Button
            type="button"
            size="lg"
            variant="transparentRed"
            className={css.button_delete}
            disabled={isLoading}
            isLoading={isDeleting}
            onClick={deleteGuestHandler}
          >
            <Icon name="trash" />
            Удалить гостя
          </Button>
          <VaulFooter>
            <Button
              type="submit"
              size="lg"
              variant="primary"
              className={css.button}
              disabled={isLoading}
              isLoading={isUpdating}
            >
              Сохранить
            </Button>
          </VaulFooter>
        </form>
      </Form>
    </div>
  )
}
