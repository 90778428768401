import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction
} from 'react'

import { ReservationCard } from '@/entities/reservation'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui'

import css from './ReservationPopover.module.css'

type ReservationPopoverProps = PropsWithChildren & {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  slot: TimeSlot
}

export default function ReservationPopover({
  open,
  setOpen,
  slot,
  children
}: ReservationPopoverProps) {
  return (
    <Popover
      modal
      open={open}
      onOpenChange={(open) => slot.booked && setOpen(open)}
    >
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        side="right"
        variant="glass"
        className={css.popover}
      >
        <ReservationCard
          id={+slot.id}
          guest={slot.guest!}
          personsCount={slot.persons_count!}
          startDate={slot.start_date}
          mainTableNumber={slot.main_table_number!}
          source={slot.source}
          link={slot.link}
          tags={slot.tags}
          status={slot.status}
          endDate={slot.end_date}
          withLink
          isWhite
        />
      </PopoverContent>
    </Popover>
  )
}
