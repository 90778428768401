import { add } from 'date-fns'
import { useStopwatch } from 'react-timer-hook'

import { getCountUpSec, secondsToMinAndSec } from '@/shared/lib'
import { Badge, Icon } from '@/shared/ui'

type TimerUpProps = {
  startTime: Date
}

export function TimerUp({ startTime }: TimerUpProps) {
  const now = new Date()
  const passedSec = getCountUpSec(startTime)
  const offsetTimestamp = add(now, { seconds: passedSec })
  const { totalSeconds } = useStopwatch({
    autoStart: true,
    offsetTimestamp
  })

  const [min, sec] = secondsToMinAndSec(totalSeconds)

  return (
    <Badge size="xs" variant={totalSeconds > 600 ? 'red' : 'default'}>
      {`Ожидает - ${min}:${sec.toString().padStart(2, '0')}`}
      <Icon name="hourglass" size={12} />
    </Badge>
  )
}
