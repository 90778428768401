export const dayIntervalsHours = {
  morning: {
    start: 6,
    end: 10
  },
  afternoon: {
    start: 11,
    end: 15
  },
  evening: {
    start: 16,
    end: 20
  },
  night: {
    start: 21,
    end: 1
  }
}

export const dayIntervalsHoursEndTime = {
  one: {
    start: 0,
    end: 4
  },
  two: {
    start: 5,
    end: 9
  },
  three: {
    start: 10,
    end: 14
  },
  four: {
    start: 15,
    end: 19
  },
  five: {
    start: 20,
    end: 23
  }
}
