import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithReauth } from './baseQueryWithReauth'
import { authTagTypes, tagTypes } from './tags'

export const baseApi = createApi({
  tagTypes: [...Object.values(tagTypes), ...Object.values(authTagTypes)],
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({})
})
