import { parseISOWithoutTimezone } from '@/shared/lib'

export const mapTimelineTables = (tables: ResponseTimelineTable[]): Table[] => {
  return tables.map((table) => ({
    ...table,
    reservations: table.reservations
      .filter(
        (res) => res.status !== 'unconfirmed' && res.status !== 'canceled'
      )
      .map((res) => ({
        ...res,
        start_date: parseISOWithoutTimezone(res.start_date),
        end_date: parseISOWithoutTimezone(res.end_date)
      }))
  }))
}
