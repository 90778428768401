import { createSelector } from '@reduxjs/toolkit'

export const selectReservationDate = (state: RootState) =>
  state.createUpdateReservation.date

export const selectReservationPersonsCount = (state: RootState) =>
  state.createUpdateReservation.personsCount

export const selectReservationStart = (state: RootState) =>
  state.createUpdateReservation.start

export const selectReservationEnd = (state: RootState) =>
  state.createUpdateReservation.end

export const selectReservation = (state: RootState) =>
  state.createUpdateReservation

export const selectReservationStep = (state: RootState) =>
  state.createUpdateReservation.step

export const selectMobileReservationStep = (state: RootState) =>
  state.createUpdateReservation.mobileStep

export const selectMobileReservationPickersStep = (state: RootState) =>
  state.createUpdateReservation.mobilePickersStep

export const selectReservationDuration = (state: RootState) =>
  state.createUpdateReservation.duration

export const selectReservationTags = (state: RootState) =>
  state.createUpdateReservation.tags

export const selectReservationComments = (state: RootState) =>
  state.createUpdateReservation.comments

export const selectReservationSkipStep = (state: RootState) =>
  state.createUpdateReservation.skipStep

export const selectReservationMode = (state: RootState) =>
  state.createUpdateReservation.mode

export const selectReservationTables = (state: RootState) =>
  state.createUpdateReservation.tables

export const selectReservationTableIds = (state: RootState) =>
  state.createUpdateReservation.tableIds

export const selectReservationHall = (state: RootState) =>
  state.createUpdateReservation.hall

export const selectReservationGuest = (state: RootState) =>
  state.createUpdateReservation.guest

export const selectReservationIsNewGuest = (state: RootState) =>
  state.createUpdateReservation.isNewGuest

export const selectIsShowPrompt = (state: RootState) =>
  state.createUpdateReservation.isShowPrompt

export const selectSelectedMaxPersonsCount = createSelector(
  (state: RootState) => state.createUpdateReservation.tables,
  (tables) => {
    return tables.reduce((acc, cur) => acc + cur.item.max_persons_count, 0)
  }
)

export const selectHasUnsentComments = (state: RootState) =>
  state.createUpdateReservation.hasUnsentComments
