import { CreateGuest as MobileCreate } from './create-mobile'
import { DeleteButton as Delete } from './delete'
import { GuestForm as Form } from './edit'
import { EditGuest as MobileEdit } from './edit-mobile'
import { MobileFilters } from './mobile-filters'

export const Guest = {
  Delete,
  Form,
  MobileCreate,
  MobileEdit,
  MobileFilters
}
