import { type PropsWithChildren, useState } from 'react'

import { useDeleteReservationRequestMutation } from '@/entities/reservation-request'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps
} from '@/shared/ui'

type DeleteButtonProps = PropsWithChildren &
  ButtonStyleProps & {
    id: number
    className?: string
  }

export default function DeleteButton({
  id,
  className,
  children,
  ...props
}: DeleteButtonProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const [open, setOpen] = useState(false)

  const [deleteReservationReq, { isLoading }] =
    useDeleteReservationRequestMutation()

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  const deleteReservationReqHandler = () => {
    if (!addressId) return
    deleteReservationReq({ addressId, reservationReqId: id })
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button className={className} variant="transparentRed" {...props}>
          {children}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;заявка удалится из списка заявок,
            это действие будет нельзя отменить
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              onClick={deleteReservationReqHandler}
            >
              Удалить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
