import { cx } from 'class-variance-authority'

import {
  MobileMapPagesEnum,
  selectMobileMapPage,
  setMobileMapPage
} from '@/entities/map'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

import MobileMapHalls from '../MobileMapHalls/MobileMapHalls'

import css from './MobileMapFooter.module.css'

export default function MobileMapFooter() {
  const dispatch = useAppDispatch()
  const mobileMapPage = useAppSelector(selectMobileMapPage)

  return (
    <div className={css.footer}>
      <div className={css.pagination}>
        <Button
          variant="transparent"
          className={cx(css.button, {
            [css.button_active]: mobileMapPage === MobileMapPagesEnum.MAP
          })}
          onClick={() => dispatch(setMobileMapPage(MobileMapPagesEnum.MAP))}
        >
          <Icon name="map" size={20} />
        </Button>
        <Button
          variant="transparent"
          className={cx(css.button, {
            [css.button_active]: mobileMapPage === MobileMapPagesEnum.LIST
          })}
          onClick={() => dispatch(setMobileMapPage(MobileMapPagesEnum.LIST))}
        >
          <Icon name="list" size={20} />
        </Button>
        <Button
          variant="transparent"
          className={cx(css.button, {
            [css.button_active]: mobileMapPage === MobileMapPagesEnum.WAITING
          })}
          disabled
          onClick={() => dispatch(setMobileMapPage(MobileMapPagesEnum.WAITING))}
        >
          <Icon name="clockSnooze" size={20} />
        </Button>
      </div>
      <MobileMapHalls isGray withCount={false} />
    </div>
  )
}
