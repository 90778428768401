import { cx } from 'class-variance-authority'

import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Badge, Button, Icon } from '@/shared/ui'

import { depositColorsDark } from '../../lib'
import { selectShowDeposits } from '../../model/selectors'
import { setShowDeposits } from '../../model/slice'

import css from './TablesInfo.module.css'

const depositTypeLabels = {
  for_table: 'стол',
  for_person: 'чел',
  for_hour: 'час'
}

export default function TablesInfo({
  deposits
}: {
  deposits: DepositWithColors[]
}) {
  const dispatch = useAppDispatch()
  const showDeposits = useAppSelector(selectShowDeposits)

  const toggleShowDepositsHandler = () => {
    dispatch(setShowDeposits(!showDeposits))
  }

  return (
    <div className={cx(css.info, { [css.info_hidden]: !showDeposits })}>
      {showDeposits ? (
        <Button
          size="icon"
          variant="white"
          className={css.info__hide}
          onClick={toggleShowDepositsHandler}
        >
          <Icon name="eyeOff" />
        </Button>
      ) : (
        <Button
          size="xxs"
          variant="white"
          className={css.info__show}
          onClick={toggleShowDepositsHandler}
        >
          <Icon name="eye" />
          Депозиты
        </Button>
      )}
      {showDeposits && (
        <div className={css.info__badges}>
          {deposits.map(({ id, value }, idx) => (
            <Badge
              key={id}
              size="xs"
              style={{
                backgroundColor:
                  depositColorsDark?.[idx]?.backgroundColor ?? '#000',
                color: depositColorsDark?.[idx]?.color ?? '#fff'
              }}
            >
              {value}р./{depositTypeLabels[deposits[0].type]}
            </Badge>
          ))}

          <Badge size="xs" className={css.booked}>
            Забронировано
          </Badge>
          <Badge size="xs" className={css.selected}>
            Выбрано вами
          </Badge>
        </div>
      )}
    </div>
  )
}
