import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'

import { Badge, type BadgeStyleProps, Tag } from '@/shared/ui'

import css from './GuestTags.module.css'

type GuestTagsProps = BadgeStyleProps &
  ComponentPropsWithoutRef<'div'> & {
    showCount?: number
    tags?: Tag[]
    onlyFirstOne?: boolean
  }

export default function GuestTags({
  className,
  variant,
  tags = [],
  size = 'xxs',
  showCount = 2,
  onlyFirstOne = false
}: GuestTagsProps) {
  const visibleTags = tags.slice(0, showCount)
  const restTagsCount = tags.slice(showCount).length

  return (
    <div className={cx(css.tags, className)}>
      {visibleTags.map((tag) => (
        <Tag key={tag.id} tag={tag} size={size} />
      ))}
      {restTagsCount >= 1 && !onlyFirstOne && (
        <Badge variant={variant} size={size}>
          +{restTagsCount}
        </Badge>
      )}
    </div>
  )
}
