enum TimelineOrderingEnum {
  StartDateASC = 'start_date',
  StartDateDESC = '-start_date',
  StartDateDefault = 'default_start_date',
  TablesASC = 'tables',
  TablesDESC = '-tables',
  TablesDefault = 'default_tables',
  BookedASC = 'booked',
  BookedDESC = '-booked',
  BookedDefault = 'default_booked'
}

export { TimelineOrderingEnum }
