import { baseApi, PROFILE_DATA_TAG, SESSION_TAG } from '@/shared/api'

import { type RequestLoginBody, type Session } from './types'

import { transformProfileAddresses } from '../lib/transformProfileAddresses'

export const sessionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<Session, RequestLoginBody>({
      query: (body) => ({
        url: '/admin/auth/staff-login/',
        method: 'POST',
        body
      }),
      invalidatesTags: [SESSION_TAG]
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/admin/auth/staff-logout/',
        method: 'POST'
      }),
      invalidatesTags: [SESSION_TAG]
    }),
    profileData: build.query<ProfileData, void>({
      query: () => ({
        url: '/staff/profile/'
      }),
      providesTags: [PROFILE_DATA_TAG],
      transformResponse: (response: ProfileData): ProfileData =>
        transformProfileAddresses(response)
    })
  })
})

export const { useLoginMutation, useLogoutMutation, useProfileDataQuery } =
  sessionApi
