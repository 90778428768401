export const transformFilters = (data: ResponseFiltersData): OldFilter[] => {
  return data.map((filter) => ({
    id: filter.id,
    name: filter.slug,
    title: filter.title,
    description: filter.description,
    defaultValue: filter.default_values,
    filterValues: filter.filter_values,
    selectedDescription: filter.selected_description,
    type: filter.type,
    strategy: 'multi'
  }))
}
