import {
  type MouseEvent,
  type PropsWithChildren,
  useEffect,
  useState
} from 'react'

import { differenceInMinutes } from 'date-fns'

import { addReservation } from '@/entities/create-update-reservation'
import { unsetTakeTable } from '@/entities/map'
import {
  resetReservationByIdState,
  selectReservation
} from '@/entities/reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  type ButtonStyleProps,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { MobileReservationChange } from '@/widgets/reservation'
import { MobileChangeReservationTableVaulContent } from '@/widgets/reservation/MobileReservationChangeTable/MobileChangeReservationTableVaulContent'

type MobileTransferProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number
    className?: string
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation | Visit>
    ) => void
  }

export function MobileTransfer({
  children,
  reservationId,
  size,
  className,
  updateReservationsList,
  ...props
}: MobileTransferProps) {
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)
  const reservation = useAppSelector(selectReservation)
  const dispatch = useAppDispatch()

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  useEffect(() => {
    if (!open) {
      setStep(1)
    }
  }, [open])

  useEffect(() => {
    if (reservation) {
      dispatch(resetReservationByIdState())
      dispatch(unsetTakeTable())
      dispatch(
        addReservation({
          start_date: reservation.start_date,
          end_date: reservation.end_date,
          guest: reservation.guest,
          hall_id: reservation.hall_id,
          persons_count: reservation.persons_count,
          places: [],
          duration: differenceInMinutes(
            reservation.end_date,
            reservation.start_date
          ),
          status: reservation.status
        })
      )
    }
  }, [])

  const renderContent = () => {
    switch (step) {
      case 1:
        return <MobileReservationChange onConfirm={() => setStep(2)} />
      case 2:
        return (
          <MobileChangeReservationTableVaulContent
            reservationId={reservationId}
            setOpen={setOpen}
            updateReservationsList={updateReservationsList}
          />
        )
    }
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button
          size={size}
          className={className}
          {...props}
          onClick={toggleDialogHandler}
        >
          {children}
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>{renderContent()}</VaulContent>
    </Vaul>
  )
}
