import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type SearchGuestSliceState = {
  step: SearchGuestStep
  phone: string
  search: string
}

export enum SearchGuestSteps {
  one = 1,
  two = 2
}

const initialState: SearchGuestSliceState = {
  step: 1,
  phone: '',
  search: ''
}

export const searchGuestSlice = createSlice({
  name: 'searchGuest',
  initialState,
  reducers: {
    setSearchGuestPhone(state, { payload }: PayloadAction<string>) {
      state.phone = payload
    },
    setSearchGuestValue(state, { payload }: PayloadAction<string>) {
      state.search = payload
    },
    setSearchGuestStep(state, { payload }: PayloadAction<SearchGuestStep>) {
      state.step = payload
    },
    resetSearchGuests: () => initialState
  }
})

export const {
  setSearchGuestStep,
  setSearchGuestValue,
  setSearchGuestPhone,
  resetSearchGuests
} = searchGuestSlice.actions
