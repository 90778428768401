import { memo } from 'react'

import { cx } from 'class-variance-authority'

import { selectSelectedTimelineTable, selectTables } from '@/entities/timeline'
import { useAppSelector } from '@/shared/model'
import { Icon } from '@/shared/ui'

import css from './TablesInfoList.module.css'

export const TablesInfoList = memo(function TablesInfoList() {
  const tables = useAppSelector(selectTables)
  const selectedTimelineTable = useAppSelector(selectSelectedTimelineTable)

  return (
    <>
      {tables.map((table) => (
        <div className={css.root} key={table.id}>
          <span
            className={cx(css.root__number, {
              [css.root__number_selected]:
                selectedTimelineTable && selectedTimelineTable === table.id
            })}
          >
            №{table.item_number}
          </span>
          <span className={css.root__amount}>
            {`${table.min_persons_count}-${table.max_persons_count}`}
            <Icon name="users" />
          </span>
        </div>
      ))}
    </>
  )
})
