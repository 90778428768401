import { skipToken } from '@reduxjs/toolkit/dist/query'

import {
  useDeleteReservationCommentMutation,
  useReservationCommentsQuery,
  useSendReservationCommentMutation,
  useUpdateReservationCommentMutation
} from '@/entities/comments'
import { useSetReservationTagsMutation } from '@/entities/reservation/api/reservationApi'
import { selectSelectedAddressId } from '@/entities/session'
import {
  useCreateReservationTagMutation,
  useReservationTagsQuery
} from '@/entities/tags'
import { useToastSuccessHandling } from '@/shared/hooks'
import { useAppSelector } from '@/shared/model'

import MobileTagsAndComments from '../MobileTagsAndComments'

type MobileReservationTagsAndCommentsProps = {
  tags: SimpleTag[]
  reservationId: number
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

export default function MobileReservationTagsAndComments({
  tags,
  reservationId,
  updateReservationsList
}: MobileReservationTagsAndCommentsProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments
  } = useReservationCommentsQuery(
    { reservationId: +reservationId },
    { refetchOnMountOrArgChange: true }
  )

  const {
    data: tagsKit,
    isLoading: isLoadingTagsKit,
    isError: isErrorTagsKit
  } = useReservationTagsQuery(addressId ? { addressId } : skipToken, {
    refetchOnMountOrArgChange: true
  })

  const [
    deleteComment,
    { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }
  ] = useDeleteReservationCommentMutation()
  useToastSuccessHandling(isDeleteSuccess, 'Комментарий успешно удален')

  const [sendComment, { isLoading: isSendLoading, isSuccess: isSendSuccess }] =
    useSendReservationCommentMutation()
  useToastSuccessHandling(isSendSuccess, 'Комментарий успешно отправлен')

  const [
    updateComment,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess }
  ] = useUpdateReservationCommentMutation()
  useToastSuccessHandling(isUpdateSuccess, 'Комментарий успешно обновлен')

  const [
    setReservationTags,
    { isLoading: isLoadingSetTags, isSuccess: isSetTagsSuccess }
  ] = useSetReservationTagsMutation()
  useToastSuccessHandling(isSetTagsSuccess, 'Теги успешно обновлены')
  const [
    createTag,
    { isLoading: isLoadingCreateTag, isSuccess: isCreateTagSuccess }
  ] = useCreateReservationTagMutation()
  useToastSuccessHandling(isCreateTagSuccess, 'Тег успешно создан')

  const deleteCommentHandle = async (id: number) => {
    if (!comments) return

    await deleteComment({
      reservationId,
      commentId: id
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length - 1
    })
  }

  const updateCommentHandle = async (id: number, text: string) => {
    await updateComment({
      reservationId,
      commentId: id,
      body: {
        text
      }
    })
  }

  const createCommentHandle = async (text: string) => {
    if (!comments) return

    await sendComment({
      reservationId,
      body: {
        text
      }
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length + 1
    })
  }

  const setTagsHandle = async (tags: SimpleTag[]) => {
    if (!addressId) return

    await setReservationTags({
      addressId,
      reservationId,
      body: {
        tags
      }
    })
    updateReservationsList?.(reservationId, false, { tags })
  }

  const createTagKitHandle = async (name: string) => {
    if (!addressId) return

    await createTag({ addressId, body: { name } })
  }

  return (
    <MobileTagsAndComments
      tags={tags}
      tagsKit={tagsKit}
      isErrorTagsKit={isErrorTagsKit}
      isLoadingTagsKit={isLoadingTagsKit}
      isLoadingSetTags={isLoadingSetTags}
      isLoadingCreateTag={isLoadingCreateTag}
      setTagsHandle={setTagsHandle}
      createTagKitHandle={createTagKitHandle}
      comments={comments}
      isLoadingComments={isLoadingComments}
      isErrorComments={isErrorComments}
      deleteCommentHandle={deleteCommentHandle}
      updateCommentHandle={updateCommentHandle}
      createCommentHandle={createCommentHandle}
      isDeleteLoading={isDeleteLoading}
      isSendLoading={isSendLoading}
      isUpdateLoading={isUpdateLoading}
    />
  )
}
