import { type PropsWithChildren } from 'react'

import { cx } from 'class-variance-authority'

import { Button, Icon } from '@/shared/ui'

import { SortVariantsEnum } from '../../lib/const'

import css from './SortButton.module.css'

type SortButtonProps = PropsWithChildren & {
  sort: SortVariantsEnum
  setSort: (value: SortVariantsEnum) => void
  upContent: string
  downContent: string
}

export function SortButton({
  sort,
  setSort,
  upContent,
  downContent,
  children
}: SortButtonProps) {
  const onClick = (
    sort: SortVariantsEnum,
    setSort: (value: SortVariantsEnum) => void
  ) => {
    switch (sort) {
      case SortVariantsEnum.NONE:
        setSort(SortVariantsEnum.DOWN)
        break
      case SortVariantsEnum.DOWN:
        setSort(SortVariantsEnum.UP)
        break
      case SortVariantsEnum.UP:
        setSort(SortVariantsEnum.NONE)
        break
    }
  }

  const getIconName = (sort: SortVariantsEnum) => {
    switch (sort) {
      case SortVariantsEnum.UP:
      case SortVariantsEnum.DOWN: {
        return 'arrowUp'
      }
      case SortVariantsEnum.NONE:
        return 'minus'
    }
  }

  return (
    <Button
      size="lg"
      contentClassName={css.button__content}
      onClick={() => onClick(sort, setSort)}
    >
      {children}
      <div
        className={cx(css.button__info, {
          [css.button__info_active]: sort !== SortVariantsEnum.NONE
        })}
      >
        {sort === SortVariantsEnum.DOWN && <span>{downContent}</span>}
        {sort === SortVariantsEnum.UP && <span>{upContent}</span>}
        <Icon
          name={getIconName(sort)}
          className={cx(css.button__icon, {
            [css['button__icon_arrow-down']]: sort === SortVariantsEnum.DOWN
          })}
        />
      </div>
    </Button>
  )
}
