import { useEffect, useState } from 'react'

const useIsTouchScreen = () => {
  const [isTouchScreen, setIsTouchScreen] = useState(false)

  const onTouchStart = () => {
    !isTouchScreen && setIsTouchScreen(true)
  }

  const onMouseEnter = () => {
    isTouchScreen && setIsTouchScreen(false)
  }

  useEffect(() => {
    document.addEventListener('touchstart', onTouchStart)
    document.addEventListener('mouseenter', onMouseEnter)

    return () => {
      document.removeEventListener('touchstart', onTouchStart)
      document.removeEventListener('mouseenter', onMouseEnter)
    }
  }, [isTouchScreen])

  return { isTouchScreen }
}

export default useIsTouchScreen
