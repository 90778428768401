import { useMemo, useState } from 'react'

import 'swiper/css'

import { A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { config } from '@/app/config'
import { useUpdateComponent } from '@/shared/hooks'

import { SwiperPointer } from './SwiperPointer'
import { SwiperStep } from './SwiperStep'

import { generateSteps } from '../lib'

import css from './TimeSwiper.module.css'
const { WORKING_HOURS_START, WORKING_HOURS_END } = config

type TimeSwiperProps = {
  selectedDate: Date
  setActiveTime: (date: Date) => void
}

export default function TimeSwiper({
  selectedDate,
  setActiveTime
}: TimeSwiperProps) {
  useUpdateComponent(30)
  const [isActiveLong, setIsActiveLong] = useState(false)
  const screenWidth = window.visualViewport?.width
  const stepsPerView = screenWidth ? screenWidth / 18 : 21

  const timeSteps = useMemo(
    () => generateSteps(WORKING_HOURS_START, WORKING_HOURS_END, selectedDate),
    [WORKING_HOURS_START, WORKING_HOURS_END, selectedDate]
  )

  return (
    <div className={css.swiper}>
      <SwiperPointer isLong={isActiveLong} />
      <Swiper
        className={css.swiper__content}
        modules={[A11y]}
        spaceBetween={0}
        centeredSlides
        slidesPerView={stepsPerView}
      >
        {timeSteps.map((step) => (
          <SwiperSlide
            key={step.toISOString()}
            data-vaul-no-drag
            className={css.swiper__slide}
          >
            {({ isActive }) => (
              <SwiperStep
                isActive={isActive}
                step={step}
                timeSteps={timeSteps}
                setIsActiveLong={setIsActiveLong}
                setActiveTime={setActiveTime}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
