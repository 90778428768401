/* eslint-disable @typescript-eslint/no-explicit-any */
import { type RefObject, useEffect, useRef } from 'react'

const useClickOutside = (cb: Function): RefObject<any> => {
  const ref = useRef<HTMLElement>(null)
  const refCb = useRef<Function>(cb)

  useEffect(() => {
    const handler = (e: any) => {
      if (!ref.current?.contains(e.target)) {
        refCb.current()
      }
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [])

  return ref
}

export default useClickOutside
