import {
  ReservationSortEnum,
  selectReservationsSortType,
  setReservationsSortType
} from '@/entities/reservations'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Tab, TabsGroup, TabsProvider } from '@/shared/ui'

import css from './MobileReservationsHeader.module.css'

const tabs = [
  { id: ReservationSortEnum.closest, label: 'Предстоящие' },
  { id: ReservationSortEnum.inProgress, label: 'Текущие' },
  { id: ReservationSortEnum.default, label: 'Все' },
  { id: ReservationSortEnum.requests, label: 'Заявки' }
]

export default function MobileReservationsHeader() {
  const dispatch = useAppDispatch()
  const sortType = useAppSelector(selectReservationsSortType)

  const valueChangeHandler = (value: string) => {
    dispatch(setReservationsSortType(value as ReservationSortEnum))
  }

  return (
    <TabsProvider value={sortType} onValueChange={valueChangeHandler}>
      <TabsGroup className={css.list} variant="borderless">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            variant="transparent"
            className={css.tab}
            value={tab.id.toString()}
          >
            {tab.label}
          </Tab>
        ))}
      </TabsGroup>
    </TabsProvider>
  )
}
