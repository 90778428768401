import { type Dispatch, type SetStateAction, useEffect } from 'react'

import {
  MobileReservationSteps,
  resetReservation,
  selectMobileReservationStep
} from '@/entities/create-update-reservation'
import { resetSearchGuests } from '@/entities/search-guests'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import MobileReservationPickTableStep from './pick-table'
import MobileReservationInfoStep from './reservation-info'
import { MobileReservationPickersStep } from './reservation-pickers'
import { MobileGuestsSearchStep } from './search-guests'

type MobileNewReservationVaulProcessProps = {
  setOpen?: Dispatch<SetStateAction<boolean>>
}

export default function MobileNewReservationVaulProcess({
  setOpen
}: MobileNewReservationVaulProcessProps) {
  const dispatch = useAppDispatch()
  const step = useAppSelector(selectMobileReservationStep)

  useEffect(() => {
    return () => {
      dispatch(resetSearchGuests())
      dispatch(resetReservation())
    }
  }, [])

  switch (step) {
    case MobileReservationSteps.ONE:
      return <MobileGuestsSearchStep />
    case MobileReservationSteps.TWO:
      return <MobileReservationPickersStep />
    case MobileReservationSteps.THREE:
      return <MobileReservationPickTableStep />
    case MobileReservationSteps.FOUR:
      return <MobileReservationInfoStep setOpen={setOpen} />
    default:
  }
}
