import { baseApi, HALLS_TAG } from '@/shared/api'

import { mapHalls } from '../lib'

export const hallsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    halls: builder.query<Hall[], HallParams>({
      query: ({ addressId, limit, offset, start_date, end_date, persons }) => ({
        url: `/book/addresses/${addressId}/halls/`,
        params: {
          limit,
          offset,
          start_date,
          end_date,
          persons
        }
      }),
      providesTags: [HALLS_TAG],
      transformResponse: (response: RawHall[]): Hall[] => mapHalls(response)
    }),
    // TODO change this request later
    hallsStats: builder.query<IHallStats, HallStatsParams>({
      query: ({ address, hallId, start_date, end_date }) => ({
        url: `/staff/addresses/${address}/halls/${hallId}/stats/`,
        params: {
          start_date,
          end_date
        }
      })
    })
  })
})

export const { useHallsQuery, useHallsStatsQuery } = hallsApi
