import { useState } from 'react'

import {
  ReservationSteps,
  selectReservationIsNewGuest,
  selectSelectedMaxPersonsCount,
  setReservationStep,
  useNewReservationGuestAvailable
} from '@/entities/create-update-reservation'
import { SearchGuestSteps, setSearchGuestStep } from '@/entities/search-guests'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps,
  Icon
} from '@/shared/ui'

import css from './GoToPickGuestReservationButton.module.css'

type GoToPickGuestReservationButtonProps = ButtonStyleProps & {
  onNextStep?: () => void
}

export function GoToPickGuestReservationButton({
  onNextStep,
  ...props
}: GoToPickGuestReservationButtonProps) {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [isAvailable, reservation] = useNewReservationGuestAvailable()
  const selectedMaxPersonsCount = useAppSelector(selectSelectedMaxPersonsCount)
  const isNewGuest = useAppSelector(selectReservationIsNewGuest)

  const nextStepHandler = () => {
    if (onNextStep) {
      onNextStep()
    } else {
      if (isNewGuest) {
        dispatch(setReservationStep(ReservationSteps.TWO))
        dispatch(dispatch(setSearchGuestStep(SearchGuestSteps.two)))
      } else {
        dispatch(setReservationStep(ReservationSteps.TWO))
      }
    }
  }

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button
          disabled={!isAvailable}
          variant="primary"
          size="sm"
          className={css.button}
          onClick={
            reservation.personsCount <= selectedMaxPersonsCount
              ? nextStepHandler
              : toggleDialogHandler
          }
          {...props}
        >
          Продолжить
          <Icon name="arrowRight" size={20} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Количество гостей превышает количество мест
          </AlertDialogTitle>
          <AlertDialogDescription>
            Вы&nbsp;можете продолжить бронирование или вернуться назад
            и&nbsp;добавить дополнительный стол
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button onClick={nextStepHandler}>Продолжить</Button>
          </AlertDialogAction>
          <AlertDialogCancel onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
