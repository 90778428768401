export const phone = [
  {
    id: 9,
    visit_count: 16,
    duration: '00:59:06.600000',
    this_month_visits_count: 16,
    last_visit_date: '2023-09-25T14:24:49+03:00',
    canceled_visits: 1,
    status: 'premium',
    tags: [
      {
        slug: 'premium',
        name: 'Премиум',
        color: '#342B66',
        category: 'status'
      }
    ],
    first_name: 'Торнике',
    last_name: 'Двалашвили',
    created_at: '2023-08-30T22:17:11.311139+03:00',
    updated_at: '2023-09-25T11:27:34.250104+03:00',
    phone: '79653855126'
  },
  { phone: '79654555128' }
]
