import {
  selectSearchGuestValue,
  setSearchGuestValue
} from '@/entities/search-guests'
import { Guest } from '@/features/guest'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { SearchInput } from '@/shared/ui'

export default function MobileGuestsFooter() {
  const value = useAppSelector(selectSearchGuestValue)
  const dispatch = useAppDispatch()

  return (
    <SearchInput
      placeholder="поиск"
      variant="primary"
      value={value}
      onChange={(value) => dispatch(setSearchGuestValue(value))}
      isCancelButton
      renderFilters={() => <Guest.MobileFilters />}
    />
  )
}
