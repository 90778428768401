import { useState } from 'react'

import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { Loading, ScrollArea } from '@/shared/ui'

import { CommentActionsPopover } from './CommentActionsPopover'
import { SendCommentForm } from './SendCommentForm'

import Comment from '../Comment/Comment'

import css from './CommentsList.module.css'

function EmptyPlaceholder() {
  return <div className={css.placeholder}>Пока нет комментариев</div>
}

function WrapperCard(props: ItemProps<UserComment>) {
  return <div {...props} className={css['wrapper-card']} />
}

export default function CommentsList({
  placeholder,
  comments,
  isDeleteLoading,
  isSendLoading,
  isUpdateLoading,
  deleteCommentHandler,
  sendCommentHandler,
  updateCommentHandler
}: {
  placeholder: string
  comments?: UserComment[]
  deleteCommentHandler: (commentId: number) => Promise<void>
  isSendLoading?: boolean
  isUpdateLoading?: boolean
  isDeleteLoading?: boolean
  sendCommentHandler: (text: string) => Promise<void>
  updateCommentHandler: (commentId: number, text: string) => Promise<void>
}) {
  const [scrollParent, setScrollParent] = useState<HTMLDivElement | null>(null)
  const [commentToEdit, setCommentToEdit] = useState<UserComment | undefined>()

  return (
    <div className={css.comments}>
      {!comments ? (
        <Loading />
      ) : (
        <>
          <ScrollArea
            isEmptyVirtualList={comments.length === 0}
            setRefViewport={setScrollParent}
          >
            <Virtuoso
              totalCount={comments.length}
              defaultItemHeight={68}
              data={comments}
              initialTopMostItemIndex={comments.length}
              followOutput
              firstItemIndex={comments.length}
              components={{ Item: WrapperCard, EmptyPlaceholder }}
              itemContent={(_, comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  actionsPopoverSlot={
                    <CommentActionsPopover
                      comment={comment}
                      isDeleteLoading={isDeleteLoading}
                      deleteCommentHandler={deleteCommentHandler}
                      setCommentToEdit={setCommentToEdit}
                    />
                  }
                />
              )}
              customScrollParent={scrollParent ?? undefined}
            />
          </ScrollArea>
          <SendCommentForm
            placeholder={placeholder}
            isUpdateLoading={isUpdateLoading}
            isSendLoading={isSendLoading}
            sendCommentHandler={sendCommentHandler}
            updateCommentHandler={updateCommentHandler}
            commentToEdit={commentToEdit}
            setCommentToEdit={setCommentToEdit}
          />
        </>
      )}
    </div>
  )
}
