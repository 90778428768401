/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import {
  endOfDay,
  isEqual,
  isSameDay,
  startOfDay,
  startOfWeek,
  sub,
  subDays,
  subMonths,
  subYears
} from 'date-fns'
import { type DateRange } from 'react-day-picker'

import { nowWithTimezone } from '@/shared/lib'
import { Button } from '@/shared/ui'

import css from './Templates.module.css'

interface TemplatesProps {
  dateInterval?: DateRange
  setInterval?: (range?: DateRange) => void
}

export const TEMPLATES = [
  {
    label: 'Сегодня',
    from: startOfDay(nowWithTimezone()),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: 'Вчера',
    from: subDays(startOfDay(nowWithTimezone()), 1),
    to: subDays(endOfDay(nowWithTimezone()), 1)
  },
  {
    label: 'Эта неделя',
    from: startOfWeek(nowWithTimezone(), { weekStartsOn: 1 }),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: '7 дней',
    from: subDays(startOfDay(nowWithTimezone()), 6),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: '14 дней',
    from: subDays(startOfDay(nowWithTimezone()), 13),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: '30 дней',
    from: subDays(startOfDay(nowWithTimezone()), 29),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: 'Квартал',
    from: subMonths(startOfDay(nowWithTimezone()), 3),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: 'Год',
    from: subYears(startOfDay(nowWithTimezone()), 1),
    to: endOfDay(nowWithTimezone())
  },
  {
    label: 'Все время',
    from: undefined,
    to: undefined
  }
]

export function Templates({ dateInterval, setInterval }: TemplatesProps) {
  const isToday =
    (dateInterval?.from &&
      isSameDay(dateInterval?.from, nowWithTimezone()) &&
      dateInterval?.to === undefined) ||
    (dateInterval?.from &&
      dateInterval?.to &&
      isSameDay(dateInterval?.from, nowWithTimezone()) &&
      isSameDay(dateInterval?.to, nowWithTimezone()))
  const isYesterday =
    (dateInterval?.from &&
      isSameDay(dateInterval?.from, sub(nowWithTimezone(), { days: 1 })) &&
      dateInterval?.to === undefined) ||
    (dateInterval?.from &&
      dateInterval?.to &&
      isSameDay(dateInterval?.from, sub(nowWithTimezone(), { days: 1 })) &&
      isSameDay(dateInterval?.to, sub(nowWithTimezone(), { days: 1 })))

  return (
    <div className={css.container}>
      {TEMPLATES.map((template) => (
        <Button
          key={template.label}
          size="xs"
          variant={
            (dateInterval?.from &&
              template?.from &&
              dateInterval?.to &&
              template?.to &&
              isEqual(
                startOfDay(dateInterval.from),
                startOfDay(template.from)
              ) &&
              isEqual(startOfDay(dateInterval.to), startOfDay(template.to))) ||
            (dateInterval?.from &&
              template?.from &&
              isSameDay(dateInterval.from, template?.from) &&
              (isToday || isYesterday))
              ? 'white'
              : 'secondary'
          }
          className={css.button}
          onClick={() => {
            if (
              (dateInterval?.from &&
                template?.from &&
                dateInterval?.to &&
                template?.to &&
                isEqual(
                  startOfDay(dateInterval.from),
                  startOfDay(template.from)
                ) &&
                isEqual(
                  startOfDay(dateInterval.to),
                  startOfDay(template.to)
                )) ||
              (dateInterval?.from &&
                template?.from &&
                isSameDay(dateInterval.from, template?.from) &&
                (isToday || isYesterday))
            ) {
              setInterval?.({
                from: undefined,
                to: endOfDay(nowWithTimezone())
              })
            } else {
              setInterval?.({ from: template.from, to: template.to })
            }
          }}
        >
          {template.label}
        </Button>
      ))}
    </div>
  )
}
