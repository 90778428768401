import { useState } from 'react'

import { cx } from 'class-variance-authority'

import {
  selectSearch,
  selectTimelineSort,
  setSearch
} from '@/entities/timeline'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  SearchInput,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'

import { SortVaul } from './Sort/SortVaul'

import css from './MobileMapFilter.module.css'

export function MobileMapFilter() {
  const search = useAppSelector(selectSearch)
  const dispatch = useAppDispatch()
  const [openSort, setOpenSort] = useState<boolean>(false)
  const selectSortType = useAppSelector(selectTimelineSort)

  return (
    <div className={css.container}>
      <SearchInput
        placeholder="№ столика"
        variant="primary"
        value={search}
        onChange={(value) => dispatch(setSearch(value))}
      />
      <Vaul open={openSort} onOpenChange={setOpenSort}>
        <VaulTrigger asChild>
          <Button
            variant="tertiary"
            size="icon"
            className={cx(css.button__sort, {
              [css.button__sort_selected]: Object.values(selectSortType).some(
                (value) => !value.startsWith('default')
              )
            })}
          >
            <Icon name="filter" size={20} />
          </Button>
        </VaulTrigger>
        <VaulContent>
          <SortVaul setOpen={setOpenSort} />
        </VaulContent>
      </Vaul>
    </div>
  )
}
