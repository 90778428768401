import { useEffect, useState } from 'react'

import {
  selectReservationsSortDate,
  selectReservationsSortTable
} from '@/entities/reservations/model/selectors'
import {
  setReservationsSortDate,
  setReservationsSortTable
} from '@/entities/reservations/model/slice'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, VaulBody, VaulFooter, VaulHeader } from '@/shared/ui'

import { SortVariantsEnum } from '../../lib/const'
import {
  getSortDateAdapterBackToFront,
  getSortDateAdapterFrontToBack,
  getSortTableAdapterBackToFront,
  getSortTableAdapterFrontToBack
} from '../../lib/utils'

import css from './SortVaul.module.css'

type SortVaulProps = {
  setOpen: (value: boolean) => void
}

export function SortVaul({ setOpen }: SortVaulProps) {
  const selectSortDate = useAppSelector(selectReservationsSortDate)
  const selectSortTable = useAppSelector(selectReservationsSortTable)
  const [sortDate, setSortDate] = useState<SortVariantsEnum>(
    getSortDateAdapterBackToFront(selectSortDate)
  )
  const [sortTable, setSortTable] = useState<SortVariantsEnum>(
    getSortTableAdapterBackToFront(selectSortTable)
  )
  const dispatch = useAppDispatch()

  const onImprove = () => {
    dispatch(setReservationsSortDate(getSortDateAdapterFrontToBack(sortDate)))
    dispatch(
      setReservationsSortTable(getSortTableAdapterFrontToBack(sortTable))
    )
    setOpen(false)
  }

  useEffect(() => {
    if (sortDate !== SortVariantsEnum.NONE) {
      setSortTable(SortVariantsEnum.NONE)
    }
  }, [sortDate])

  useEffect(() => {
    if (sortTable !== SortVariantsEnum.NONE) {
      setSortDate(SortVariantsEnum.NONE)
    }
  }, [sortTable])

  return (
    <>
      <VaulHeader>
        <h4 className={css.header}>Сортировка</h4>
      </VaulHeader>
      <VaulBody className={css.body}></VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button}
          variant="primary"
          size="lg"
          onClick={onImprove}
        >
          Применить
        </Button>
      </VaulFooter>
    </>
  )
}
