import { wsInstance as ws } from './initWS'

export function sendToWebSocket(
  address: number,
  message: string,
  pathname: string
) {
  const socket = ws(`${pathname}/${address}/`)
  if (socket.readyState === WebSocket.OPEN) {
    socket.send(message)
    return
  }

  socket.addEventListener('open', () => {
    socket.send(message)
  })
}
