import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { mailingsApi } from '@/entities/mailing'

import { FilterSelectStrategy, type FilterTypesEnum } from './filterEnums'

import { filters } from '../../../../mock/filters'
import { filtersApi } from '../api/filtersApi'

type FiltersState = {
  openFilter: boolean
  selectedFilters: GroupedFilters
  initFiltersData: GroupedFilters
  clearedFiltersData: GroupedFilters
}

const initialState: FiltersState = {
  openFilter: false,
  selectedFilters: {},
  initFiltersData: {},
  clearedFiltersData: {}
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    switchFilter: (state) => {
      state.openFilter = !state.openFilter
    },
    closeFilter: (state) => {
      state.openFilter = false
    },
    changeFiltersData: (
      state,
      {
        payload
      }: PayloadAction<{
        type: string
        name: string
        value: string
        strategy: FilterSelectStrategyType
      }>
    ) => {
      const filter = state.selectedFilters[payload.type][payload.name]
      if (!filter) return

      let newValue: string[] = []

      switch (payload.strategy) {
        case FilterSelectStrategy.single:
          newValue = []
          break
        case FilterSelectStrategy.multi:
          newValue = [...filter]
          break
      }

      if (filter.includes(payload.value)) {
        newValue = newValue.filter((i) => i !== payload.value)
      } else newValue.push(payload.value)

      state.selectedFilters[payload.type][payload.name] = newValue
    },
    resetFilter: (
      state,
      {
        payload
      }: PayloadAction<{
        type: string
        name: string
      }>
    ) => {
      state.selectedFilters[payload.type][payload.name] = []
    },
    resetAllCurFilters: (
      state,
      { payload }: PayloadAction<FilterTypesEnum>
    ) => {
      // state.selectedFilters[payload] = state.initFiltersData[payload]
      state.selectedFilters[payload] = state.clearedFiltersData[payload]
    },
    resetAllFilters: (state) => {
      state.selectedFilters = state.initFiltersData
    }
  },
  extraReducers: (builder) => {
    const addFilters = (
      state: FiltersState,
      { payload }: PayloadAction<OldFilter[]>
    ) => {
      payload.forEach((filter) => {
        const filterTypeExists = state.initFiltersData[filter.type]

        const newFilters = Object.fromEntries([
          [filter.name, filter.defaultValue]
        ])

        const clearedFilter = Object.fromEntries([[filter.name, []]])

        state.clearedFiltersData[filter.type] = {
          ...state.clearedFiltersData[filter.type],
          ...clearedFilter
        }

        if (filterTypeExists) {
          state.initFiltersData[filter.type] = {
            ...state.initFiltersData[filter.type],
            ...newFilters
          }
        } else {
          state.initFiltersData[filter.type] = newFilters
        }
      })
      state.selectedFilters = {
        ...state.initFiltersData,
        ...state.selectedFilters
      }
    }

    const addMockFilters = (state: FiltersState) => {
      // TODO: just mock filters for mailing page, remove later
      addFilters(state, { payload: filters } as PayloadAction<OldFilter[]>)
    }

    builder
      .addMatcher(filtersApi.endpoints.filters.matchFulfilled, addFilters)
      .addMatcher(mailingsApi.endpoints.mailings.matchFulfilled, addMockFilters)
      .addMatcher(mailingsApi.endpoints.mailing.matchFulfilled, addMockFilters)
  }
})

export const {
  changeFiltersData,
  resetFilter,
  resetAllFilters,
  resetAllCurFilters,
  switchFilter,
  closeFilter
} = filtersSlice.actions
