import { useLayoutEffect } from 'react'

import { isPWA } from '@/shared/lib'

export function PWAProvider() {
  useLayoutEffect(() => {
    if (isPWA()) {
      document.body.style.setProperty(
        '--mobile-footer-height',
        'var(--mobile-footer-height-pwa)'
      )
      document.body.style.setProperty(
        '--vaul-footer-height',
        'var(--vaul-footer-height-pwa)'
      )
    }
  }, [])

  return null
}
