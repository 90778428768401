import { type ReactElement, useLayoutEffect } from 'react'

import * as qs from 'querystringify'

import { clearSessionData, setAuthFromQsToken } from '@/entities/session'
import { useAppDispatch } from '@/shared/model'

type DemoProviderProps = {
  children: ReactElement
}

export function DemoProvider({ children }: DemoProviderProps) {
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    const searchParams = window.location.search

    if (
      searchParams &&
      window.location.pathname.startsWith(import.meta.env.VITE_BASE_PATH)
    ) {
      const params = qs.parse(searchParams.slice(1)) as {
        demo: string
      }
      if (params.demo === import.meta.env.VITE_DEMO_TOKEN) {
        dispatch(clearSessionData())
        dispatch(setAuthFromQsToken(`Staff ${params.demo}`))
      }
    }
  }, [dispatch])
  return children
}
