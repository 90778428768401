import { toast } from 'react-toastify'
import { type ErrorEvent } from 'reconnecting-websocket'

import { config } from '@/app/config'
import { clearSessionData } from '@/entities/session'
import { baseApi, getTz, replaceDateTimezones, ws } from '@/shared/api'
import { GlobalWsEvents } from '@/shared/lib'

import { TelephonyWsEvents } from './telephonyWsEvents'

const { FEATURE_FLAG_LOGS } = config

export const telephonyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    telephonyWs: builder.query<ResponseTelephonyWS[][], RequestTelephonyWS>({
      queryFn: async () => ({ data: [] }),
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          dispatch,
          getState
        }
      ) {
        await cacheDataLoaded

        const {
          session: { accessToken }
        } = getState() as RootState

        const tz = getTz()

        const socket = ws(`voip-calls/${arg.addressId}/`)

        try {
          const listener = (e: MessageEvent) => {
            const { type, data, detail } = JSON.parse(e.data)

            if (tz) replaceDateTimezones(data, tz)

            switch (type) {
              case TelephonyWsEvents.GetCalls: {
                updateCachedData((draft) => {
                  draft.push(data)
                })
                break
              }
              case GlobalWsEvents.Error: {
                if (detail === 'Invalid token') {
                  toast.error('Ошибка авторизации')
                  dispatch(clearSessionData())
                } else {
                  toast.error(detail)
                }
                break
              }
            }
          }

          const openListener = () => {
            console.log('Соединение установлено')
            const authMessage = JSON.stringify({
              type: GlobalWsEvents.Auth,
              data: {
                authorization: accessToken
              }
            })
            socket.send(authMessage)
          }

          const closeListener = () => {
            console.log('Соединение закрыто')
          }

          const errorListener = (error: ErrorEvent) => {
            if (FEATURE_FLAG_LOGS) {
              toast.error(JSON.stringify(error, null, 2))
            }
            console.log(`Ошибка: ${error.message}`)
          }

          socket.addEventListener('open', openListener)
          socket.addEventListener('close', closeListener)
          socket.addEventListener('error', errorListener)
          socket.addEventListener('message', listener)
        } catch {}
        await cacheEntryRemoved
        console.log('cache removed, socket telephony closed')
        socket.close()
      }
    })
  })
})

export const { useTelephonyWsQuery } = telephonyApi
