import { formatInTimeZone } from 'date-fns-tz'

function isDateString(str: string) {
  const parsedDate = Date.parse(str)
  return !isNaN(parsedDate) && str.length >= 20
}

function replaceTimezone(
  dateString: string,
  tz: string,
  options?: { withSeconds?: boolean }
) {
  const date = new Date(dateString)
  return formatInTimeZone(
    date,
    tz,
    `yyyy-MM-dd HH:mm${options?.withSeconds ? ':ss' : ''}`
  )
}

export function replaceDateTimezones(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  tz: string,
  options?: { withSeconds?: boolean }
) {
  for (const key in data) {
    if (typeof data[key] === 'object') {
      replaceDateTimezones(data[key], tz, options)
    } else if (typeof data[key] === 'string' && isDateString(data[key])) {
      data[key] = replaceTimezone(data[key], tz, options)
    }
  }
}
