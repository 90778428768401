import { Suspense } from 'react'

import '@/shared/styles/base.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/dist/style.css'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider } from '@/entities/theme'
import { Loading } from '@/shared/ui'

import { appStore, persistedStore } from './appStore'
import { NODE_ENV, SENTRY_DSN, TOAST_CLOSE_TIME } from './config'
import { CustomRouterProvider, DemoProvider, PWAProvider } from './providers'
import { DynamicViewportUnits } from './utils'

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [],
  enabled: NODE_ENV === 'production'
})

const root = document.getElementById('root') as HTMLElement

ReactDOM.createRoot(root).render(
  <ReduxProvider store={appStore}>
    <PersistGate loading={null} persistor={persistedStore}>
      <DynamicViewportUnits />
      <PWAProvider />
      <ThemeProvider>
        <DemoProvider>
          <Suspense fallback={<Loading fullscreen />}>
            <Sentry.ErrorBoundary fallback={<p>Ошибка</p>}>
              <CustomRouterProvider />
            </Sentry.ErrorBoundary>
          </Suspense>
        </DemoProvider>
      </ThemeProvider>
      <ToastContainer autoClose={TOAST_CLOSE_TIME} />
    </PersistGate>
  </ReduxProvider>
)
