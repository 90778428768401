import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { guestApi } from '../api/guestApi'

export enum GuestPageSteps {
  VIEW,
  EDIT
}

type GuestFilterState = {
  step: GuestPageSteps
  isCreateGuest: boolean
}

const initialState: GuestFilterState = {
  step: GuestPageSteps.VIEW,
  isCreateGuest: false
}

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestPageStep(state, { payload }: PayloadAction<GuestPageSteps>) {
      state.step = payload
    },
    setIsCreateGuest(state, { payload }: PayloadAction<boolean>) {
      state.isCreateGuest = payload
    },
    resetGuestPageRecord: () => initialState
  },
  extraReducers: (builder) => {
    const setCreate = (state: GuestFilterState) => {
      state.isCreateGuest = true
    }

    builder.addMatcher(guestApi.endpoints.createGuest.matchFulfilled, setCreate)
    builder.addMatcher(guestApi.endpoints.deleteGuest.matchFulfilled, setCreate)
  }
})

export const { setGuestPageStep, resetGuestPageRecord, setIsCreateGuest } =
  guestSlice.actions

export const selectGuestPageStep = (state: RootState) => state.guest.step
