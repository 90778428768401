/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type Dispatch, type SetStateAction } from 'react'

import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  MobileReservationSteps,
  selectReservationComments,
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationGuest,
  selectReservationPersonsCount,
  selectReservationStart,
  selectReservationTables,
  selectReservationTags,
  setMobileReservationStep
} from '@/entities/create-update-reservation'
import { selectSelectedHallWithInfo } from '@/entities/halls'
import {
  MobileNewReservationTagsAndComments,
  ReservationCard,
  useCreateReservationMutation
} from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { getTz } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Icon, VaulBody } from '@/shared/ui'

import { ReservationProcessFooter } from '../ReservationProcessFooter'
import { ReservationProcessHeader } from '../ReservationProcessHeader'

import css from './index.module.css'

type MobileReservationInfoStepProps = {
  setOpen?: Dispatch<SetStateAction<boolean>>
}

export default function MobileReservationInfoStep({
  setOpen
}: MobileReservationInfoStepProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedGuest = useAppSelector(selectReservationGuest)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const selectedPersonsCount = useAppSelector(selectReservationPersonsCount)
  const selectedTables = useAppSelector(selectReservationTables)
  const selectedHall = useAppSelector(selectSelectedHallWithInfo)
  const tags = useAppSelector(selectReservationTags)
  const comments = useAppSelector(selectReservationComments)

  const tz = getTz()

  const [createReservation, { isLoading }] = useCreateReservationMutation()

  if (
    !selectedGuest ||
    !selectedStart ||
    !selectedPersonsCount ||
    !selectedHall ||
    selectedEnd === undefined
  ) {
    return null
  }

  const backHandler = () => {
    dispatch(setMobileReservationStep(MobileReservationSteps.THREE))
  }

  const submitHandler = async () => {
    if (!addressId || !isSubmitAvailable || !tz) return

    const body: RequestNewReservation = {
      start_date: format(selectedStart, 'yyyy-MM-dd HH:mm'),
      end_date: selectedEnd
        ? format(selectedEnd, 'yyyy-MM-dd HH:mm')
        : undefined,
      persons_count: selectedPersonsCount,
      places: selectedTables.map((table) => table.id),
      guest: selectedGuest.id,
      source: 'book',
      tags: tags?.map((tag) => tag.id) as number[],
      comments: comments?.map((comment) => comment.text)
    }
    const res = await createReservation({ addressId, body })

    if ('data' in res) {
      if (res.data.comments?.length && res.data.tags?.length) {
        toast.success('Бронь c комментариями и тегами успешно создана !')
      } else if (res.data.comments?.length) {
        toast.success('Бронь c комментариями успешно создана !')
      } else if (res.data.tags?.length) {
        toast.success('Бронь c тегами успешно создана !')
      } else {
        toast.success('Бронь успешно создана !')
      }

      setOpen?.(false)
      navigate('/')
    }
  }

  const isSubmitAvailable =
    selectedGuest &&
    selectedDate &&
    selectedStart &&
    (selectedEnd || selectedEnd === null) &&
    (selectedDuration || selectedDuration === null) &&
    selectedPersonsCount &&
    selectedTables.length > 0

  return (
    <>
      <ReservationProcessHeader
        backHandler={backHandler}
        title="Предпросмотр брони"
      />
      <VaulBody className={css.step}>
        <ReservationCard
          guest={selectedGuest}
          personsCount={selectedPersonsCount}
          startDate={selectedStart}
          mainTableNumber={selectedTables
            .map((table) => table.item_number)
            .join(', ')}
          source="book"
          link={null}
          tags={tags ?? []}
          endDate={selectedEnd}
        />
        <MobileNewReservationTagsAndComments />
        <ReservationProcessFooter
          onClick={submitHandler}
          isLoading={isLoading}
          disabled={!isSubmitAvailable || isLoading}
        >
          Забронировать
          <Icon name="check" />
        </ReservationProcessFooter>
      </VaulBody>
    </>
  )
}
