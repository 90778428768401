import { useNavigate } from 'react-router-dom'

import { resetReservation } from '@/entities/create-update-reservation'
import { selectCanEditAddress } from '@/entities/session'
import { PATHS } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, type ButtonStyleProps, Icon } from '@/shared/ui'

type InitNewReservationButtonProps = ButtonStyleProps & {
  text?: string
  extraHandler?: () => void
}

export function InitNewReservationButton({
  extraHandler,
  text = 'Создать бронь'
}: InitNewReservationButtonProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const canEditAddress = useAppSelector(selectCanEditAddress)

  const initReservationHandler = () => {
    dispatch(resetReservation())
    extraHandler?.()
    navigate(PATHS.newReservation)
  }

  return (
    <Button
      variant="primary"
      onClick={initReservationHandler}
      disabled={!canEditAddress}
    >
      {text}
      <Icon name="plus" size={20} />
    </Button>
  )
}
