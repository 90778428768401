import { type Dispatch, type SetStateAction, useState } from 'react'

import {
  Button,
  Icon,
  Textarea,
  VaulBody,
  VaulFooter,
  VaulHeader
} from '@/shared/ui'

import css from './TextAreaTagsVaul.module.css'

type TextAreaTagsVaulProps = {
  isLoadingCreateTagKit?: boolean
  setIsNewTagPage: Dispatch<SetStateAction<boolean>>
  createTagKitHandle?: (name: string) => Promise<void>
}

export function TextAreaTagsVaul({
  isLoadingCreateTagKit = false,
  setIsNewTagPage,
  createTagKitHandle
}: TextAreaTagsVaulProps) {
  const [value, setValue] = useState<string>('')

  const onImprove = async () => {
    try {
      await createTagKitHandle?.(value)
      setIsNewTagPage(false)
    } catch {
      // do nothing
    }
  }

  return (
    <>
      <VaulHeader>
        <Button
          size="icon"
          className={css.button_back}
          onClick={() => setIsNewTagPage(false)}
        >
          <Icon name="arrowLeft" />
        </Button>
        <h4 className={css.header}>Новый тег</h4>
      </VaulHeader>
      <VaulBody className={css.body}>
        <Textarea
          className={css.textarea}
          placeholder="Введите название нового тега"
          autoResize
          autoFocus
          value={value}
          setValue={setValue}
        />
      </VaulBody>
      <VaulFooter className={css.footer}>
        <Button
          className={css.button_create}
          variant="primary"
          size="lg"
          disabled={!value}
          isLoading={isLoadingCreateTagKit}
          onClick={onImprove}
        >
          Создать
          <Icon name="plus" />
        </Button>
      </VaulFooter>
    </>
  )
}
