import { MobileReservationGuest } from '@/entities/reservation'
import { Reservation } from '@/features/create-update-reservation'
import { Icon, TabContent, VaulBody, VaulFooter } from '@/shared/ui'
import { MobileGuestTagsAndComments } from '@/widgets/guest/MobileGuestTagsAndComments'
import { VisitsInfo } from '@/widgets/guest/VisitsInfo'
import { MobileGuestReservationsList } from '@/widgets/reservations'

import css from './GuestVaulCardContent.module.css'

type GuestVaulCardContentProps = {
  guest: Guest
}

export default function GuestVaulCardContent({
  guest
}: GuestVaulCardContentProps) {
  return (
    <VaulBody className={css.card}>
      <TabContent value="info" className={css.content}>
        <MobileReservationGuest guest={guest} withoutComments isGuestPage />
        <VisitsInfo guest={guest} />
        <MobileGuestTagsAndComments guest={guest} />
      </TabContent>
      <TabContent value="reservations" className={css.content}>
        <div className={css.reservations}>
          <MobileGuestReservationsList guest={guest} />
        </div>
      </TabContent>
      <VaulFooter>
        <Reservation.MobileInit
          className={css.button}
          size="lg"
          variant="primary"
          guest={guest}
        >
          <Icon name="bookmark" />
          Забронировать
        </Reservation.MobileInit>
      </VaulFooter>
    </VaulBody>
  )
}
