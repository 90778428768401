import { createAsyncThunk } from '@reduxjs/toolkit'

import { clearSessionData, sessionApi } from '@/entities/session'
import { PROFILE_DATA_TAG, SESSION_TAG } from '@/shared/api'
import { nextTick } from '@/shared/lib'

export const logoutThunk = createAsyncThunk<void, void, { state: RootState }>(
  'authentication/logout',
  async (_: unknown, { dispatch }) => {
    dispatch(clearSessionData())

    // Wait 10ms to invalidateTags in next event loop tick.
    // Otherwise after invalidate related requests with SESSION_TAG
    // will be started, but isAuthorized will still be equal to true
    await nextTick()

    // 👇 ATTENTION: This line clear all baseApi state instead of sessionApi
    // dispatch(sessionApi.util.resetApiState())

    dispatch(sessionApi.util.invalidateTags([SESSION_TAG, PROFILE_DATA_TAG]))
  }
)
