import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import * as RadixCheckbox from '@radix-ui/react-checkbox'
import { cva, type VariantProps } from 'class-variance-authority'

import { Icon } from '../Icon'

import css from './Checkbox.module.css'

const checkboxStyle = cva(css.checkbox, {
  variants: {
    variant: {
      default: css.checkbox_variantDefault,
      primary: css.checkbox_variantPrimary
    },
    size: {
      sm: css.checkbox_sizeSm
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'sm'
  }
})

const indicatorStyle = cva(css.indicator, {
  variants: {
    variant: {
      default: css.indicator_variantDefault,
      primary: css.indicator_variantPrimary
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

export type CheckboxStyleProps = VariantProps<typeof checkboxStyle>

type CheckboxProps = PropsWithChildren &
  ComponentPropsWithoutRef<typeof RadixCheckbox.Root> &
  CheckboxStyleProps & {
    classNameIndicator?: string
  }

export function Checkbox({
  children,
  variant,
  size,
  className,
  classNameIndicator,
  id,
  checked = false,
  ...props
}: CheckboxProps) {
  return (
    <RadixCheckbox.Root
      className={checkboxStyle({
        variant,
        size,
        className
      })}
      id={id}
      checked={checked}
      {...props}
    >
      <RadixCheckbox.Indicator
        className={indicatorStyle({ variant, className: classNameIndicator })}
      >
        <Icon name="check" size={16} />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  )
}
