import { parseISO } from 'date-fns'

export const mapReservationsTables = (
  tables: ResponseTimelineTable[]
): TransformedNewReservations => {
  const reservations = [] as TransformedNewReservations

  tables?.forEach((table) => {
    table.reservations
      .filter((reservation) => reservation.status !== 'unconfirmed')
      .forEach((reservation) => {
        reservations.push({
          ...reservation,
          table: table.id,
          start_date: parseISO(reservation.start_date),
          end_date: parseISO(reservation.end_date)
        })
      })
  })
  return reservations
}
