import { useEffect, useState } from 'react'

import { nowWithTimezone } from '../lib'

const useClock = (): Date => {
  const [time, setTime] = useState(nowWithTimezone())

  useEffect(() => {
    const timerId = setInterval(() => setTime(nowWithTimezone()), 5000)

    return () => {
      clearInterval(timerId)
    }
  }, [])

  return time
}

export default useClock
