enum SortVariantsEnum {
  DOWN,
  UP,
  NONE
}

enum TypeFilterButtonEnum {
  Status = 'status',
  GuestTag = 'guest_tag',
  Source = 'source'
}

export { SortVariantsEnum, TypeFilterButtonEnum }
