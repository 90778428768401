export const mapHalls = (halls: RawHall[]): Hall[] => {
  return halls
    .filter((hall) => hall.is_published)
    ?.map(
      ({
        id,
        name,
        places_count,
        places_free_count,
        configurations,
        schedule
      }) => ({
        count: `${places_free_count}/${places_count}`,
        id,
        name,
        configurations,
        schedule
      })
    )
}
