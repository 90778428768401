import { add, format, isSameDay } from 'date-fns'

import { nowWithTimezone } from './nowWithTimezone'

export const formatDateLabel = (date: Date) => {
  const now = nowWithTimezone()

  const isToday = isSameDay(date, now)
  const isTomorrow = isSameDay(date, add(now, { days: 1 }))

  let label = format(date, 'dd.MM.yyyy')

  if (isToday) {
    label = `Сегодня, ${label}`
  }

  if (isTomorrow) {
    label = `Завтра, ${label}`
  }
  return label
}
