import { type ComponentPropsWithoutRef } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import css from './SwiperPointer.module.css'

const pointerStyle = cva(css.pointer, {
  variants: {
    isLong: {
      true: css.pointer_long,
      false: ''
    }
  },
  defaultVariants: {
    isLong: false
  }
})

type SwiperPointerProps = VariantProps<typeof pointerStyle> &
  ComponentPropsWithoutRef<'div'>

export default function SwiperPointer({
  isLong,
  className,
  ...props
}: SwiperPointerProps) {
  return <div className={pointerStyle({ isLong, className })} {...props} />
}
