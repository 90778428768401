import * as React from 'react'

import * as ScrollAreaUI from '@radix-ui/react-scroll-area'
import { cva, cx, type VariantProps } from 'class-variance-authority'

import { useIsTouchScreen } from '@/shared/hooks'

import css from './ScrollArea.module.css'

const scrollbarStyle = cva(css.scrollbar, {
  variants: {
    size: {
      md: css.scrollbar_sizeMd,
      default: css.scrollbar_sizeDefault
    },
    padding: {
      default: css.scrollbar_paddingDefault,
      md: css.scrollbar_paddingMd,
      xl: css.scrollbar_paddingXl,
      xxl: css.scrollbar_paddingXXl,
      lg: css.scrollbar_paddingLg
    }
  },
  defaultVariants: {
    size: 'default',
    padding: 'default'
  }
})

const thumbStyle = cva(css.thumb, {
  variants: {
    variant: {
      default: css.thumb_variantDefault,
      black: css.thumb_variantBlack
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type ScrollBarRef = React.ElementRef<typeof ScrollAreaUI.ScrollAreaScrollbar>

type ScrollBarProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaUI.ScrollAreaScrollbar
> &
  ThumbStyleProps &
  ScrollbarStyleProps & {
    scrollBarClassName?: string
  }

export type ScrollbarStyleProps = VariantProps<typeof scrollbarStyle>
export type ThumbStyleProps = VariantProps<typeof thumbStyle>

const ScrollBar = React.forwardRef<ScrollBarRef, ScrollBarProps>(
  (
    {
      variant,
      size,
      padding,
      orientation = 'vertical',
      scrollBarClassName,
      ...props
    },
    ref
  ) => (
    <ScrollAreaUI.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={scrollbarStyle({
        size,
        padding,
        className: scrollBarClassName
      })}
      {...props}
    >
      <ScrollAreaUI.ScrollAreaThumb
        data-vaul-no-drag
        className={thumbStyle({ variant })}
      />
    </ScrollAreaUI.ScrollAreaScrollbar>
  )
)
ScrollBar.displayName = 'ScrollBar'

type ScrollAreaRef = React.ElementRef<typeof ScrollAreaUI.Root>

type ScrollAreaProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaUI.Root
> &
  ThumbStyleProps &
  ScrollbarStyleProps & {
    orientation?: 'horizontal' | 'vertical' | 'both'
    viewPortClassName?: string
    scrollBarClassName?: string
    setRefViewport?:
      | React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
      | React.RefObject<HTMLDivElement>
    isEmptyVirtualList?: boolean
  }

const ScrollArea = React.forwardRef<ScrollAreaRef, ScrollAreaProps>(
  (
    {
      variant,
      size,
      padding,
      className,
      children,
      orientation = 'vertical',
      viewPortClassName,
      scrollBarClassName,
      isEmptyVirtualList = false,
      setRefViewport,
      ...props
    },
    ref
  ) => {
    const { isTouchScreen } = useIsTouchScreen()

    if (isEmptyVirtualList) {
      return children
    }

    return (
      <ScrollAreaUI.Root
        ref={ref}
        className={cx(css.root, className)}
        {...props}
        type={isTouchScreen ? 'scroll' : 'hover'}
      >
        <ScrollAreaUI.Viewport
          ref={setRefViewport}
          className={cx(css.viewport, viewPortClassName)}
        >
          {children}
        </ScrollAreaUI.Viewport>
        {orientation === 'horizontal' && (
          <ScrollBar
            orientation="horizontal"
            variant={variant}
            size={size}
            padding={padding}
            scrollBarClassName={scrollBarClassName}
          />
        )}
        {orientation === 'vertical' && (
          <ScrollBar
            orientation="vertical"
            variant={variant}
            size={size}
            padding={padding}
            scrollBarClassName={scrollBarClassName}
          />
        )}
        {orientation === 'both' && (
          <>
            <ScrollBar
              orientation="horizontal"
              variant={variant}
              size={size}
              padding={padding}
              scrollBarClassName={scrollBarClassName}
            />
            <ScrollBar
              orientation="vertical"
              variant={variant}
              size={size}
              padding={padding}
              scrollBarClassName={scrollBarClassName}
            />
          </>
        )}
        <ScrollAreaUI.Corner />
      </ScrollAreaUI.Root>
    )
  }
)
ScrollArea.displayName = 'ScrollArea'

export { ScrollArea, ScrollBar }
