import {
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'

import { phone } from '../../../../mock/phone'

type PhoneState = {
  isShowButton: boolean
  contactsList: PhoneContactList
}

const initialState: PhoneState = {
  isShowButton: false,
  contactsList: phone
}

export const phoneSlice = createSlice({
  name: 'phone',
  initialState,
  reducers: {
    togglePhoneButton: (state) => {
      state.isShowButton = !state.isShowButton
    },
    setPhoneContactList: (state, action: PayloadAction<PhoneContactList>) => {
      state.contactsList = action.payload
    }
  }
})

export const { togglePhoneButton, setPhoneContactList } = phoneSlice.actions

export const selectShowPhoneButton = (state: RootState) =>
  state.phone.isShowButton
export const selectPhoneContactsList = (state: RootState) =>
  state.phone.contactsList
export const selectPhoneContactsCount = createSelector(
  selectPhoneContactsList,
  (list) => list.length
)
